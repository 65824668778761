import React from 'react';
import { Alert } from "@amzn/awsui-components-react";

/**
 * If a user is in more than one IC settlements group, this generic Polaris Alert will alert the end user.
 * The alert is used in the three settlement requests within TRS and thus can be commonized.
 */
export const ldapMembershipAlert = (ic_ldap_overlap) => {
    return (
        <Alert header = "Cannot Use Module">
            This module cannot be used because you are a member of more than one of the following LDAP groups: {ic_ldap_overlap.join(", ")}.
            To use this module, please reach out to the admins so that you are in only one of the LDAP groups listed above.
        </Alert>
    )
};