import React from 'react';
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import {POTENTIAL_EXCEPTIONS} from "../../../config/table";
import {Header, Table} from "@amzn/awsui-components-react";

export const ExceptionCheckModal = (props) => {
    return (
        <Modal
            onDismiss={props.handleDismissExceptionModalClick}
            visible={props.duplicateExceptionVisible}
            expandToFit={true}
            size="max"
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={props.handleDismissExceptionModalClick}>Cancel</Button>
                        <Button variant="primary" onClick={props.handleViewModalClick}>Create Exception</Button>
                    </SpaceBetween>
                </Box>
            }
            header="Duplicate Exception Warning">
            <Table
                columnDefinitions={POTENTIAL_EXCEPTIONS}
                items={props.potentialDuplicatedExceptions}
                stickyHeader={true}
                resizableColumns={true}
                header={<Header variant="h3">Potential Duplicate Exception(s)</Header>}
                wrapLines={true}
            />
        </Modal>
    );
}
