import CONSTANT from "../utils/constant";

export const EXPORT_FILE_360T_HEADER =
    [CONSTANT.TR_COL_CFPRODUCT, CONSTANT.TR_COL_CF_GROUPID, CONSTANT.TR_COL_CF_EXTERNALID,
        CONSTANT.TR_COL_CFLEGALENTITY, CONSTANT.TR_COL_CF_ACTION, CONSTANT.TR_COL_CF_CURRENCY1, CONSTANT.TR_COL_CF_CURRENCY2, CONSTANT.TR_COL_CF_NOTIONALCURRENCY,
        CONSTANT.TR_COL_CFNOTIONALAMOUNT1, CONSTANT.TR_COL_CFNOTIONALAMOUNT2, CONSTANT.TR_COL_CFEFFECTIVEDATE,
        CONSTANT.TR_COL_CF_MATURITYDATE, CONSTANT.TR_COL_CF_LIMITRATE, CONSTANT.TR_COL_CF_STOPRATE, CONSTANT.TR_COL_CF_FIXING, CONSTANT.TR_COL_CF_FIXINGDATE,
        CONSTANT.TR_COL_CF_EDITABLE, 
        //new 14 headers
        CONSTANT.TR_COL_CF_PRODUCT_FIXINGDATE1, CONSTANT.TR_COL_CF_PRODUCT_FIXINGDATE2,
        CONSTANT.TR_COL_CF_PRODUCT_FIXING_REFERENCE, CONSTANT.TR_COL_CF_TWOWAY_PRICING,
        CONSTANT.TR_COL_CF_PROVIDER_LIST, CONSTANT.TR_COL_CF_PREMIUM_CURRENCY,
        CONSTANT.TR_COL_CF_EXPIRY_TIME, CONSTANT.TR_COL_CF_SPOT_RATE,
        CONSTANT.TR_COL_CF_STRIKE_RATE, CONSTANT.TR_COL_CF_DELIVERY_DATE,
        CONSTANT.TR_COL_CF_OPTION_STYLE_TYPE, CONSTANT.TR_COL_CF_OCO_LEG,
        CONSTANT.TR_COL_CF_OFFLINE_SPOT_RATE, CONSTANT.TR_COL_CF_OFFLINE_FORWARD_POINTS,
        
        CONSTANT.TR_COL_CF_MISYS_Beneficiary, CONSTANT.TR_COL_CF_AMZNTT, CONSTANT.TR_COL_CF_AMZNSOURCE, CONSTANT.TR_COL_CF_DESCRIPTION,
        CONSTANT.TR_COL_CF_PAYREF, CONSTANT.TR_COL_CF_PAYDATE, CONSTANT.TR_COL_CF_TRADEIDCR];

export const EXPORT_FILE_360T_DELIMITER = ";";

export const EXPORT_FILE_360T_BOOKTYPE = "csv";


export const EXPORT_FILE_BLOOMBERG_HEADER =
    [CONSTANT.TR_COL_BL_INSTRUMENT, CONSTANT.TR_COL_BL_CURRENCYPAIR, CONSTANT.TR_COL_BL_SIDE, CONSTANT.TR_COL_BL_TT, CONSTANT.TR_COL_BL_AMZNTRADEID,
            CONSTANT.TR_COL_BL_SOURCEACCOUNT, CONSTANT.TR_COL_BL_CURRENCY, CONSTANT.TR_COL_BL_AMOUNT, CONSTANT.TR_COL_BL_SSIKEYWORD, CONSTANT.TR_COL_BL_TRADECODE, CONSTANT.TR_COL_BL_ACCOUNT];

export const EXPORT_FILE_BLOOMBERG_DELIMITER = ";";

export const EXPORT_FILE_BLOOMBERG_BOOKTYPE = "xls";

export const DOWNLOAD_FILE_PAYMENT_DELIMITER = ";";

export const FX_FILE_PAYMENT_HEADER = [CONSTANT.PR_FX_COL_TT_ID, CONSTANT.PR_FX_COL_TRADE_ID, CONSTANT.PR_FX_COL_VALUE_DATE, CONSTANT.PR_FX_COL_SOURCE_ACCOUNT, 
    CONSTANT.PR_FX_COL_NOTIONAL_CCY, CONSTANT.PR_FX_COL_NOTIONAL, CONSTANT.PR_FX_COL_BENEFICIARY, CONSTANT.PR_FX_COL_TRADE_CODE, CONSTANT.PR_FX_COL_TRADE_DETAILS];

export const FX_FILE_PAYMENT_DELIMITER = ",";

export const FX_FILE_PAYMENT_BOOKTYPE = "xls";

export const WIRE_FILE_PAYMENT_HEADER = [
    CONSTANT.PR_WIRE_COL_1,
    CONSTANT.PR_WIRE_COL_2,
    CONSTANT.PR_WIRE_COL_3,
    CONSTANT.PR_WIRE_COL_4,
    CONSTANT.PR_WIRE_COL_5,
    CONSTANT.PR_WIRE_COL_6,
    CONSTANT.PR_WIRE_COL_7,
    CONSTANT.PR_WIRE_COL_8,
    CONSTANT.PR_WIRE_COL_9,
    CONSTANT.PR_WIRE_COL_10,
    CONSTANT.PR_WIRE_COL_11,
    CONSTANT.PR_WIRE_COL_12,
    CONSTANT.PR_WIRE_COL_13,
    CONSTANT.PR_WIRE_COL_14,
    CONSTANT.PR_WIRE_COL_15,
    CONSTANT.PR_WIRE_COL_16,
    CONSTANT.PR_WIRE_COL_17,
    CONSTANT.PR_WIRE_COL_18,
    CONSTANT.PR_WIRE_COL_19 
];

export const WIRE_FILE_PAYMENT_DELIMITER = "|";

export const WIRE_FILE_PAYMENT_BOOKTYPE = "csv";

// E&I Constants
export const DOWNLOAD_FILE_E_AND_I_DELIMITER = ",";
