import React, {FC} from 'react';
import {SideNavigation, SideNavigationProps} from '@amzn/awsui-components-react';
import { arrayIntersection } from '../utils/arrayIntersection';
import CONSTANT, { IC_SETTLEMENTS_LDAP } from "../utils/constant";
import { useNavigate, useLocation } from 'react-router';
import { TRSFeatureProps } from '../model/Model';

/**
 * A functional component that defines the sidebar nagivation within TRS.
 * 
 * @returns {JSX.Element}
 *      The component that defines the navigation pane for the users.
 */
const Navigation: FC<TRSFeatureProps> = (): JSX.Element => {

    const userGroup: string = localStorage.getItem(CONSTANT.USER_GROUP_NAME) ?? '';
    const header: SideNavigationProps.Header = {text: 'Menu', href: '#/'};
    const items: SideNavigationProps.Section[] = [];
    const nav = useNavigate();
    const location = useLocation();
    
    // The sidebar items displayed under the "Trade Request" dropdown is defined here along
    // with its corresponding endpoints.
    // Only the PROD admins and the FX team should be able to access the Trade Dashboards.
    if (arrayIntersection([CONSTANT.USER_GROUP_REVIEWER, CONSTANT.USER_GROUP_PROD_ADMIN], userGroup).length > 0) {
        items.push({
            type: 'section',
            text: 'Trade Request',
            items: [
                {type: 'link', text: 'Open Trades', href: '/QueryTrade'},
                {type: 'link', text: 'Historical Trades', href: '/QueryHistoryTradeData'},
                {type: 'link', text: 'Trade Dashboards', href: '/Dashboards/FX'}
            ]
        });
    }

    // The sidebar items displayed under the "Settlement Request" dropdown is defined here along
    // with its corresponding endpoints. Only accessible if you are in one of the following groups:
    // TTECH-TRADE-CASH-POSITIONER-ACCESS, TTECH-TRADE-INTERCOMPANY-ACCESS,
    // TTECH-SETTLEMENT-HISTORICAL-GENERAL-ACCESS, or tbo-trs-payment-authorizers.
    if (arrayIntersection([...IC_SETTLEMENTS_LDAP, CONSTANT.USER_GROUP_PROD_ADMIN], userGroup).length > 0) {
        items.push({
            type: 'section',
            text: 'Settlement Request',
            items: [
                {type: 'link', text: 'Pending Liquidity Checks', href: '/QueryPendingSettlement'},
                {type: 'link', text: 'Pending Settlements', href: '/QueryApprovedSettlement'},
                {type: 'link', text: 'Historical Settlements', href: '/QueryHistoricalSettlement'}
            ]
        });
    }

    // The sidebar items displayed under the "Upload Data" dropdown is defined here along
    // with its corresponding endpoints.
    if (arrayIntersection([
        ...IC_SETTLEMENTS_LDAP,
        CONSTANT.USER_GROUP_REVIEWER,
        CONSTANT.USER_GROUP_IC_SETTLEMENT,
        CONSTANT.USER_GROUP_MO,
        CONSTANT.USER_GROUP_PROD_ADMIN,
    ], userGroup).length > 0) {
        items.push({
            type: 'section',
            text: 'Upload Data',
            items: [
                {type: 'link', text: 'Attach Multiple Documents', href: '/DocumentRequest'},
                {type: 'link', text: 'Upload Data File', href: '/UploadFile'},
                {type: 'link', text: 'Upload Single Trade Request', href: '/UploadSingleTradeRequest'}
            ]
        });
    }

    // The sidebar items displayed under the "Approval" dropdown is defined here along
    // with its corresponding endpoints.
    items.push({
        type: 'section',
        text: 'Approval',
        items: [
            {type: 'link', text: 'Approval Request', href: '/Approval'}
        ]
    });

    // The sidebar items displayed under the "Exceptions and Investigations" dropdown is defined here along
    // with its corresponding endpoints.
    // Only the PROD admins, the Middle Office team, and IC Settlements Group should be able to access the E&I Dashboards.
    if (arrayIntersection([CONSTANT.USER_GROUP_MO, CONSTANT.USER_GROUP_PROD_ADMIN, CONSTANT.USER_GROUP_IC_SETTLEMENT, CONSTANT.USER_GROUP_REVIEWER], userGroup).length > 0) {
        items.push({
            type: 'section',
            text: 'Exceptions and Investigations',
            items: [
                {type: 'link', text: 'All Trades', href: '/QueryAllTrades'},
                {type: 'link', text: 'Exceptions', href: '/QueryException'},
                {type: 'link', text: 'E&I Dashboards', href: '/Dashboards/MO'}
            ]
        });
    }

    // Constructs the sidebar navigation props and returns it.
    const sideNavigationProps: SideNavigationProps = {
        items,
        header,
        onFollow: (e: CustomEvent<SideNavigationProps.FollowDetail>) => {
            e.stopPropagation();
            e.preventDefault();

            // Refreshes the page if the path is the same--otherwise go to the followed URL.
            if (e.detail.href === location.pathname) {
                nav(0);
            } else {
                nav(e.detail.href);
            }
        }
    }

    return <SideNavigation {...sideNavigationProps}/>;
}

export default Navigation;