import React, { Component } from 'react';
import Logger from "../utils/logger";
import SpecifyTemplate from "./specifyTemplate";
import SingleTradeRequestForm from "./singleTradeRequestForm";
import UploadTradeDataPage from "./uploadTradeData";

/*
    Parent module for holding SingleTrade related pages, handling the data transimission between pages.
*/
class SingleTradeModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tradeData: {},
            currentIndex : 0,
            validationData: {},
            errorText: {},
            typeOfTrade: "",
            requesterGroup: "",
            firstPageNextDisable: true
        }
    }

    handleTradeDataChange = (newTradeData) => {
        Logger.logInfo("Trade Data Change");
        this.setState({tradeData: newTradeData});
    }

    handleTradeTypeChange = (value) => {
        this.setState({
            typeOfTrade: value,
            requesterGroup: ""
        }, this.validateFirstPage);
    }

    handleRequesterGroupChange = (value) => {
        this.setState({requesterGroup: value}, this.validateFirstPage);
    }

    validateFirstPage = () => {
        let firstPageNextDisable = (this.state.typeOfTrade === "") || (this.state.requesterGroup === "");
        this.setState({firstPageNextDisable: firstPageNextDisable});
    }

    handleNextStep = () => {
        Logger.logInfo(this.state.tradeData);
        this.setState({currentIndex: this.state.currentIndex + 1});
    }

    handlePreviousStep = () => {
        this.setState({currentIndex: this.state.currentIndex - 1});
    }

    handleResetPage = () => {
        this.setState({
            currentIndex: 0,
            tradeData: {},
            typeOfTrade: ""
        });
    }

    handleEdit = (tradeData) => {
        this.setState({
            currentIndex: 0,
            tradeData: tradeData
        });
    }

    getStepContent = () => {
        switch(this.state.currentIndex) {
            case 0: return <SpecifyTemplate
                                    handleNextStep={this.handleNextStep}
                                    handleTradeTypeChange={this.handleTradeTypeChange}
                                    handleRequesterGroupChange={this.handleRequesterGroupChange}
                                    firstPageNextDisable={this.state.firstPageNextDisable}
                            />;
            case 1: return <SingleTradeRequestForm 
                                    handleTradeDataChange={this.handleTradeDataChange}
                                    handleSubmit={this.handleSubmit}
                                    handleNextStep={this.handleNextStep}
                                    handlePreviousStep={this.handlePreviousStep}
                                    setErrorText={this.setErrorText}
                                    typeOfTrade={this.state.typeOfTrade}
                                    tradeData={this.state.tradeData}/>;
            case 2: return <UploadTradeDataPage
                                    handlePreviousStep = {this.handlePreviousStep}
                                    tradeData={this.state.tradeData}
                                    requesterGroup={this.state.requesterGroup}
                                    handleResetPage={this.handleResetPage}/>;
        }
    }

    render() {
        return (
            <div>
                {this.getStepContent()}
            </div>
        );
    }
}


export default SingleTradeModule;