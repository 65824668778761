import React, { Component } from 'react';
import '@amzn/awsui-global-styles/polaris.css';
import { ColumnLayout, Button, Modal, Container, Header, Box, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import {Spin} from "antd";
import { 
    FutureDateSTRElement, 
    DropDownElement, 
    FreeTextElement, 
    FreeTextAlphaNumericElement,
    DecimalWithCommaElement
} from "../component/dataEntry";
import Logger from "../utils/logger";
import CONSTANT from '../utils/constant';
import APIClient from '../utils/apiClient';
import moment from "moment-timezone";
import DataContentTable from '../component/dataContentTable';
import {PolarisV3FlashBar} from "../component/dataDisplay";


/**
 * ActionStrip contains button
 */
class ActionStrip extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let nextStepDisable = false;
        let validateText = 'Validate';
        if (this.props.validationStatus === CONSTANT.FEEDBACK_VALIDATION_NOT_TRIGGERED
            || this.props.validationStatus === CONSTANT.FEEDBACK_VALIDATION_START) {
            validateText = 'Validate';
            nextStepDisable = true;
        } else if (this.props.validationStatus === CONSTANT.FEEDBACK_VALIDATION_STATUS_SUCCESS_TRADE_ERROR
            || this.props.validationStatus === CONSTANT.FEEDBACK_UI_VALIDATION_INPUT_ERROR
            || this.props.validationStatus === CONSTANT.FEEDBACK_UI_VALIDATION_REQUIRED_EMPTY
            || this.props.validationStatus === CONSTANT.FEEDBACK_VALIDATION_STATUS_ERROR) {
            validateText = 'Validate Again';
            nextStepDisable = true;
        } else if (this.props.validationStatus === CONSTANT.FEEDBACK_VALIDATION_STATUS_SUCCESS) {
            validateText = 'Validate';
            nextStepDisable = false;
        }
        return (
            <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                    <Button
                        disabled={false}
                        onClick={() => { this.props.handlePreviousStep() }}
                    >Back</Button>
                    <Button variant="primary"
                        disabled={false}
                        onClick={() => { this.props.handleValidate() }}
                    >{validateText}</Button>
                    <Button
                        disabled={nextStepDisable}
                        onClick={() => { this.props.handleNextStep() }}
                    >Next Step: Upload</Button>
                </SpaceBetween>
            </Box>
        );
    }
}

/**
 * Element to show the file upload status based on the value of this.props.status
 */
class ValidationStatus extends Component{
    render() {
        switch (this.props.validationStatus) {
            case CONSTANT.FEEDBACK_VALIDATION_NOT_TRIGGERED:
                return (
                    <></>
                );
            case CONSTANT.FEEDBACK_VALIDATION_START:
                return (
                    <Box textAlign='center' padding='xl'>
                        <SpaceBetween direction='vertical' size='m'>
                            <StatusIndicator type="pending">Validating your trade</StatusIndicator>
                            <Spin/>
                        </SpaceBetween>
                    </Box>
                );
            case CONSTANT.FEEDBACK_VALIDATION_STATUS_SUCCESS:
                return (
                    <Box textAlign='center' padding='xl'>
                        <SpaceBetween direction='vertical' size='m'>
                            <StatusIndicator type="success">Validation Succeessful</StatusIndicator>
                            <div>Congratulations, your trade has been successfully validated, please upload</div>
                        </SpaceBetween>
                    </Box>
                );
            case CONSTANT.FEEDBACK_VALIDATION_STATUS_SUCCESS_TRADE_ERROR:
                return (
                    <Box textAlign='center' padding='xl'>
                        <SpaceBetween direction='vertical' size='m'>
                            <StatusIndicator type="warning">Validation Errors Found</StatusIndicator>
                            <div>Whoops, there are some validation errors in your trade request, please see the below table and modify your request.</div>
                        </SpaceBetween>
                    </Box>
                );
            case CONSTANT.FEEDBACK_VALIDATION_STATUS_ERROR:
                return (
                    <Box textAlign='center' padding='xl'>
                        <SpaceBetween direction='vertical' size='m'>
                            <StatusIndicator type="warning">Validation System Error</StatusIndicator>
                            <div>Whoops, our system is busy or go wrong currently, please click validation button again</div>
                        </SpaceBetween>
                    </Box>
                );
            default: return <Box textAlign='center' padding='xl'>Wrong status</Box>;
        }
    }
}

/*
 *  Summary Modal for trade export
 */
class VDTypeWarningModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let weekendWarning, tomWarning;
        if (this.props.valueDateWeekendWarning) {
            weekendWarning = <li>The selected date is at weekend, please choose another day</li>;
        }

        if (this.props.valueDateTomWarning) {
            tomWarning = <li>The selected Date is on the next day. It&apos;s not a preferred value date due to the risk of settlement </li>;
        }
        return (
            <Modal
                visible={this.props.VDTypeWarningVisble}
                header="Value Date Warning"
                expandToFit={true}
                onDismiss={this.props.handleModalDismissClick}
                footer={<div></div>}>
            <p>Attention on the items below</p>
            <ul>
                {weekendWarning}
                {tomWarning}
            </ul>
            </Modal>
            );
    }
}

class ErrorTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let header = <h3>{this.props.errorTitle}</h3>;
        return (
            <DataContentTable
                            wrapLines={false}
                            columnDefinitions={this.props.columns}
                            distributions={this.props.items}
                            header={header}
                            filterOptions={[]}
                            elementSelected={this.props.elementSelected}
                            onSelectionChange={this.props.onSelectionChange}
                            contentSelectorOptions={this.props.contentSelectorOptions}
                        />
            );
    }
}

/*
    UI Component for viewing the validation result, including the validation status,
    and error rows.
*/
class ValidationResultBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            elementSelected: []
        }
    }

    getErrorTable = () => {
        if (this.props.validationStatus === CONSTANT.FEEDBACK_VALIDATION_STATUS_SUCCESS_TRADE_ERROR) {
            return (
            <ErrorTable
                    errorTitle={"Validation Errors"}
                    columns={this.props.errorColumns}
                    items={this.props.validationErrors}
                    elementSelected={this.state.elementSelected}
                    contentSelectorOptions={this.props.contentSelectorOptions}
                    onSelectionChange={this.handleErrorTableSelection}
                />);
        } else {
            return <div></div>
        }
    }

    handleErrorTableSelection = ({detail}) => {
        Logger.logInfo(detail);
        this.setState({elementSelected: detail.selectedItems});
    }

    render() {
        return (
            <Container
                    header={
                        <Header
                            variant="h2"
                        >
                            Validation Result
                        </Header>
                    }>
                <ValidationStatus
                    validationStatus={this.props.validationStatus}
                />
                {this.getErrorTable()}
            </Container>
        );
    }
}

/*
    Main UI Form for users to input the necessary STR fields
*/
class SingleTradeRequestForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputTypeOfTrade: this.props.typeOfTrade,
            inputBuyOrSell: '',
            inputValueDate: '',
            inputAmount: '',
            inputVDOption: '',
            inputCCY: '',
            inputSourceAccount: '',
            inputBeneficiary: '',
            inputTradeCode: '',
            inputTradeDetails: '',
            inputTTID: '',
            handleSubmit: this.props.handleSubmit,
            errorText: {},
            kyribaAccountToCurrencyMapping: {},
            bloombergAccountToCurrencyMapping: {},
            finastraAccountToCurrencyMapping: {},
            combinedAccountToCurrencyMapping: {},
            nonFX3rdPtyKyribaToMisysTemplateMapping: {},
            nonFX3rdInternalCodeList: [],
            fx3rdPtyExternalCodeList: [],
            sourceAccountList: [],
            beneficiaryList: [],
            currencyList: [],
            kyribaAccountList: [],
            finastraAccountList: [],
            bloombergAccountList: [],
            status: '',
            queryAccountStatus: CONSTANT.FEEDBACK_QUERY_ACCOUNT_LOADING,
            validationStatus: CONSTANT.FEEDBACK_VALIDATION_NOT_TRIGGERED,
            validationErrors: [],
            errorFormat: [],
            VDTypeWarningVisble: false,
            valueDateTomWarning: false,
            valueDateWeekendWarning: false,
        }
    }

    componentDidMount() {
        if (Object.keys(this.props.tradeData).length !== 0) {
            let tradeData = this.props.tradeData;
            this.setState({
                inputTypeOfTrade: tradeData.tradeType,
                inputBuyOrSell: tradeData.buyOrSell,
                inputValueDate: tradeData.valueDate,
                inputAmount: tradeData.amount,
                inputVDOption: tradeData.vdOption,
                inputCCY: tradeData.ccy,
                inputSourceAccount: tradeData.sourceAccount,
                inputBeneficiary: tradeData.beneficiary,
                inputTradeCode: tradeData.tradeCode,
                inputTradeDetails: tradeData.tradeDetails,
                inputTTID: tradeData.ttID
            })
        }

        /*
            Query the necessary STR Data from backend, Including Kyriba Account List, Finastra Account List, Misys Kyriba Mapping.
            These lists/map will be used for generating beneficiary list and source account list based on the trade type.
        */

        APIClient.invoke('GET', 'singleTrade', '',
            undefined,
            (err, data) => {
                this.setState({ loading: false });
                if (!err) {
                    let kyribaAccountList, finastraAccountList, bloombergAccountList, nonFX3rdInternalCodeList;
                    let kyribaAccountToCurrencyMapping, finastraAccountToCurrencyMapping, bloombergAccountToCurrencyMapping
                    let nonFX3rdPtyKyribaToMisysTemplateMapping, fx3rdPtyExternalCodeList;
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            Logger.logInfo("Response data: " + JSON.stringify(data.data));
                            kyribaAccountList = [];
                            finastraAccountList = [];
                            bloombergAccountList = [];
                            nonFX3rdInternalCodeList = [];
                            kyribaAccountToCurrencyMapping = data.data.kyribaAccountToCurrencyMapping;
                            finastraAccountToCurrencyMapping = data.data.finastraAccountToCurrencyMapping;
                            bloombergAccountToCurrencyMapping = data.data.bloombergAccountToCurrencyMapping;
                            for (let kyribaAccount in kyribaAccountToCurrencyMapping) {
                                kyribaAccountList.push(kyribaAccount);
                            }

                            for (let finastraAccount in finastraAccountToCurrencyMapping) {
                                finastraAccountList.push(finastraAccount);
                            }

                            nonFX3rdPtyKyribaToMisysTemplateMapping = data.data.nonFX3rdPtyKyribaToMisysTemplateMapping;
                            for (let internalCode in nonFX3rdPtyKyribaToMisysTemplateMapping) {
                                nonFX3rdInternalCodeList.push(internalCode);
                            }

                            for (let bloombergAccount in bloombergAccountToCurrencyMapping) {
                                if (!nonFX3rdInternalCodeList.includes(bloombergAccount)) {
                                    bloombergAccountList.push(bloombergAccount);
                                }
                            }

                            fx3rdPtyExternalCodeList = data.data.fx3rdPtyExternalCodeList;

                            this.setState({
                                validationStatus: CONSTANT.FEEDBACK_VALIDATION_NOT_TRIGGERED,
                                queryAccountStatus: CONSTANT.FEEDBACK_QUERY_ACCOUNT_SUCCESS,
                                kyribaAccountToCurrencyMapping: data.data.kyribaAccountToCurrencyMapping,
                                finastraAccountToCurrencyMapping: data.data.finastraAccountToCurrencyMapping,
                                bloombergAccountToCurrencyMapping: data.data.bloombergAccountToCurrencyMapping,
                                kyribaAccountList: kyribaAccountList,
                                sourceAccountList: kyribaAccountList,
                                bloombergAccountList: bloombergAccountList,
                                finastraAccountList: finastraAccountList,
                                nonFX3rdInternalCodeList: nonFX3rdInternalCodeList,
                                nonFX3rdPtyKyribaToMisysTemplateMapping: nonFX3rdPtyKyribaToMisysTemplateMapping,
                                fx3rdPtyExternalCodeList: fx3rdPtyExternalCodeList,
                                combinedAccountToCurrencyMapping: Object.assign({}, kyribaAccountToCurrencyMapping,
                                    finastraAccountToCurrencyMapping, bloombergAccountToCurrencyMapping)
                            });
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Not able to query STR account/currency information ");
                            this.setState({
                                queryAccountStatus: CONSTANT.FEEDBACK_QUERY_ACCOUNT_ERROR,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other Error when Querying STR account/currency information");
                    this.setState({
                        queryAccountStatus: CONSTANT.FEEDBACK_QUERY_ACCOUNT_ERROR,
                        statusMsg: "Other System Error when Querying STR account/currency information"
                    });
                }
            });
    }

    // Get feedback message from query and show as flash
    getFeedbackMessage = () => {
        switch (this.state.queryAccountStatus) {
            case CONSTANT.FEEDBACK_QUERY_ACCOUNT_LOADING:
                return (
                    <PolarisV3FlashBar 
                        type="success"
                        content="Fetching Source Account/Beneficiary List.."
                        dismissible={true}
                        loading={true}
                        key={this.state.queryAccountStatus}
                    />);
            case CONSTANT.FEEDBACK_QUERY_ACCOUNT_SUCCESS:
                return (
                    <PolarisV3FlashBar 
                        type="success"
                        content="Successfuly Get Source Account/Beneficiary List"
                        dismissible={true}
                        key={this.state.queryAccountStatus}
                    />);
    
            case CONSTANT.FEEDBACK_QUERY_ACCOUNT_ERROR:
                return (
                    <PolarisV3FlashBar 
                        type="error"
                        content="Something went wrong during fetching Source Account/Beneficiary List"
                        dismissible={true}
                        key={this.state.queryAccountStatus}
                    />);    
            default: return;
        }
    }

    //Get feedback message before validation happens and show as flash
    getValidationFeedbackMessage = () => {
        switch (this.state.validationStatus) {    
            case CONSTANT.FEEDBACK_UI_VALIDATION_REQUIRED_EMPTY:
                return (
                    <PolarisV3FlashBar 
                        type="error"
                        content="One or more required fields are empty, please fill out it and submit again"
                        dismissible={true}
                        key={this.state.validationStatus}
                    />);    
            default: return;
        }
    }

    setErrorText = (name, errorText) => {
        let newErrorText = Object.assign({}, this.state.errorText, {[name]: errorText});
        if(errorText === "")
            delete newErrorText[name];
        this.setState({errorText: newErrorText});
    };



    // Handle Value Change
    handleValueChange = (name, value) => {
        Logger.logInfo("UpdatePaymentModal handleValueChange: " + name + " " + value);

        this.setState({ [name]: value });

        if (name === 'inputValueDate') {
            let selectedDate = moment(value);
            let currDate = moment(new Date().toLocaleString("en-US", {timeZone: "America/Los_Angeles"}));
            let differenceInHour = selectedDate.diff(currDate, 'hours');
            let valueDateTomWarning = false; //default is false
            let valueDateWeekendWarning = false;

            //TOM Warning for 9am PST Cutoff Time, should skip weekend
            if (currDate.hour() >= 9) { //T+2 skip weekend
                if (currDate.day() === 4 || currDate.day() === 5) {
                    if (differenceInHour < 87) { //15 + 24*3 = 87
                        valueDateTomWarning = true;
                    }
                } else {
                    if (differenceInHour < 39) {
                        valueDateTomWarning = true;
                    }
                }
            } else { //T+1 skip weekend
                if (currDate.day() === 5) {
                    if (differenceInHour < 72) {
                        valueDateTomWarning = true;
                    }
                } else {
                    if (differenceInHour < 24) {
                        valueDateTomWarning = true;
                    }
                }
            }


            //Override if today is weekend, set Mon and Tue to warning
            if (currDate.day() === 6 || currDate.day() === 0) {
                if (differenceInHour < 72 && (selectedDate.day() === 1 || selectedDate.day() === 2)) {
                    valueDateTomWarning = true;
                }
            }

            //Weekend warning
            if ((selectedDate.day() === 0) || (selectedDate.day() === 6)) {
                valueDateWeekendWarning = true;
                valueDateTomWarning = false;
            }
            if (valueDateTomWarning || valueDateWeekendWarning) {
                this.setState({
                    VDTypeWarningVisble: true,
                    valueDateTomWarning: valueDateTomWarning,
                    valueDateWeekendWarning: valueDateWeekendWarning
                });
            } else {
                this.setState({
                    valueDateTomWarning: valueDateTomWarning,
                    valueDateWeekendWarning: valueDateWeekendWarning
                });
            }
            
        } else if (name === 'inputBuyOrSell') {
            this.generateCCYOptionsByTradeAction(value);
        } else if (name === 'inputCCY') {
            this.changeTradeActionByCCY(value);
        } else if (name === 'inputSourceAccount' || name === 'inputBeneficiary' || name === 'inputTypeOfTrade') {
            this.setState({
                inputCCY: '',
                currencyList: [],
                inputBuyOrSell: ''
            })
        }
        this.setState({
            validationStatus: CONSTANT.FEEDBACK_VALIDATION_NOT_TRIGGERED
        })
    };

    // handle submit STR form for validation
    handleSubmit = () => {
        Logger.logInfo(this.state.tradeData);
        
        Logger.logInfo(this.state.errorText);
        if (!this.validateInputForm()) {
            Logger.logInfo("Error");
            return;
        }
        this.setState({
            validationStatus: CONSTANT.FEEDBACK_VALIDATION_START
        })
        let tradeData = this.formatTradeData();

        let parameter = {
            requestType: "validate"
        }


        APIClient.invoke('POST', 'singleTrade', parameter, tradeData, (err, requestData) => {
            Logger.logInfo("Response data: " + JSON.stringify(requestData));
            if (!err) {
                let newStatus;
                switch (requestData.status) {
                    case CONSTANT.RESPONSE_SUCCESS:
                        //delete the corresponding record from UI table
                        Logger.logInfo("Validation result: " + JSON.stringify(requestData.data))
                        newStatus = requestData.data.validationErrors.length === 0 
                                        ? CONSTANT.FEEDBACK_VALIDATION_STATUS_SUCCESS
                                        : CONSTANT.FEEDBACK_VALIDATION_STATUS_SUCCESS_TRADE_ERROR
                        this.setState({
                            validationStatus: newStatus,
                            validationErrors: requestData.data.validationErrors,
                            errorFormat: requestData.data.errorFormat});
                        Logger.logInfo("Success validation: " + JSON.stringify(this.state.tradeData));
                        break;
                    case CONSTANT.RESPONSE_ERROR:
                        this.setState({
                            validationStatus: CONSTANT.FEEDBACK_VALIDATION_STATUS_ERROR
                        })
                        Logger.logError("Error updating data records with tradeId id list = " );
                        break;
                    case CONSTANT.RESPONSE_SYSTEM_ERROR:
                        this.setState({
                            validationStatus: CONSTANT.FEEDBACK_VALIDATION_STATUS_ERROR
                        })
                        Logger.logError("System Error when updating data records with tradeId id list = " );
                        break;
                    default:
                }
            } else {
                this.setState({
                    validationStatus: CONSTANT.FEEDBACK_VALIDATION_STATUS_ERROR
                })
                Logger.logError("Other System Error when updating trade data records with tradeId = " + tradeData.toString());
            }
        })
    }

    handleNextStep = () => {
        this.props.handleTradeDataChange(this.formatTradeData());
        this.props.handleNextStep();
    }

    handlePreviousStep = () => {
        this.props.handlePreviousStep();
    }

    handleModalDismissClick = () => {
        this.setState({VDTypeWarningVisble: false});
    }

    // Get trade type selection list
    getTradeTypeSelections = (options) => {
        return options.map(tradeType => (({
            label: tradeType,
            id: tradeType,
            value: tradeType
        })));
    }

    // Generate beneficiary options based on type of trade, using the combination of external/internal code list
    generateBeneficiaryOptions = (inputTypeOfTrade) => {
        if (inputTypeOfTrade === 'M and A') {
            let combinedAccountList = this.state.nonFX3rdInternalCodeList.concat(this.state.fx3rdPtyExternalCodeList);
            return this.getTradeTypeSelections(combinedAccountList);
        } else if (inputTypeOfTrade === 'AP') {
            return this.getTradeTypeSelections(this.state.fx3rdPtyExternalCodeList);
        } else {
            let combinedAccountList = this.state.nonFX3rdInternalCodeList.concat(this.state.bloombergAccountList);
            return this.getTradeTypeSelections(combinedAccountList);
        }
    }

    // Generate Currency Option based on trade action(Buy or Sell) and beneficiary/source account
    generateCCYOptionsByTradeAction = (tradeAction) => {
        if (this.state.inputSourceAccount === '' || this.state.inputBeneficiary === '' || this.state.inputTypeOfTrade === ''
            || Object.keys(this.state.combinedAccountToCurrencyMapping).length === 0
            || Object.keys(this.state.kyribaAccountToCurrencyMapping).length === 0) {
            return;
        }

        let newCurrencyList = [];
        newCurrencyList.push(this.state.combinedAccountToCurrencyMapping[this.state.inputBeneficiary]);
        newCurrencyList.push(this.state.kyribaAccountToCurrencyMapping[this.state.inputSourceAccount]);
        
        Logger.logInfo(newCurrencyList);
        
        //0 Buy -> Beneficiary CCY, 1 Sell -> Source CCY
        let newInputCCY = '';
        if (tradeAction === 'Buy') {
            newInputCCY = newCurrencyList[0];
        } else {
            newInputCCY = newCurrencyList[1];
        }

        this.setState({currencyList: newCurrencyList, inputCCY: newInputCCY});
    }

    // Change Trade Action by Currency
    changeTradeActionByCCY = (inputCCY) => {
        if (inputCCY === this.state.currencyList[0]) {
            this.setState({inputBuyOrSell: 'Buy'});
        } else if (inputCCY === this.state.currencyList[1]) {
            this.setState({inputBuyOrSell: 'Sell'});
        }
    }

    // Format trade data prepared for valdation in backend or transfer to the next upload page
    formatTradeData = () => {
        //clean possible comma in input amount field
        let amount = this.state.inputAmount.replaceAll(',', '');
        let beneficiaryExternalCode = this.getBeneficiaryConversion(this.state.inputBeneficiary);

        let tradeData = {
            sourceAccount: this.state.inputSourceAccount,
            buyOrSell: this.state.inputBuyOrSell,
            ccy: this.state.inputCCY,
            amount: amount,
            beneficiary: beneficiaryExternalCode,
            tradeCode: this.state.inputTradeCode,
            tradeDetails: this.state.inputTradeDetails,
            tradeType: this.state.inputTypeOfTrade,
            vdOption: this.state.inputVDOption,
            valueDate: this.state.inputValueDate,
            ttID: this.state.inputTTID
        }

        return tradeData;
    }

    // Get the beneficiary conversion based on the Kyriba to Misys Template Mapping (Non Fx 3rd Party only)
    getBeneficiaryConversion = (beneficiary) => {
        if (this.state.nonFX3rdInternalCodeList.includes(beneficiary)) {
            return this.state.nonFX3rdPtyKyribaToMisysTemplateMapping[beneficiary];
        }
        return beneficiary;
    }

    // Validate input form on the UI side (Empty check, invalid number check)
    validateInputForm = () => {
        if (Object.keys(this.state.errorText).length !== 0) {
            this.setState({ validationStatus: CONSTANT.FEEDBACK_UI_VALIDATION_INPUT_ERROR });
            return false;
        }

        if (this.state.inputTypeOfTrade === '' || this.state.inputSourceAccount === '' || this.state.inputBeneficiary === ''
            || this.state.inputBuyOrSell === '' || this.state.inputCCY === '' || this.state.inputAmount === '' 
            || this.state.inputValueDate === '' || this.state.inputTTID === '' || this.state.inputTradeCode === '') {
            this.setState({ validationStatus: CONSTANT.FEEDBACK_UI_VALIDATION_REQUIRED_EMPTY });
            return false;
        }

        return true;
    }

    // Form the Validation Result Block
    getValidationResultBlock = () => {
        let validationResultBlock = null;
        Logger.logInfo(this.state.validationStatus);
        if (this.state.validationStatus === CONSTANT.FEEDBACK_VALIDATION_STATUS_SUCCESS
            || this.state.validationStatus === CONSTANT.FEEDBACK_VALIDATION_STATUS_SUCCESS_TRADE_ERROR
            || this.state.validationStatus === CONSTANT.FEEDBACK_VALIDATION_START
            || this.state.validationStatus === CONSTANT.FEEDBACK_VALIDATION_STATUS_ERROR) {
            
            let columnDefinitions = [];
            let contentSelectorOptions = [];
            let contentSelectorOption = {};
            contentSelectorOption["label"] = "Properties";
            let visibleOptions = [];

            this.state.errorFormat.map(column => {
                let columnDefinitionItem = {};
                let contentSelectorItem = {};
                columnDefinitionItem["id"] = column;
                columnDefinitionItem["header"] = column;
                columnDefinitionItem["cell"] = (item => item[column]);
                columnDefinitions.push(columnDefinitionItem);

                contentSelectorItem['id'] = column;
                contentSelectorItem['label'] = column;
                contentSelectorItem['editable'] = true;
                contentSelectorItem['visible'] = true;


                visibleOptions.push(contentSelectorItem);
            });

            contentSelectorOption["options"] = visibleOptions;
            contentSelectorOptions.push(contentSelectorOption);

            validationResultBlock = <ValidationResultBlock
                        validationErrors={this.state.validationErrors}
                        errorColumns={columnDefinitions}
                        contentSelectorOptions={contentSelectorOptions}
                        validationStatus={this.state.validationStatus}
            />
        }
        return validationResultBlock;
    }

    render() {
        let headerText = "Single Trade Request - " + this.state.inputTypeOfTrade;
        return (
            <div>
                {this.getFeedbackMessage()}
                {this.getValidationFeedbackMessage()}
                <br/>
                <VDTypeWarningModal
                    VDTypeWarningVisble={this.state.VDTypeWarningVisble}
                    handleModalDismissClick={this.handleModalDismissClick}
                    valueDateTomWarning={this.state.valueDateTomWarning}
                    valueDateWeekendWarning={this.state.valueDateWeekendWarning}
                />
                <ColumnLayout columns={1}>
                    <div data-awsui-column-layout-root="true">
                    <Container
                    header={
                        <Header
                            variant="h2"
                        >
                            {headerText}
                        </Header>
                    }
                >
                            <ColumnLayout columns={3}>
                                <DropDownElement title={'Source Account'}
                                    name={'inputSourceAccount'}
                                    value={this.state.inputSourceAccount}
                                    options={this.getTradeTypeSelections(this.state.sourceAccountList)}
                                    disabled={false}
                                    required={true}
                                    handleChange={this.handleValueChange}
                                    setErrorFlag={this.setErrorText} />
                                <DropDownElement title={'Beneficiary'}
                                    name={'inputBeneficiary'}
                                    value={this.state.inputBeneficiary}
                                    options={this.generateBeneficiaryOptions(this.state.inputTypeOfTrade)}
                                    disabled={false}
                                    required={true}
                                    handleChange={this.handleValueChange}
                                    setErrorFlag={this.setErrorText} />
                            </ColumnLayout>
                            <ColumnLayout columns={3}>
                                <DropDownElement title={'Buy or Sell'}
                                    name={'inputBuyOrSell'}
                                    value={this.state.inputBuyOrSell}
                                    options={this.getTradeTypeSelections(CONSTANT.STR_BUY_OR_SELL_LIST)}
                                    disabled={false}
                                    required={true}
                                    handleChange={this.handleValueChange}
                                    setErrorFlag={this.setErrorText} />
                                <DropDownElement title={'CCY'}
                                    name={'inputCCY'}
                                    value={this.state.inputCCY}
                                    options={this.getTradeTypeSelections(this.state.currencyList)}
                                    disabled={false}
                                    required={true}
                                    handleChange={this.handleValueChange}
                                    setErrorFlag={this.setErrorText} />
                                <DecimalWithCommaElement title={"Amount"}
                                    name={"inputAmount"}
                                    value={this.state.inputAmount}
                                    min={0}
                                    errorText={"ErrorText"}
                                    disabled={false}
                                    required={true}
                                    handleChange={this.handleValueChange}
                                    setErrorFlag={this.setErrorText} />
                                <FutureDateSTRElement title="Value Date"
                                    name={'inputValueDate'}
                                    handleChange={this.handleValueChange}
                                    value={this.state.inputValueDate}
                                    module={"str"}
                                    disabled={false}
                                    required={true}
                                    nextMonthAriaLabel="Next month"
                                    placeholder="YYYY/MM/DD"
                                    previousMonthAriaLabel="Previous month"
                                    todayAriaLabel="Today" />
                            </ColumnLayout>
                            <ColumnLayout columns={2}>
                                <FreeTextAlphaNumericElement title={"Ticket ID"}
                                    name={"inputTTID"}
                                    value={this.state.inputTTID}
                                    errorText={"ErrorText"}
                                    disabled={false}
                                    required={true}
                                    handleChange={this.handleValueChange}
                                    setErrorFlag={this.setErrorText} />
                                <FreeTextElement title={"Additional Trade Details (i.e. OCM Tag)"}
                                    name={"inputTradeCode"}
                                    value={this.state.inputTradeCode}
                                    errorText={"ErrorText"}
                                    disabled={false}
                                    required={true}
                                    handleChange={this.handleValueChange}
                                    setErrorFlag={this.setErrorText} />
                            </ColumnLayout>
                        </Container>
                        <div></div>
                        <div>
                            <br/>
                            {this.getValidationResultBlock()}
                            <br/>
                            <ActionStrip
                            handleValidate={this.handleSubmit}
                            handleNextStep={this.handleNextStep}
                            handlePreviousStep={this.handlePreviousStep}
                            validationStatus={this.state.validationStatus} />
                        </div>
                    </div>
                </ColumnLayout>
                <br/>
                <br/>
                <br/>
            </div>
        );
    }
}

export default SingleTradeRequestForm;