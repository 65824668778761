import {CognitoAuth} from 'amazon-cognito-auth-js';
import config from "../config/config";
import {getStage} from "../utils/environment";

export function getAuthConfig(){
    return config['authConfig'][getStage()];
}

// This code removes the "?code=..." from the URL. It is because the grant code is not reusable.
//  Sometimes the SDK will report weird message because of using old grant code.
function removeQueryFromLocation() {
    // Replace the href because the Cognito passes the OAuth2 grant code in the query string
    // And the grant code is not reusable
    if (window.history.length > 0) {
        const newHref = window.location.href.split('?')[0];
        window.history.replaceState(undefined, 'TRS', newHref);
    }
}

/**
 * Ensures the user is authenticated.
 * If authenticated, return auth object. Otherwise force authentication.
 * Auth object will be used to call API gateway
 */
export function ensureAuthenticated(){
    return new Promise((resolve) => {
        const auth = new CognitoAuth(getAuthConfig());
        console.log("auth: "+JSON.stringify(auth));
        auth.useCodeGrantFlow();

        // Register callback functions
        auth.userhandler = {
            onFailure: (err) => {
                console.log('Cognito onFailure', err);
                removeQueryFromLocation();
                //reject(err);
                /* Log in may fail if an authorized user's refresh token has expired.
                * Clear the cache will redirect them to midway page for authentication and acquire a new refresh token
                * Yet this may result in infinite loop for unauthorized user.
                * May need to change this to sign out button in future.
                */
                auth.clearCachedTokensScopes();
                console.log("Start reloading page after clearing cache.");
                window.location.reload();
            },
            onSuccess: () => {
                removeQueryFromLocation();
                resolve(auth);
            },
        };

        const href = window.location.href;
        let session = auth.getSignInUserSession();

        if (session.isValid()) {
            resolve(auth);
        } else if (href.indexOf('?') > 0) {
            // This is required because Cognito needs to get the authentication result from the query string
            // The parsing is done asynchronously, and the result will be passed to the userHandler.
            // Once the result is parsed, onSuccess userhandler will resolve the promise.
            console.log('Parsing session');
            auth.parseCognitoWebResponse(href);
            console.log('Parsed session', session);
        } else {
            console.log("session not valid and no parsed session");
            // Cognito SDK will handle session refresh / authentication.
            session = auth.getSession();
        }
    });
}

// Utility function to prompt reauthentication when the user clears the cache.
export const reAuthenticate = () => {
    ensureAuthenticated().then((auth) =>  {
        let userInfo = auth.getSignInUserSession().getIdToken().decodePayload();
        localStorage.setItem("userID", userInfo['cognito:username'].split('_')[1]);
        localStorage.setItem("groups", userInfo['profile']);
    })
}