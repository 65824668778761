import { CognitoAuth } from "amazon-cognito-auth-js";
import { CognitoUserAttributes } from "./model/Model";
import CONSTANT from "./utils/constant";

/**
 * Capitalizes the first letter of the input.
 * 
 * @param {string} inputString
 *      Any input string with at least 1 character.
 * @returns {string}
 *      A string with the first character capitalized.
 */
export const capitalizeFirstLetter = (inputString: string): string => {
    return inputString ? inputString.charAt(0).toUpperCase() + inputString.slice(1) : "";
}

/**
 * Depending on the CognitoAuth object that gets passed in, updates localStorage with the username and profile
 * for the authenticated user.
 * 
 * @param {CognitoAuth} auth
 *      The CognitoAuth object containing the user attributes.
 */
export const setUserInformation = (auth: CognitoAuth): void => {
    const userInfo : CognitoUserAttributes = auth.getSignInUserSession().getIdToken().decodePayload() as CognitoUserAttributes;
    localStorage.setItem(CONSTANT.LOCAL_STORAGE_USERID, userInfo["cognito:username"].split('_')[1] ?? '');
    localStorage.setItem(CONSTANT.USER_GROUP_NAME, userInfo["profile"] ?? '');
}