import React, {Component} from "react";
import {
    Button,
    Modal,
    Spinner,
    Icon, SpaceBetween, Box
} from "@amzn/awsui-components-react";
import '@amzn/awsui-global-styles/polaris.css';
import CONSTANT from "../../../utils/constant";

/*
*   Validate Payment Export Modal
*   Checking for: Duplicate Relationship, Missing Beneficiary.
*/
class ValidatePaymentExportModal extends Component {
    constructor(props) {
        super(props);
    }

    //Generate duplicate warnings summary based on the validation result, form into lists.
    generateDuplicateWarningsSummary = (paymentValidationResult) => {
        let duplicateWarnings = [];
        let keyIdx = 0;
        for (let settlement in paymentValidationResult) {
            let title = <p><b>{settlement}:</b></p>;
            let listItems = [];
            for (let index = 0; index < paymentValidationResult[settlement].length; index++) {
                let listItem = paymentValidationResult[settlement][index][CONSTANT.PR_COL_SETTLEMENT_ID] + ": "
                + paymentValidationResult[settlement][index][CONSTANT.PR_COL_OCM_MAPPING] + ", "
                + paymentValidationResult[settlement][index][CONSTANT.PR_COL_SETTLEMENT_USD_EQUIV] + " USD Equiv, "
                + paymentValidationResult[settlement][index][CONSTANT.PR_COL_STATUS];
                listItems.push(<li key={index}>{listItem}</li>);
            }

            duplicateWarnings.push(
                <div key={keyIdx++}>
                    {title}
                    <ul>{listItems}</ul>
                    <br/>
                </div>
            );
        }

        return duplicateWarnings;
    }

    //Get duplicate relationship validation result block
    getDuplicateValidationResultBlock = () => {
        let duplicateValidationResultBlock;
        if (this.props.paymentValidationStatus === CONSTANT.MODAL_PAYMENT_VALIDATE_START) {
            duplicateValidationResultBlock = <span><Spinner />Loading duplicate payments...</span>;
        } else if (this.props.paymentValidationStatus === CONSTANT.MODAL_PAYMENT_VALIDATE_FINISH) {
            if (Object.keys(this.props.ocmDuplicates).length > 0) {
                let duplicateWarnings = this.generateDuplicateWarningsSummary(this.props.ocmDuplicates);
                duplicateValidationResultBlock = 
                <div>
                    <Box><Icon variant="warning" name="status-warning"></Icon> Please carefully check duplicates below: </Box>
                    <br/>
                    <div>
                        {duplicateWarnings}
                    </div>
                </div>;
            } else {
                duplicateValidationResultBlock = <Box><Icon variant="success" name="status-positive"></Icon> No duplicate payment found</Box>;
            }
        } else {
            duplicateValidationResultBlock = <Box><Icon variant="error" name="status-positive"></Icon> System error when finding duplicate payments, please reach out to
            the development team</Box>;
        }

        return <><Box>Duplicate Payment Check:</Box>{duplicateValidationResultBlock}</>;
    }


    // Get missing beneficiary summary
    getMissingBeneficiaryBlock = () => {
        // Only render Missing Beneficiary Block if payment is FX
        if (this.props.exportPaymentType !== 'FX') { 
            return <></>;
        }

        let listItems = [];
        for (let index = 0; index < this.props.missingBeneficiary.length; index++) {
            const payment = this.props.missingBeneficiary[index];
            const tradeId = payment[CONSTANT.PR_COL_SETTLEMENT_ID];
            const notionalCCY = payment[CONSTANT.PR_COL_TARGET_CURRENCY];
            const notionalAmount = payment[CONSTANT.PR_COL_TARGET_AMOUNT].toLocaleString("en-US");
            
            const listItem = `Trade ID: ${tradeId}, Notional CCY: ${notionalCCY}, Notional Amount: ${notionalAmount}`;
            listItems.push(<li key={index}>{listItem}</li>);
        }

        let missingBeneficiaryBlock;
        if (Object.keys(listItems).length > 0) {
            missingBeneficiaryBlock = 
            <div>
                <Box><Icon variant="warning" name="status-warning"></Icon> Please carefully review the missing beneficiary list below: </Box>
                <br/>
                <div>
                    <ul>
                        {listItems}
                    </ul>
                </div>
            </div>;
        } else {
            missingBeneficiaryBlock = <Box><Icon variant="success" name="status-positive"></Icon> No missing beneficiary found. </Box>;
        }

        return <><Box>Missing Beneficiary Check:</Box> {missingBeneficiaryBlock}</>;
    };

    render() {
        let footer =
            <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link"
                            onClick={() => this.props.handleDismissClick()}>Cancel</Button>
                    <Button variant="primary"
                            disabled={this.props.paymentValidationStatus !== CONSTANT.MODAL_PAYMENT_VALIDATE_FINISH}
                            onClick={() => this.props.handleConfirmValidateClick()}>Export Payments</Button>
                </SpaceBetween>
            </Box>

        //Duplicate Validation Result Block
        let duplicateValidationResultBlock = this.getDuplicateValidationResultBlock();

        //Payment with Cancellation Block
        let missingBeneficiaryBlock = this.getMissingBeneficiaryBlock();

        return (
            <Modal
                visible={this.props.validatePaymentExportModalVisible}
                header="Payment Export Review"
                expandToFit={true}
                onDismiss={this.props.handleDismissClick}
                footer={footer}>
                    {duplicateValidationResultBlock}
                    {missingBeneficiaryBlock}
            </Modal>
            );
    }
}

export default ValidatePaymentExportModal;
