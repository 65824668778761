import * as React from "react";
import {getStage} from "../../utils/environment";
import S3Logic from "../../utils/s3Logic";
import {useState} from "react";
import {Box, Button, Container, Header, SpaceBetween, TextContent} from "@amzn/awsui-components-react";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import FileUpload from "@amzn/awsui-components-react/polaris/file-upload";
import UploadMappingFile from "./uploadMappingFile";
import CONSTANT, {TEMP_ATTACHING_DOCUMENT_BUCKET_PREFIX} from "../../utils/constant";
import {chooseFiles, UploadDocumentsStatus} from "./documentUtils";

const BulkDocumentModule = () =>  {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [presignedURLData, setPresignedURLData] = useState('');
    const [validFiles, setValidFiles] = useState([]);
    const [uploadStatus, setUploadStatus] = useState(CONSTANT.FEEDBACK_UPLOAD_NOT_TRIGGERED);
    const [uploadId, setUploadId] = useState('');

    const handleExitBulkUpload = () => {
        setCurrentIndex(0);
        setPresignedURLData('');
        setValidFiles([]);
        setUploadStatus(CONSTANT.FEEDBACK_UPLOAD_NOT_TRIGGERED);
        setUploadId('');
    }

    const handleFileUploadAndNextStep = async () => {
        if (validFiles.length === 0) {
            console.error("No valid files selected.");
            return;
        }
        try {
            let partialUploadFailed = false;
            let stage = getStage();
            if (stage === "dev") {
                stage = "beta";
            }
            const bucket = TEMP_ATTACHING_DOCUMENT_BUCKET_PREFIX + stage;
            const getURL = await S3Logic.fetchPostPresignedUrl(bucket);
            console.log("URL on POST Presigned: ", getURL.url.url);
            console.log("POST Presigned on the fields: ", getURL.url.fields);
            if (!getURL?.url.url) {
                // If URL cannot be fetched, do not attempt execution
                console.error("Unable to fetch URL, during file staging.", getURL.status);
                return;
            }
            setPresignedURLData(getURL);

            const id = (getURL.url.fields.key).split('/')
            setUploadId(id[0]);

            const formData = new FormData();
            // Append presigned URL fields to formData
            Object.keys(getURL.url.fields).forEach((key) => {
                formData.append(key, getURL.url.fields[key]);
            });
            setUploadStatus(CONSTANT.FEEDBACK_UPLOAD_TRACKING_IN_PROGRESS)
            for (const file of validFiles) {
                // Append each file to formData and post to S3 bucket
                // https://docs.aws.amazon.com/AmazonS3/latest/API/RESTObjectPOST.html
                // The file or text content must be the last field in the form.
                formData.append("file", file);

                const opts = {
                    method: "POST",
                    url: getURL.url.url,
                    body: formData,
                };
                const response = await fetch(getURL.url.url, opts);

                // Clean up the file from formData after each successful upload
                formData.delete("file");
                if (response.ok) {
                    console.log("File uploaded successfully: ", file.name);
                } else {
                    console.error("An error occurred during file upload:", response.status);
                    partialUploadFailed = true;
                }
            }
            if (partialUploadFailed) {
                console.log("Some files failed to be uploaded.");
                setUploadStatus(CONSTANT.FEEDBACK_UPLOAD_ERROR)
            } else {
                console.log("All valid files have been uploaded.");
                setUploadStatus(CONSTANT.FEEDBACK_UPLOAD_NOT_TRIGGERED);
                setCurrentIndex(currentIndex + 1);
            }
        } catch (error) {
            console.error("An error occurred during file upload:", error);
            setUploadStatus(CONSTANT.FEEDBACK_UPLOAD_ERROR)
        }
    };

    const handleChooseFiles = (e) => {
        let files = chooseFiles(e);
        setValidFiles(files);
    }
    const handleCancel = () => {
        setCurrentIndex(0);
        setPresignedURLData('');
        setValidFiles([]);
        setUploadStatus(CONSTANT.FEEDBACK_UPLOAD_NOT_TRIGGERED);
    }

    const getStepContent = () => {
        if (currentIndex === 0) {
            return (
                <div>
                    <h1>Attach Multiple Documents</h1>
                    <Container
                        header={
                            <Header variant="h2">Step 1 - Choose documents</Header>
                        }
                    >
                        <FormField
                            label="Select all documents to upload except the Mapping File."
                            description=""
                        >
                            <FileUpload
                                onChange={handleChooseFiles}
                                value={validFiles}
                                accept=".pdf, .doc, .docx, .xlsx, .csv, .msg, .png, .jpeg, .htm"
                                i18nStrings={{
                                    uploadButtonText: e =>
                                        e ? "Choose files" : "Choose file",
                                    dropzoneText: e =>
                                        e
                                            ? "Drop files to upload"
                                            : "Drop file to upload",
                                    removeFileAriaLabel: e =>
                                        `Remove file ${e + 1}`,
                                    limitShowFewer: "Show fewer files",
                                    limitShowMore: "Show more files",
                                    errorIconAriaLabel: "Error"
                                }}
                                multiple
                                showFileLastModified
                                showFileSize
                                showFileThumbnail
                                tokenLimit={3}
                                constraintText="Only accept the following type: .pdf, .doc, .docx, .xlsx, .csv, .msg, .png, .jpeg, .htm"
                            />
                        </FormField>
                        <Box float="right">
                            <TextContent><h5>Total Files to Upload: {validFiles.length}</h5></TextContent>
                        </Box>
                    </Container>

                    <br/>
                    <div>
                        <Box float='right'>
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button
                                    disabled={false} onClick={handleCancel}>Cancel</Button>
                                <Button variant="primary"
                                        disabled={false} onClick={handleFileUploadAndNextStep}>Next</Button>
                            </SpaceBetween>
                        </Box>
                    </div>
                </div>
            )
        }
        if (currentIndex === 1) {
            return <UploadMappingFile presignedURLData={presignedURLData}
                                      onCancel={handleCancel}
                                      handleExitBulkUpload={handleExitBulkUpload}
                                      uploadId={uploadId}/>
        }
    }
    return (
        <div>
            {getStepContent()}
            <br/>
            <br/>
            <UploadDocumentsStatus
                uploadStatus={uploadStatus}/>
        </div>
    );
}

export default BulkDocumentModule;
