import * as React from "react";
import {getStage} from "../../utils/environment";
import {useState} from "react";
import CONSTANT, {TEMP_ATTACHING_DOCUMENT_BUCKET_PREFIX} from "../../utils/constant";
import {
    Box,
    Button,
    Container,
    FormField,
    Header,
} from "@amzn/awsui-components-react";
import FileUpload from "@amzn/awsui-components-react/polaris/file-upload";
import S3Logic from "../../utils/s3Logic";
import {chooseFiles, UploadDocumentsStatus} from "./documentUtils";
import MappingFileForm from "./mappingFileForm";
import Logger from "../../utils/logger";
import {SINGLE_UPLOAD_FILE_CATEGORY_DROPDOWN_ITEMS} from "../../config/table";
import {DropDownElement, FreeTextElement} from "../../component/dataEntry";

const SingleDocumentModule = (props) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [presignedURLData, setPresignedURLData] = useState('');
    const [validFile, setValidFile] = useState([]);
    const [category, setCategory] = useState("");
    const [otherCategory, setOtherCategory] = useState("");
    const [otherCategoryDisabled, setOtherCategoryDisabled] = useState(true);
    const [csvRow, setCsvRow] = useState([]);
    const [uploadStatus, setUploadStatus] = useState(CONSTANT.FEEDBACK_UPLOAD_NOT_TRIGGERED);
    const [uploadId, setUploadId] = useState("");
    const [uploadDisabled, setUploadDisabled] = useState(false);

    const handleChooseFile = (e) => {
        let file = chooseFiles(e);
        setValidFile(file);
    };
    const handleFileUpload = async () => {
        /* Validating inputs. */
        if (validFile.length === 0 || (category === "Other" && otherCategory === "")) {
            console.error("Invalid category or no file is selected.");
            return;
        }
        try {
            setUploadStatus(CONSTANT.FEEDBACK_UPLOAD_TRACKING_IN_PROGRESS);
            setUploadDisabled(true);
            let stage = getStage();
            if (stage === "dev") {
                stage = "beta";
            }
            const bucket = TEMP_ATTACHING_DOCUMENT_BUCKET_PREFIX + stage;
            const getURL = await S3Logic.fetchPostPresignedUrl(bucket);

            if (!getURL?.url.url) {
                // If URL cannot be fetched, do not attempt execution
                console.error("Unable to fetch URL, during file staging.", getURL.status);
                return;
            }
            setPresignedURLData(getURL);

            console.log("getPresignedURL: " + presignedURLData);
            const id = (getURL.url.fields.key).split('/')
            console.log("upload Id:", id[0]);
            setUploadId(id[0]);
            const formData = new FormData();
            // Append presigned URL fields to formData
            Object.keys(getURL.url.fields).forEach((key) => {
                formData.append(key, getURL.url.fields[key]);
            });
            // Append each file to formData and post to S3 bucket
            // https://docs.aws.amazon.com/AmazonS3/latest/API/RESTObjectPOST.html
            // The file or text content must be the last field in the form.
            formData.append("file", validFile[0]);

            const opts = {
                method: "POST",
                url: getURL.url.url,
                body: formData,
            };
            const response = await fetch(getURL.url.url, opts);
            if (response.ok) {
                console.log("File uploaded successfully: ", validFile[0].name);
                setUploadStatus(CONSTANT.FEEDBACK_UPLOAD_SUCCESS)
                console.log("getPresignedURL after single file uploaded: " + JSON.stringify(presignedURLData));

                setUploadStatus(CONSTANT.FEEDBACK_UPLOAD_MAPPING_FILE_IN_PROGRESS);
                setCurrentIndex(currentIndex + 1);

                // Initiate uploading mapping file
                await generateAndUploadCSV(getURL);
            } else {
                console.error("An error occurred during file upload:", response.status);
                setUploadStatus(CONSTANT.FEEDBACK_UPLOAD_ERROR)
            }
        } catch (error) {
            console.error("An error occurred during file upload:", error);
            setUploadStatus(CONSTANT.FEEDBACK_UPLOAD_ERROR)
        }
    };

    const handleFileCategoryValueChange = (name, value) => {
        Logger.logInfo("handleFileCategoryValueChange handleValueChange: " + name + " " + value);
        let otherCategoryDisabled = value === 'Other' ? false: true;

        setOtherCategoryDisabled(otherCategoryDisabled);
        setCategory(value);

        if (otherCategoryDisabled) {
            setOtherCategory("");
        }
    }

    const handleOtherCategoryValueChange = (name, value) => {
        Logger.logInfo("handleOtherCategoryValueChange: " + name + " " + value);
        setOtherCategory(value);
    };

    const generateCSVRow = (values) => {
        return `${values.documentName},${values.id},${values.category},${values.fileRequired},${values.uploadType}\n`
    }

    const generateCsvContent = (csvRow) => {
        const headers = 'Document Name,ID,Category,File Required,Upload Type';
        const row = csvRow.join('\n');
        return `${headers}\n${row}`;
    };

    const generateAndUploadCSV = async (presignedUrlPasedIn) => {
        const fileCategory = category === "Other" ? otherCategory : category;
        const newRow = generateCSVRow({
            id: props.id,
            documentName: validFile[0].name,
            category: fileCategory,
            fileRequired: "",
            uploadType: "",
        });

        const updatedCsvRow = [...csvRow, newRow];
        const csvContent = generateCsvContent(updatedCsvRow);
        // Create the Blob on CSV data
        const blob = new Blob([csvContent], { type: 'text/csv' });

        const formData = new FormData();
        // Append presigned URL fields to formData
        Object.keys(presignedUrlPasedIn.url.fields).forEach((key) => {
            formData.append(key, presignedUrlPasedIn.url.fields[key]);
        });

        formData.append("file",blob, 'MappingFile.csv');
        const opts = {
            method: "POST",
            url: presignedUrlPasedIn.url.url,
            body: formData,
        };
        const response = await fetch(presignedUrlPasedIn.url.url, opts);

        if (response.ok) {
            console.log("Mapping File uploaded successfully.");
            setUploadStatus(CONSTANT.FEEDBACK_UPLOAD_MAPPING_FILE_SUCCESS);
        } else {
            console.error("An error occurred during file upload: ", response.status);
            setUploadStatus(CONSTANT.FEEDBACK_UPLOAD_ERROR);
        }

        setCsvRow(updatedCsvRow);
        Logger.logInfo("after URL updatedCsvRow: " + updatedCsvRow);
    };

    const getStepContent = () => {
        let mappingFileForm = "";
        if (currentIndex === 1) {
            const fileCategory = category === "Other" ? otherCategory : category;
            mappingFileForm =  <MappingFileForm presignedURLData={presignedURLData}
                                    id={props.id}
                                    category={fileCategory}
                                    documentName={validFile[0].name}
                                    handleDismissSingleUpload={props.handleDismissSingleUpload}
                                    uploadId={uploadId}
            />
        }
        return (
            <div>
                    <div>
                            <Container
                                header={
                                    <Header variant="h2"
                                            actions={
                                                <Button formAction="none"
                                                        iconName="close"
                                                        variant="icon"
                                                        size='xl'
                                                        onClick={props.handleDismissSingleUpload}>Exit
                                                </Button>}
                                    >Upload Single Document</Header>
                                }
                        >
                            <FormField label="Select One document to upload."
                                       description=""
                            >
                                <FileUpload
                                    onChange={handleChooseFile}
                                    value={validFile}
                                    accept=".pdf, .doc, .docx, .xlsx, .csv, .msg, .png, .jpeg, .htm"
                                    i18nStrings={{
                                        uploadButtonText: e =>
                                            e ? "Choose files" : "Choose file",
                                        dropzoneText: e =>
                                            e
                                                ? "Drop files to upload"
                                                : "Drop file to upload",
                                        removeFileAriaLabel: e =>
                                            `Remove file ${e + 1}`,
                                        limitShowFewer: "Show fewer files",
                                        limitShowMore: "Show more files",
                                        errorIconAriaLabel: "Error"
                                    }}
                                    showFileLastModified
                                    showFileSize
                                    showFileThumbnail
                                    tokenLimit={3}
                                    constraintText="Only accept the following type: .pdf, .doc, .docx, .xlsx, .csv, .msg, .png, .jpeg, .htm"
                                />
                            <DropDownElement title={'File Category'}
                                             name={'category'}
                                             value={category}
                                             options={SINGLE_UPLOAD_FILE_CATEGORY_DROPDOWN_ITEMS}
                                             disabled={false}
                                             required={true}
                                             handleChange={handleFileCategoryValueChange}
                                             setErrorFlag={null}/>
                            <FreeTextElement title={"Other Category"}
                                             name={"category"}
                                             value={otherCategory}
                                             errorText={"ErrorText"}
                                             disabled={otherCategoryDisabled}
                                             required={false}
                                             handleChange={handleOtherCategoryValueChange}
                                             setErrorFlag={null}/>
                        </FormField>

                        <div>
                            <Box float='right'>
                                <Button variant="primary"
                                        disabled={uploadDisabled}
                                        onClick={handleFileUpload}>Upload Document</Button>
                            </Box>
                        </div>
                    </Container>
                </div>
                <div>
                    {mappingFileForm}
                </div>
            </div>
        )
    }
    return (
        <div>
            {getStepContent()}
            <br/>
            <br/>
            <UploadDocumentsStatus uploadStatus={uploadStatus}
                                   uploadId={uploadId}
            />
        </div>
    );
}
export default SingleDocumentModule;