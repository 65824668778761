import CONSTANT from "../../utils/constant";
import Logger from "../../utils/logger";
import React from "react";
import '@amzn/awsui-global-styles/polaris.css';
import FormatData from "../../utils/formatData";
import {PolarisV3FlashBar} from "../../component/dataDisplay";

/* Calculate the liquidity based on this formula:
  Sending Bank Account Balance
  MINUS (If Sending Account is XYZ, sum Sending Amount for all XYZ accounts in the Sending Account column)
  PLUS (sum Receiving Amount for all XYZ accounts in Receiving Account column)

  More Specifically, let
  [SENDING ACC] = sendingAccount of the paymentRecord.
  [RECEIVING ACC] = receivingAccount of the paymentRecord.
  [sendingAccountBalance] = sendingAccountBalance of the paymentRecord.
    1. netSendingAmount = find all rows in the allPaymentsDistribution that has [SENDING ACC] as sendingAccount and add up each sending amount.
    2. netReceivingAmount = find all rows in the table that has [SENDING ACC] as receivingAccount and add up each receiving amount.
    liquidity = sendingAccountBalance - netSendingAmount + netReceivingAmount)    */
export function calculateLiquidity(kyribaBankAccountToBalanceMap, paymentRecord, allPaymentsDistribution) {

    let sendingAccount = paymentRecord[CONSTANT.PR_COL_SENDING_ACCOUNT];
    let sendingAccountBalance = lookUpAccountBalance(kyribaBankAccountToBalanceMap, paymentRecord[CONSTANT.PR_COL_SENDING_ACCOUNT]);
    let netSendingAmount = 0;
    let netReceivingAmount = 0;

    if (!allPaymentsDistribution?.length ||
        typeof sendingAccount === 'undefined' ||
        typeof sendingAccountBalance === 'undefined') {
        return 'Not Available';
    }

    allPaymentsDistribution.forEach(currPayment => {
        if (currPayment[CONSTANT.PR_COL_SENDING_ACCOUNT] === sendingAccount) {
            netSendingAmount += Number(currPayment[CONSTANT.PR_COL_SENDING_AMOUNT]);
        }

        if (currPayment[CONSTANT.PR_COL_RECEIVING_ACCOUNT] === sendingAccount) {
            netReceivingAmount += Number(currPayment[CONSTANT.PR_COL_RECEIVING_AMOUNT]);
        }
    })

    return sendingAccountBalance - netSendingAmount + netReceivingAmount;
}

/* Determine the IC out 50% field based on this formula：
  [SENDING COX] = sendingCo of the paymentRecord.
  [sendingAccountBalance] = sendingAccountBalance of the paymentRecord.
    1. netSendingAmount = find all rows in the allPaymentsDistribution that has sending co as [SENDING COX] and add up each sending amount.
    2. netReceivingAmount = find all rows in the table that has receiving co as [SENDING COX] and add up each receiving amount.
    IC Out 50% =  (((netReceivingAmount - netSendingAmount) / (sendingAccountBalance * -1)) > 0.5) ? TRUE: FALSE
 */

export function ifSendingBalanceIsOverHalfOfSendingAccountBalance(kyribaBankAccountToBalanceMap, paymentRecord, allPaymentsDistribution) {
    let sendingCompany = paymentRecord[CONSTANT.PR_COL_SENDING_CO];
    let sendingAccountBalance = lookUpAccountBalance(kyribaBankAccountToBalanceMap, paymentRecord[CONSTANT.PR_COL_SENDING_ACCOUNT]);
    let netSendingAmount = 0;
    let netReceivingAmount = 0;

    if (!allPaymentsDistribution?.length || typeof sendingCompany === 'undefined' || typeof sendingAccountBalance === 'undefined') {
        return 'Not Available';
    } else if (sendingAccountBalance === 0) {
        return 'Zero Balance'
    }

    allPaymentsDistribution.forEach(currPayment => {
        if(currPayment[CONSTANT.PR_COL_SENDING_CO] === sendingCompany) {
            netSendingAmount += Number(currPayment[CONSTANT.PR_COL_SENDING_AMOUNT]);
        }

        if(currPayment[CONSTANT.PR_COL_RECEIVING_CO] === sendingCompany) {
            netReceivingAmount += Number(currPayment[CONSTANT.PR_COL_RECEIVING_AMOUNT]);
        }
    })

    return ((netReceivingAmount - netSendingAmount) / (sendingAccountBalance * -1)) > 0.5 ? 'Flag' : '';
}

//Look up the balance of the Kyriba account
export function lookUpAccountBalance(kyribaBankAccountToBalanceMap, accountCode) {
    if (Object.keys(kyribaBankAccountToBalanceMap).length !== 0) {
        let bankAccountBalanceRecord = kyribaBankAccountToBalanceMap[accountCode]
        if (bankAccountBalanceRecord) {
            return bankAccountBalanceRecord['availabilityBalance']
        }
    }
}

// Get the latest comment from the list of comments stored in a paymentRecord
export function getLatestComment(paymentRecord) {
    let userCommentList = [];
    try {
        if (paymentRecord[CONSTANT.PR_COL_USER_COMMENTS]) {
            userCommentList = JSON.parse(paymentRecord[CONSTANT.PR_COL_USER_COMMENTS])
        }

        if (userCommentList.length !== 0) {
            var latestComment = userCommentList[0];

            for (var i = 1; i < userCommentList.length; i ++) {
                if (Date.parse(userCommentList[i]['CommentDate']) > Date.parse(latestComment['CommentDate'])) {
                    latestComment = userCommentList[i];
                }
            }

            return latestComment['Comment'];
        }
    } catch(e) {
        return '';
    }
    return '';
}

// Look up the region based on the receivingAccountRegion or sendingAccountRegion of the paymentRecord.
export function lookupRegion(countryToRegionMap, paymentRecord, co, accountCode, companyCodeToKyribaBankAccountsMap) {
    let accountRegion = '';
    if (Object.keys(companyCodeToKyribaBankAccountsMap).length !== 0 &&
        Object.keys(countryToRegionMap).length !== 0 &&
        co !== undefined) {
        let accountsList = companyCodeToKyribaBankAccountsMap[co];
        if (accountsList !== undefined) {
            let kyribaAccount = accountsList.find(function (kyribaBankAccountRecord) {
                return kyribaBankAccountRecord['beneficiary'] === accountCode ;
            });

            if (kyribaAccount) {
                accountRegion = countryToRegionMap[kyribaAccount['categoryFive']];
            }
        }
    }
    return accountRegion;
}

// Populate sending/receiving region by the value of region field from the KyribaBankAccount table.
export function populateRegion(paymentRecord, columnName) {
    return (columnName === 'Sending Region') ? paymentRecord[CONSTANT.PR_COL_REGION] : paymentRecord[CONSTANT.PR_COL_RECEIVING_REGION];
}

// Look up the accountCategory of the sending/receiving account of the paymentRecord.
export function lookupAccountCategory4(paymentRecord, columnName, companyCodeToKyribaBankAccountsMap) {
    let accountCode = (columnName === 'Sending Account Type') ? paymentRecord[CONSTANT.PR_COL_SENDING_ACCOUNT] :
        paymentRecord[CONSTANT.PR_COL_RECEIVING_ACCOUNT];
    let companyCode = (columnName === 'Sending Account Type') ? paymentRecord[CONSTANT.PR_COL_SENDING_CO] :
        paymentRecord[CONSTANT.PR_COL_RECEIVING_CO];
    let accountList = companyCodeToKyribaBankAccountsMap[companyCode];

    let accountCategory = '';

    if (accountCode !== undefined
        && companyCode !== undefined
        && (Object.keys(companyCodeToKyribaBankAccountsMap).length !== 0)
        && accountList !== undefined) {

        let kyribaBankAccountRecord = accountList.find(function (kyribaBankAccountRecord) {
            return kyribaBankAccountRecord['beneficiary'] === accountCode ;
        });

        if (kyribaBankAccountRecord) {
            accountCategory = kyribaBankAccountRecord['categoryFour']
        }
    }

    return accountCategory;
}

// Look up the branch of the sending/receiving account of the paymentRecord.
export function lookupBranch(code, company, companyCodeToKyribaBankAccountsMap) {
    let accountBranch = '';
    if (Object.keys(companyCodeToKyribaBankAccountsMap).length !== 0 &&
        code !== undefined &&
        company !== undefined) {
        let accountsList = companyCodeToKyribaBankAccountsMap[company];
        if (accountsList !== undefined) {
            let kyribaAccount = accountsList.find(function (kyribaBankAccountRecord) {
                return kyribaBankAccountRecord['beneficiary'] === code ;
            });

            if (kyribaAccount) {
                accountBranch =  kyribaAccount['branch'];
            }
        }
    }
    return accountBranch;
}

// Determine whether the currency of a KryibaAccountRecord is RUB.
export function kyribaAccountHasRUBCurrency(kyribaAccountRecord) {
    if (kyribaAccountRecord && kyribaAccountRecord['currency'] === 'RUB') {
        return true;
    }
    return false;
}

/**
 * Get first day of the current month in YYYY-MM-DD format, using browser's local time.
 */
export function getFirstDayOfTheMonthInDateString() {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1; //Month is 0-indexed
    if (month < 10) {month = '0' + month.toString();}
    return year + '-' + month +  "-01";
}

/**
 * Get current Date in YYYY-MM-DD format, using browser's local time.
 */
export function getCurrentDateInDateString(offset) {
    let currentDate = new Date();
    if (offset && typeof offset === 'number') {
        currentDate.setDate(currentDate.getDate() + offset);
    }

    let year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1; //Month is 0-indexed
    if (month < 10) {month = '0' + month.toString();}
    let day = currentDate.getDate();
    if (day < 10) {day = '0' + day.toString();}
    return year + '-' + month +  '-' + day;
}

// Render the Modal message at the top of the UI container for displaying various states of the system
export function getFeedbackMessage(status, statusMsg) {
    Logger.logInfo("Modal Message " + status);
    switch (status) {
        case CONSTANT.MODAL_OTHER_ERROR:
            return (<PolarisV3FlashBar
                        dismissible={true}
                        content={<div>{statusMsg}</div>}
                        header="Error Occurred"
                        key={status}
                        type="error"/>);
        case CONSTANT.MODAL_UPDATE_ERROR:
            return (<PolarisV3FlashBar
                        dismissible={true}
                        content={<div>{FormatData.formatFailedUpdatedBatchIntoBrowserMessage(statusMsg)}</div>}
                        header="Update failed"
                        key={status}
                        type="error"/>);
        case CONSTANT.MODAL_QUERY_ERROR:
            return (<PolarisV3FlashBar
                        dismissible={true}
                        content={<div>Failed to Query the Records! {statusMsg}</div>}
                        header="Query failed"
                        key={status}
                        type="error"/>);
        case CONSTANT.MODAL_REQUEST_ERROR:
            return (<PolarisV3FlashBar
                        dismissible={true}
                        content={<div>Request Failed! {statusMsg}</div>}
                        header="Update failed"
                        key={status}
                        type="error"/>);
        case CONSTANT.MODAL_SYSTEM_ERROR:
            Logger.logInfo("MODAL_SYSTEM_ERROR ");
            return (<PolarisV3FlashBar
                        dismissible={true}
                        content={<div>Request Failed! {statusMsg}</div>}
                        header="System Error"
                        key={status}
                        type="error"/>);
        case CONSTANT.MODAL_EMPTY_COMMENTS:
            return (<PolarisV3FlashBar
                        dismissible={true}
                        content={<div>Please provide comment</div>}
                        header="Comments failed"
                        key={status}
                        type="error"/>);
        case CONSTANT.MODAL_PAYMENT_UPDATE_SUCCESS:
            return (
                <PolarisV3FlashBar
                        type="success"
                        content={<div>Settlements have been Updated/Rejected successfully</div>}
                        dismissible={true}
                        key={status}
                />);
        case CONSTANT.MODAL_APPROVE_SUCCESS:
            return (
                <PolarisV3FlashBar
                        type="success"
                        content={<div>Settlements have been Approved for liquidity</div>}
                        dismissible={true}
                        key={status}
                />);
        case CONSTANT.MODAL_NOTIFICATION_SUCCESS:
            return (
                <PolarisV3FlashBar
                        type="success"
                        content={<div>Email reminder has been successfully sent to corresponding groups.</div>}
                        dismissible={true}
                        key={status}
                />);
        case CONSTANT.MODAL_SUBMITTING:
            return <PolarisV3FlashBar
                type="success"
                loading={true}
                content={<>Updating... Please wait.</>}
                dismissible={false}
                key={status}
            />;
        case CONSTANT.MODAL_QUERYING:
            return <PolarisV3FlashBar
                type="success"
                loading={true}
                content={<>Busy fetching data... Please wait.</>}
                dismissible={false}
                key={status}
            />;
        case CONSTANT.MODAL_QUERY_RANGE_ERROR:
            return (
                <PolarisV3FlashBar
                        type="error"
                        content={<div>End Date cannot be ahead of Start Date</div>}
                        true={false}
                        header="Error"
                        key={status}
                />);
        case CONSTANT.MODAL_FILE_GENERATION_SUCCESS:
            return (
                <PolarisV3FlashBar
                        type="success"
                        content={<div>{statusMsg}</div>}
                        header="File Export Succeeded."
                        dismissible={true}
                        key={status}
                />);
        default:
            return;
    }
}