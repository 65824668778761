import React, {FunctionComponent, useState, useEffect} from 'react';
import TRSRoute from './TRSRoute';
import TRSRestrict from './TRSRestrict';
import {setUserInformation} from './TRSController';
import CONSTANT from './utils/constant';
import {ensureAuthenticated} from './auth/authenticate';
import {AuthStatus, BannerProps} from './model/Model';
import {CognitoAuth} from 'amazon-cognito-auth-js';
import {getStage} from './utils/environment';
import {AppLayout, AppLayoutProps, Spinner} from '@amzn/awsui-components-react';
import Navigation from './common/navigation';
import Banner from './common/banner';
import config from "./config/config.json"
import "./style/header.css";

/**
 * Defines the App layer of TRS.
 * Upon initial access, authentication via Cognito will be attempted and will either return TRS' landing page
 * or an error page mentioning that the user is not part of a valid group.
 * 
 * @returns {JSX.Element}
 *      The JSX element that contains TRS' base page.
 */
const TRSApp: FunctionComponent = (): JSX.Element => {

    const [authStatus, setAuthStatus] = useState<AuthStatus>("PENDING");
    const [bannerProps, setBannerProps] = useState<BannerProps>({photoUrl: '', userID: '', stage: 'dev'});

    // Retrieves all gated features per stage and passes them into the child components as props.
    const featureProps = { gatedFeatures: config.gatedFeatures[getStage()] };

    // Authentication will be attempted once upon initial mount of the component.
    useEffect(() => {
        ensureAuthenticated().then((auth: CognitoAuth) => {
            setUserInformation(auth);
            setAuthStatus("SUCCESS");
            setBannerProps({
                userID: localStorage.getItem(CONSTANT.LOCAL_STORAGE_USERID) ?? '',
                photoUrl: 'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + localStorage.getItem(CONSTANT.LOCAL_STORAGE_USERID),
                stage: getStage()
            });
        }).catch(() => {
            setAuthStatus("FAILURE");
        })
    }, []);

    // Returns a spinner if the authentication is ongoing, and an error message
    // if the authentication fails, or if the authenticated user is not part of
    // a valid LDAP group.
    switch (authStatus) {
        case "PENDING":
            return <Spinner />;
        case "FAILURE":
            return <TRSRestrict />;
        default:
    }
    
    // If the user is part of a valid LDAP group, generate the AppLayout for TRS
    const appLayoutProps: AppLayoutProps = {
        content: <TRSRoute {...featureProps} />,
        headerSelector: '[class^=menu-list-]',
        navigation: <Navigation {...featureProps} />,
        contentType: 'table',
        disableBodyScroll: true,
        toolsHide: true
    }

    // Serves the landing page to the user along with the Banner component.
    return <>
        <Banner {...bannerProps}/>
        <AppLayout {...appLayoutProps}/>
    </>;
}

export default TRSApp;