import * as React from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";

export const NoDocumentModal = (props) => {
    return (
        <Modal
            onDismiss={props.handleDismissDocumentModalClick}
            visible={true}
            header="Document"
        >No document under this transaction.
        </Modal>
    );
}