import React from "react";
import {
    Container,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween
} from "@amzn/awsui-components-react";

const MappingFileForm = (props) => {

    return<>
        <form onSubmit={e => e.preventDefault()}>
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                    </SpaceBetween>
                }
            >
                <Container
                    header={
                        <Header variant="h2"> Generated Mapping File for Single Upload
                        </Header>
                    }
                >
                    <SpaceBetween direction="vertical" size="l">
                        <FormField label="ID">
                            <Input value={props.id}
                                   type="text"
                                   readOnly
                            />
                        </FormField>
                        <FormField label="Document Name">
                            <Input value={props.documentName}
                                   type="text"
                                   readOnly
                            />
                        </FormField>
                        <FormField label="Category">
                            <Input value={props.category}
                                   type="text"
                                   readOnly
                            />
                        </FormField>
                    </SpaceBetween>
                </Container>
            </Form>
        </form>
    </>
}

export default MappingFileForm;