const Logger = {
    //TODO: need to replace with invoking logging service in future
    logError: function(error, errorInfo){
        console.log(error, errorInfo);
    },
    logInfo: function(message){
        console.log(message);
    }
};

export default Logger;