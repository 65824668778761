import CONSTANT from "./constant";
import Logger from "./logger";

class PaymentGeneration {

    static validatePaymentType = (payments, paymentType) => {
        Logger.logInfo("Verifying payment types match for: " + payments);
        let paymentsValid = true;

        payments.forEach(payment => {
            if (payment[CONSTANT.PR_COL_PAYMENT_TYPE] !== paymentType) {
                paymentsValid = false;
            }
        });
        return paymentsValid;
    }
}

export default PaymentGeneration;