import React, {Component} from 'react';
import {
    SpaceBetween,
} from "@amzn/awsui-components-react";
import CONSTANT, {REQUIRED_E_AND_I_FIELDS} from "../utils/constant";
import APIClient from "../utils/apiClient";
import {
    exceptionAPICallback,
    getCurrentDateInDateString,
    getFirstDayOfTheMonthInDateString,
    enrichElementsForDownload,
    stageHeaderColumnForDownload,
    getNewTTString, getDuplicateException
} from "./common/exceptionUtils";
import {
    SearchBar,
    ExceptionDataContentBlock,
    FeedbackMessage,
    getColumnDefinitionsAndTableConfiguration
} from "./common/exceptionComponents";
import {
    VISIBLE_COLUMN_OPTIONS_ALL_TRADES,
    HIDDEN_COLUMN_OPTIONS_ALL_TRADES,
    ALL_TRADES_ATTRIBUTES_FOR_DOWNLOAD,
    VISIBLE_COLUMN_OPTIONS_EXCEPTION,
    HIDDEN_COLUMN_OPTIONS_EXCEPTION
} from "../config/table";
import {getStage} from "../utils/environment";
import FormatData from "../utils/formatData";
import {DOWNLOAD_FILE_E_AND_I_DELIMITER} from "../config/file";
import xlsxParser from "../utils/xlsxParser";
import DocumentationModal from "./common/modal/documentationModal";
import { reAuthenticate } from '../auth/authenticate';
import Logger from "../utils/logger";
import {ExceptionCheckModal} from "./common/modal/exceptionCheckModal";
import {NoDocumentModal} from "../document/noDocumentModal";
import {QueryDocumentData} from "../document/queryDocumentData";
import SingleDocumentModule from "../document/upload/singleDocumentModule";

/**
 * The parent class of both the E&I and All Trades module, which wraps the DataContentBlock container and routes business logic handlers.
 */
class QueryExceptionData extends Component {
    constructor(props) {
        super(props);

        this.elementSelected = [];

        // [TO-DO]: A collective decision was made to leverage Redux--the user and userGroup will eventually be migrated over
        // to a global store once Redux integration is complete.
        this.userGroup = localStorage.getItem(CONSTANT.USER_GROUP_NAME) + "";
        this.user = localStorage.getItem(CONSTANT.LOCAL_STORAGE_USERID) + "";
        this.readAndWrite = (this.userGroup.includes(CONSTANT.USER_GROUP_MO) ||
            this.userGroup.includes(CONSTANT.USER_GROUP_PROD_ADMIN) ||
            (this.userGroup.includes(CONSTANT.USER_GROUP_DEVELOPER) && getStage() !== 'prod'));
        this.readOnly = !this.readAndWrite && (this.userGroup.includes(CONSTANT.USER_GROUP_IC_SETTLEMENT) || this.userGroup.includes(CONSTANT.USER_GROUP_REVIEWER)
            || this.userGroup.includes(CONSTANT.USER_GROUP_EXCEPTION_VIEW_ACCESS));
        this.userValid = this.readAndWrite || this.readOnly;

        this.state = {
            status: CONSTANT.MODAL_EMPTY,
            statusMsg: "",
            loading: false,
            distributions: [],
            exceptionsTradeIdMapping: [],
            potentialDuplicatedExceptions:[],
            duplicateExceptionVisible: false,
            documentationWindowVisible: false,
            recordIdToDocumentIdMapping: {},
            recordIdToDocumentIdMappingLoaded: false,
            downloadWindowVisible: false,
            downloadSelection: "",
            selectedElement: {},
            newComment: "",
            updateFlag: this.props.type === CONSTANT.EXCEPTIONS_MODULE,
            tempRelatedTT: "",
            newRelatedTT: "",
            resetTTComponent: false,
            feedbackFlashBarItems: [],
            fromValue: getFirstDayOfTheMonthInDateString(),
            toValue: getCurrentDateInDateString(),
            documentData:[],
            documentVisible: false,
            singleUploadVisible: false,
        };
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
    }

    setFeedbackFlashbarItems = (items) => {
        this.setState({feedbackFlashBarItems: items});
    }

    // queryDocuments for the queryDocumentData page.
    handleQueryDocument = () => {
        const id = this.state.selectedElement[CONSTANT.EI_COL_TRADE_ID];
        this.setState({
            status: CONSTANT.MODAL_QUERYING
        })

        Logger.logInfo("handleQueryDocument: " + JSON.stringify(id));
        const parameter = {
            id: id,
        };

        APIClient.invoke('GET', 'attachDocuments/viewDocument', parameter, this.state.elementSelected, (err, requestData) => {
            Logger.logInfo("Response data: " + JSON.stringify(requestData));
            if (!err) {
                switch (requestData.status) {
                    case CONSTANT.RESPONSE_SUCCESS:
                        this.setState({
                            documentData: requestData.data,
                            documentVisible: true,
                            status: CONSTANT.MODAL_UPDATE_SUCCESS
                        });
                        Logger.logInfo("Query Document Data succeed.");

                        break;
                    case CONSTANT.RESPONSE_ERROR:
                        Logger.logError("Error Query Document Data = " + this.state.elementSelected.toString());
                        this.setState({
                            documentVisible: false,
                            status: CONSTANT.MODAL_QUERY_ERROR
                        });
                        break;
                    case CONSTANT.RESPONSE_SYSTEM_ERROR:
                        Logger.logError("System Error when Query Document Data = " + this.state.elementSelected.toString());
                        this.setState({
                            status: CONSTANT.MODAL_SYSTEM_ERROR,
                            statusMsg: JSON.stringify(requestData['errorData'])
                        });
                        break;
                    default:
                }
            } else {
                Logger.logError("Other System Error when Querying Document Data = " + this.state.elementSelected.toString());
                if (this.state.status !== CONSTANT.MODAL_OTHER_ERROR) {
                    this.setState({
                        status: CONSTANT.MODAL_OTHER_ERROR,
                        statusMsg: "Other System Error when querying document data records."
                    });
                }
            }
        })
    };

    /**
     * Function for selection change in the data table.
     * The selectedElement is the JSON Object from list of rows selected.
     * If multiple rows selected, selectedElement is empty
     */
    onSelectionChange({detail}) {
        this.elementSelected = detail.selectedItems;
        this.setState({
            selectedElement: this.elementSelected.length === 1 ? this.elementSelected[0] : {},
            status: CONSTANT.MODAL_UPDATE_SUCCESS,
            tempRelatedTT: this.elementSelected.length === 1 ? this.elementSelected[0][CONSTANT.EI_COL_RELATED_TTS] : ""
        })
    }

    /**
     * Function for row click in the data table.
     * The selectedElement is the row selected.
     */
    onRowClick({detail}) {
        if (this.elementSelected.includes(detail.item)) {
            this.elementSelected = this.elementSelected.filter((x) => x !== detail.item)
        } else {
            this.elementSelected.push(detail.item)
        }
        this.setState({
            selectedElement: this.elementSelected.length === 1 ? this.elementSelected[0] : {},
            status: CONSTANT.MODAL_UPDATE_SUCCESS,
            tempRelatedTT: this.elementSelected.length === 1 ? this.elementSelected[0][CONSTANT.EI_COL_RELATED_TTS] : ""
        })
    }

    /**
     * Handle updates for Exception Status in Exception Table
     * selectedElement is a JSON object.
     * Previous key/value pairs need to be preserved.
     * Resolution Date must be populated or cleared depending on change.
     */
    handleExceptionStatusUpdate = (name, value) => {
        if (value === CONSTANT.EI_RESOLVED_STATUS) {
            this.handleFieldUpdate(CONSTANT.EI_COL_RESOLUTION_DATE, getCurrentDateInDateString());
        } else {
            this.handleFieldUpdate(CONSTANT.EI_COL_RESOLUTION_DATE, '');
        }
        this.handleFieldUpdate(name, value);
     };

    /**
     * Handle the dismiss Exception Modal
     * set both documentation Window Visible and duplicate Exception visible to false
     * user cannot create Exception.
     */
    handleDismissExceptionModalClick = () => {
        this.setState(
            {
                documentationWindowVisible: false,
                duplicateExceptionVisible: false,
            })
    }

    /**
     * HandleExceptionClick is created to make sure
     * the data from ExceptionsByTradeIdMapping is fully loaded
     * before the DuplicateExceptionCheck happens.
     */
    handleExceptionClick = () => {
        this.getExceptionsByTradeIDMapping()
            .then(() => {
                this.handleDuplicateExceptionCheck();
            })
            .catch((error) => {
                this.setState({
                    statusMsg: "Validation failed with the error: " + error + ". Please try again.",
                })
                Logger.logError("Error when calling the handleExceptionClick");
            });
    };

    // load the indexString- AllExceptionsTradeIdMapping in All Trades Module.
    getExceptionsByTradeIDMapping = () => {
        return new Promise((resolve, reject) => {
            const exceptionTradeIdParam = {
                indexString: CONSTANT.INDEX_STRING_EXCEPTION_MAPPING_BY_TRADE_ID,
            };
            APIClient.invoke('GET', "allTrades", exceptionTradeIdParam, undefined, (err, data) => {
                if (!err && data.status === CONSTANT.RESPONSE_SUCCESS) {
                    this.setState(
                        {
                            exceptionsTradeIdMapping: data.data,
                        },
                        () => {
                            Logger.logInfo("Querying ExceptionsByTradeIDMappingReview: " + JSON.stringify(this.state.exceptionsTradeIdMapping));
                            resolve();
                        }
                    );
                    resolve();
                } else {
                    Logger.logError("Error when Querying ExceptionsByTradeIDMappingReview");
                    this.setState(
                        {
                            status: err ? CONSTANT.MODAL_OTHER_ERROR : CONSTANT.MODAL_QUERY_ERROR,
                            exceptionsTradeIdMapping: err ? [] : data.data,
                            statusMsg: err ? "Other System Error when Querying ExceptionsByTradeIDMappingReview" : JSON.stringify(data['errorData']),
                        },
                        () => {
                            reject(err || data);
                        }
                    );
                    reject(err || data);
                }
            });
        });
    };

    /**
     * Handle the potential duplicated Exception check
     * where it shows the warning to users if exception already existed
     * under the same TradeId, Sold CCY and Amount of Sold CCY.
     *
     * First, use the map <TradeId, List<Exception>> and check against the TradeId
     * If TradeId is found, get the list of exception associated with Trade Id and filter the matching
     * Trade Id, Sold Amount, and Sold CCY.
     */
    handleDuplicateExceptionCheck = () => {
        let duplicateTradeID = JSON.stringify(this.state.exceptionsTradeIdMapping)
            .includes(JSON.stringify(this.state.selectedElement['Trade ID']));
        if (Object.keys(this.state.exceptionsTradeIdMapping).length !== 0 && duplicateTradeID) {
            const foundException = getDuplicateException(this.state.exceptionsTradeIdMapping, this.state.selectedElement);
            this.setState({
                duplicateExceptionVisible: true,
                documentationWindowVisible: false,
                potentialDuplicatedExceptions: foundException,
            })
        } else {
            // If no potential duplicate Exception found, allow to create Exception.
            this.handleViewModalClick();
        }
    }

    // Handles the view Documentation Window Click by setting the documentation window's visibility to true,
    // and forcing a refresh of the related TTs by togging the resetTTComponent.
    handleViewModalClick = () => {
        if (this.props.type === CONSTANT.ALL_TRADES_MODULE &&
            !(CONSTANT.EI_COL_INQUIRY_DATE in this.state.selectedElement)) {
            this.handleFieldUpdate(CONSTANT.EI_COL_INQUIRY_DATE, getCurrentDateInDateString());
        }
        this.setState({
            documentationWindowVisible: true,
            duplicateExceptionVisible: false,
            resetTTComponent: !this.state.resetTTComponent,
        });
    };

    // Handles the dismiss documentation click.
    //
    // Sets the documentationWindowVisible value to false, in addition to resetting the Related TT list to be
    // retrieved from selectedElement. This allows the Related TT tags to reset when reopening the modal in case the
    // user clicks "Cancel" instead of "Add". Also run resetTTComponent to rerender the component within the modal.
    handleDismissDocumentationClick = () => {
        this.setState({
            documentationWindowVisible: false,
            tempRelatedTT: this.props.type === CONSTANT.EXCEPTIONS_MODULE ? this.state.selectedElement[CONSTANT.EI_COL_RELATED_TTS] : "",
            resetTTComponent: !this.state.resetTTComponent
        })
    };

    // Function for changing states that aren't associated directly associated with a row in Exception Table.
    handleNonFieldUpdate = (name, value) => {
        this.setState({
            [name]: value
        })
    };

    /**
     * Handle updates to a row in Exception Table
     * The selectedElement is a JSON object.
     * Previous key/value pairs need to be preserved.
     */
    handleFieldUpdate = (name, value) => {
        this.setState(prevState => ({
            selectedElement:{
                ...prevState.selectedElement,
                [name]: value
            }
        }))
    };

    // Handles updating a comment related to an exception.
    handleCommentUpdate = (value) => {
        this.setState({
            newComment: value
        })
    };

    // Handles updating a row in the Exception Table.
    handleExceptionUpdate = () => {

        const missingFields = [];
        const requiredFields = [
            ...REQUIRED_E_AND_I_FIELDS
        ];

        if (CONSTANT.EI_SUBCATEGORY_LISTS[this.state.selectedElement[CONSTANT.EI_COL_EXCEPTION_CATEGORY]].length !== 0) {
            requiredFields.push(CONSTANT.EI_COL_EXCEPTION_SUBCATEGORY);
        }
        for (let i=0; i < requiredFields.length; i++) {
            const field = requiredFields[i];
            if (this.state.selectedElement[field] === undefined || this.state.selectedElement[field] === "") {
                missingFields.push(field);
            }
        }

        if (missingFields.length > 0) {
            this.setState({
                status: CONSTANT.MODAL_UPDATE_ERROR,
                statusMsg: "Missing fields: " + missingFields
            });
            return;
        }

        // If new comment, add to current comment list of JSON objects
        const newCommentArray = [];
        if (this.state.newComment !== "") {
            newCommentArray.push({
                commentDate: new Date().toDateString(),
                comment: this.state.newComment,
                user: this.user,
            });
        }

        this.setState({
            selectedElement: {
                ...this.state.selectedElement,
                [CONSTANT.EI_COL_RELATED_TTS]: this.props.type === CONSTANT.ALL_TRADES_MODULE
                    ? this.state.newRelatedTT
                    : getNewTTString(this.state.tempRelatedTT, this.state.newRelatedTT),
                [CONSTANT.EI_COL_COMMENTS]: [
                    ...(this.state.selectedElement[CONSTANT.EI_COL_COMMENTS] || []),
                    ...newCommentArray
                ],
                [CONSTANT.EI_COL_UPDATE_DATE]: FormatData.formatNewDate(),
                [CONSTANT.EI_COL_UPDATED_BY]: this.user
            },
            status: CONSTANT.MODAL_SUBMITTING,
            loading: true,
            newComment: "",
            documentationWindowVisible: false
        });
    };

    // Handles the query click to make a GET call against either the /allTrades or /exceptions endpoint and
    // populate the Cloudscape table.
    handleQueryClick = (indexString) => {
        this.elementSelected = [];
        this.setState({
            status: CONSTANT.MODAL_QUERYING
        });

        /**
         * Following groups get to review all Exceptions or All Trades:
         * Middle Office, Prod Admins, Developers in Non-Prod Env, and IC Settlements Group
         * If not a member of these groups, no Exceptions or All Trades Records will be returned
         * and info message will be displayed telling user they lack permissions.
         */
        if (this.userValid) {

            // Before running an API call to retrieve data corresponding to a date range, verifies that the date range is valid.
            // If the date range is invalid, display a flashbar indicating that the date range is invalid and return immediately without
            // making an API call.
            if (this.state.fromValue > this.state.toValue) {
                this.setState({
                    status: CONSTANT.DATE_RANGE_ERROR,
                    statusMsg: `"From Trade Date": ${this.state.fromValue} cannot be later than the "To Trade Date": ${this.state.toValue}.`
                });
                return;
            }

            const parameter = {
                indexString: indexString,
                userGroup: this.userGroup,
                fromValue: this.state.fromValue,
                toValue: this.state.toValue
            };

            const endpoint = this.props.type === CONSTANT.ALL_TRADES_MODULE ? "allTrades" : "exception";

            APIClient.invoke('GET', endpoint, parameter, undefined,
                (error, data) => {
                    this.setState(exceptionAPICallback(error, data));

                });
        } else {
            this.setState( {
                status: CONSTANT.RESPONSE_INVALID_REQUEST,
                statusMsg: "Insufficient permissions to view this table."
            });
        }

    };

    handleDismissSingleUpload = () => {
        this.setState({
            singleUploadVisible: false,
        })
    }
    handleSingleDocumentClick = () => {
        this.setState({
            singleUploadVisible: true
        })
    }

    handleDismissDocumentModalClick = () => {
        this.setState({
            documentVisible: false,
        })
    }

    // Handles the dismiss download Click by setting the visibility of the download modal to false.
    handleDismissDownloadClick = () => {
        this.setState({downloadWindowVisible: false})
    };

    // Handles view download Window Click by setting the visibility of the download modal to true.
    handleViewDownloadClick = (button_id) => {
        this.setState({
            downloadWindowVisible: true,
            downloadSelection: button_id
        })
    };

    // Handles the download items click by invoking the xlsxParser and prompting the download of either
    // a CSV file or an XLSX file based on the user's selection.
    handleDownloadItemsClick = (fileType, selectedDownload) => {
        const fileName = `TRS_${this.props.type}_Download_${FormatData.formatNewFileDate()}.${fileType}`;
        const delimiter = DOWNLOAD_FILE_E_AND_I_DELIMITER;
        const unprocessedHeader = this.props.type === CONSTANT.ALL_TRADES_MODULE ? ALL_TRADES_ATTRIBUTES_FOR_DOWNLOAD : VISIBLE_COLUMN_OPTIONS_EXCEPTION;
        const header = stageHeaderColumnForDownload(unprocessedHeader);

        let selectedItems;

        if (selectedDownload === "download_selected") {
            selectedItems = this.elementSelected
        } else if (selectedDownload === "download_all") {
            selectedItems = this.state.distributions
        }

        const enrichedItems = enrichElementsForDownload(selectedItems, unprocessedHeader, this.state.recordIdToDocumentIdMapping);

        if (fileType === 'xlsx') {
            xlsxParser.writeFile(fileName, delimiter, enrichedItems, header, fileType);
        } else if (fileType === 'csv') {
            xlsxParser.writeFileANSI(fileName, delimiter, enrichedItems, header);
        }

        this.setState({downloadWindowVisible:false})
    };

    componentDidMount() {

        // If the user cleared their cache while attempting to re-access the page, localStorage
        // needs to be re-updated with the authentication information.
        if (!localStorage.getItem(CONSTANT.USER_GROUP_NAME) || !localStorage.getItem(CONSTANT.LOCAL_STORAGE_USERID)) {
            reAuthenticate();
        }

        this.setState({
            status: CONSTANT.MODAL_QUERYING
        });

        /**
         * Following groups get to review all All Trades:
         * Middle Office, Prod Admins, Developers in Non-Prod Env, and IC Settlements Group
         * If not a member of these groups, no All Trades Records will be returned
         * and info message will be displayed telling user they lack permissions.
         */
        if (this.userValid) {
            const parameter = {
                indexString: CONSTANT.INDEX_STRING_EXCEPTION_BY_TRADE_DATE,
                userGroup: this.userGroup,
                fromValue: this.state.fromValue,
                toValue: this.state.toValue
            };

            const endpoint = this.props.type === CONSTANT.ALL_TRADES_MODULE ? "allTrades" : 'exception';

            APIClient.invoke('GET', endpoint, parameter, undefined,
                (error, data) => {
                    this.setState(exceptionAPICallback(error, data));
                });
        } else {
            this.setState( {
                status: CONSTANT.RESPONSE_INVALID_REQUEST,
                statusMsg: "Insufficient permissions to view this table."
            });
        }

        //Query RecordIdToDocumentIdMapping
        const paramGetRecordIdToDocumentIdMapping = {dataType:
            CONSTANT.QUERY_STRING_PARAM_TYPE_DOCUMENT_ID_MAPPING};

        APIClient.invoke('GET', '/attachDocuments/documentData', paramGetRecordIdToDocumentIdMapping,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    this.setState({
                        status: CONSTANT.MODAL_UPDATE_SUCCESS,
                        recordIdToDocumentIdMapping: data,
                        recordIdToDocumentIdMappingLoaded: true,
                    });
                    Logger.logInfo("recordIdToDocumentIdMaping state:" + JSON.stringify(this.state.recordIdToDocumentIdMapping))
                } else {
                    Logger.logError("Other Error when Querying recordIdToDocumentIdMapping information");
                    if (this.state.status !== CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            recordIdToDocumentIdMappingLoaded: true,
                            statusMsg: "Other System Error when Querying recordIdToDocumentIdMapping information"
                        });
                    }
                }
            });
    }

    // When the component is in the MODAL_SUBMITTING status, this.state.selectedElement will be used to run an API Gateway call.
    // At this point the elementSelected object itself shall not be updated (and hence the Cloudscape table shall not be updated),
    // because we do not know if the database persistence is successful at this point.
    handleSubmitState() {

        // Verified LDAP group of the user. If the user is not in a valid LDAP group, jump to the else statement.
        if (this.readAndWrite) {

            const parameter = {
                indexString: CONSTANT.INDEX_STRING_EXCEPTION_STATUS_REVIEW,
                request: "Update"
            };

            // Run the POST operation to save the updates currently in the temporary selectedElement.
            APIClient.invoke('POST', 'exception', parameter, [this.state.selectedElement], (error, data) => {

                // If the POST operation call is successful, the exceptionAPICallback will return MODAL_UPDATE_SUCCESS.
                // The MODAL_UPDATE_SUCCESS status is then used to determine if the exception update is successful
                // so that EXCEPTION_UPDATE_SUCCESS can be set as the new status, and the handleSuccessfulState function can be invoked.
                // Otherwise update the state to an error state so that the lifecycle hook can capture it.
                const returnState = exceptionAPICallback(error, data);
                const newState = returnState['status'] === CONSTANT.MODAL_UPDATE_SUCCESS
                    ? CONSTANT.EXCEPTION_UPDATE_SUCCESS
                    : CONSTANT.EXCEPTION_UPDATE_FAILURE;
                this.setState({
                    status: newState,
                    statusMsg: returnState['statusMsg'],
                    loading: false
                });
            });
        }
        // If the user is not in a valid LDAP group, display a flashbar indicating that the user does not have permissions.
        else {
            this.setState( {
                status: CONSTANT.RESPONSE_INVALID_REQUEST,
                statusMsg: "Insufficient permissions to update this table.",
                loading: false
            });
        }
    }

    // Handles the successful update scenario. Updates elementSelected to update the Cloudscape table, then sets the state to
    // reset the modal inputs. Moreover, a success flashbar will display at the top indicating that the exception creation was successful.
    handleSuccessState() {

        // Update elementSelected keys to execute a real-time update the Cloudscape table in the UI.
        for (let key in this.state.selectedElement) {
            if (Object.prototype.hasOwnProperty.call(this.state.selectedElement, key)) {
                this.elementSelected[0][key] = this.state.selectedElement[key];
            }
        }

        // Deletes selections made by the user to reset the modal inputs.
        let resetSelection = this.state.selectedElement;

        if (this.props.type === CONSTANT.ALL_TRADES_MODULE) {
            
            // Deleting system ID to avoid conflict with the Exception module's system ID.
            delete this.elementSelected[0][CONSTANT.EI_COL_SYSTEM_ID];

            delete resetSelection[CONSTANT.EI_COL_INQUIRY_DATE];
            delete resetSelection[CONSTANT.EI_COL_EXCEPTION_OWNER];
            delete resetSelection[CONSTANT.EI_COL_EXCEPTION_CATEGORY];
            delete resetSelection[CONSTANT.EI_COL_EXCEPTION_SUBCATEGORY];
            delete resetSelection[CONSTANT.EI_COL_RELATED_TTS];
            delete resetSelection[CONSTANT.EI_COL_EXCEPTION_STATUS];
            delete resetSelection[CONSTANT.EI_COL_RESOLUTION_DATE];
            delete resetSelection[CONSTANT.EI_COL_EXCEPTION_CCY];
            delete resetSelection[CONSTANT.EI_COL_EXCEPTION_COST_USD];
            delete resetSelection[CONSTANT.EI_COL_ROOT_CAUSE];
            delete resetSelection[CONSTANT.EI_COL_COMMENTS];
        }

        // Update the tile display and prompt a success flashbar display in the UI.
        this.setState({
            tempRelatedTT: this.props.type === CONSTANT.ALL_TRADES_MODULE ? "" : this.state.selectedElement[CONSTANT.EI_COL_RELATED_TTS],
            newRelatedTT: "",
            selectedElement: this.props.type === CONSTANT.ALL_TRADES_MODULE ? resetSelection : {},
            statusMsg: `Successfully ${this.props.type === CONSTANT.ALL_TRADES_MODULE ? 'created' : 'updated'} Exception for Trade ID - ${this.state.selectedElement[CONSTANT.EI_COL_TRADE_ID]}`,
        });

        // Reset selection if the user is in the Exception module.
        if (this.props.type === CONSTANT.EXCEPTIONS_MODULE) {
            this.elementSelected = [];
        }
    }

    // Update failure scenarios will be captured here--a flashbar will display indicating that there was an error in updating the modal.
    handleUpdateFailureState() {
        this.setState({
            statusMsg: `Error occurred while ${this.props.type === CONSTANT.ALL_TRADES_MODULE ? 'creating' : 'updating'} Exception for Trade ID - ${this.state.selectedElement[CONSTANT.EI_COL_TRADE_ID]}`,
        });
    }

    // Error scenarios will be captured here--a flashbar will display indicating that there was an error in updating the modal.
    handleErrorState() {
        this.setState({
            statusMsg: `System error occurred while querying ${this.props.type === CONSTANT.ALL_TRADES_MODULE ? 'All Trades' : 'Exceptions'}`,
        });
    }

    // Adding a componentDidUpdate lifecycle hook.
    // When the status updates to one of the query result statuses, execute the state handler corresponding to the new status.
    componentDidUpdate(_, prevState) {

        // The lifecycle hook tracks the status changes.
        if (prevState.status !== this.state.status) {
            switch (this.state.status) {

                // Handles the submission state scenario.
                case CONSTANT.MODAL_SUBMITTING:
                    this.handleSubmitState();
                    break;

                // Handles the successful update scenario.
                case CONSTANT.EXCEPTION_UPDATE_SUCCESS:
                    this.handleSuccessState();
                    break;

                case CONSTANT.EXCEPTION_UPDATE_FAILURE:
                    this.handleUpdateFailureState();
                    break;

                // Handles the error scenario.
                case CONSTANT.MODAL_UPDATE_ERROR:
                case CONSTANT.MODAL_REQUEST_ERROR:
                case CONSTANT.MODAL_SYSTEM_ERROR:
                case CONSTANT.MODAL_OTHER_ERROR:
                    this.handleErrorState();
                    break;
                
                default:
            }
        }
    }

    render() {
        const {visibleColumnOption, sortableColumns, columnDefinitions, filterOptions} = this.props.type === CONSTANT.ALL_TRADES_MODULE
                ? getColumnDefinitionsAndTableConfiguration(VISIBLE_COLUMN_OPTIONS_ALL_TRADES, HIDDEN_COLUMN_OPTIONS_ALL_TRADES, this.state.recordIdToDocumentIdMapping)
                : getColumnDefinitionsAndTableConfiguration(VISIBLE_COLUMN_OPTIONS_EXCEPTION, HIDDEN_COLUMN_OPTIONS_EXCEPTION, this.state.recordIdToDocumentIdMapping);
        const tableHeader = (
            <SpaceBetween direction="vertical" size="m">
                <FeedbackMessage status={this.state.status} statusMsg={this.state.statusMsg} setFeedbackFlashBarItems={this.setFeedbackFlashbarItems} />
                    <SearchBar handleQueryClick={this.handleQueryClick}
                            handleValueChange={this.handleNonFieldUpdate}
                            defaultFromValue={this.state.fromValue}
                            defaultToValue={this.state.toValue}
                            fromValue={this.state.fromValue}
                            toValue={this.state.toValue}
                            userValid={this.userValid}
                            type={this.props.type}
                    />
            </SpaceBetween>
        )

        /**
         * Display the Single Upload Document Module.
         */
        if (this.state.singleUploadVisible === true) {
            const recordId =  this.state.selectedElement[CONSTANT.EI_COL_TRADE_ID];
            return <SingleDocumentModule id={recordId}
                                         handleDismissSingleUpload ={this.handleDismissSingleUpload}
            />
        }

        return <>
            {this.state.duplicateExceptionVisible &&
                <ExceptionCheckModal
                    handleViewModalClick={this.handleViewModalClick}
                    handleDuplicateExceptionCheck={this.handleDuplicateExceptionCheck}
                    handleExceptionClick={this.handleExceptionClick}
                    handleDismissExceptionModalClick={this.handleDismissExceptionModalClick}
                    potentialDuplicatedExceptions={this.state.potentialDuplicatedExceptions}
                    duplicateExceptionVisible={this.state.duplicateExceptionVisible}
                    documentationWindowVisible={this.state.documentationWindowVisible}
                    columnDefinitions={columnDefinitions}
                    contentSelectorOptions={visibleColumnOption}
                    filterOptions={filterOptions}
                    elementSelected={this.elementSelected}
                    onSelectionChange={this.onSelectionChange}
                    sortableColumns={sortableColumns}
                />
            }
            {this.state.documentVisible === true && this.state.documentData.length > 0 &&
            <QueryDocumentData distributions={this.state.documentData}
                               handleDismissDocumentModalClick={this.handleDismissDocumentModalClick}
            />}
            {this.state.documentVisible === true && this.state.documentData.length === 0 &&
            <NoDocumentModal handleDismissDocumentModalClick={this.handleDismissDocumentModalClick}/>}
                    <DocumentationModal documentationWindowVisible={this.state.documentationWindowVisible}
                                        selectedElement={this.state.selectedElement}
                                        status={this.state.status}
                                        statusMsg={this.state.statusMsg}
                                        newComment={this.state.newComment}
                                        handleDismissClick={this.handleDismissDocumentationClick}
                                        handleFieldUpdate={this.handleFieldUpdate}
                                        handleNonFieldUpdate={this.handleNonFieldUpdate}
                                        handleExceptionStatusUpdate={this.handleExceptionStatusUpdate}
                                        handleCommentUpdate={this.handleCommentUpdate}
                                        resetTTComponent={this.state.resetTTComponent}
                                        setQueryState={this.setState.bind(this)}
                                        handleExceptionUpdate={this.handleExceptionUpdate}
                                        readOnly={this.readOnly}
                                        tempRelatedTTs={this.state.tempRelatedTT}
                                        updateFlag={this.state.updateFlag}
                                        type={this.props.type}/>
                    <ExceptionDataContentBlock distributions={this.state.distributions}
                                        tableHeader={tableHeader}
                                        loading={this.state.loading}
                                        handleViewDownloadClick={this.handleViewDownloadClick}
                                        downloadWindowVisible={this.state.downloadWindowVisible}
                                        downloadSelection={this.state.downloadSelection}
                                        handleDismissDownloadClick={this.handleDismissDownloadClick}
                                        filterOptions={filterOptions}
                                        contentSelectorOptions={visibleColumnOption}
                                        sortableColumns={sortableColumns}
                                        columnDefinitions={columnDefinitions}
                                        elementSelected={this.elementSelected}
                                        userValid={this.userValid}
                                        readOnly={this.readOnly}
                                        readAndWrite={this.readAndWrite}
                                        onSelectionChange={this.onSelectionChange}
                                        onRowClick={this.onRowClick}
                                        handleViewModalClick={this.handleViewModalClick}
                                        handleDuplicateExceptionCheck={this.handleDuplicateExceptionCheck}
                                        handleExceptionClick={this.handleExceptionClick}
                                        handleDownloadItemsClick={this.handleDownloadItemsClick}
                                        type={this.props.type}
                                        handleQueryDocument={this.handleQueryDocument}
                                        recordIdToDocumentIdMapping={this.state.recordIdToDocumentIdMapping}
                                        //Single Document Upload
                                        handleSingleDocumentClick={this.handleSingleDocumentClick}
                        />
                </>
    }
}

export default QueryExceptionData;
