import React, {Component} from "react";
import {
    Button,
    Modal,
    Textarea,
    Box,
    SpaceBetween,
    Table
} from "@amzn/awsui-components-react";
import '@amzn/awsui-global-styles/polaris.css';
import {PAYMENT_USER_COMMENTS_DEFINITION} from "../../../config/table";


// UI Modal for the Comment Window.
class CommentModal extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <Modal
            visible={this.props.commentVisible}
            header="Comments"
            expandToFit={true}
            onDismiss={this.props.handleDismissClick}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link"
                                onClick={() => this.props.handleDismissClick()}>Cancel</Button>
                        <Button variant="primary"
                                disabled={false}
                                onClick={() => this.props.handleAddCommentClick(this.props.elementSelected, this.props.newComment)}>Add</Button>
                    </SpaceBetween>
                </Box>}>
                         <Box>
                            <Box>
                                Add comments below.
                                <Textarea name="newComment" value={this.props.newComment}
                                          onChange={this.props.handleCommentUpdate}></Textarea>
                            </Box>

                                  <div>
                                        <Table
                                            columnDefinitions={PAYMENT_USER_COMMENTS_DEFINITION}
                                            items={this.props.userComments['userComment']}
                                            stickyHeader={true}
                                            resizableColumns={true}
                                            header={<b>Comments Log</b>}
                                            wrapLines={true}
                                        >
                                        </Table>
                                  </div>
                         </Box>
        </Modal>;
    }
}

export default CommentModal;