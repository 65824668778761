import React, {useState} from 'react';
import {Button, Header, Table} from "@amzn/awsui-components-react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Logger from "../utils/logger";
import {getStage} from "../utils/environment";
import S3Logic from "../utils/s3Logic";
import CONSTANT, {PERM_ATTACHING_DOCUMENT_BUCKET_PREFIX} from "../utils/constant";
import {DOCUMENT_DATA} from "../config/table";
import {DocumentFeedbackMessage} from "./upload/documentUtils";
import {DeleteSingleDocumentModal} from "./deleteSingleDocumentModal";


/**
 * Display the records in the Document table.
 */
export const QueryDocumentData = (props) => {
    const user = localStorage.getItem(CONSTANT.LOCAL_STORAGE_USERID);
    const [selectedItems, setSelectedItems] = useState([]);
    const [deleteWindowVisible, setDeleteWindowVisible] = useState(false);
    const [feedbackStatus, setFeedbackStatus] = useState("");
    /**
     * Function for row click in the data table.
     * The elementSelected is the row selected.
     */
    const onRowClick = ({detail}) => {
        let newElementSelected = [...selectedItems];
        if (newElementSelected.includes(detail.item)) {
            newElementSelected = newElementSelected.filter(x => x !== detail.item)
        } else {
            newElementSelected.push(detail.item)
        }
        onSelectionChange({detail: {selectedItems: newElementSelected}});
    };
    const onSelectionChange = ({ detail }) => {
        setSelectedItems(detail.selectedItems)
        Logger.logInfo("selectItems:" + JSON.stringify(selectedItems));
        Logger.logInfo("Length of selectItems:" + selectedItems.length);
    }

    const handleDownloadClick = async () => {
        Logger.logInfo("handle download Click: " + JSON.stringify(selectedItems));

        if (selectedItems.length < 1) {
            return;
        }

        let stage = getStage();
        if (stage === "dev") {
            stage = "beta";
        }
        const bucket = PERM_ATTACHING_DOCUMENT_BUCKET_PREFIX + stage;

        setFeedbackStatus(CONSTANT.MODAL_DOWNLOAD_SUBMITTING);

        try {
            for (const item of selectedItems) {
                const versionId = item.versionId;
                const documentName = item.documentName;

                Logger.logInfo(`download Click data: ${documentName} ${versionId} ${bucket}`);

                const urlResponse = await S3Logic.fetchPresignedUrl("GET", documentName, bucket, versionId);
                Logger.logInfo("URL Response: " + JSON.stringify(urlResponse.url));

                if (!urlResponse?.url) {
                    Logger.logError(`Unable to fetch URL for ${documentName}`);
                    continue; // Skip to next item if URL can't be fetched
                }

                // Fetch the file data from the presigned URL
                const response = await fetch(urlResponse.url);
                const blob = await response.blob();

                // Create a temporary download link
                // https://developer.mozilla.org/en-US/docs/Web/API/Response/blob
                Logger.logInfo("File blob: " + blob.type + " : " + blob);
                const downloadLink = document.createElement("a");
                downloadLink.href = URL.createObjectURL(blob);
                // Replace forward slashes with _ since documentName is the pattern: <settlement_id>/<file_name>
                // Sets the file download name to the documentName
                downloadLink.download = documentName.replace("/", "_");

                // Trigger the download by simulating a click
                document.body.appendChild(downloadLink);
                downloadLink.click();

                // Clean up
                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(downloadLink.href);

                // Add a small delay between downloads to prevent overwhelming the browser
                await new Promise(resolve => setTimeout(resolve, 500));
            }
            setFeedbackStatus(CONSTANT.MODAL_DOWNLOAD_SUCCESS);
        } catch (error) {
            Logger.logError("Error during download: " + error);
            setFeedbackStatus(CONSTANT.MODAL_DOWNLOAD_ERROR);
        }
    };


    const handleDeleteClick = () => {
        Logger.logInfo("handle delete Click: " + JSON.stringify(selectedItems));
        if (selectedItems.length !== 1) {
            return;
        }
        setSelectedItems(selectedItems);
        setDeleteWindowVisible(true);
    }

    const handleDismissDeleteClick = () => {
        setDeleteWindowVisible(false);
        setSelectedItems([]);
    }

    const handleCancelDeleteClick = () => {
        setDeleteWindowVisible(false);
    }

    return <>
        {
            deleteWindowVisible && <DeleteSingleDocumentModal user={user}
                                                              selectedItem={selectedItems}
                                                              dismissDeleteClick={handleDismissDeleteClick}
                                                              cancelDeleteClick={handleCancelDeleteClick}
                                                              deleteWindowVisible={deleteWindowVisible}
                                                              handleDismissDocumentModalClick={props.handleDismissDocumentModalClick}/>
        }
        <Modal
            onDismiss={props.handleDismissDocumentModalClick}
            visible={true}
            expandToFit={true}
            size="max"
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={props.handleDismissDocumentModalClick}>Cancel</Button>
                        <Button variant="primary" disabled ={selectedItems.length !== 1} onClick={handleDeleteClick}>Delete</Button>
                        <Button variant="primary" disabled ={selectedItems.length < 1} onClick={handleDownloadClick}>Download</Button>
                    </SpaceBetween>
                </Box>
            }
            header="Document(s)">
            <Table onRowClick={onRowClick}
                   onSelectionChange={onSelectionChange}
                   selectedItems={selectedItems}
                   columnDefinitions={DOCUMENT_DATA}
                   items={props.distributions}
                   stickyHeader={true}
                   resizableColumns={true}
                   header={<Header variant="h3">Document: </Header>}
                   wrapLines={true}
                   selectionType="multi"/>
            <br/>
            <br/>
            <DocumentFeedbackMessage status={feedbackStatus}/>
            <br/>
        </Modal>
    </>
}
