import React, {useEffect, useState} from 'react';
import '@amzn/awsui-global-styles/polaris.css';
import {
    Table,
    CollectionPreferences,
    PropertyFilter,
    Pagination,
    Box,
    Button,
    ColumnLayout,
    Toggle,
    SpaceBetween,
    RadioGroup,
    Checkbox,
    TextContent
} from "@amzn/awsui-components-react";
import {useCollection} from '@amzn/awsui-collection-hooks';
import {
    PAGE_SELECTOR_OPTIONS_SETTLEMENT,
    PROPERTY_FILTERING_I18N_CONSTANTS,
    PAGE_SIZE,
} from "../config/table";

/**
 * The DataContentTable functional component defines the generic table properties used across various modules within TRS.
 * @param props 
 *      The props that define the shape of the Polaris table.
 * @returns Table
 *      Returns a <Table> component that will adjust its shape depending on the props passed in.
 */
const DataContentTable = (props) => {


    const initializeCustom = (options) => {
        const visibleContentOptions = {};
        options?.forEach((attr) => {visibleContentOptions[attr.id] = attr.visible});
        return visibleContentOptions
    }

    // Initial preference values are defined for page size, visible columns, word wrap, and sorting so that the user would have a
    // default preference made available to them. The setPreferences will allow users to configure the preferences as needed within
    // the application.
    const [preferences, setPreferences] = useState({
        sortingDescending: props.sortingDescending === undefined ? true : props.sortingDescending,
        custom: {
            pageSize: PAGE_SIZE,
            visibleContent: props.contentSelectorOptions[0]?.options?.filter((attr) => attr.visible).map((attr) => attr.id),
            wrapLines: props.wrapLines === undefined ? true : props.wrapLines,
            visibleContentOptions: initializeCustom(props.contentSelectorOptions[0]?.options)
        }
    });


    useEffect(() => {
        const newVisibleContent = [];
        Object.entries(preferences.custom.visibleContentOptions).forEach(([key, checked]) => {
            if (checked) {
                newVisibleContent.push(key);
            }
        });
        setPreferences({
            custom: {
                ...preferences.custom,
                visibleContent: newVisibleContent
            }
        });
    }, [preferences.custom?.visibleContentOptions])

    // Filtering Properties are defined here, to be used in the useCollection hook to retrieve the
    // propertyFilterProps.
    const filteringProperties = props.filterOptions.map(filteringOption => {
        filteringOption.values = props.distributions.map(distribution => {
            if (typeof distribution[filteringOption.propertyKey] === 'number') {
                return distribution[filteringOption.propertyKey].toString();
            } else {
                return distribution[filteringOption.propertyKey];
            }
        });
        // The filtering is only done using the ":" operator, which is a wildcard search.
        // Searching on "trade" will return any text that contains "trade" without case sensitivity.
        // (e.g. "tradeStart", "noTrade", "traded", etc.)
        return {
            key: filteringOption.propertyKey,
            value: filteringOption.values,
            groupValuesLabel: "Properties",
            propertyLabel: filteringOption.propertyLabel,
            operators: [':', '!:', '=', '!='],
            defaultOperator: ':'
        }
    });

    // As of the Polaris v3 upgrade, the sorting field is defined within the Column Definitions.
    // As such, the columnDefinition is retrieved from the props and the sorting field is added
    // as a scaffolded property for each value within columnDefinitions.
    const columnDefinitionsWithSortingField = props.columnDefinitions.map((columnDefinition) => {
        return {
            ...columnDefinition,
            sortingField: columnDefinition.id
        }
    });

    // Returns the count of records in a Polaris table after filtering the data.
    const getFilterCounterText = count => `${count} ${count === 1 ? 'match' : 'matches'}`;

    // Uses the useCollection hook to allow client-side collection manipulation.
    // More details on the useCollection hook can be found here: https://polaris.a2z.com/get-started/dev-guides/collection-hooks/
    const {
        items,
        propertyFilterProps,
        filteredItemsCount,
        collectionProps,
        paginationProps
    } = useCollection(props.distributions, {
        propertyFiltering: {
            filteringProperties: filteringProperties,
        },
        sorting: props.defaultSortingState ? props.defaultSortingState : {},
        selection: {},
        pagination: {
            pageSize: preferences.custom?.pageSize,
        },
    });

    // Returns a Table Polaris component using the configuration setup defined within the props.
    // The Table component's specification is defined here: https://polaris.a2z.com/components/table/?tabId=playground
    return (
        <Table {...collectionProps}
            columnDefinitions={columnDefinitionsWithSortingField}
            items={items}
            stickyHeader={true}
            resizableColumns={true}
            header={props.header}
            trackBy={props.trackBy}
            pagination={<Pagination {...paginationProps} />}
            visibleColumns={preferences.custom?.visibleContent}
            wrapLines={preferences.custom?.wrapLines}
            selectedItems={props.elementSelected}
            selectionType="multi"
            onSelectionChange={props.onSelectionChange}
            isItemDisabled={props.isItemDisabled ? props.isItemDisabled : () => false}
            onRowClick={props.onRowClick ? props.onRowClick : null}
            filter={
                <PropertyFilter
                    {...propertyFilterProps}
                    i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                    filteringPlaceholder={PROPERTY_FILTERING_I18N_CONSTANTS.placeholder}
                    removeTokenButtonLabel={({propertyLabel, value}) =>
                        `Remove filtering token for ${propertyLabel} with value ${value}`
                    }
                    countText={getFilterCounterText(filteredItemsCount)}
                />
            }
            preferences={
                <CollectionPreferences
                    title="Preferences"
                    confirmLabel="Confirm"
                    onConfirm={({detail}) => setPreferences(detail)}
                    cancelLabel="Cancel"
                    preferences={preferences}
                    customPreference={(customValue, setCustomValue) => (
                        <ColumnLayout columns={2} borders="vertical">
                            <SpaceBetween direction="vertical" size="m">
                                Page Size
                                <RadioGroup items={props.pageSelectorOptions === undefined ? PAGE_SELECTOR_OPTIONS_SETTLEMENT: props.pageSelectorOptions}
                                    value={customValue.pageSize}
                                    onChange={({ detail }) => setCustomValue({
                                        ...customValue,
                                        pageSize: detail.value
                                    })}
                                />
                                <Checkbox checked={customValue.wrapLines}
                                    onChange={({detail}) => setCustomValue({
                                        ...customValue,
                                        wrapLines: detail.checked
                                    })}
                                >
                                    <Box padding="n">
                                        Wrap Lines
                                        <TextContent>
                                            <small>Check to wrap lines and see all text.</small>
                                        </TextContent>
                                    </Box>
                                </Checkbox>
                            </SpaceBetween>
                            <SpaceBetween direction="vertical" size="m">
                                Select Visible Columns
                                <SpaceBetween direction="horizontal" size="m">
                                    <Button onClick={() => {
                                        const newVisibleContentOptions = {};
                                        Object.keys(customValue.visibleContentOptions).forEach((key) => {
                                            newVisibleContentOptions[key] = true;
                                        })
                                        setCustomValue({
                                            ...customValue,
                                            visibleContentOptions: newVisibleContentOptions
                                        });
                                    }}>Select All</Button>
                                    <Button onClick={() => {
                                        const newVisibleContentOptions = {};
                                        Object.keys(customValue.visibleContentOptions).forEach((key) => {
                                            newVisibleContentOptions[key] = false;
                                        })
                                        setCustomValue({
                                            ...customValue,
                                            visibleContentOptions: newVisibleContentOptions
                                        });
                                    }}>Deselect All</Button>
                                </SpaceBetween>
                                <AttributeListWithToggle customValue={customValue} setCustomValue={setCustomValue}/>
                            </SpaceBetween>
                        </ColumnLayout>
                    )}
                />
            }
        />
    );
}

/**
 * A functional component that holds the list of attributes to show in the content preferences,
 * along with a corresponding toggle to adjust visibility.
 */
const AttributeListWithToggle = ({customValue, setCustomValue}) => {

    const attributeListWithToggle = [];
    Object.entries(customValue.visibleContentOptions).forEach(([key, checked]) => {
        attributeListWithToggle.push(
            <div style={{display: "flex", justifyContent: "space-between"}}>
                {key}
                <AttributeWithToggle toggleKey={key} toggleChecked={checked} customValue={customValue} setCustomValue={setCustomValue}/>
            </div>
        )
    });
    return <SpaceBetween direction="vertical" size="xs">
        <ColumnLayout borders="horizontal">{attributeListWithToggle}</ColumnLayout>
    </SpaceBetween>
}

/**
 * A functional component that holds the column name and its corresponding toggle for one attribute.
 * The checked state is maintained here and will invoke prop.setCustomValue every time the toggle
 * associated with an attribute is checked or unchecked.
 */
const AttributeWithToggle = ({toggleKey, toggleChecked, customValue, setCustomValue}) => {

    const [checked, setChecked] = useState(toggleChecked);

    useEffect(() => {
        setChecked(toggleChecked);
    }, [toggleChecked]);

    useEffect(() => {
        setCustomValue({
            ...customValue,
            visibleContentOptions: {
                ...customValue.visibleContentOptions,
                [toggleKey]: checked
            }
        });
    }, [checked]);

    return <Toggle onChange={({detail}) => setChecked(detail.checked)} checked={checked}/>
}

export default DataContentTable;