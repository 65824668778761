import React, {Component} from 'react';
import {
    Button,
    ColumnLayout,
    DatePicker,
    FormField,
    Modal,
    Table,
    Header,
    SpaceBetween,
    Box,
    Grid
} from "@amzn/awsui-components-react";
import {DropDownElement, FreeTextElement, TextAreaBlurElement} from "../../../component/dataEntry";
import CONSTANT, {EI_STATUS_LIST} from "../../../utils/constant";
import FormatData from "../../../utils/formatData";
import {USER_COMMENTS_DEFINITION} from "../../../config/table";
import {isDateOnOrBeforeCurrentDate} from "../exceptionUtils";
import {FeedbackMessage} from "../exceptionComponents";
import moment from "moment";
import {DocumentationModalRelatedTTs} from './documentationModalUtils';

class DocumentationModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            feedbackFlashBarItems: []
        }
    }

    setFeedbackFlashBarItems = (items) => {
        this.setState({feedbackFlashBarItems: items})
    }

    // Function for safely retrieving values from the selected row of the Exceptions Table
    getFromSelected = (key) => {
        return (key in this.props.selectedElement) ? this.props.selectedElement[key] : '';
    };

    // Function for safely retrieving comments from the selected row of the Exception Table
    getComments = () => {
        if (CONSTANT.EI_COL_COMMENTS in this.props.selectedElement) {
            return this.props.selectedElement[CONSTANT.EI_COL_COMMENTS];
        } else {
            return [];
        }
    };

    // Function to handle logic of determining valid Resolution Dates based on current date and Inquiry date
    isResolutionDateValid = (date) => {
        return this.getFromSelected(CONSTANT.EI_COL_INQUIRY_DATE) !== '' &&
               date >= moment(this.getFromSelected(CONSTANT.EI_COL_INQUIRY_DATE)) &&
               isDateOnOrBeforeCurrentDate(date)
    };

    // Get the list of selection options for the Sub-Category element
    getSelections = (mapping) => {
        if (mapping.length === 0) {
            return [];
        }
        return mapping.map(subCategory => ({
            label: subCategory,
            id: subCategory,
            value: subCategory
        }));
    };

    // Function to return list of possible Sub Category values based on chosen Category Value
    getSubCategoryValue = () => {
        const possibleSubCategories = CONSTANT.EI_SUBCATEGORY_LISTS[this.getFromSelected(CONSTANT.EI_COL_EXCEPTION_CATEGORY)];
        if (!possibleSubCategories.includes(this.getFromSelected(CONSTANT.EI_COL_EXCEPTION_SUBCATEGORY))) {
            delete this.props.selectedElement[CONSTANT.EI_COL_EXCEPTION_SUBCATEGORY]
        }
        return this.getFromSelected(CONSTANT.EI_COL_EXCEPTION_SUBCATEGORY);
    };

    render() {
        return <Modal
            visible={this.props.documentationWindowVisible}
            header={"Exception Documentation - " + this.getFromSelected(CONSTANT.EI_COL_TRADE_ID)}
            size={"large"}
            onDismiss={this.props.handleDismissClick}
            footer={
                <Box float="right"> {
                    this.props.readOnly ?
                        <Button variant="primary" onClick={this.props.handleDismissClick}>Close</Button> :
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={this.props.handleDismissClick}>Cancel</Button>
                            <Button variant="primary" onClick={this.props.handleExceptionUpdate}>{this.props.updateFlag ? "Update": "Add"}</Button>
                        </SpaceBetween>
                } </Box>
            }
            >
            <FeedbackMessage status={this.props.status} statusMsg={this.props.statusMsg} setFeedbackFlashBarItems={this.setFeedbackFlashBarItems} />
            <span>
                <ColumnLayout borders="vertical" columns={2}>
                        <div>
                            <FreeTextElement
                                title={"Trade ID"}
                                value={this.getFromSelected(CONSTANT.EI_COL_TRADE_ID)}
                                disabled={true}/>
                            <FreeTextElement
                                title={"Counterparty"}
                                value={this.getFromSelected(CONSTANT.EI_COL_COUNTERPARTY)}
                                disabled={true}/>
                            <FreeTextElement
                                title={"Trade Date"}
                                value={this.getFromSelected(CONSTANT.EI_COL_TRADE_DATE)}
                                disabled={true}/>
                            <FreeTextElement
                                title={"Value Date"}
                                value={this.getFromSelected(CONSTANT.EI_COL_VALUE_DATE)}
                                disabled={true}/>
                            <FreeTextElement
                                title={"Amount of Sold CCY"}
                                value={FormatData.formatDisplayAmount(this.getFromSelected(CONSTANT.EI_COL_SOLD_AMOUNT))}
                                disabled={true}/>
                            <FreeTextElement
                                title={"Sold CCY"}
                                value={this.getFromSelected(CONSTANT.EI_COL_SOLD_CCY)}
                                disabled={true}/>
                            <FreeTextElement
                                title={"Amount of Bought CCY"}
                                value={FormatData.formatDisplayAmount(this.getFromSelected(CONSTANT.EI_COL_BOUGHT_AMOUNT))}
                                disabled={true}/>
                            <FreeTextElement
                                title={"Bought CCY"}
                                value={this.getFromSelected(CONSTANT.EI_COL_BOUGHT_CCY)}
                                disabled={true}/>
                            <FreeTextElement
                                title={"Source Account Code"}
                                value={this.getFromSelected(CONSTANT.EI_COL_SOURCE_ACCOUNT_CODE)}
                                disabled={true}/>
                            <FreeTextElement
                                title={"MISYS ID"}
                                value={this.getFromSelected(CONSTANT.EI_COL_MISYS_ID)}
                                disabled={true}/>
                            <FreeTextElement
                                title={"Beneficiary Account Code"}
                                value={this.getFromSelected(CONSTANT.EI_COL_BENEFICIARY_ACCOUNT_CODE)}
                                disabled={true}/>
                        </div>
                        <div>
                            <FormField label="Inquiry Date*">
                                <DatePicker
                                             onChange={
                                                 event => {
                                                     this.props.handleFieldUpdate(CONSTANT.EI_COL_INQUIRY_DATE, event.detail.value)
                                                 }}
                                             isDateEnabled={(date) => isDateOnOrBeforeCurrentDate(date)}
                                             value={this.getFromSelected(CONSTANT.EI_COL_INQUIRY_DATE)}
                                             disabled={this.getFromSelected(CONSTANT.EI_COL_EXCEPTION_STATUS) === CONSTANT.EI_RESOLVED_STATUS || this.props.readOnly}
                                             nextMonthAriaLabel="Next month"
                                             placeholder="YYYY/MM/DD"
                                             previousMonthAriaLabel="Previous month"
                                             todayAriaLabel="Today"/>
                            </FormField>
                            <DropDownElement title={"Exception Owner"}
                                             name={CONSTANT.EI_COL_EXCEPTION_OWNER}
                                             value={this.getFromSelected(CONSTANT.EI_COL_EXCEPTION_OWNER)}
                                             options={this.getSelections(CONSTANT.EI_MO_USERS)}
                                             disabled={this.getFromSelected(CONSTANT.EI_COL_EXCEPTION_STATUS) === CONSTANT.EI_RESOLVED_STATUS || this.props.readOnly}
                                             required={true}
                                             handleChange={this.props.handleFieldUpdate}
                            />
                            <DropDownElement title={"Exception Category"}
                                             name={CONSTANT.EI_COL_EXCEPTION_CATEGORY}
                                             value={this.getFromSelected(CONSTANT.EI_COL_EXCEPTION_CATEGORY)}
                                             options={this.getSelections(CONSTANT.EI_CATEGORY_LIST)}
                                             disabled={this.getFromSelected(CONSTANT.EI_COL_EXCEPTION_STATUS) === CONSTANT.EI_RESOLVED_STATUS || this.props.readOnly}
                                             required={true}
                                             handleChange={this.props.handleFieldUpdate}
                            />
                            <DropDownElement title={"Exception Sub-Category"}
                                             name={CONSTANT.EI_COL_EXCEPTION_SUBCATEGORY}
                                             value={this.getSubCategoryValue()}
                                             options={this.getSelections(CONSTANT.EI_SUBCATEGORY_LISTS[this.getFromSelected(CONSTANT.EI_COL_EXCEPTION_CATEGORY)])}
                                             disabled={this.getFromSelected(CONSTANT.EI_COL_EXCEPTION_STATUS) === CONSTANT.EI_RESOLVED_STATUS ||
                                                       this.getSelections(CONSTANT.EI_SUBCATEGORY_LISTS[this.getFromSelected(CONSTANT.EI_COL_EXCEPTION_CATEGORY)]).length === 0 ||
                                                       this.props.readOnly}
                                             required={this.getSelections(CONSTANT.EI_SUBCATEGORY_LISTS[this.getFromSelected(CONSTANT.EI_COL_EXCEPTION_CATEGORY)]).length !== 0}
                                             handleChange={this.props.handleFieldUpdate}
                            />
                            {/* A custom modal was created to support the display of related TTs. */}
                            <DocumentationModalRelatedTTs title={"Related TTs (for more than one ticket, separate IDs with \",\")"}
                                            name={CONSTANT.EI_COL_RELATED_TTS}
                                            errorText={"ErrorText"}
                                            disabled={this.getFromSelected(CONSTANT.EI_COL_EXCEPTION_STATUS) === CONSTANT.EI_RESOLVED_STATUS || this.props.readOnly}
                                            required={false}
                                            tempRelatedTTs={this.props.tempRelatedTTs}
                                            currentRelatedTTs={this.getFromSelected(CONSTANT.EI_COL_RELATED_TTS)}
                                            resetTTComponent={this.props.resetTTComponent}
                                            setQueryState={this.props.setQueryState}
                            />
                            <DropDownElement title={"Exception Status*"}
                                             name={CONSTANT.EI_COL_EXCEPTION_STATUS}
                                             value={this.getFromSelected(CONSTANT.EI_COL_EXCEPTION_STATUS)}
                                             options={this.getSelections(EI_STATUS_LIST)}
                                             disabled={this.props.readOnly}
                                             required={false}
                                             handleChange={this.props.handleExceptionStatusUpdate}
                            />
                            <FormField label="Resolution Date">
                                <DatePicker
                                    onChange={event => {
                                        this.props.handleFieldUpdate(CONSTANT.EI_COL_RESOLUTION_DATE, event.detail.value)
                                    }}
                                    isDateEnabled={date =>
                                        this.isResolutionDateValid(date)
                                    }
                                    value={this.getFromSelected(CONSTANT.EI_COL_RESOLUTION_DATE)}
                                    disabled={this.getFromSelected(CONSTANT.EI_COL_EXCEPTION_STATUS) !== CONSTANT.EI_RESOLVED_STATUS || this.props.readOnly}
                                    nextMonthAriaLabel="Next month"
                                    placeholder="YYYY/MM/DD"
                                    previousMonthAriaLabel="Previous month"
                                    todayAriaLabel="Today"/>
                            </FormField>
                            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                                <DropDownElement title={'CCY'}
                                                 name={CONSTANT.EI_COL_EXCEPTION_CCY}
                                                 value={'USD'}
                                                 options={this.getSelections(['USD'])}
                                                 disabled={true}
                                                 required={false}
                                                 handleChange={this.props.handleFieldUpdate}
                                />
                                <FreeTextElement title={"Exception Cost"}
                                                 name={CONSTANT.EI_COL_EXCEPTION_COST_USD}
                                                 value={this.getFromSelected(CONSTANT.EI_COL_EXCEPTION_COST_USD)}
                                                 errorText={"ErrorText"}
                                                 disabled={this.getFromSelected(CONSTANT.EI_COL_EXCEPTION_STATUS) === CONSTANT.EI_RESOLVED_STATUS || this.props.readOnly}
                                                 required={false}
                                                 handleChange={this.props.handleFieldUpdate}
                                />
                            </Grid>
                            <h4>
                                Root Cause
                            </h4>
                            <TextAreaBlurElement title={CONSTANT.EI_COL_ROOT_CAUSE}
                                      name={CONSTANT.EI_COL_ROOT_CAUSE}
                                      value={this.getFromSelected(CONSTANT.EI_COL_ROOT_CAUSE)}
                                      disabled={this.getFromSelected(CONSTANT.EI_COL_EXCEPTION_STATUS) === CONSTANT.EI_RESOLVED_STATUS || this.props.readOnly}
                                      handleChange={this.props.handleFieldUpdate}/>
                            <h4>
                                User Comments
                            </h4>
                            <TextAreaBlurElement title={CONSTANT.EI_ATTR_NEW_COMMENT}
                                      name={CONSTANT.EI_ATTR_NEW_COMMENT}
                                      value={this.props.newComment}
                                      disabled={this.props.readOnly}
                                      handleChange={this.props.handleNonFieldUpdate}/>
                            <Table columnDefinitions={USER_COMMENTS_DEFINITION}
                                   items={this.getComments()}
                                   stickyHeader={true}
                                   resizableColumns={true}
                                   header={<Header variant="h2" >Comments Log</Header>}
                                   wrapLines={true}>
                            </Table>
                        </div>
                </ColumnLayout>
            </span>
        </Modal>
    }
}

export default DocumentationModal;