import React, {useReducer, useEffect, FunctionComponent} from 'react';
import CONSTANT from "../utils/constant";
import APIClient from "../utils/apiClient";
import {Button, Flashbar, Link, Box} from "@amzn/awsui-components-react";
import "../style/quicksightComponent.css";
import {arrayIntersection} from '../utils/arrayIntersection';
import {DashboardState, APIClientQuickSightRequest, APIClientQuickSightIngestionResponse, APIClientQuickSightDashboardUrlResponse} from './dashboardModel';
import {LambdaRuntimeErrorResponse} from '../model/Model';
import {useParams} from 'react-router';

//The initial state of the component that is defined upon initial mount, and post-useEffect trigger.
const initialState: DashboardState = {
    updateEnabled: false,
    url: "",
    quicksightUpdated: false,
    componentHeader: "",
    flashBarItems: [{
        type: "success",
        loading: true,
        dismissible: false,
        content: "Loading dashboard...",
    }],
    updateData: "",
}

/**
 * The component is responsible for rendering Quicksight dashboards.
 * 
 * @returns {JSX.Element}
 *       The React fragment contains the following:
 *          - A Polaris flashbar that is visible when the dashboard is loading or if it failed to load.
 *          - The iframe object containing the embedded Quicksight dashboard.
 *          - For TRS-owned dashboards, the "Update" button that reingests (refreshes) the dashboard.
 */
const Dashboards: FunctionComponent = (): JSX.Element => {

    // The user does not need to be persisted in the state, as it will stay
    // constant throughout the session post-authentication.
    const user: string = localStorage.getItem(CONSTANT.LOCAL_STORAGE_USERID) ?? "";

    const params = useParams(); 

    // Handles the "Update" button click - the click will attempt a reingestion
    // of the dashboard by invoking the quicksightIngestion endpoint.
    const handleIngestionClick: () => void = () => {

        const parameter: APIClientQuickSightRequest = {User:user, Email:user+"@amazon.com"};

        APIClient.invoke('GET', 'quicksightIngestion', parameter, undefined, (
            err: LambdaRuntimeErrorResponse,
            data: APIClientQuickSightIngestionResponse
        ) => {
                if (!err) {
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            setState({
                                updateData: data['data'] ?? "",
                                quicksightUpdated: true
                            });
                            break;
                        case CONSTANT.RESPONSE_SYSTEM_ERROR:
                            setState({
                                updateData: data['errorData'] ?? "",
                                quicksightUpdated: true
                            });
                            break;
                        default:
                    }
                } else {
                    setState({
                        flashBarItems: [{
                            type: "error",
                            loading: false,
                            content: "Failed to update the dashboard. Please try again or reach out to the administrators.",
                            dismissible: false
                        }],
                        quicksightUpdated: true
                    })
                }
            }
        );
    }

    let parameter: APIClientQuickSightRequest, endpoint: string;

    // Define a reducer to update the state in a sequential manner using the current and new state.
    // Defaults to the initialState defined above.
    const [state, setState] = useReducer((state: DashboardState, newState: Partial<DashboardState>) => {
        return {...state, ...newState}
    }, initialState);

    // The useEffect hook will trigger upon two conditions.
    // - 1. Upon initial mount of the component.
    // - 2. When the params.client value changes.
    // The second condition will occur if the user directly uses the navigation sidebar to
    // toggle between the two dashboard endpoints, thus prompting the frontend endpoint change
    // from /Dashboards/FX to /Dashboards/MO (or vice versa), since the endpoint is parameterized.
    useEffect(() => {

        // Reset the state back to its initial values.
        setState(initialState);

        // Verifies that the user is in an LDAP group that is allowed to access the /Dashboards/FX endpoint.
        if (params.client === CONSTANT.QUICKSIGHT_DASHBOARD_MAPPING.USER.FX_TEAM
            && arrayIntersection(
                [CONSTANT.USER_GROUP_REVIEWER, CONSTANT.USER_GROUP_PROD_ADMIN],
                localStorage.getItem(CONSTANT.USER_GROUP_NAME) ?? []
            ).length > 0) {
            parameter = {User:user, Email:user+"@amazon.com"};
            endpoint = CONSTANT.QUICKSIGHT_DASHBOARD_MAPPING.OWNER.TRS;
            setState({updateEnabled: true});
        }

        // Verifies that the user is in an LDAP group that is allowed to access the /Dashboards/MO endpoint.
        else if (params.client === CONSTANT.QUICKSIGHT_DASHBOARD_MAPPING.USER.MO_TEAM
            && arrayIntersection(
                [CONSTANT.USER_GROUP_MO, CONSTANT.USER_GROUP_PROD_ADMIN, CONSTANT.USER_GROUP_IC_SETTLEMENT, CONSTANT.USER_GROUP_REVIEWER],
                localStorage.getItem(CONSTANT.USER_GROUP_NAME) ?? []
            ).length > 0) {
            parameter = {User:user};
            endpoint = CONSTANT.QUICKSIGHT_DASHBOARD_MAPPING.OWNER.TCBI;
            setState({
                updateEnabled: false,
                componentHeader: "FX Settlements"
            });
        }
        
        // Otherwise, display an error flashbar and return immediately to bypass API Gateway invocation.
        else {
            setState({flashBarItems: [{
                type: "error",
                loading: false,
                content: "Invalid URL. Please try again by accessing the dashboard from the navigation bar.",
                dismissible: false
            }]});
            return;
        }

        // Invoke the /quicksightDashboard/{owner} endpoint to retrieve the appropriate dashboard URL
        // to embed.
        APIClient.invoke('GET', `quicksightDashboard/${endpoint}`, parameter, undefined, (
            err: LambdaRuntimeErrorResponse,
            data: APIClientQuickSightDashboardUrlResponse
        ) => {
            if (!err) {
                // If the /quicksightDashboard/{owner} API call contains a "Registered" parameter key and is not equal to false,
                // or if it does not contain a "Registered" key (indicating that the dashboard is TRS owned and thus
                // the registration status is not applicable), return the embed URL from the response. Moreover,
                // the flashbar will disappear if the quicksight dashboard load is successful.
                if (!(Object.prototype.hasOwnProperty.call(data, 'Registered')) || data.Registered !== false) {
                    setState({
                        url: data.URL,
                        flashBarItems: []
                    })
                    return;
                }
                // If the /quicksightDashboard/{owner} API call contains a "Registered" parameter and is equal to false,
                // do not render the dashboard--render an access denied flashbar instead as the user
                // does not have access to the dashboard.
                else if (data.Registered === false) {
                    setState({flashBarItems: [{
                        type: "error",
                        loading: false,
                        content: <>Access denied. Please follow the steps in this <Link color="inverted" href="https://w.amazon.com/bin/view/FinTech-Treasury/Data/TCBI/#HOnboardingtoAWSQuickSight" target="_blank">link</Link> to be granted access.</>,
                        dismissible: false
                    }]});
                    return;
                }
            }
            // If the two if statements above resolve to false or if the API response gave an err object, there is an issue with the API call.
            // Render a flashbar prompting the user to try again or reach out to the admins.
            setState({flashBarItems: [{
                type: "error",
                loading: false,
                content: "Failed to load dashboard. Please try again or reach out to the administrators.",
                dismissible: false
            }]});
        });
    }, [params.client]);

    // A React Fragment is returned that contains the flashbar needed to display loading and failure status,
    // and the dashboard itself with an optional "Update" button to invoke reingestion if the embedded dashboard
    // is owned by TRS.
    return <><Flashbar items={state.flashBarItems} /> {
        state.url && <Box textAlign='center' className="Quicksight-component">
            <Box variant="h1" textAlign='left'>{state.componentHeader}</Box>
            <iframe className="AnalyticsIframe" src={state.url} allowFullScreen={true} frameBorder="0"/>
            {
                state.updateEnabled &&
                <><p>{state.updateData}</p>
                <Button formAction="submit"
                        disabled={state.quicksightUpdated}
                        onClick={() => handleIngestionClick()}>
                    Update
                </Button></>
            }
        </Box>
    }</>
};

export default Dashboards;
