import React, {FC} from 'react';
import {useRoutes, RouteObject} from 'react-router-dom';
import TRSWelcome from './TRSWelcome';
import QueryTradeData from './trade/queryTradeData';
import QueryHistoryTradeData from './trade/queryHistoryTradeData';
import QueryHistoryPaymentData from './payment/queryHistoryPaymentData';
import QueryApprovedPaymentData from './payment/queryApprovedPaymentData';
import QueryPaymentData from './payment/queryPendingPaymentData';
import SingleTradeModule from './upload/singleTradeModule';
import QueryExceptionData from './exception/queryExceptionData';
import BulkDocumentModule from "./document/upload/bulkDocumentModule";
import Dashboards from './dashboards/dashboards';
import CONSTANT from './utils/constant';
import { TRSFeatureProps } from './model/Model';
import { InvokeExternalUrl } from './TRSExternal';

/**
 * Defines the routing for TRS. The path associated with each navigation selection is defined in the
 * Navigation component. This file associates the path with the component to be rendered.
 * 
 * @returns {JSX.Element}
 */
const TRSRoute: FC<TRSFeatureProps> = (): JSX.Element => {
    const routes: RouteObject[] = [
        {path: '/', element: <TRSWelcome />},
        {path: 'index.html', element: <TRSWelcome />},
        {path: 'QueryTrade', element: <QueryTradeData />},
        {path: 'QueryHistoryTradeData', element: <QueryHistoryTradeData />},
        {path: 'QueryPendingSettlement', element: <QueryPaymentData />},
        {path: 'QueryApprovedSettlement', element: <QueryApprovedPaymentData />},
        {path: 'QueryHistoricalSettlement', element: <QueryHistoryPaymentData />},
        {path: 'UploadSingleTradeRequest', element: <SingleTradeModule />},
        {path: 'DocumentRequest', element: <BulkDocumentModule />},
        {path: 'UploadFile', element: <InvokeExternalUrl externalUrlKey='FocusUrl' />},
        {path: 'Approval', element: <InvokeExternalUrl externalUrlKey='ApprovalUrl' />},
        {path: 'QueryException', element: <QueryExceptionData type={CONSTANT.EXCEPTIONS_MODULE} key={CONSTANT.EXCEPTIONS_MODULE}/>},
        {path: 'QueryAllTrades', element: <QueryExceptionData type={CONSTANT.ALL_TRADES_MODULE} key={CONSTANT.ALL_TRADES_MODULE}/>},
        {path: 'Dashboards/:client', element: <Dashboards />},
        {path: '*', element: <TRSWelcome />}
    ]
    return useRoutes(routes) || <TRSWelcome />
};

export default TRSRoute;