import React, {Component, useState, useEffect} from 'react';
import {FormField, Input, Select, Textarea} from '@amzn/awsui-components-react';
import ErrorBoundary from '../err/errorBoundary';
import {DatePicker} from 'antd';
import 'antd/dist/antd.min.css';
import config from '../config/config';
import moment from 'moment-timezone';

//Component for free text input
class FreeTextElement extends Component {
    constructor(props) {
        super(props);
        this.state = {errorText: ''};
    }

    handleChange = (event) => {
        let inputValue = event.detail.value;
        this.props.handleChange(this.props.name, inputValue);
        if (this.props.required && inputValue === "") {
            this.setState({errorText: 'This field is required'});
        } else {
            this.setState({errorText: ''});
        }
        if (this.props.setErrorFlag) {
            this.props.setErrorFlag(this.props.name, this.state.errorText);
        }
    };

    render() {
        return (
            <ErrorBoundary content={this.props.title}>
                <FormField label={this.props.title + (this.props.required ? '*' : '')}
                           description={this.props.description}
                           errorText={this.state.errorText}>
                    <Input name={this.props.name}
                           value={this.props.value}
                           onChange={this.handleChange}
                           placeholder={this.placeholder}
                           disabled={this.props.disabled}/>
                </FormField>
            </ErrorBoundary>);
    }
}

// A component used to support Text Area inputs with a decoupled state managed within it.
// Note that the TextAreaBlurElement uses onBlur to trigger handleChange instead of onChange. This
// means that handleChange will only trigger when the browser focus shifts from this element
// to a different one, and will use the managed state within the component to carry this out.
const TextAreaBlurElement = (props) => {

    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        setInputValue(props.value);
    }, [props.value]);

    return <ErrorBoundary content={props.title}>
        <Textarea
            disabled={props.disabled === true}
            value={inputValue}
            onChange={({detail}) => setInputValue(detail.value)}
            onBlur={() => props.handleChange(props.name, inputValue)}
        />
    </ErrorBoundary>;
}

//Component for alphanumeric text input
class FreeTextAlphaNumericElement extends Component {
    constructor(props) {
        super(props);
        this.state = {errorText: ''};
    }

    handleChange = (event) => {
        let inputValue = event.detail.value;
        this.props.handleChange(this.props.name, inputValue);
        if (this.props.required && inputValue === "") {
            this.setState({errorText: 'This field is required'});
        } else if (!inputValue.match(/^[0-9a-zA-Z]+$/)) {
            this.setState({errorText: 'This field can only be alphanumeric'});
        }else {
            this.setState({errorText: ''});
        }
        if (this.props.setErrorFlag) {
            this.props.setErrorFlag(this.props.name, this.state.errorText);
        }
    };

    render() {
        return (
            <ErrorBoundary content={this.props.title}>
                <FormField label={this.props.title + (this.props.required ? '*' : '')}
                           description={this.props.description}
                           errorText={this.state.errorText}>
                    <Input name={this.props.name}
                           value={this.props.value}
                           onChange={this.handleChange}
                           placeholder={this.placeholder}
                           disabled={this.props.disabled}/>
                </FormField>
            </ErrorBoundary>);
    }
}


//Component for decimal with comma input
class DecimalWithCommaElement extends Component {
    constructor(props) {
        super(props);
        this.state = {errorText: ''};
    }

    handleChange = (event) => {
        let inputValue = event.detail.value;
        if (inputValue === '') {
            if (this.props.required)
                this.setState({errorText: 'This field is required.'});
        } else {
            let inputValueNoComma = inputValue.replaceAll(',', '');
            if (isNaN(inputValueNoComma)) {
                this.setState({errorText: (inputValue + " is not a valid decimal")});
            } else if (this.props.max !== undefined && Number(inputValueNoComma) > Number(this.props.max)) {
                this.setState({errorText: 'Only decimal <= ' + this.props.max + " are allowed"});
            } else if (this.props.min !== undefined && Number(inputValueNoComma) < Number(this.props.min)) {
                this.setState({errorText: 'Only decimal >= ' + this.props.min + " are allowed"});
            } else this.setState({errorText: ''});
        }
        this.props.handleChange(this.props.name, inputValue);
        if (this.props.setErrorFlag) {
            this.props.setErrorFlag(this.props.name, this.state.errorText);
        }

    };

    render() {
        return (
            <ErrorBoundary content={this.props.title}>
                <FormField label={this.props.title + (this.props.required ? '*' : '')}
                           description={this.props.description}
                           errorText={this.state.errorText}>
                    <Input name={this.props.name}
                           value={this.props.value}
                           onChange={this.handleChange}
                           placeholder={this.placeholder}
                           disabled={this.props.disabled}/>
                </FormField>
            </ErrorBoundary>);
    }
}

//Component for enum input
class DropDownElement extends Component {
    constructor(props) {
        super(props);
        this.state = {errorText: ''};
    }

    handleChange = (event) => {
        let selectedValue = event.detail.selectedOption?.value;
        if (selectedValue === "" && this.props.required) this.setState({errorText: 'This field is required'});
        else this.setState({errorText: ''});
        this.props.handleChange(this.props.name, selectedValue);
        if (this.props.setErrorFlag) {
            this.props.setErrorFlag(this.props.name, this.state.errorText);
        }

    };
    render() {
        return (
            <ErrorBoundary content={this.props.title}>
                <FormField label={this.props.title + (this.props.required ? '*' : '')}
                           description={this.props.description}
                           errorText={this.state.errorText}>
                    <Select options={this.props.options}
                            loadingText="Loading..."
                            selectedOption={{id: this.props.value, label: this.props.value, value: this.props.value}}
                            placeholder={this.props.value}
                            filteringType="auto"
                            onChange={this.handleChange}
                            disabled={this.props.disabled}
                            virtualScroll={true}/>
                </FormField>
            </ErrorBoundary>
        );
    }
}

//Component for date input
class FutureDateElement extends Component {
    constructor(props) {
        super(props);
    }

    handleChange = (date, dateString) => {
        //according to ant design library, date with invalid format won't trigger this function
        this.props.handleChange(this.props.name, dateString);
    };

    render() {
        let datePicker = "";
        if (this.props.value === undefined || this.props.value === '') {
            datePicker =
                <DatePicker defaultValue={moment(this.props.defaultValue)} format={config.dateFormat}
                            onChange={this.handleChange} disabled={this.props.disabled}
                            disabledDate={(current) => {
                                return moment().add(-1, 'days') >= current;
                            }}/>
        } else {
            datePicker =
                <DatePicker defaultValue={moment(this.props.defaultValue)} format={config.dateFormat}
                            onChange={this.handleChange} value={moment(this.props.value)}
                            disabled={this.props.disabled} disabledDate={(current) => {
                    return moment().add(-1, 'days') >= current;
                }}/>
        }
        return (
            <ErrorBoundary content={this.props.title}>
                <FormField label={this.props.title + (this.props.required ? '*' : '')}
                           description={this.props.description}
                           errorText={this.props.errorText}>
                    {datePicker}
                </FormField>
            </ErrorBoundary>);
    }
}

//Future Date based on Options in Single Trade Request
class FutureDateSTRElement extends Component {
    constructor(props) {
        super(props);
    }

    handleChange = (date, dateString) => {
        //according to ant design library, date with invalid format won't trigger this function
        this.props.handleChange(this.props.name, dateString);
    };

    render() {
        let datePicker = "";
        if (this.props.value === undefined || this.props.value === '') {
            datePicker =
                <DatePicker format={config.dateFormat}
                            onChange={this.handleChange} disabled={this.props.disabled}
                            disabledDate={(current) => {
                                return moment().add(0, 'days') >= current;
                            }}/>
        } else {
            datePicker =
                <DatePicker format={config.dateFormat}
                            onChange={this.handleChange} value={moment(this.props.value)}
                            disabled={this.props.disabled} disabledDate={(current) => {
                    return moment().add(0, 'days') >= current;
                }}/>
        }
        return (
            <ErrorBoundary content={this.props.title}>
                <FormField label={this.props.title + (this.props.required ? '*' : '')}
                           description={this.props.description}
                           errorText={this.props.errorText}>
                    {datePicker}
                </FormField>
            </ErrorBoundary>);
    }
}


export {
    FreeTextElement,
    TextAreaBlurElement,
    FreeTextAlphaNumericElement,
    DecimalWithCommaElement,
    DropDownElement,
    FutureDateElement,
    FutureDateSTRElement
}
