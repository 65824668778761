import React, {Component} from "react";
import {
    Button,
    Modal,
    DatePicker,
    TimeInput,
    ColumnLayout,
    Box,
    SpaceBetween
} from "@amzn/awsui-components-react";
import '@amzn/awsui-global-styles/polaris.css';
import CONSTANT from "../../../utils/constant";
import {PolarisV3FlashBar} from "../../../component/dataDisplay";


// UI Modal for the Comment Window.
class EmailNotificationModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputEventDate: '',
            inputEventTime: '00:00',
            validationFailureMessage: '',
            status: CONSTANT.MODAL_CREATE_SUCCESS
        }
    }

    /**
     * Determine whether a valueDate is NOT before the Current Date, current date being in browser's locale time.
     * @param valueDate
     */
    isValueDateNotBeforeCurrentDate = (valueDate) => {
        const currentDate = new Date().setHours(0,0,0,0);
        return valueDate >= currentDate;
    }

    /**
     * Handler for clicking sendEmail button
     */
    handleSendEmailClick = () => {
        if (this.state.inputEventDate === '' || this.state.inputEventTime === '') {
            this.setState({
                status: CONSTANT.MODAL_UPDATE_VALIDATION_FAIL,
                validationFailureMessage: "The deadline date and time cannot be empty."
            })
        } else {
            let deadline = this.state.inputEventDate + " " + this.state.inputEventTime + " PST";
            this.props.handleSendEmailNotification(deadline);
            this.resetStateDataAndDismiss();
        }
    }

    // Render the Modal message and progress bar based on the modal state
    getFeedbackMessage = () => {
        switch (this.state.status) {
            case CONSTANT.MODAL_UPDATE_VALIDATION_FAIL:
                return (
                    <PolarisV3FlashBar type="error"
                           content={<div>{this.state.validationFailureMessage} </div>}
                           dismissible={false}
                           header="Send Email failed"
                           key={this.state.status}
                    />);
            default:
                return;
        }
    }

    resetStateDataAndDismiss = () => {
        this.setState({
            inputEventDate: '',
            inputEventTime: '00:00',
            validationFailureMessage: '',
            status: CONSTANT.MODAL_CREATE_SUCCESS
        });

        this.props.handleDismissClick();
    }

    render() {
        return <Modal
            visible={this.props.emailNotificationWindowVisible}
            header="Send Email Notification"
            expandToFit={true}
            onDismiss={this.resetStateDataAndDismiss}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link"
                            onClick={() => this.resetStateDataAndDismiss()}>Cancel</Button>
                        <Button variant="primary"
                            disabled={false}
                            onClick={() => this.handleSendEmailClick()}>Send Email</Button>
                </SpaceBetween>
                </Box>}>
                    <Box>
                            <Box>
                                Please specify a time that cash positioners will be notified to complete the checks by.
                            </Box>
                            <div>
                                {this.getFeedbackMessage()}
                            </div>

                    </Box>
                    <ColumnLayout columns={2}>
                        <DatePicker
                            onChange={event => {
                                this.setState({ inputEventDate: event.detail.value })
                            }}
                            isDateEnabled={date =>
                                this.isValueDateNotBeforeCurrentDate(date)
                            }
                            value={this.state.inputEventDate}
                            nextMonthAriaLabel="Next month"
                            placeholder="YYYY/MM/DD"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"/>

                        <TimeInput
                            onChange={event => {
                                this.setState({ inputEventTime: event.detail.value })
                            }}
                            value={this.state.inputEventTime}
                            format="hh:mm"
                            placeholder="hh:mm"/>
                    </ColumnLayout>
        </Modal>;
    }
}

export default EmailNotificationModal;