import config from '../config/config.json';
import { Stage } from '../model/Model';

//get the stage this application is running on based on url
export const getStage = (): Stage => {
    const url: string = window.location.host;
    let stage: Stage;
    if(url.includes(config.urlSignature.dev)){
        stage = "dev";
    } else if(url.includes(config.urlSignature.beta)){
        stage = "beta";
    } else if(url.includes(config.urlSignature.gamma)) {
        stage = "gamma";
    } else if(url.includes(config.urlSignature.prod)) {
        stage = "prod";
    } else {
        throw new Error(`Invalid stage with url = ${url}`);
    }
    return stage;
}