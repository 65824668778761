/**
 * A utility function that takes in any number of parameters, and retrieves the array intersection between the inputs.
 * 
 * @params inputs: Rest parameter of string[] or string
 *      A REQUIRED parameter that contains an array of strings. If this is the only parameter passed in, it will retrieve
 *      the array intersection between this array and the LDAP group membership of the authenticated user.
 * @returns: string[]
 *      Returns the intersection between all input strings.
 * 
 * 0 arguments: Returns empty array.
 * 
 * 1 argument: Returns input as is.
 * 
 * 2 or more Inputs: If argument 1 contains [LDAP1, LDAP2] and argument 2 contains [LDAP2, LDAP3],
 * the function will return the overlapping group membership between the two arrays as an array. In this case that would be [LDAP2].
 * 
 * Note: The first array will be filtered by its subsequent arrays. That means if there are duplicates in the first array, they will not be de-dup'd.
 */
export const arrayIntersection = (...args: [string[], ...Array<string[] | string>]): string[] => {
    // Iteratively runs intersections against each array argument when 2 or more arguments are passed in.
    if (args.length > 1) {
        return args.reduce((acc: string[], arg: string[] | string): string[] => {
            return acc.filter((elem: string) => arg.includes(elem));
        }, args[0]);
    } else if (args.length === 1) {
        return args[0]
    }
    return [];
}

