import CONSTANT from "../../../utils/constant";
import Logger from "../../../utils/logger";
import APIClient from "../../../utils/apiClient";


/**
 * Queries the selected settlements for any documents/files attached to them, calls TRS APIs
 * @param selectedElements selected elements
 * @returns dictionary containing the document data, status, and error message
 */
export async function queryDocuments(selectedElements) {
        const selectedIds = selectedElements.map(item => item[CONSTANT.PR_COL_SETTLEMENT_ID]);
        Logger.logInfo("Querying documents for: " + JSON.stringify(selectedIds));

        try {
            // Create array of promises for concurrent API calls
            const getDocumentPromises = selectedIds.map(id => {
                return new Promise((resolve, reject) => {
                    const parameter = { id: id };
                    APIClient.invoke('GET', 'attachDocuments/viewDocument', parameter, null, (err, responseData) => {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(responseData);
                        }
                    });
                });
            });

            const getDocumentResults = await Promise.all(getDocumentPromises);

            const allDocumentData = [];
            let responseStatus = CONSTANT.RESPONSE_SUCCESS
            let systemErrorMessage = null;


            getDocumentResults.forEach(result => {

                if (result.status === CONSTANT.RESPONSE_SUCCESS) {
                    allDocumentData.push(...result.data);
                } else if (result.status === CONSTANT.RESPONSE_ERROR) {
                    responseStatus = CONSTANT.RESPONSE_ERROR;
                    Logger.logError("Error Querying Document Data");
                } else if (result.status === CONSTANT.RESPONSE_SYSTEM_ERROR) {
                    responseStatus = CONSTANT.RESPONSE_SYSTEM_ERROR;
                    systemErrorMessage = result.errorData;
                    Logger.logError("System Error when Querying Document Data");
                }
            });

            return {
                documentData: allDocumentData,
                status: responseStatus,
                errorMessage: systemErrorMessage
            };

        } catch (error) {
            Logger.logError("Error querying document data for the selected settlements: " + selectedIds.toString() + " Error: " + error);
            return {
                documentData: [],
                status: CONSTANT.MODAL_OTHER_ERROR,
                errorMessage: null
            };
        }

}

