import React, {Component} from "react";
import {
    Button,
    Modal,
    SpaceBetween
} from "@amzn/awsui-components-react";

// UI Modal for the Download Payment Window.
class DownloadModal extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <Modal
            visible={this.props.downloadWindowVisible}
            header={"Select file format for " + this.props.dataType + " Records"}
            onDismiss={this.props.handleDismissDownloadClick}>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <SpaceBetween direction="horizontal" size="xs" >
                    <Button variant={"primary"}
                            onClick={() => this.props.handleDownloadItemsClick('xlsx', this.props.downloadSelection)}
                    >XLSX</Button>
                    <Button variant={"primary"}
                            onClick={() => this.props.handleDownloadItemsClick('csv', this.props.downloadSelection)}
                    >CSV</Button>
                    </SpaceBetween>
                </div>
            </Modal>;
    }
}

export default DownloadModal;