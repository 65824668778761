import CONSTANT from "./constant";
import moment from "moment-timezone";

class FormatData {
    //Convert Date to ISO String
    static formatDate(inputDate) {
        var month = '' + (inputDate.getMonth() + 1);
        var day = '' + inputDate.getDate();
        var year = inputDate.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day,].join('-');
    }

    //Convert Date to local format for Display
    static formatDisplayDate(inputDate) {
        return new Date(new Date(inputDate).toLocaleString("en-US", {timeZone: "America/Los_Angeles"}));
    }

    //Convert ISO Date without T and Z
    static formatNewDate() {
        moment.tz.setDefault(CONSTANT.MOMENT_PST_TIMEZONE);
        return moment().format(CONSTANT.MOMENT_PST_DATEFORMAT);
    }

    //Convert ISO Date without T and Z
    static formatNewCurrDate() {
        moment.tz.setDefault(CONSTANT.MOMENT_PST_TIMEZONE);
        return new Date(moment().format(CONSTANT.MOMENT_PST_DATEFORMATNOTIME));
    }

    //Generate new UTC ISO DateString
    static formateNewUTCCurrentTime() {
        return moment.utc();
    }

    //Generate new single trade request value date based on option
    static generateNewSTRValueDate(option) {
        moment.tz.setDefault(CONSTANT.MOMENT_PST_TIMEZONE);
        let currentDate = new Date(this.formatNewDate());
        let hour = currentDate.getHours();
        let lateDay = (hour >= 21 && hour <= 23) ? 1 : 0;

        if (option === 'Spot') {
            return moment().add(2 + lateDay, 'd').format(CONSTANT.MOMENT_PST_DATEFORMATNOTIME);
        } else if (option === 'Tom') {
            return moment().add(1 + lateDay, 'd').format(CONSTANT.MOMENT_PST_DATEFORMATNOTIME);
        } else {
            return moment().add(3, 'd').format(CONSTANT.MOMENT_PST_DATEFORMATNOTIME);
        }
    }

    //Convert UTC ISO format Date String to local timezone Date String
    static formatISODateToPSTTimezoneDate(isoDateString) {
        if(isoDateString) {
            moment.tz.setDefault(CONSTANT.MOMENT_PST_TIMEZONE);
            return moment(isoDateString).format(CONSTANT.MOMENT_PST_DATEFORMAT_NO_MS);
        } else {
            return "";
        }
    }

    //Convert ISO Date without T and Z
    static formatNewFileDate() {
        moment.tz.setDefault(CONSTANT.MOMENT_PST_TIMEZONE);
        return moment().format(CONSTANT.MOMENT_PST_FILE_DATEFORMAT);
    }

    //Convert Amount decimal to Comma
    static formatAmount(amount) {
        return amount.toString().replace(".", ",");
    }

    //Convert Amount decimal to Comma
    static formatDisplayAmount(amount) {
        var nfObject = new Intl.NumberFormat();
        if (isNaN(amount))
            return "";
        else
            return nfObject.format(amount);
    }

    //Convert Amount to USD
    static formatUSDDisplayAmount(amount) {
        var nfObject = new Intl.NumberFormat('en-US', { style: 'currency',currency: 'USD' })
        if (isNaN(amount))
            return "";
        else
            return nfObject.format(amount).substr(1);
    }

    //Convert Amount to USD
    static formatUSDDisplayAmountAccounting(amount) {
        var nfObject = new Intl.NumberFormat('en-US', { style: 'currency',currency: 'USD', currencySign: 'accounting'})
        if (isNaN(amount))
            return "";
        else
            return nfObject.format(amount).replace('$', '');
    }

    //Convert ISO To EUR Date format
    static formatEURDate(inputDate) {

        var month = inputDate.substr(5, 2);
        var day = inputDate.substr(8, 2);
        var year = inputDate.substr(0, 4);

        return [day, month, year].join('.');
    }

    //Format Wire File Value Date, Change YYYY-MM-DD to MM/DD/YYYY
    static formatWireFileValueDate(valueDate) {
        if (valueDate === undefined || valueDate === "") {
            return "";
        }

        var dateArr = valueDate.split("-");
        return [dateArr[1], dateArr[2], dateArr[0]].join('/');
    }

    //Convert elementSelected to 360T format
    static format360T(elementSelected) {

        let export360TElement = [];

        elementSelected.map((entry, index) => {
            let export360TObject = {};

            export360TObject[CONSTANT.TR_COL_CFPRODUCT] = elementSelected[index][CONSTANT.TR_COL_CFPRODUCT];
            export360TObject[CONSTANT.TR_COL_CF_GROUPID] = elementSelected[index][CONSTANT.TR_COL_CF_GROUPID];
            export360TObject[CONSTANT.TR_COL_CF_EXTERNALID] = elementSelected[index][CONSTANT.TR_COL_CF_EXTERNALID];
            export360TObject[CONSTANT.TR_COL_CFLEGALENTITY] = elementSelected[index][CONSTANT.TR_COL_CFLEGALENTITY];
            export360TObject[CONSTANT.TR_COL_CF_ACTION] = elementSelected[index][CONSTANT.TR_COL_CF_ACTION];
            export360TObject[CONSTANT.TR_COL_CF_CURRENCY1] = elementSelected[index][CONSTANT.TR_COL_CF_CURRENCY1];
            export360TObject[CONSTANT.TR_COL_CF_CURRENCY2] = elementSelected[index][CONSTANT.TR_COL_CF_CURRENCY2];
            export360TObject[CONSTANT.TR_COL_CF_NOTIONALCURRENCY] = elementSelected[index][CONSTANT.TR_COL_CF_NOTIONALCURRENCY];
            export360TObject[CONSTANT.TR_COL_CFNOTIONALAMOUNT1] = elementSelected[index][CONSTANT.TR_COL_CFNOTIONALAMOUNT1];
            export360TObject[CONSTANT.TR_COL_CFNOTIONALAMOUNT2] = elementSelected[index][CONSTANT.TR_COL_CFNOTIONALAMOUNT2];
            export360TObject[CONSTANT.TR_COL_CFEFFECTIVEDATE] = elementSelected[index][CONSTANT.TR_COL_CFEFFECTIVEDATE];
            export360TObject[CONSTANT.TR_COL_CF_MATURITYDATE] = elementSelected[index][CONSTANT.TR_COL_CF_MATURITYDATE];
            export360TObject[CONSTANT.TR_COL_CF_LIMITRATE] = elementSelected[index][CONSTANT.TR_COL_CF_LIMITRATE];
            export360TObject[CONSTANT.TR_COL_CF_STOPRATE] = elementSelected[index][CONSTANT.TR_COL_CF_STOPRATE];
            export360TObject[CONSTANT.TR_COL_CF_FIXING] = elementSelected[index][CONSTANT.TR_COL_CF_FIXING];
            export360TObject[CONSTANT.TR_COL_CF_FIXINGDATE] = elementSelected[index][CONSTANT.TR_COL_CF_FIXINGDATE];
            export360TObject[CONSTANT.TR_COL_CF_EDITABLE] = elementSelected[index][CONSTANT.TR_COL_CF_EDITABLE];
            //new 14 headers
            export360TObject[CONSTANT.TR_COL_CF_PRODUCT_FIXINGDATE1] = elementSelected[index][CONSTANT.TR_COL_CF_PRODUCT_FIXINGDATE1];
            export360TObject[CONSTANT.TR_COL_CF_PRODUCT_FIXINGDATE2] = elementSelected[index][CONSTANT.TR_COL_CF_PRODUCT_FIXINGDATE2];
            export360TObject[CONSTANT.TR_COL_CF_PRODUCT_FIXING_REFERENCE] = elementSelected[index][CONSTANT.TR_COL_CF_PRODUCT_FIXING_REFERENCE];
            export360TObject[CONSTANT.TR_COL_CF_TWOWAY_PRICING] = elementSelected[index][CONSTANT.TR_COL_CF_TWOWAY_PRICING];
            export360TObject[CONSTANT.TR_COL_CF_PROVIDER_LIST] = elementSelected[index][CONSTANT.TR_COL_CF_PROVIDER_LIST];
            export360TObject[CONSTANT.TR_COL_CF_PREMIUM_CURRENCY] = elementSelected[index][CONSTANT.TR_COL_CF_PREMIUM_CURRENCY];
            export360TObject[CONSTANT.TR_COL_CF_EXPIRY_TIME] = elementSelected[index][CONSTANT.TR_COL_CF_EXPIRY_TIME];
            export360TObject[CONSTANT.TR_COL_CF_SPOT_RATE] = elementSelected[index][CONSTANT.TR_COL_CF_SPOT_RATE];
            export360TObject[CONSTANT.TR_COL_CF_STRIKE_RATE] = elementSelected[index][CONSTANT.TR_COL_CF_STRIKE_RATE];
            export360TObject[CONSTANT.TR_COL_CF_DELIVERY_DATE] = elementSelected[index][CONSTANT.TR_COL_CF_DELIVERY_DATE];
            export360TObject[CONSTANT.TR_COL_CF_OPTION_STYLE_TYPE] = elementSelected[index][CONSTANT.TR_COL_CF_OPTION_STYLE_TYPE];
            export360TObject[CONSTANT.TR_COL_CF_OCO_LEG] = elementSelected[index][CONSTANT.TR_COL_CF_OCO_LEG];
            export360TObject[CONSTANT.TR_COL_CF_OFFLINE_SPOT_RATE] = elementSelected[index][CONSTANT.TR_COL_CF_OFFLINE_SPOT_RATE];
            export360TObject[CONSTANT.TR_COL_CF_OFFLINE_FORWARD_POINTS] = elementSelected[index][CONSTANT.TR_COL_CF_OFFLINE_FORWARD_POINTS];

            export360TObject[CONSTANT.TR_COL_CF_MISYS_Beneficiary] = elementSelected[index][CONSTANT.TR_COL_CF_MISYS_Beneficiary];
            export360TObject[CONSTANT.TR_COL_CF_AMZNTT] = elementSelected[index][CONSTANT.TR_COL_CF_AMZNTT];
            export360TObject[CONSTANT.TR_COL_CF_AMZNSOURCE] = elementSelected[index][CONSTANT.TR_COL_CF_AMZNSOURCE];
            export360TObject[CONSTANT.TR_COL_CF_DESCRIPTION] = elementSelected[index][CONSTANT.TR_COL_CF_DESCRIPTION];
            export360TObject[CONSTANT.TR_COL_CF_PAYREF] = elementSelected[index][CONSTANT.TR_COL_CF_PAYREF] == null 
                                                ? CONSTANT.TR_COL_BLANK_STRING
                                                : elementSelected[index][CONSTANT.TR_COL_CF_PAYREF];
            export360TObject[CONSTANT.TR_COL_CF_PAYDATE] = elementSelected[index][CONSTANT.TR_COL_CF_PAYDATE] == null 
                                                ? CONSTANT.TR_COL_BLANK_STRING
                                                : elementSelected[index][CONSTANT.TR_COL_CF_PAYREF];
            export360TObject[CONSTANT.TR_COL_CF_TRADEIDCR] = elementSelected[index][CONSTANT.TR_COL_CF_TRADEID] + CONSTANT.TR_COL_CR;

            export360TElement[index] = export360TObject;
        })

        return export360TElement;
    }

    //Convert elementSelected to Bloomberg format
    static formatBloomberg(elementSelected) {

        let exportBloombergElement = [];

        elementSelected.map((entry, index) => {
            let exportBloombergObject = {};

            exportBloombergObject[CONSTANT.TR_COL_BL_INSTRUMENT] = elementSelected[index][CONSTANT.TR_COL_BL_INSTRUMENT];
            exportBloombergObject[CONSTANT.TR_COL_BL_CURRENCYPAIR] = elementSelected[index][CONSTANT.TR_COL_BL_CURRENCYPAIR];
            exportBloombergObject[CONSTANT.TR_COL_BL_SIDE] = elementSelected[index][CONSTANT.TR_COL_BL_SIDE];
            exportBloombergObject[CONSTANT.TR_COL_BL_TT] = elementSelected[index][CONSTANT.TR_COL_BL_TT];
            exportBloombergObject[CONSTANT.TR_COL_BL_AMZNTRADEID] = elementSelected[index][CONSTANT.TR_COL_BL_AMZNTRADEID];
            exportBloombergObject[CONSTANT.TR_COL_BL_SOURCEACCOUNT] = elementSelected[index][CONSTANT.TR_COL_BL_SOURCEACCOUNT];
            exportBloombergObject[CONSTANT.TR_COL_BL_CURRENCY] = elementSelected[index][CONSTANT.TR_COL_BL_CURRENCY];
            exportBloombergObject[CONSTANT.TR_COL_BL_AMOUNT] = elementSelected[index][CONSTANT.TR_COL_BL_AMOUNT];
            exportBloombergObject[CONSTANT.TR_COL_BL_SSIKEYWORD] = elementSelected[index][CONSTANT.TR_COL_BL_SSIKEYWORD];
            exportBloombergObject[CONSTANT.TR_COL_BL_TRADECODE] = elementSelected[index][CONSTANT.TR_COL_BL_TRADECODE];
            exportBloombergObject[CONSTANT.TR_COL_BL_ACCOUNT] = elementSelected[index][CONSTANT.TR_COL_BL_ACCOUNT];

            exportBloombergElement[index] = exportBloombergObject;
        })

        return exportBloombergElement;
    }

    //Format failed updated item JSON Object into user Notification Message:
    static formatFailedUpdatedBatchIntoBrowserMessage(failedBatchString) {
        let failedBatchObject = JSON.parse(failedBatchString);
        let containConditionalUpdateException = false;
        let failedBatchIdList = [];

        for(let item of failedBatchObject) {
            const itemId = (Object.prototype.hasOwnProperty.call(item, 'SettlementId')) ? item['SettlementId'] : item['TradeId'];
            const itemExceptionCode = item['Error'];
            failedBatchIdList.push(itemId);
            if (itemExceptionCode === 'ConditionalCheckFailedException') {
                containConditionalUpdateException = true;
            }
        }

        const messagePostFix = containConditionalUpdateException ? CONSTANT.UPDATE_ERROR_TEXT_POSTFIX_CONDITIONAL : '';

        return CONSTANT.UPDATE_ERROR_TEXT_PREFIX + failedBatchIdList.join() + messagePostFix;
    }
}

export default FormatData;