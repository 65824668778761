import * as React from "react";
import CONSTANT from "../../utils/constant";
import {Box, SpaceBetween, StatusIndicator} from "@amzn/awsui-components-react";
import {PolarisV3FlashBar} from "../../component/dataDisplay";


export const UploadDocumentsStatus = (props) => {
    switch (props.uploadStatus) {
        case CONSTANT.FEEDBACK_UPLOAD_NOT_TRIGGERED:
            return (
                <></>
            );
        case CONSTANT.FEEDBACK_UPLOAD_TRACKING_IN_PROGRESS:
            return (
                <Box textAlign='center' padding='xl'>
                    <SpaceBetween direction='vertical' size='m'>
                        <StatusIndicator type="progress">Upload In Progress</StatusIndicator>
                        <div><h3>Please wait! Uploading file(s).</h3></div>
                    </SpaceBetween>
                </Box>
            );
        case CONSTANT.FEEDBACK_UPLOAD_MAPPING_FILE_IN_PROGRESS:
            return (
                <Box textAlign='center' padding='xl'>
                    <SpaceBetween direction='vertical' size='m'>
                        <StatusIndicator type="progress">Upload Mapping File In Progress</StatusIndicator>
                        <div><h3>Please wait! The mapping file is being uploaded.</h3></div>
                    </SpaceBetween>
                </Box>
            );
        case CONSTANT.FEEDBACK_UPLOAD_MAPPING_FILE_SUCCESS:
            return (
                <Box textAlign='center' padding='xl'>
                    <SpaceBetween direction='vertical' size='m'>
                        <div><h3>All documents have been sent for further processing.</h3></div>
                        <div><h3>Once completed, you will receive email notification regarding the status of documents upload.</h3></div>
                    </SpaceBetween>
                </Box>
            );
        case CONSTANT.FEEDBACK_UPLOAD_SUCCESS:
            return (
                <Box textAlign='center' padding='xl'>
                    <SpaceBetween direction='vertical' size='m'>
                        <div><h3>Click Next to continue the process.</h3></div>
                    </SpaceBetween>
                </Box>
            );
        case CONSTANT.FEEDBACK_UPLOAD_ERROR:
            return (
                <Box textAlign='center' padding='xl'>
                    <SpaceBetween direction='vertical' size='m'>
                        <StatusIndicator type="warning">Uploading System Error</StatusIndicator>
                        <div>Whoops, our system is busy or experiencing internal issues, please retry.</div>
                    </SpaceBetween>
                </Box>
            );
        default: return <Box textAlign='center' padding='xl'>Wrong status</Box>;
    }
}

/**
 * Renders the Modal message by the status of the system.
 */
export const DocumentFeedbackMessage = (props) => {
    switch (props.status) {
        case CONSTANT.MODAL_DELETE_SUCCESS:
            return (<PolarisV3FlashBar
                dismissible={true}
                content={<div>Successfully delete the Record! {props.statusMsg}</div>}
                header="Delete succeeded"
                key={props.status}
                type="success"/>);
        case CONSTANT.MODAL_DOWNLOAD_SUCCESS:
            return (<PolarisV3FlashBar
                dismissible={true}
                content={<div>Successfully download the document.</div>}
                header="Download succeeded"
                key={props.status}
                type="success"/>);
        case CONSTANT.MODAL_DOWNLOAD_ERROR:
            return (<PolarisV3FlashBar
                dismissible={true}
                content={<div>Failed to download the document(s)!</div>}
                header="Download failed"
                key={props.status}
                type="error"/>);
        case CONSTANT.MODAL_QUERY_ERROR:
            return (<PolarisV3FlashBar
                dismissible={true}
                content={<div>Failed to Query the Record(s)! {props.statusMsg}</div>}
                header="Query failed"
                key={props.status}
                type="error"/>);
        case CONSTANT.MODAL_REQUEST_ERROR:
            return (<PolarisV3FlashBar
                dismissible={true}
                content={<div>Request Failed! {props.statusMsg}</div>}
                header="Update failed"
                key={props.status}
                type="error"/>);
        case CONSTANT.MODAL_SYSTEM_ERROR:
            return (<PolarisV3FlashBar
                dismissible={true}
                content={<div>Request Failed! {props.statusMsg}</div>}
                header="System Error"
                key={props.status}
                type="error"/>);
        case CONSTANT.MODAL_OTHER_ERROR:
            return (<PolarisV3FlashBar
                dismissible={true}
                content={<div>{props.statusMsg}</div>}
                header="error"
                key={props.status}
                type="error"/>);
        case CONSTANT.MODAL_DOWNLOAD_SUBMITTING:
            return (<PolarisV3FlashBar
                dismissible={true}
                content={<div>Downloading document... Please wait.</div>}
                loading={true}
                key={props.status}
                type="success"/>);
        case CONSTANT.MODAL_SUBMITTING:
            return (<PolarisV3FlashBar
                dismissible={true}
                content={<div>Busy fetching data... Please wait.</div>}
                loading={true}
                key={props.status}
                type="success"/>);
        default:
            return null;
    }
}

export const chooseFiles = (e) => {
    const files = e.detail.value;
    const validFiles = [];
    const invalidFiles = [];

    for (const file of files) {
        if (file.name === "MappingFile.csv") {
            invalidFiles.push(file.name);
        }
        validFiles.push(file);
    }
    console.log("validFiles: ", validFiles);
    console.log("invalidFiles: ", invalidFiles);
    return validFiles;
}

/**
 * Helper function that determines whether the record has an document attached to it.
 * @param recordId
 * @param recordIdToDocumentIdMapping : {String:recordId, [String]: listOfDocumentIds }
 */
export const hasDocumentAttached = (recordId, recordIdToDocumentIdMapping) => {

    return Object.keys(recordIdToDocumentIdMapping).length !== 0 &&
        Object.prototype.hasOwnProperty.call(recordIdToDocumentIdMapping, recordId);
}