import * as xlsx from 'xlsx';
import Logger from "./logger";

/**
 * A parser which include a collection of XLSX related functions
 */
class XLSXParser {

    static FILE_HEADER_INDEX = 0;

    /**
     * Generate a xls with Custom Delimiter
     * @param fileName: the name of this file
     * @param delimiter: file delimiter
     * @param arrayOfContentInJSON: a array including the data for file
     * @param arrayOfContentHeader: a array including the header(title row)
     */
    static writeFile = (fileName, delimiter, arrayOfContentInJSON, arrayOfContentHeader,bookType) => {

        Logger.logInfo("writeFile: " + fileName + " " + delimiter);
        let wb = xlsx.utils.book_new();
        let ws = xlsx.utils.json_to_sheet(arrayOfContentInJSON, {header: arrayOfContentHeader});

        xlsx.utils.book_append_sheet(wb, ws);
        xlsx.writeFile(wb, fileName, {bookType: bookType, FS: delimiter});

        Logger.logInfo("End writeFile: ");
    };

    /**
     * Generate a CSV with Custom Delimiter and ANSI Format
     * @param fileName: the name of this file
     * @param delimiter: file delimiter
     * @param arrayOfContentInJSON: a array including the data for file
     * @param arrayOfContentHeader: a array including the header(title row)
     */
    static writeFileANSI = (fileName, delimiter, arrayOfContentInJSON, arrayOfContentHeader) => {

        Logger.logInfo("writeFile string: " + fileName + " " + delimiter);
        let ws = xlsx.utils.json_to_sheet(arrayOfContentInJSON, {header: arrayOfContentHeader});

        let csv = xlsx.utils.sheet_to_csv(ws,{FS: delimiter});
        XLSXParser.downloadCSV(csv, fileName);

        Logger.logInfo("End writeFile: ");
    };

    /**
     * Download a CSV File
     * @param fileName: the name of this file
     * @param csv: file data
     */
    static downloadCSV = (csv, filename) => {
        Logger.logInfo("Start downloadCSV: ");
        var csvFile;
        var downloadLink;

        csvFile = new Blob([csv], {type: "text/csv;charset=ANSI"});
        downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        Logger.logInfo("End downloadCSV: ");
    }


    /**
     * Generate a CSV String with Custom Delimiter and ANSI Format
     * @param fileName: the name of this file
     * @param delimiter: file delimiter
     * @param arrayOfContentInJSON: a array including the data for file
     * @param arrayOfContentHeader: a array including the header(title row)
     */
    static createCSV = (fileName, delimiter, arrayOfContentInJSON, arrayOfContentHeader) => {

        Logger.logInfo("creatingCSV string: " + fileName + " " + delimiter);
        let ws = xlsx.utils.json_to_sheet(arrayOfContentInJSON, {header: arrayOfContentHeader});

        let csv = xlsx.utils.sheet_to_csv(ws,{FS: delimiter});
        return csv;
    };
}

export default XLSXParser;