import React from 'react';
import TRSApp from './TRSApp';
import {createRoot} from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom';
import "./style/header.css";

// The index layer for TRS. Wraps the App layer with the
// BrowserRouter and renders it to the document root.
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById("root")!).render(
    <BrowserRouter>
        <TRSApp />
    </BrowserRouter>
);