import React, {Component} from "react";
import {
    Button,
    Modal,
    Spinner,
    Icon, SpaceBetween, Box
} from "@amzn/awsui-components-react";
import '@amzn/awsui-global-styles/polaris.css';
import CONSTANT from "../../../utils/constant";

/*
*   Validate Liquidity Warning Modal
*   Checking for: Duplicate Relationship, Payment with Cancelled Status.
*/
class ValidateLiquidityWarningModal extends Component {
    constructor(props) {
        super(props);
    }

    //Generate duplicate warnings summary based on the validation result, form into lists.
    generateDuplicateWarningsSummary = (paymentValidationResult) => {
        let duplicateWarnings = [];
        let keyIdx = 0;
        for (let settlement in paymentValidationResult) {
            let title = <p><b>{settlement}:</b></p>;
            let listItems = [];
            for (let index = 0; index < paymentValidationResult[settlement].length; index++) {
                let listItem = paymentValidationResult[settlement][index][CONSTANT.PR_COL_SETTLEMENT_ID] + ": "
                + paymentValidationResult[settlement][index][CONSTANT.PR_COL_OCM_MAPPING] + ", "
                + paymentValidationResult[settlement][index][CONSTANT.PR_COL_SETTLEMENT_USD_EQUIV] + " USD Equiv, "
                + paymentValidationResult[settlement][index][CONSTANT.PR_COL_STATUS];
                listItems.push(<li key={index}>{listItem}</li>);
            }

            duplicateWarnings.push(
                <div key={keyIdx++}>
                    {title}
                    <ul>{listItems}</ul>
                    <br/>
                </div>
            );
        }

        return duplicateWarnings;
    }

    //Get duplicate relationship validation result block
    getDuplicateValidationResultBlock = () => {
        let duplicateValidationResultBlock;
        if (this.props.paymentValidationStatus === CONSTANT.MODAL_PAYMENT_VALIDATE_START) {
            duplicateValidationResultBlock = <span><Spinner />Loading duplicate relationship..</span>;
        } else if (this.props.paymentValidationStatus === CONSTANT.MODAL_PAYMENT_VALIDATE_FINISH) {
            if (Object.keys(this.props.paymentValidationResult).length > 0) {
                let duplicateWarnings = this.generateDuplicateWarningsSummary(this.props.paymentValidationResult);
                duplicateValidationResultBlock = 
                <div>
                    <Box><Icon variant="warning" name="status-warning"></Icon> Please carefully check duplicates below </Box>
                    <br/>
                    <div>
                        {duplicateWarnings}
                    </div>
                </div>;
            } else {
                duplicateValidationResultBlock = <Box><Icon variant="success" name="status-positive"></Icon> No duplicate relationship found</Box>;
            }
        } else {
            duplicateValidationResultBlock = <Box><Icon variant="error" name="status-positive"></Icon> System error when finding duplicate relationship, please reach out to
            the development team</Box>;
        }

        return duplicateValidationResultBlock;
    }

    //Get cancellation payment check message block
    getCancellationPaymentCheckMessage = () => {
        if (this.checkIfCancelledPaymentTypeExists(this.props.elementSelected)) {
            return <Box><Icon variant="warning" name="status-warning"></Icon> One or more settlements have a payment type that is Cancelled or Hold</Box>;
        } else {
            return <Box><Icon variant="success" name="status-positive"></Icon> All settlements have valid status</Box>;
        }
    }

    //Check If there's payment with payment type = cancelled or Hold.
    checkIfCancelledPaymentTypeExists = (payments) => {
        for (let index = 0; index < payments.length; index++) {
            if (payments[index][CONSTANT.PR_COL_PAYMENT_TYPE] === 'Cancelled' || payments[index][CONSTANT.PR_COL_PAYMENT_TYPE] === 'Hold') {
                return true;
            }
        }
        return false;
    }

    render() {
        let footer =
            <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link"
                            onClick={() => this.props.handleDismissClick()}>Cancel</Button>
                    <Button variant="primary"
                            disabled={this.props.paymentValidationStatus !== CONSTANT.MODAL_PAYMENT_VALIDATE_FINISH}
                            onClick={() => this.props.handleConfirmValidateClick(this.props.elementSelected)}>Validate Liquidity</Button>
                </SpaceBetween>
            </Box>

        //Duplicate Validation Result Block
        let duplicateValidationResultBlock = this.getDuplicateValidationResultBlock();

        //Payment with Cancellation Block
        let cancellationPaymentCheckMessage = this.getCancellationPaymentCheckMessage();

        return (
            <Modal
                visible={this.props.validateWarningModalVisble}
                header="Validate Liquidity Review"
                expandToFit={true}
                onDismiss={this.props.handleDismissClick}
                footer={footer}>
                    <Box>Duplicate Relationship Check:</Box>
                    {duplicateValidationResultBlock}
                    <Box>Cancelled/Hold Status Check:</Box>
                    {cancellationPaymentCheckMessage}
            </Modal>
            );
    }
}

export default ValidateLiquidityWarningModal;