import React, {useEffect, useState} from 'react';
import {FormField, Input, Button, Tiles, Grid} from '@amzn/awsui-components-react';
import CONSTANT from '../../../utils/constant'
import ErrorBoundary from '../../../err/errorBoundary';

/**
 * Defines the Utility functions related to the DocumentationModal.jsx implementation.
 */

// Retrieving the tile list to be displayed as a Renderable <a> element to the frontend.
const getTileListForRelatedTTs = (ttList, disabled) => {
    const ttListWithLink = [];
    if(ttList && ttList.trim().length > 0) {
        for (let tt of ttList.split(CONSTANT.RELATED_TT_DELIMITER)) {
            let url;
            // Display the URL as is if it starts with "http". Otherwise prepend TT URL at the beginning.
            if (tt.startsWith(CONSTANT.TT_HTTP_PREFIX)) {
                url = tt
            } else {
                url = CONSTANT.TT_URL + tt
            }
            ttListWithLink.push({
                // Each label will be a renderable JSX element that contains a hyperlink to
                // t.corp.amazon.com.
                "label": <a href={url} target="_blank" rel="noreferrer">{tt}</a>,
                "value": tt,
                "disabled": disabled,
            })
        }
    }
    return ttListWithLink;
}

/**
 * Custom functional component that is defined to support the related TT display in the modal
 * and its tiles.
 * 
 * @param props
 *      Passed in from DocumentationModal.jsx.
 * @returns JSX.Element
 *      The JSX element will contain the text field that the user can use to input values,
 *      A "Remove Selected" Polaris button, and a list of tiles containing a list of renderable
 *      <a> elements.
 */
export const DocumentationModalRelatedTTs = (props) => {

    // The boolean toggle of resetTTComponent will force the useEffect hook to trigger, resetting the
    // input text field and also to re-retrieve the tile list to display on the modal.
    useEffect(() => {
        setInputValue('');
        getTileListForRelatedTTs(props.relatedTTs);
    }, [props.resetTTComponent]);

    const [errorText, setErrorText] =  useState('');
    const [ttToRemove, setTTToRemove] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    // Associating the text input with a state so that it can be purged after closing & reopening the modal.
    const [inputValue, setInputValue] = useState('');

    //The text field input is tied to the state and is updated as the change event gets fired.
    const handleChange = (event) => {
        let userInput = event.detail.value;
        handleRelatedTTInsert(userInput);
        if (props.required && userInput === "") {
            setErrorText('This field is required');
        } else {
            setErrorText('');
            setInputValue(userInput);
        }
        props.setErrorFlag(props.name, errorText);
    };

    // When the "Remove Selected" button gets clicked, the handleRelatedTTDelete function from the props will get fired, bubbling
    // up to either the queryException or the queryAllTrades parent class.
    const handleClick = () => {
        handleRelatedTTDelete(ttToRemove);
        setTTToRemove(null);
        setButtonDisabled(true);
    }

    // When a Polaris tile is selected, the setTTToRemove state is set to the value associated with the tile, and the "Remove Selected"
    // button will be enabled.
    const handleTileChange = ({detail}) => {
        setTTToRemove(detail.value);
        setButtonDisabled(false);
    }

    // Handles the related TT inserts in the Documentation Modal. Note that both comma and semicolon delimiters can be used
    // to split the TTs, and extraneous delimiter values at the beginning and end of the text will automatically be trimmed.
    // In addition, there is a de-duplication logic of TTs set by converrting the split values to a set and then back to a list.
    // The following text field inputs are all supported and will be converted to "P321;P123" in the Cloudscape table.
    // - "P321,P123"
    // - "P321    ,P123"
    // - "P321;P123"
    // - "P321,P321,P321,P123"
    // - "P321,;,  ;P123"
    // - ", ; P321,;,  ;P123 ,"
    const handleRelatedTTInsert = (value) => {
        const splitValue = value.replace(/(^[;|,|\s]*,)|([;|,|\s]*$)/g, '').split(/\s*[;|,]+\s*/);
        const newRelatedTT = [...new Set(splitValue)].join(CONSTANT.RELATED_TT_DELIMITER);
        props.setQueryState({
            newRelatedTT: newRelatedTT
        });
    }

    // Runs a splice against the list of related TTs to allow the UI to delete a tile based on the Tile selection made by the
    // user in the documentation modal.
    const handleRelatedTTDelete = (value) => {
        const tempRelatedTT = props.tempRelatedTTs.split(CONSTANT.RELATED_TT_DELIMITER);
        tempRelatedTT.splice(tempRelatedTT.indexOf(value), 1);
        props.setQueryState({
            tempRelatedTT: tempRelatedTT.join(CONSTANT.RELATED_TT_DELIMITER)
        });
    }

    // Returns the JSX elemenent to be used in the documentation modal as a functional component.
    return (
        <ErrorBoundary content={props.title}>
            <FormField label={props.title + (props.required ? '*' : '')}
                    description={props.description}
                    errorText={errorText}>
                    <Grid gridDefinition={[{colspan: 6},{colspan: 6},{colspan: 6},{colspan: 6} ]}>
                            <Input name={props.name}
                                value={inputValue}
                                onChange={handleChange}
                                disabled={props.disabled}/>
                            <Button disabled={props.disabled || buttonDisabled} onClick={handleClick}>Remove Selected</Button>
                            <Tiles value={ttToRemove}
                                disabled={props.disabled}
                                onChange={handleTileChange}
                                columns={2}
                                items={getTileListForRelatedTTs(props.tempRelatedTTs, props.disabled).filter((_, idx) => idx % 2 === 0)}
                            />
                            <Tiles value={ttToRemove}
                                disabled={props.disabled}
                                onChange={handleTileChange}
                                columns={2}
                                items={getTileListForRelatedTTs(props.tempRelatedTTs, props.disabled).filter((_, idx) => idx % 2 === 1)}
                            />
                    </Grid>
            </FormField>
        </ErrorBoundary>
    )
}
