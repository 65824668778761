import APIClient from "./apiClient";
import CONSTANT from "./constant";

class S3Logic {

    /**
     * This method writes a file to an AWS S3 bucket. 
     * It creates a key for that object and generates an presigned URL that can be used to write the file.
     * That URL is then executed, with the file in the body of the request. 
     * If the execution is successful, S3 will return a version ID that can be used to later identify the object. 
     * 
     * @returns - A JSON object representing the function's execution status, and the object's S3 intake location or an error message. 
     */
    static stageFile = async (objectKey, bucket, fileData) => {
        // Fetch presigned URL and then stage file to data store
        const urlResponse = await this.fetchPresignedUrl("PUT", objectKey, bucket);
        if (!urlResponse?.url) { // If URL cannot be fetched, do not attempt execution
            return { status: urlResponse.status, errorMessage: "Unable to fetch URL, during file staging." };
        }

        // Set up & execute the presigned URL, to stage the user uploaded file(s)
        const opts = {
            method: "PUT",
            body: fileData,
            headers: { 'Content-Type': 'text/csv' }
        };
        const response = window.fetch(urlResponse.url, opts).then((response) => {
            if (response.status === 200) {
                return { status: CONSTANT.FEEDBACK_STAGING_SUCCESS };
            } else { // If presigned URL execution fails, display errors 
                throw new Error(response);
            }
        }).catch(() => {
            return { status: CONSTANT.MODAL_SYSTEM_ERROR, errorMessage: "Unable to execute URL, for file staging." };
        });
        return response;
    };

    /**
     * This method makes an API call to generate a presigned URL to read/write to an S3 bucket. 
     * It is an async wrapper method, that can be leveraged in a generic fashion throughout the 
     * any of the TRS UI components.
     */
    static fetchPostPresignedUrl = (bucket) => {
        const body = {
            bucketName: bucket,
            uploader: localStorage.getItem(CONSTANT.LOCAL_STORAGE_USERID) ?? '',
        };
        return new Promise((resolve) => {
            APIClient.invoke("POST", "attachDocuments", {}, body, (err, data) => {
                if (!err) {
                    console.log("data: ", data);
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            console.log("SUCCESS: ");
                            return resolve({ status: CONSTANT.MODAL_UPDATE_SUCCESS, url: data.data });
                        case CONSTANT.RESPONSE_ERROR:
                            console.log("RESPONSE_ERROR: ");
                            return resolve({ status: CONSTANT.MODAL_QUERY_ERROR });
                        case CONSTANT.RESPONSE_SYSTEM_ERROR:
                            console.log("SYSTEM_ERROR: ");
                            return resolve({ status: CONSTANT.MODAL_SYSTEM_ERROR });
                        default:
                            console.log("DEFAULT: ");
                            return resolve({ status: CONSTANT.MODAL_REQUEST_ERROR });
                    }
                } else {
                    console.log("err: ", err);
                    console.log("OTHER_ERROR: ");
                    return resolve({ status: CONSTANT.MODAL_OTHER_ERROR });
                }
            });
        });
    };

    /**
     * This method makes an API call to generate a presigned URL to read/write to an S3 bucket.
     * It is an async wrapper method, that can be leveraged in a generic fashion throughout the
     * any of the TRS UI components.
     *
     * @param {*} operation - A String representing an HTTP operation
     * @param {*} key - A String representing the S3 object key to perform the operation on
     * @param {*} versionId - Optional - A String representing the version of the S3 object
     * @param {*} bucket - Optional - A String representing the bucket to generate the URL for
     * @param {*} metadata - Optional - A Map representing any metadata for the URL request
     * @returns - A presigned URL and the API execution status - SUCCESS/INVALID_REQUEST/ERROR
     */
    static fetchPresignedUrl = (operation, key, bucket = '', versionId = '') => {
        const body = {
            operation: operation,
            key: key,
            versionId: versionId,
            bucket: bucket
        };
        return new Promise((resolve) => {
            APIClient.invoke("POST", "/file/url", {}, body, (err, data) => {
                if (!err) {
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            return resolve({ status: CONSTANT.FEEDBACK_QUERY_SUCCESS, url: data.url });
                        case CONSTANT.RESPONSE_ERROR:
                        case CONSTANT.RESPONSE_DEV_BUG:
                        default:
                            return resolve({ status: CONSTANT.MODAL_SYSTEM_ERROR });
                    }
                } else {
                    return resolve({ status: CONSTANT.MODAL_OTHER_ERROR });
                }
            });
        });
    };
}

export default S3Logic;