import * as React from "react";
import {useState} from "react";
import {Box, Button, Container, Header, SpaceBetween, TextContent} from "@amzn/awsui-components-react";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import FileUpload from "@amzn/awsui-components-react/polaris/file-upload";
import CONSTANT from "../../utils/constant";
import xlsxParser from '../../utils/xlsxParser';
import {UploadDocumentsStatus} from "./documentUtils";
import {DOCUMENT_MAPPING_TABLE_HEADER} from "../../config/table";

const UploadMappingFile = ({presignedURLData, onCancel, uploadId, handleExitBulkUpload}) => {
    const [validFile, setValidFile] = useState([]);
    const [uploadDisable, setUploadDisable] = useState(false);
    const [uploadMappingStatus, setMappingUploadStatus] = useState(CONSTANT.FEEDBACK_UPLOAD_NOT_TRIGGERED);


    /**
     * Handle the download Document upload mapping file header click.
     */
    const handleExportMappingFileHeader = () => {
        const fileName = "TRS_Upload_Mapping_Table_Header.csv";
        const delimiter = ',';
        const header = DOCUMENT_MAPPING_TABLE_HEADER;
        const fileType = 'csv';
        //xlsxParser.writeFileANSI(fileName, delimiter, [[]], header, fileType);
        xlsxParser.writeFile(fileName, delimiter, [], header, fileType);
    };

    const handleFileUpload = async () => {
        if (validFile.length === 0) {
            console.error("No valid files selected.");
            return;
        }
        try {
            const formData = new FormData();
            // Append presigned URL fields to formData
            Object.keys(presignedURLData.url.fields).forEach((key) => {
                formData.append(key, presignedURLData.url.fields[key]);
            });

            // Append each file to formData and post to S3 bucket
            // https://docs.aws.amazon.com/AmazonS3/latest/API/RESTObjectPOST.html
            // The file or text content must be the last field in the form.
            console.log("File before added to the formData:", validFile);
            for (const file of validFile) {
                if (validFile.length === 1 ) {
                    console.log("File before rename to the formData:", file.name);
                    const renamedFile = new File([file], "MappingFile.csv", {
                        file: file.type,
                    });
                    formData.append("file", renamedFile);

                    const opts = {
                        method: "POST",
                        url: presignedURLData.url.url,
                        body: formData,
                    };
                    const response = await fetch(presignedURLData.url.url, opts);
                    setMappingUploadStatus(CONSTANT.FEEDBACK_UPLOAD_TRACKING_IN_PROGRESS)
                    if (response.ok) {
                        console.log("File uploaded successfully: ", renamedFile.name);
                    } else {
                        console.error("An error occurred during file upload:", response.status);
                    }
                }
            }
            console.log("All valid files have been uploaded.");
            setUploadDisable(true);
            setMappingUploadStatus(CONSTANT.FEEDBACK_UPLOAD_MAPPING_FILE_SUCCESS);
        } catch (error) {
            console.error("An error occurred during file upload:", error);
            setMappingUploadStatus(CONSTANT.FEEDBACK_UPLOAD_ERROR);
        }
    };

    const handleChooseFile = (e) => {
        const files = e.detail.value;
        const validFile = [];
        for (const file of files) {
            validFile.push(file);
        }
        setValidFile(validFile);
    };

    return (
        <div>
            <h1>MAPPING FILE PAGE</h1>
            <Container
                header={
                    <Header variant="h2"
                            actions={
                                <Button formAction="none"
                                        iconName="close"
                                        variant="icon"
                                        size='xl'
                                        onClick={handleExitBulkUpload}>Exit
                                </Button>}
                    >Step 2 - Choose your MappingFile.</Header>
                }
            >
                <FormField
                    label="Select the MappingFile to upload"
                    description=""
                >
                    <FileUpload
                        onChange={handleChooseFile}
                        value={validFile}
                        accept=".csv"
                        i18nStrings={{
                            uploadButtonText: e =>
                                e ? "Choose files" : "Choose file",
                            dropzoneText: e =>
                                e
                                    ? "Drop files to upload"
                                    : "Drop file to upload",
                            removeFileAriaLabel: e =>
                                `Remove file ${e + 1}`,
                            limitShowFewer: "Show fewer files",
                            limitShowMore: "Show more files",
                            errorIconAriaLabel: "Error"
                        }}
                        showFileLastModified
                        showFileSize
                        showFileThumbnail
                        tokenLimit={3}
                        constraintText="Only accept the file type: .csv"
                    />
                </FormField>
                <Box float="right">
                    <TextContent><h5>Total Files to Upload: {validFile.length}</h5></TextContent>
                </Box>
            </Container>
            <br/>
            <div>
                <Box float='right'>
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="primary"
                                disabled={false}
                                onClick={handleExportMappingFileHeader}>Export Mapping File Header</Button>
                        <Button variant="primary"
                                disabled={uploadDisable}
                                onClick={handleFileUpload}>Upload</Button>
                        <Button disabled={false}
                                onClick={onCancel}>Cancel</Button>
                    </SpaceBetween>
                </Box>
                <br/>
                <br/>
                <UploadDocumentsStatus uploadStatus={uploadMappingStatus}
                                       uploadId={uploadId}/>
            </div>
        </div>
    );

}
export default UploadMappingFile;
