import React, {Component} from "react";
import CONSTANT from "../../../utils/constant";
import Logger from "../../../utils/logger";
import APIClient from "../../../utils/apiClient";
import {DropDownElement, FreeTextElement} from "../../../component/dataEntry";
import {performValidationCheckForTargetCCYMatching} from "./paymentModalUtils";
import {
    ColumnLayout,
    Button,
    ProgressBar,
    DatePicker,
    FormField,
    Modal,
    SpaceBetween,
    Box
} from "@amzn/awsui-components-react";
import '@amzn/awsui-global-styles/polaris.css';
import FormatData from "../../../utils/formatData";
import {lookupBranch, lookupRegion, kyribaAccountHasRUBCurrency} from "../paymentRecordTableCommon";
import {getStage} from "../../../utils/environment";
import {PolarisV3FlashBar} from "../../../component/dataDisplay";
import paymentUpdateUtil from "../../../utils/paymentUpdateUtil";

// UI Modal for the UpdatePayment Window.
class UpdatePaymentModal extends Component {

    constructor(props) {
        super(props);

        let selectedItemPaymentType = this.props.selectedPayment[CONSTANT.PR_COL_PAYMENT_TYPE] ?
            this.props.selectedPayment[CONSTANT.PR_COL_PAYMENT_TYPE] : '';
        let selectedItemCancellationReason = this.props.selectedPayment[CONSTANT.PR_COL_CANCELLATION_REASON] ?
            this.props.selectedPayment[CONSTANT.PR_COL_CANCELLATION_REASON] : '';

        this.state = {
            inputSettlementId: this.props.selectedPayment[CONSTANT.PR_COL_SETTLEMENT_ID],
            inputValueDate: this.props.selectedPayment[CONSTANT.PR_COL_VALUE_DATE] ?
                this.props.selectedPayment[CONSTANT.PR_COL_VALUE_DATE] : '',
            inputSendingAccount: this.props.selectedPayment[CONSTANT.PR_COL_SENDING_ACCOUNT] ?
                this.props.selectedPayment[CONSTANT.PR_COL_SENDING_ACCOUNT] : '',
            inputSendingCurrency: this.props.selectedPayment[CONSTANT.PR_COL_SENDING_CURRENCY] ?
                this.props.selectedPayment[CONSTANT.PR_COL_SENDING_CURRENCY] : '',
            inputSendingAmount: this.props.selectedPayment[CONSTANT.PR_COL_SENDING_AMOUNT] ?
                this.props.selectedPayment[CONSTANT.PR_COL_SENDING_AMOUNT] : '',
            inputReceivingAccount: this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_ACCOUNT] ?
                this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_ACCOUNT] : '',
            inputReceivingCurrency: this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_CURRENCY] ?
                this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_CURRENCY] : '',
            inputReceivingAmount: this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_AMOUNT] ?
                this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_AMOUNT] : '',
            inputTargetCurrency: this.props.selectedPayment[CONSTANT.PR_COL_TARGET_CURRENCY] ?
                this.props.selectedPayment[CONSTANT.PR_COL_TARGET_CURRENCY] : '',
            inputTargetAmount: this.props.selectedPayment[CONSTANT.PR_COL_TARGET_AMOUNT] ?
                this.props.selectedPayment[CONSTANT.PR_COL_TARGET_AMOUNT] : '',
            inputUSDEquivalent: this.props.selectedPayment[CONSTANT.PR_COL_SETTLEMENT_USD_EQUIV] ?
                this.props.selectedPayment[CONSTANT.PR_COL_SETTLEMENT_USD_EQUIV] : '',
            inputPaymentType: selectedItemPaymentType,
            previousSendingAccount: this.props.selectedPayment[CONSTANT.PR_COL_SENDING_ACCOUNT] ?
                this.props.selectedPayment[CONSTANT.PR_COL_SENDING_ACCOUNT] : '' ,
            previousReceivingAccount: this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_ACCOUNT] ?
                this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_ACCOUNT] : '',
            sendingAccountsList: this.props.companyCodeToKyribaBankAccountsMap[this.props.selectedPayment[CONSTANT.PR_COL_SENDING_CO]] ?
                this.props.companyCodeToKyribaBankAccountsMap[this.props.selectedPayment[CONSTANT.PR_COL_SENDING_CO]] : [],
            receivingAccountsList: this.props.companyCodeToKyribaBankAccountsMap[this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_CO]] ?
                this.props.companyCodeToKyribaBankAccountsMap[this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_CO]] : [],
            fxRequiringCurrencyMapping: this.props.fxRequiringCurrencyMapping,
            status: CONSTANT.MODAL_EMPTY,
            statusMsg: '',
            rejectionWindowVisible: false,
            inputCancellationReason: selectedItemCancellationReason,
            inputAdditionalCancellationReason: '',
            cancellationReasonDropdownDisabled: selectedItemPaymentType === 'Cancelled' ? false : true,
            additionalCancellationReasonDisabled: false,
            validationFailureMessage: '',
            failedPaymentTypeValidation: false,
        }

        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleKyribaBankAccountValueChange = this.handleKyribaBankAccountValueChange.bind(this);
        this.handleValueDateChange = this.handleValueDateChange.bind(this);
        Logger.logInfo("Intialized UpdatePaymentModal");
    }

    // Handle Value Change
    handleValueChange = (name, value) => {
        Logger.logInfo("UpdatePaymentModal handleValueChange: " + name + " " + value);
        this.setState({[name]: value});
    };

    // Handle ValueDate Change
    handleValueDateChange = (detail) => {
        Logger.logInfo("UpdatePaymentModal handleValueDateChange: "  + detail.value);
        this.setState({inputValueDate: detail.value});
    };

    // Handle Payment Type Value Change
    handlePaymentTypeValueChange = (name, value) => {
        Logger.logInfo("handlePaymentTypeValueChange handleValueChange: " + name + " " + value);
        let cancellationReasonDropdownDisabled = value === 'Cancelled' ? false: true
        this.setState({
            [name]: value,
            cancellationReasonDropdownDisabled: cancellationReasonDropdownDisabled
        });

        if(cancellationReasonDropdownDisabled) {
            this.setState({
                inputCancellationReason : '',
                inputAdditionalCancellationReason : '',
                additionalCancellationReasonDisabled: false
            });
        }
    };

    // Handle Cancellation Reason Value Change.
    handleCancellationReasonValueChange = (name, value) => {
        Logger.logInfo("handleCancellationReasonValueChange handleValueChange: " + name + " " + value);
        let additionalCancellationReasonDisabled = value === 'Other' ? false: true;
        this.setState({[name]: value});
        this.setState({additionalCancellationReasonDisabled: additionalCancellationReasonDisabled});

        if (additionalCancellationReasonDisabled) {
            this.setState({
                inputAdditionalCancellationReason : ''
            });
        }
    };

    resetAdditionalCancellationReason = () => {
        if (this.state.additionalCancellationReasonDisabled) {
            this.setState({inputAdditionalCancellationReason: ''});
        }
    }

    // Transform KyribaBankAccountRecords into UI items for polaris list-selection components.
    getKyribaBankAccountSelectionsfromKyribaBankAccountRecordsList = (kyribaBankAccountRecords) => {
        return  kyribaBankAccountRecords.
            filter(kyribaBankAccountRecord => !kyribaAccountHasRUBCurrency(kyribaBankAccountRecord))
            .map(kyribaBankAccountRecord => (({
                label: kyribaBankAccountRecord['beneficiary'],
                id: kyribaBankAccountRecord['beneficiary'],
                value: kyribaBankAccountRecord['beneficiary']
            })));
    }

    // Get the list of selection options for Payment Type Dropdown element
    getPaymentTypeSelections = () => {
        return  CONSTANT.PAYMENT_TYPE_LIST.map(paymentType => (({
            label: paymentType,
            id: paymentType,
            value: paymentType
        })));
    }

    // Get the list of cancellation reasons for the dropdown element
    getCancellationReasons = () => {
        return  CONSTANT.CANCELLATION_REASON_LIST.map(cancellationReason => (({
            label: cancellationReason,
            id: cancellationReason,
            value: cancellationReason
        })));
    }

    // Check whether the exchange rate query response is valid.
    hasValidExchangeRate = (listOfExchangeRateRecords, fromCurrency, toCurrency) => {

        //Check if there's at least one exchange rate record.
        if (listOfExchangeRateRecords.length === 0) {
            return false;
        }

        let validExchangeRatesRecord = listOfExchangeRateRecords[listOfExchangeRateRecords.length - 1];

        //Check whether the record matches the queried pair.
        return (validExchangeRatesRecord['fromCurrency'] === fromCurrency &&
            validExchangeRatesRecord['toCurrency'] === toCurrency &&
            validExchangeRatesRecord['conversionRate']);
    }

    /* Get the value date for currency conversion. This method is needed as Non-prod stages does not have
        currency conversion rates for each day as a design choice to save space and cost. */
    getValueDateForCurrencyConversionBasedOnStage = () => {
        const stage = getStage();
        if (stage === 'dev' || stage === 'beta') {
            return '2020-01-01';
        } else if (stage === 'gamma') {
            return '2020-07-01';
        } else if (stage === 'prod') {
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 2);
            return this.formatJSDateInYyyyMmDd(yesterday);
        }
    }

    // Query the exchange rates and convert account amount based on rates.
    queryExchangeRateAndConvertAmount = (fromCurrency, toCurrency,
                                         name, updatedBankAccountCode,
                                         currencyVariableName, amountVariableName, previousAccountVariableName,
                                         fromCurrencyAmount) => {
        Logger.logInfo("Start to query Exchange Rate")
        Logger.logInfo("fromCurrency : " + fromCurrency
            + " \n toCurrency: " + toCurrency
            + " \n name: " + name
            + " \n updatedBankAccountCode: " + updatedBankAccountCode
            + " \n currencyVariableName: " + currencyVariableName
            + " \n amountVariableName: " + amountVariableName
            + " \n fromCurrencyAmount: " + fromCurrencyAmount);
        var parameter = {fromCurrency:fromCurrency, toCurrency: toCurrency};
            //valueDate: this.getValueDateForCurrencyConversionBasedOnStage()};
        this.setState({
            status: CONSTANT.MODAL_QUERYING,
            statusMsg: 'Converting the amount based on exchange rates...'
        });

        APIClient.invoke('GET', 'exchangerate', parameter,
            undefined,
            (err, data) => {
                if (!err && this.hasValidExchangeRate(data['exchangeRatesRecord'], fromCurrency, toCurrency)) {
                    let exchangeRatesRecords = data['exchangeRatesRecord'];    
                    // exchangeRatesRecords are sorted by conversionDate, using the latest conversionDate by default.
                    let latestExchangeRateRecord = exchangeRatesRecords[exchangeRatesRecords.length - 1];
                    Logger.logInfo("Latest latestExchangeRateRecord from API call:" + JSON.stringify(latestExchangeRateRecord))
                    let conversionRate =  latestExchangeRateRecord['conversionRate'];

                    // Retrieve Decimal Digits from KyribaCurrency table for toCurrency
                    const decimalDigits = this.props.currencyToDecimalDigitMapping[toCurrency];
                    Logger.logInfo("Decimal Digits for " + toCurrency + " currency: " + decimalDigits);

                    // Calculate updated amount, based on conversion rate, and convert to proper decimal format
                    let updatedAmount = fromCurrencyAmount * conversionRate;
                    Logger.logInfo("Updated Amount before formatting: " + updatedAmount);
                    updatedAmount = decimalDigits !== undefined ? updatedAmount.toFixed(decimalDigits) : updatedAmount;
                    Logger.logInfo("Updated Amount after formatting: " + updatedAmount);

                    this.setState({
                        [name]: updatedBankAccountCode,
                        [currencyVariableName]: toCurrency,
                        [amountVariableName]: updatedAmount,
                        [previousAccountVariableName] : updatedBankAccountCode,
                        status: CONSTANT.MODAL_QUERY_SUCCESS,
                        statusMsg: 'Successfully converted the amount based on exchange rates.'
                    }, () => this.updateTargetCurrencyAndAmount(name));
                } else {
                    Logger.logError("Unable to get valid Exchange Rate, reverting to previous bankAccount value");
                    let previousBankAccountValue = (name === 'inputSendingAccount') ?
                        this.state.previousSendingAccount : this.state.previousReceivingAccount ;
                    let currencyPair = '{fromCurrency: ' + fromCurrency + ' toCurrency: '+ toCurrency + '}';
                    this.setState({
                        [name]: previousBankAccountValue,
                        status: CONSTANT.MODAL_QUERY_ERROR,
                        statusMsg: 'Failed to update account to: {' + updatedBankAccountCode +
                            '} Reason: failed query valid exchange rates for ' + currencyPair +
                            ' Reverting the account to the previously selected one: ' + previousBankAccountValue
                    })
                }
            });
    }

    // Handle Value Change for Kyriba account selections.
    handleKyribaBankAccountValueChange = (name, updatedBankAccountCode) => {
        Logger.logInfo("handleKyribaBankAccountValueChange: " + name + " " + updatedBankAccountCode);
        // Gather variable names for updating the React states.
        const currencyVariableName = (name === 'inputSendingAccount') ? 'inputSendingCurrency' : 'inputReceivingCurrency';
        const amountVariableName = (name === 'inputSendingAccount') ? 'inputSendingAmount' : 'inputReceivingAmount';
        const previousAccountVariableName = (name === 'inputSendingAccount') ? 'previousSendingAccount' : 'previousReceivingAccount';

        // Get the currency of the account being updated
        const accountsList = (name === 'inputSendingAccount') ? this.state.sendingAccountsList : this.state.receivingAccountsList;
        const updatedCurrency = accountsList.find(function (kyribaBankAccountRecord) {
            return kyribaBankAccountRecord['beneficiary'] === updatedBankAccountCode ;
        })['currency']

        // Get the currency of the original account.  
        const originalCurrency = (name === 'inputSendingAccount') ? this.state.inputSendingCurrency : this.state.inputReceivingCurrency;

        // Get the currency & amount of the paired account.
        const pairedCurrency = (name === 'inputSendingAccount') ? this.state.inputReceivingCurrency : this.state.inputSendingCurrency;
        const pairedCurrencyAmount = (name === 'inputSendingAccount') ? this.state.inputReceivingAmount : this.state.inputSendingAmount;

        const targetCurrency = this.props.selectedPayment[CONSTANT.PR_COL_TARGET_CURRENCY];

        // Start to recalculate the amount of the account being updated.
        let updatedAmount = '';

        if (updatedCurrency === originalCurrency) {
            // If the updatedCurrency matches the original currency, only update bank account information. 
            this.setState({
                [name]: updatedBankAccountCode,
                [previousAccountVariableName]: updatedBankAccountCode
            });
        } else if (updatedCurrency === 'USD') {
            // If the updatedCurrency is USD, use the USD equivalent amount as the updatedAmount.
            updatedAmount = this.props.selectedPayment[CONSTANT.PR_COL_SETTLEMENT_USD_EQUIV];
            this.setState({
                [name]: updatedBankAccountCode,
                [currencyVariableName]: updatedCurrency,
                [amountVariableName]: updatedAmount,
                [previousAccountVariableName]: updatedBankAccountCode
            }, () => this.updateTargetCurrencyAndAmount(name));
        } else if (updatedCurrency === targetCurrency) {
            // If the updatedCurrency is the same as target Currency, use the target amount as the updatedAmount.
            updatedAmount = this.props.selectedPayment[CONSTANT.PR_COL_TARGET_AMOUNT];
            this.setState({
                [name]: updatedBankAccountCode,
                [currencyVariableName]: updatedCurrency,
                [amountVariableName]: updatedAmount,
                [previousAccountVariableName]: updatedBankAccountCode
            },() => this.updateTargetCurrencyAndAmount(name));
        } else if (updatedCurrency === pairedCurrency) {
            // If the updatedCurrency is the same as the pairedCurrency, use the amount of the pairedCurrency as updatedAmount.
            updatedAmount = pairedCurrencyAmount;
            this.setState({
                [name]: updatedBankAccountCode,
                [currencyVariableName]: updatedCurrency,
                [amountVariableName]: updatedAmount,
                [previousAccountVariableName]: updatedBankAccountCode
            },() => this.updateTargetCurrencyAndAmount(name));
        } else {
            // In other cases, convert the updatedAmount based on the exchange rates as followed:
            // fromCurrency: PairedCurrency, toCurrency: UpdateCurrency
            const fromCurrency = pairedCurrency
            const fromCurrencyAmount = pairedCurrencyAmount;
            const toCurrency = updatedCurrency;

            this.queryExchangeRateAndConvertAmount(fromCurrency, toCurrency,
                name, updatedBankAccountCode,
                currencyVariableName, amountVariableName, previousAccountVariableName,
                fromCurrencyAmount);
        }
    };

    /* Update the Target Currency and Amount to sendingCurrency if Target Currency does not
     match neither sendingCurrency nor receivingCurrency after account updates. */
    updateTargetCurrencyAndAmount = (name) => {
        let targetCurrency = this.state.inputTargetCurrency;
        let updatedCurrency = (name === 'inputSendingAccount') ? this.state.inputSendingCurrency : this.state.inputReceivingCurrency;
        let pairedCurrency = (name === 'inputSendingAccount') ? this.state.inputReceivingCurrency : this.state.inputSendingCurrency;

        Logger.logInfo("inputTargetCurrency: " + JSON.stringify(this.state.inputTargetCurrency));
        Logger.logInfo("updatedCurrency: " + JSON.stringify(updatedCurrency));
        Logger.logInfo("pairedCurrency: " + JSON.stringify(pairedCurrency));

        // If the target CCY is CNH, CNY as sending/receiving CCY has equivalency.
        if (targetCurrency === 'CNH' && (pairedCurrency === 'CNY' || updatedCurrency === 'CNY')){
            return;
        }
        if (targetCurrency !== pairedCurrency && targetCurrency !== updatedCurrency) {
            this.setState({
                inputTargetCurrency: this.state.inputSendingCurrency,
                inputTargetAmount: this.state.inputSendingAmount,
            })
        }
    }

    // Render the Modal message and progress bar based on the modal state
    getFeedbackMessage = () => {
        switch (this.state.status) {
            case CONSTANT.MODAL_QUERYING:
                return (
                    <ProgressBar
                        status="in-progress"
                        value={50}
                        label="Converting Currency Amount"
                    />);
            case CONSTANT.MODAL_QUERY_SUCCESS:
                return (
                    <ProgressBar
                        status="success"
                        resultText= {this.state.statusMsg}
                    />);
            case CONSTANT.MODAL_QUERY_ERROR:
                return (
                    <ProgressBar
                        status="error"
                        resultText= {this.state.statusMsg}
                    />);
            case CONSTANT.MODAL_UPDATE_VALIDATION_FAIL:
                /* Fully uncontrolled component with a key approach per react documentation
                 https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
                to force creating new instance of Flashbar on props changes. */
                return (<PolarisV3FlashBar
                    dismissible={true}
                    content={this.state.validationFailureMessage}
                    type="error"
                    key={this.state.validationFailureMessage}/>);
            default:
                return;
        }
    }

    /**
     * Validate whether rejection reason & additional rejection reason are null if paymentType is Cancelled.
     */
    validateRejectionReason = () => {
        if (this.state.inputPaymentType === 'Cancelled') {
            //Cancellation Reason cannot be empty if the paymentType is chosen as Cancelled.
            if (!CONSTANT.CANCELLATION_REASON_LIST.includes(this.state.inputCancellationReason)) {
                // this.setState({status: CONSTANT.MODAL_UPDATE_EMPTY_REJECTION_REASON});
                const ruleName = 'EMPTY_REJECTION_REASON';
                this.setState({
                    status: CONSTANT.MODAL_UPDATE_VALIDATION_FAIL,
                    validationFailureMessage: CONSTANT.UPDATE_VALIDATION_RULE_TO_MESSAGES_MAP[ruleName]
                });
                return false;
            } else if (this.state.inputCancellationReason === 'Other') {
                //Additional cancellation Reason cannot be empty if the Cancellation Reason is chosen as Other.
                if (this.state.inputAdditionalCancellationReason === '') {
                    const ruleName = 'EMPTY_ADDITIONAL_REJECTION_REASON';
                    this.setState({
                        status: CONSTANT.MODAL_UPDATE_VALIDATION_FAIL,
                        validationFailureMessage: CONSTANT.UPDATE_VALIDATION_RULE_TO_MESSAGES_MAP[ruleName]
                    });
                    return false;

                }
            }
        }

        return true;
    }

    // Additional Update Details field is required on any changes from update payment modal.
    validateAdditionalUpdateDetails = () => {
        if (this.state.inputAdditionalCancellationReason === '') {
            const ruleName = 'EMPTY_ADDITIONAL_REJECTION_REASON';
            this.setState({
                status: CONSTANT.MODAL_UPDATE_VALIDATION_FAIL,
                validationFailureMessage: CONSTANT.UPDATE_VALIDATION_RULE_TO_MESSAGES_MAP[ruleName]
            });
            return false;
        }
        return true;
    }

    /**
     * Determine whether the Wire payment type settlement update is valid based on the following rules:
     *
     * 1. Sending currency and receiving currency match
     * OR
     * 2. Sending currency does not match receiving currency
     *   AND USD equivalent is less than $25K
     *   AND sending currency is not in IC CCY Requiring FX Trade mapping table
     *   AND receiving currency is not in IC CCY Requiring FX Trade mapping table
     *   AND receiving currency is not in IC BOA CCY Requiring FX Trade mapping table OR sending bank branch is not BA_DUBLIN
     */
    performUpdateValidationForWirePaymentType = () => {
        const WIRE_USD_QUIV_CAP = 25000;
        const BA_DUBLIN_BRANCH = 'BA_DUBLIN';
        let fxRequiringCurrenciesSet = this.state.fxRequiringCurrencyMapping['FX'];
        let boaFxRequiringCurrenciesSet = this.state.fxRequiringCurrencyMapping['BoaFX'];
        let sendingAccountBankBranch = lookupBranch(this.state.inputSendingAccount,
            this.props.selectedPayment[CONSTANT.PR_COL_SENDING_CO],
            this.props.companyCodeToKyribaBankAccountsMap);

        if (this.state.inputSendingCurrency === this.state.inputReceivingCurrency) {
            return true;
        } else if (this.state.inputUSDEquivalent <= WIRE_USD_QUIV_CAP &&
                !fxRequiringCurrenciesSet.includes(this.state.inputSendingCurrency) &&
                !fxRequiringCurrenciesSet.includes(this.state.inputReceivingCurrency) &&
                    (!boaFxRequiringCurrenciesSet.includes(this.state.inputReceivingCurrency) ||
                        sendingAccountBankBranch !== BA_DUBLIN_BRANCH)) {
                return true;
        }

        return false;
     }

    /**
     * Determine whether the FX payment type settlement update is valid based on the following rules:
     *
     * 1. Sending currency DOES NOT match receiving currency AND USD equivalent is equal to or greater than $25K
     * OR
     * 2.
     * Sending currency DOES NOT match receiving currency AND USD equivalent less than $25K
     * AND {
	 *  sending currency is in IC CCY Requiring FX Trade mapping table
	 *  OR receiving currency is in IC CCY Requiring FX Trade mapping table
	 *  OR [receiving currency is in IC BOA CCY Requiring FX Trade mapping table AND sending bank branch is BA_DUBLIN]}
     */
    performUpdateValidationForFXPaymentType = () => {
        const FX_USD_QUIV_CAP = 25000;
        const BA_DUBLIN_BRANCH = 'BA_DUBLIN';
        let fxRequiringCurrenciesSet = this.state.fxRequiringCurrencyMapping['FX'];
        let boaFxRequiringCurrenciesSet = this.state.fxRequiringCurrencyMapping['BoaFX'];
        let sendingAccountBankBranch = lookupBranch(this.state.inputSendingAccount,
            this.props.selectedPayment[CONSTANT.PR_COL_SENDING_CO],
            this.props.companyCodeToKyribaBankAccountsMap);

        if (this.state.inputSendingCurrency !== this.state.inputReceivingCurrency) {
            if (this.state.inputUSDEquivalent >= FX_USD_QUIV_CAP) {
                return true
            } else if (fxRequiringCurrenciesSet.includes(this.state.inputSendingCurrency) ||
                fxRequiringCurrenciesSet.includes(this.state.inputReceivingCurrency) ||
                (boaFxRequiringCurrenciesSet.includes(this.state.inputReceivingCurrency) &&
                    sendingAccountBankBranch === BA_DUBLIN_BRANCH)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Validate whether an update is valid based on TBO rules.
     */
    performUpdateValidation = () => {
        const targetCCYValidationResult = performValidationCheckForTargetCCYMatching(
            this.state.inputTargetCurrency,
            this.state.inputSendingCurrency,
            this.state.inputReceivingCurrency
        );
        if (!targetCCYValidationResult) {
            this.setState({
                status: CONSTANT.MODAL_UPDATE_VALIDATION_FAIL,
                validationFailureMessage: 'TargetCCY should match either sendingCCY or receivingCCY.'
            });
            return false;
        }

        let paymentTypeValidationResult = true;
        if (this.state.inputPaymentType === 'FX') {
            paymentTypeValidationResult = this.performUpdateValidationForFXPaymentType();
        } else if (this.state.inputPaymentType === 'Wire') {
            paymentTypeValidationResult = this.performUpdateValidationForWirePaymentType();
        }

        if (!paymentTypeValidationResult) {
            this.setState({
                status: CONSTANT.MODAL_UPDATE_VALIDATION_FAIL,
                validationFailureMessage: 'Update failed because the settlement does not match the rules under its payment type.' +
                    'Please refer to the FAQ section in Settlement User Guide Wiki: ' +
                    'https://w.amazon.com/bin/view/Treasury/TreasuryTech/TRS/UserGuide/SettlementModuleGuide/#H4.5WhatarethecalculationruleswhenupdatingPaymentTypes3F'
            });
            return false;
        }

        return true;
    }

    /*  Handler function for confirming updates on a payment.
    This function simply updates fields in a payment record based on front-end UI selections and delegate the call to
    parent update handler for invoking PUT method on relative API endpoints. */
    handleUpdateClick = () => {
        if (!this.validateRejectionReason()) {
            return;
        }

        if (!this.validateAdditionalUpdateDetails()) {
            return;
        }

        if (!this.performUpdateValidation()) {
            this.setState({failedPaymentTypeValidation : true})
            return;
        }
        this.executeUpdate();
    }

    /**
     * Allow Override button only in Historical Payment UI.
     * Handler function to allow update in a payment record only if fields are failed based on
     * PaymentType logic check. Otherwise, no update is allowed.
     */
    handleOverrideClick = () => {
        if (this.state.failedPaymentTypeValidation === true) {
            this.executeUpdate();
        }
    }

    /**
     * Process update fields in a payment record.
     */
    executeUpdate() {
        // Keep the previous state of the selected payment while executing update
        const previousPaymentState = Object.assign({}, this.props.selectedPayment);

        // Update Sending region if the settlement is updated with a new sendingAccount
        if (this.props.selectedPayment[CONSTANT.PR_COL_SENDING_ACCOUNT] !== this.state.inputSendingAccount) {
            // Update the selectedPayment with the newly selected sendingAccount
            this.props.selectedPayment[CONSTANT.PR_COL_SENDING_ACCOUNT] = this.state.inputSendingAccount;
            // Look up the region for the newly selected sendingAccount
            let co = this.props.selectedPayment[CONSTANT.PR_COL_SENDING_CO];
            let accountCode = this.props.selectedPayment[CONSTANT.PR_COL_SENDING_ACCOUNT];
            let newRegion = lookupRegion(this.props.accountCategoryToRegionMap,
                this.props.selectedPayment, co, accountCode,
                this.props.companyCodeToKyribaBankAccountsMap)
            this.props.selectedPayment[CONSTANT.PR_COL_REGION] = newRegion;
        }

        // Update Receiving region if the settlement is updated with a new receivingAccount
        if (this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_ACCOUNT] !== this.state.inputReceivingAccount) {
            // Update the selectedPayment with the newly selected receivingAccount
            this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_ACCOUNT] = this.state.inputReceivingAccount;
            // Look up the region for the newly selected receivingAccount
            let co = this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_CO];
            let accountCode = this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_ACCOUNT];
            let newRegion = lookupRegion(this.props.accountCategoryToRegionMap,
                this.props.selectedPayment, co, accountCode,
                this.props.companyCodeToKyribaBankAccountsMap)
            this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_REGION] = newRegion;
        }

        this.props.selectedPayment[CONSTANT.PR_COL_SETTLEMENT_ID] = this.state.inputSettlementId;
        this.props.selectedPayment[CONSTANT.PR_COL_VALUE_DATE] = this.state.inputValueDate;
        this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_ACCOUNT] = this.state.inputReceivingAccount;
        this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_CURRENCY] = this.state.inputReceivingCurrency;
        this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_AMOUNT] = this.state.inputReceivingAmount;
        this.props.selectedPayment[CONSTANT.PR_COL_SENDING_ACCOUNT] = this.state.inputSendingAccount;
        this.props.selectedPayment[CONSTANT.PR_COL_SENDING_CURRENCY] = this.state.inputSendingCurrency;
        this.props.selectedPayment[CONSTANT.PR_COL_SENDING_AMOUNT] = this.state.inputSendingAmount;
        this.props.selectedPayment[CONSTANT.PR_COL_TARGET_CURRENCY] = this.state.inputTargetCurrency;
        this.props.selectedPayment[CONSTANT.PR_COL_TARGET_AMOUNT] = this.state.inputTargetAmount;
        this.props.selectedPayment[CONSTANT.PR_COL_SETTLEMENT_USD_EQUIV] = this.state.inputUSDEquivalent;
        this.props.selectedPayment[CONSTANT.PR_COL_PAYMENT_TYPE] = this.state.inputPaymentType;
        this.props.selectedPayment[CONSTANT.PR_COL_ADDITIONAL_CANCELLATION_REASON] = this.state.inputAdditionalCancellationReason;
        this.props.selectedPayment[CONSTANT.PR_COL_CANCELLATION_REASON] = this.state.inputCancellationReason;

        // Run specific logic to handle status overrides based on payment status and payment type change
        // The method will modify the selectedPayment object in-place
        paymentUpdateUtil.overridePaymentUpdateForPaymentTypeChange(
            previousPaymentState, this.props.selectedPayment, this.state, this.props.user
        );

        this.props.handleConfirmUpdateClick(this.props.elementSelected);
        // save inputAdditionalCancellationReason for any changes in update payment model.
        let commentData = this.props.selectedPayment[CONSTANT.PR_COL_USER_COMMENTS] ?
            JSON.parse(this.props.selectedPayment[CONSTANT.PR_COL_USER_COMMENTS]) : [];
        let rejectionComment = this.state.inputAdditionalCancellationReason;
        Logger.logInfo("inputAdditionalCancellationReason: " + JSON.stringify(this.state.inputAdditionalCancellationReason));

        let messageCommentObject = {};
        messageCommentObject["Comment"] = rejectionComment;
        messageCommentObject["User"] = this.props.user;
        messageCommentObject["CommentDate"] = FormatData.formateNewUTCCurrentTime();
        commentData.push(messageCommentObject);
        Logger.logInfo("commentData: " + JSON.stringify(commentData));
        this.props.selectedPayment[CONSTANT.PR_COL_USER_COMMENTS] = JSON.stringify(commentData);
    }

    resetStateDataAndDismiss = () => {
        let selectedItemPaymentType = this.props.selectedPayment[CONSTANT.PR_COL_PAYMENT_TYPE] ?
            this.props.selectedPayment[CONSTANT.PR_COL_PAYMENT_TYPE] : '';
        let selectedItemCancellationReason = this.props.selectedPayment[CONSTANT.PR_COL_CANCELLATION_REASON] ?
            this.props.selectedPayment[CONSTANT.PR_COL_CANCELLATION_REASON] : '';

        this.setState({
            inputSettlementId: this.props.selectedPayment[CONSTANT.PR_COL_SETTLEMENT_ID],
            inputValueDate: this.props.selectedPayment[CONSTANT.PR_COL_VALUE_DATE] ?
                this.props.selectedPayment[CONSTANT.PR_COL_VALUE_DATE] : '',
            inputSendingAccount: this.props.selectedPayment[CONSTANT.PR_COL_SENDING_ACCOUNT] ?
                this.props.selectedPayment[CONSTANT.PR_COL_SENDING_ACCOUNT] : '',
            inputSendingCurrency: this.props.selectedPayment[CONSTANT.PR_COL_SENDING_CURRENCY] ?
                this.props.selectedPayment[CONSTANT.PR_COL_SENDING_CURRENCY] : '',
            inputSendingAmount: this.props.selectedPayment[CONSTANT.PR_COL_SENDING_AMOUNT] ?
                this.props.selectedPayment[CONSTANT.PR_COL_SENDING_AMOUNT] : '',
            inputReceivingAccount: this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_ACCOUNT] ?
                this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_ACCOUNT] : '',
            inputReceivingCurrency: this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_CURRENCY] ?
                this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_CURRENCY] : '',
            inputReceivingAmount: this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_AMOUNT] ?
                this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_AMOUNT] : '',
            inputTargetCurrency: this.props.selectedPayment[CONSTANT.PR_COL_TARGET_CURRENCY] ?
                this.props.selectedPayment[CONSTANT.PR_COL_TARGET_CURRENCY] : '',
            inputTargetAmount: this.props.selectedPayment[CONSTANT.PR_COL_TARGET_AMOUNT] ?
                this.props.selectedPayment[CONSTANT.PR_COL_TARGET_AMOUNT] : '',
            inputUSDEquivalent: this.props.selectedPayment[CONSTANT.PR_COL_SETTLEMENT_USD_EQUIV] ?
                this.props.selectedPayment[CONSTANT.PR_COL_SETTLEMENT_USD_EQUIV] : '',
            inputPaymentType: selectedItemPaymentType,
            previousSendingAccount: this.props.selectedPayment[CONSTANT.PR_COL_SENDING_ACCOUNT] ?
                this.props.selectedPayment[CONSTANT.PR_COL_SENDING_ACCOUNT] : '' ,
            previousReceivingAccount: this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_ACCOUNT] ?
                this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_ACCOUNT] : '',
            sendingAccountsList: this.props.companyCodeToKyribaBankAccountsMap[this.props.selectedPayment[CONSTANT.PR_COL_SENDING_CO]] ?
                this.props.companyCodeToKyribaBankAccountsMap[this.props.selectedPayment[CONSTANT.PR_COL_SENDING_CO]] : [],
            receivingAccountsList: this.props.companyCodeToKyribaBankAccountsMap[this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_CO]] ?
                this.props.companyCodeToKyribaBankAccountsMap[this.props.selectedPayment[CONSTANT.PR_COL_RECEIVING_CO]] : [],
            status: CONSTANT.MODAL_EMPTY,
            statusMsg: '',
            rejectionWindowVisible: false,
            inputCancellationReason: selectedItemCancellationReason,
            inputAdditionalCancellationReason: this.props.selectedPayment[CONSTANT.PR_COL_ADDITIONAL_CANCELLATION_REASON] ?
                this.props.selectedPayment[CONSTANT.PR_COL_ADDITIONAL_CANCELLATION_REASON] : '',
            cancellationReasonDropdownDisabled: selectedItemPaymentType === 'Cancelled' ? false : true,
            additionalCancellationReasonDisabled: false, // any fields are updated, this is enable.
            validationFailureMessage: ''
        });

        this.props.handleDismissClick();
    }

    /**
     * Determine whether a value date is a valid/enabled based on these rules:
     *  1. The value date has to be in the future compared to the current date.
     *  2. The value date should not be on a weekend day based on the sending currency's weekend calendar.
     *  3. The value date should not be on a holiday based on the sending currency's holiday calendar.
     */
    isValueDateValid = (valueDate) => {
        const isValueDateNotBeforeCurrentDate = this.isValueDateNotBeforeCurrentDate(valueDate);
        const isValueDateNotOnCurrencyWeekendCalendar = this.isValueDateNotOnCurrencyWeekendCalendar(valueDate,
            this.state.inputSendingCurrency);
        const isValueDateNotOnCurrencyHolidayCalendar = this.isValueDateNotOnCurrencyHolidayCalendar(valueDate,
            this.state.inputSendingCurrency);

        return isValueDateNotBeforeCurrentDate &&
            isValueDateNotOnCurrencyWeekendCalendar &&
            isValueDateNotOnCurrencyHolidayCalendar;

    }

    /**
     * Determine whether a valueDate is NOT before the Current Date, current date being in browser's locale time.
     * @param valueDate
     */
    isValueDateNotBeforeCurrentDate = (valueDate) => {
        const currentDate = new Date().setHours(0,0,0,0);
        return valueDate >= currentDate;
    }

    /**
     * Determine whether the valueDate is NOT on the sendingCurrency's weekend calendar, based on the
     * weekendCalendarMapping that's passed in as props.
     *
     * weekendCalendarMapping's key: currency name.
     * weekendCalendarMapping's value: Days of the week that are weekends to this currency region.
     *
     * @param valueDate
     * @param sendingCurrency
     */
    isValueDateNotOnCurrencyWeekendCalendar = (valueDate, sendingCurrency) => {
        let result = true;
        if (Object.keys(this.props.weekendCalendarMapping).length !== 0) {
            const weekendDateList = this.props.weekendCalendarMapping[sendingCurrency];
            if (typeof weekendDateList !== 'undefined') {
                for (const weekendDateName of weekendDateList) {
                    if (valueDate.getDay() === CONSTANT.DAY_DISPLAY_NAME_TO_DAY_IN_A_WEEK_INDEX_MAP[weekendDateName]) {
                        result = false;
                    }
                }
            }
        }
        return result;
    }

    /**
     * Determine whether the valueDate is NOT on the sendingCurrency's holiday calendar, based on the
     * holidayCalendarMapping that's passed in as props.
     *
     * holidayCalendarMapping's key: a date that some currencies have holiday on.
     * holidayCalendarMapping's value: a list of currencies that have holidays on the key's date.
     *
     * @param valueDate
     * @param sendingCurrency
     */
    isValueDateNotOnCurrencyHolidayCalendar = (valueDate, sendingCurrency) => {
        let result = true;
        const valueDateInYyyyMmDd = this.formatJSDateInYyyyMmDd(valueDate);

        if (Object.keys(this.props.holidayCalendarMapping).length !== 0) {
            const currenciesThatHaveThisHoliday = this.props.holidayCalendarMapping[valueDateInYyyyMmDd];
            if (typeof  currenciesThatHaveThisHoliday !== 'undefined'
                && currenciesThatHaveThisHoliday
                && currenciesThatHaveThisHoliday.includes(sendingCurrency)){
                result = false;
            }
        }
        return result;
    }

    /**
     * Format a JS date object to the YYYY-mm-dd string in the browser's locale timezone.
     * @param valueDate
     */
    formatJSDateInYyyyMmDd = (valueDate) => {
        var yyyy = valueDate.getFullYear()
        var mm = valueDate.getMonth() + 1;
        var dd = valueDate.getDate();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        return yyyy + '-' + mm + '-' + dd;
    }

    // Render UI elements and route handler methods together.
    render() {
        return  <Modal
            visible={this.props.updateWindowVisible}
            header="Update Settlement"
            size = "large"
            onDismiss={this.resetStateDataAndDismiss}
            footer={this.props.disableOverrideButton ?
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link"
                                onClick={() => this.resetStateDataAndDismiss()}>Cancel</Button>
                        <Button variant="primary"
                                disabled={false}
                                onClick={() => this.handleUpdateClick()}>Update</Button>
                    </SpaceBetween>
                </Box> :
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link"
                                onClick={() => this.resetStateDataAndDismiss()}>Cancel</Button>
                        <Button variant="primary"
                                disabled={false}
                                onClick={() => this.handleUpdateClick()}>Update</Button>
                        <Button variant="link"
                                disabled={false}
                                onClick={() => this.handleOverrideClick()}>Override</Button>
                    </SpaceBetween>
                </Box>}>
                           <ColumnLayout columns={1}>
                                <div>
                                    <FreeTextElement title={"Settlement Id"}
                                                     name={"settlementId"}
                                                     value={this.state.inputSettlementId}
                                                     errorText={"ErrorText"}
                                                     disabled={true}
                                                     required={false}
                                                     handleChange={this.handleValueChange}
                                    />
                                </div>
                                <div>
                                    <FormField label="Value Date">
                                        <DatePicker
                                            onChange={event => {
                                                this.setState({ inputValueDate: event.detail.value })
                                                Logger.logInfo("valueDate afeterchange: " + this.state.inputValueDate)
                                            }}
                                            isDateEnabled={date =>
                                                this.isValueDateValid(date)
                                            }
                                            value={this.state.inputValueDate}
                                            nextMonthAriaLabel="Next month"
                                            placeholder="YYYY/MM/DD"
                                            previousMonthAriaLabel="Previous month"
                                            todayAriaLabel="Today"/>
                                    </FormField>
                                </div>
                                 <ColumnLayout columns={1}>
                                    <div>
                                        {this.getFeedbackMessage()}
                                    </div>
                                </ColumnLayout>
                                <ColumnLayout columns={3}>
                                        <div>
                                            <DropDownElement title={'Sending Account'}
                                                         name={'inputSendingAccount'}
                                                         value={this.state.inputSendingAccount}
                                                         options={this.getKyribaBankAccountSelectionsfromKyribaBankAccountRecordsList(this.state.sendingAccountsList)}
                                                         disabled={false}
                                                         required={true}
                                                         handleChange={this.handleKyribaBankAccountValueChange}
                                            />

                                        </div>
                                        <div>
                                            <FreeTextElement title={"Sending Currency"}
                                                         name={"sendingCurrency"}
                                                         value={this.state.inputSendingCurrency}
                                                         errorText={"ErrorText"}
                                                         disabled={true}
                                                         required={false}
                                                         handleChange={this.handleValueChange}
                                            />

                                        </div>
                                        <div>
                                            <FreeTextElement title={"Sending Amount"}
                                                         name={"sendingAmount"}
                                                         value={this.state.inputSendingAmount.toString()}
                                                         errorText={"ErrorText"}
                                                         disabled={true}
                                                         required={false}
                                                         handleChange={this.handleValueChange}
                                            />
                                        </div>
                                        <div>
                                            <DropDownElement title={'Receiving Account'}
                                                         name={'inputReceivingAccount'}
                                                         value={this.state.inputReceivingAccount}
                                                         options={this.getKyribaBankAccountSelectionsfromKyribaBankAccountRecordsList(this.state.receivingAccountsList)}
                                                         disabled={false}
                                                         required={true}
                                                         handleChange={this.handleKyribaBankAccountValueChange}
                                            />
                                        </div>
                                        <div>
                                            <FreeTextElement title={"Receiving Currency"}
                                                         name={"receivingCurrency"}
                                                         value={this.state.inputReceivingCurrency}
                                                         errorText={"ErrorText"}
                                                         disabled={true}
                                                         required={false}
                                                         handleChange={this.handleValueChange}
                                            />
                                        </div>
                                        <div>
                                            <FreeTextElement title={"Receiving Amount"}
                                                         name={"receivingAmount"}
                                                         value={this.state.inputReceivingAmount.toString()}
                                                         errorText={"ErrorText"}
                                                         disabled={true}
                                                         required={false}
                                                         handleChange={this.handleValueChange}
                                            />
                                        </div>
                                        <div>
                                            <FreeTextElement title={"Target Currency"}
                                                         name={"targetCurrency"}
                                                         value={this.state.inputTargetCurrency}
                                                         errorText={"ErrorText"}
                                                         disabled={true}
                                                         required={false}
                                                         handleChange={this.handleValueChange}
                                            />
                                        </div>
                                        <div>
                                            <FreeTextElement title={"Target Amount"}
                                                         name={"targetAmount"}
                                                         value={this.state.inputTargetAmount.toString()}
                                                         errorText={"ErrorText"}
                                                         disabled={true}
                                                         required={false}
                                                         handleChange={this.handleValueChange}
                                            />
                                        </div>
                                        <div>
                                            <FreeTextElement title={"USD Equivalent"}
                                                         name={"usdEquivalent"}
                                                         value={this.state.inputUSDEquivalent.toString()}
                                                         errorText={"ErrorText"}
                                                         disabled={true}
                                                         required={false}
                                                         handleChange={this.handleValueChange}
                                            />
                                        </div>
                                        <div>
                                            <DropDownElement title={'Payment Type'}
                                                         name={'inputPaymentType'}
                                                         value={this.state.inputPaymentType}
                                                         options={this.getPaymentTypeSelections()}
                                                         disabled={false}
                                                         required={true}
                                                         handleChange={this.handlePaymentTypeValueChange}
                                            />
                                        </div>
                                        <div>
                                            <DropDownElement title={'Cancellation Reason'}
                                                         name={'inputCancellationReason'}
                                                         value={this.state.inputCancellationReason}
                                                         options={this.getCancellationReasons()}
                                                         disabled={this.state.cancellationReasonDropdownDisabled}
                                                         handleChange={this.handleCancellationReasonValueChange}
                                            />
                                        </div>
                                    </ColumnLayout>
                                    <ColumnLayout columns={1}>
                                        <FreeTextElement title={"Additional Update Details"}
                                                         name={"inputAdditionalCancellationReason"}
                                                         value={this.state.inputAdditionalCancellationReason}
                                                         errorText={"ErrorText"}
                                                         disabled={this.state.additionalCancellationReasonDisabled}
                                                         required={true}
                                                         handleChange={this.handleValueChange}
                                        />
                                    </ColumnLayout>
                                    <Box>
                                        <p>When a new account is selected, the corresponding amount will be changed based on following rules:</p>
                                        <ul>
                                            <li>   If the updated account&apos;s currency is USD, use the settlement&apos;s [Settlement USD equivalent] as the updated amount. </li>
                                            <li>   If the updated account&apos;s currency is the same as the settlement&apos;s [Target Currency], use the settlement&apos;s [Target Amount] as the updated amount. </li>
                                            <li>   In all other cases, let pairedCurrency = sendingCurrency if receivingCurrency is updated, vice versa.
                                                the updated amount = [pairedCurrencyAmount]  * [exchange rate fromCurrency=pairedCurrency, toCurrency=updatedCurrency, date=today] </li>
                                        </ul>
                                    </Box>

                           </ColumnLayout>

        </Modal>;
    }
}

export default UpdatePaymentModal;