import CONSTANT from "../../utils/constant";
import FormatData from "../../utils/formatData";
import {hasDocumentAttached} from "../../document/upload/documentUtils";

// Common utility functions associated with queryAllTrades and queryException are defined here.

/**
 * Retrieves attributes not obtainable from selectedElement and enriches the exception element.
 * 
 * @param elements
 *      Elements that shall be enriched witin this function.
 * @param header
 *      The header elements associated with the Cloudscape table.
 * @param recordIdToDocumentIdMapping
 *      The mapping from recordId to list of attached documents.
 * @returns {Object[]}
 *      Returns the elements that were enriched as part of the enrichment process for the E&I module attributes.
 */
export const enrichElementsForDownload = (elements, header, recordIdToDocumentIdMapping) => {
    const elementCopy = JSON.parse(JSON.stringify(elements))
    // Map each element that exists within the elemets to enrich and process.
    const enrichedElements = elementCopy.map(oldElement => {
        const newElement = {};
        header.forEach((column) => {
            // Clean attribute names to be more human readable, and get the latest column from the User Comment list if one exists.
            if (column === CONSTANT.EI_COL_LATEST_COMMENT) {
                newElement[column] = getLatestComment(oldElement);
            } else if (column === CONSTANT.EI_COL_TROUBLE_TICKET) {
                newElement[CONSTANT.EI_COL_RELATED_TTS] = oldElement[column]
            } else if (column === CONSTANT.EI_COL_UPDATE_DATE) {
                newElement[CONSTANT.EI_COL_UPDATE_DATE_CLEANED] = FormatData.formatISODateToPSTTimezoneDate(oldElement[column]);
            } else if (column === CONSTANT.EI_COL_UPDATED_BY) {
                newElement[CONSTANT.EI_COL_UPDATED_BY_CLEANED] = oldElement[column];
            } else if (column === 'File Attached'){
                newElement[column] = hasDocumentAttached(newElement[CONSTANT.EI_COL_TRADE_ID],
                    recordIdToDocumentIdMapping) ? 'Yes' : 'No';
            } else {
                newElement[column] = oldElement[column];
            }
        })
        // Append System ID as the last column of the downloaded spreadsheet.
        newElement[CONSTANT.EI_COL_SYSTEM_ID] = oldElement[CONSTANT.EI_COL_SYSTEM_ID];
        return newElement;
    })
    return enrichedElements;
}

/** 
 * Function that cleans the header attributes to match the business specification.
 * (e.g. "Update_Date" -> "Update Date")
 * 
 * @param header
 *      The list of header attributes that shall be cleaned.
 * @returns {string[]}
 *      A cleaned list of header attributes based on the business specification.
 */ 
export const stageHeaderColumnForDownload = (header) => {
    const headerCopy = JSON.parse(JSON.stringify(header));
    const headerReturn = headerCopy.map((column) => {
        // Clean attribute names to be more human readable.
        if (column === CONSTANT.EI_COL_UPDATE_DATE) {
            return CONSTANT.EI_COL_UPDATE_DATE_CLEANED;
        } else if (column === CONSTANT.EI_COL_UPDATED_BY) {
            return CONSTANT.EI_COL_UPDATED_BY_CLEANED;
        } else if (column === CONSTANT.EI_COL_TROUBLE_TICKET) {
            return CONSTANT.EI_COL_RELATED_TTS;
        } else {
            return column;
        }
    })
    // Append System ID as the last column of the downloaded spreadsheet.
    headerReturn.push(CONSTANT.EI_COL_SYSTEM_ID);
    return headerReturn;
}

/** 
 * Function for determining if the date passed in is on or before the current date.
 * 
 * @param date
 *      The date in which the comparison is made.
 * @returns {boolean}
 *      A boolean that returns true if the date passed in is on or before the current date.
 */ 
export const isDateOnOrBeforeCurrentDate = (date) => {
    const currentDate = new Date().setHours(0,0,0,0);
    return date <= currentDate;
}

/** 
 * Function for getting the first date of the current month in string format.
 * 
 * @returns {string}
 *      The string representation of the first date of the current month in yyyy-MM-01 format.
 */ 
export const getFirstDayOfTheMonthInDateString = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1; //Month is 0-indexed
    if (month < 10) {month = '0' + month.toString();}
    return year + '-' + month +  "-01";
}

/** 
 * Function for getting the current day in string format.
 * 
 * @returns {string}
 *      The string representation of the current date in yyyy-MM-dd format.
 */ 
export const getCurrentDateInDateString = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1; //Month is 0-indexed
    if (month < 10) {month = '0' + month.toString();}
    let day = currentDate.getDate();
    if (day < 10) {day = '0' + day.toString();}
    return year + '-' + month +  '-' + day;
}

/**
 * Function for handling results of API Call.
 * Returns JSON object of states that shall be set for distributions, status, and statusMsg in the
 * calling method.
 * 
 * @param error
 *      The variable that holds the error object when an API call fails.
 * @param data
 *      The variable that holds the data object when the API call succeeds.
 * @return {Object}
 *      Returns the state that the calling component should be in based on the API response status.
 */
export const exceptionAPICallback = (error, data) => {
    let returnState = {};
    if (!error) {
        switch (data.status) {
            case CONSTANT.RESPONSE_SUCCESS:
                returnState = {
                    status: CONSTANT.MODAL_UPDATE_SUCCESS,
                    distributions: data.data,
                };
                break;
            case CONSTANT.RESPONSE_ERROR:
                returnState = {
                    status: CONSTANT.MODAL_UPDATE_ERROR,
                    distributions: data.data,
                    statusMsg: JSON.stringify(data['errorData'])
                };
                break;
            case CONSTANT.RESPONSE_SYSTEM_ERROR:
                returnState = {
                    status: CONSTANT.MODAL_SYSTEM_ERROR,
                    statusMsg: JSON.stringify(data['errorData'])
                };
                break;
            case CONSTANT.MODAL_REQUEST_ERROR:
                returnState = {
                    status: CONSTANT.MODAL_REQUEST_ERROR,
                    distributions: data.data,
                    statusMsg: JSON.stringify(data['errorData'])
                };
                break;
            default:
                returnState = {
                    status: CONSTANT.MODAL_OTHER_ERROR,
                    statusMsg: JSON.stringify(data['errorData'])
                }
        }
    } else {
        returnState = {
            status: CONSTANT.MODAL_OTHER_ERROR,
            statusMsg: "Other System Error"
        };
    }
    return returnState;
}


/**
 * Collects the newly added TTs in the text field entry from newRelatedTT, and the existing
 * TTs from the tempRelatedTT and combines them to update the new related TT list in both
 * Cloudscape and the database.
 * 
 * @param tempRelatedTT
 *      Related TTs associated with an exception that were already there as of opening the modal.
 * @param newRelatedTT
 *      New TTs that were added as new text field entries within the modal.
 * @returns {string}
 *      A comma-delimited string that will contain the unique list of TTs associated with the exception.
 */
export const getNewTTString = (tempRelatedTT, newRelatedTT) => {
    let newTTArray = [];
    if (tempRelatedTT?.trim().length > 0) {
        newTTArray = newTTArray.concat(tempRelatedTT.split(CONSTANT.RELATED_TT_DELIMITER));
    }
    if (newRelatedTT?.trim().length > 0) {
        newTTArray = newTTArray.concat(newRelatedTT.split(CONSTANT.RELATED_TT_DELIMITER));
    }
    // newTTArray is de-duplicated by casting the list to a set, then recasting it back to a list.
    return [...new Set(newTTArray)].join(CONSTANT.RELATED_TT_DELIMITER);
}

/**
 * Function for retrieving the latest comment or blank if no comments for clean viewing in the Exceptions Table.
 * 
 * @param item
 *      The exception distribution item that shall be parsed so that the latest comment can be obtained.
 * @returns {string}
 *      Returns the latest comment associated with the exception, or blank if there are no comments.
 */
export const getLatestComment = (item) => {
   if (CONSTANT.EI_COL_COMMENTS in item && item[CONSTANT.EI_COL_COMMENTS].length > 0) {
       return item[CONSTANT.EI_COL_COMMENTS][item[CONSTANT.EI_COL_COMMENTS].length - 1]['comment'];
   } else {
       return '';
   }
}

/**
 * Function to get the Duplicate Exception for the AllTradesModule.
 *
 * @param exceptionsTradeIdMapping
 *      The TradeId Exceptions Mapping.
 * @param selectedElement
 *      The item selected from the UI.
 * @returns {array}
 *      return the list that have exceptions matching by Trade ID, Sold Amount, and Sold CCY.
 */
export const getDuplicateException = (exceptionsTradeIdMapping, selectedElement) => {
    const potentialDuplicatedExceptions = [];
    const selectedTradeId = exceptionsTradeIdMapping[selectedElement['Trade ID']];

    for (let i = 0; i < selectedTradeId.length; i++) {
        const value = selectedTradeId[i];
        potentialDuplicatedExceptions.push(value);
    }
    return potentialDuplicatedExceptions.filter((obj) =>
        obj.tradeId === selectedElement['Trade ID'] &&
        obj.soldAmount === selectedElement['Sold Amount'] &&
        obj.soldCcy === selectedElement['Sold CCY']
    );
};