import React from "react";
import {
    ButtonDropdown,
    DatePicker,
    ColumnLayout,
    Button,
    Container,
    FormField,
    Flashbar,
    Header,
    SpaceBetween,
    Box
} from "@amzn/awsui-components-react";
import FormatData from '../../utils/formatData'
/*
 * UI container that renders the Exception data table and the action items bar.
 */
import DataContentTable from "../../component/dataContentTable";
import moment from "moment";
import {isDateOnOrBeforeCurrentDate, getLatestComment} from "./exceptionUtils";
import CONSTANT from "../../utils/constant";
import {DOWNLOAD_SETTLEMENTS_DROPDOWN_ITEMS, PAGE_SELECTOR_OPTIONS} from "../../config/table";
import DownloadModal from "./modal/downloadModal";
import {hasDocumentAttached} from "../../document/upload/documentUtils";

/**
 * 
 * A search bar component used to enable date range search capabilities within the E&I module.
 * 
 * @param props 
 *      Props passed into the Search bar component.
 * @returns {JSX.Element}
 *      Returns a JSX element that contains a date picker that the users can leverage to search on a date range,
 *      and a search button.
 */
export const SearchBar = (props) => {
    if (!props.userValid) {
        return <></>;
    }
    const searchContent = (
        <React.Fragment>
            <FormField
                description="Specify the range start of the search"
                label="From Date*"
            >
                <DatePicker onChange={event => {
                    props.handleValueChange('fromValue', event.detail.value)
                }}
                            isDateEnabled={date => (date <= moment(props.toValue))}
                            value={props.fromValue}
                            nextMonthAriaLabel="Next month"
                            placeholder="YYYY/MM/DD"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"/>
            </FormField>
            <FormField
                description="Specify the range end of the search"
                label="To Date*"
            >
                <DatePicker onChange={event => {
                    props.handleValueChange('toValue', event.detail.value)
                }}
                            isDateEnabled={date => (date >= moment(props.fromValue) && isDateOnOrBeforeCurrentDate(date))}
                            value={props.toValue}
                            nextMonthAriaLabel="Next month"
                            placeholder="YYYY/MM/DD"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"/>
            </FormField>
        </React.Fragment>
    );
    // Exception Module has 2 search by Trade Date and Inquiry Date.
    // All Trades Module has 1 search by Trade Date.
        return (
            <Container>
                <ColumnLayout columns={3}>
                    {searchContent}
                    <Box float="left">
                        <SpaceBetween direction="vertical" size="xs">
                            <Button variant="primary"
                                    iconName="angle-right"
                                    iconAlign="right"
                                    onClick={() => props.handleQueryClick(CONSTANT.INDEX_STRING_EXCEPTION_BY_TRADE_DATE)}
                            >Search by Trade Date</Button>

                            {props.type === CONSTANT.EXCEPTIONS_MODULE &&
                            <Button variant="primary"
                                    iconName="angle-right"
                                    iconAlign="right"
                                    onClick={() => props.handleQueryClick(CONSTANT.INDEX_STRING_EXCEPTION_BY_INQUIRY_DATE)}
                            >Search by Inquiry Date</Button>}
                        </SpaceBetween>
                    </Box>
                </ColumnLayout>
            </Container>
        );
}

/**
 * Data content block that constructs the modal access and excel download buttons, the modals used within the module, and
 * the Cloudscape table.
 * 
 * @param props 
 *      Props passed into the ExceptionDataContentBlock.
 * @returns {JSX.Element}
 *      Returns a JSX element that contains modal access and excel download buttons, the modals used within the module, and
 *      the Cloudscape table.
 */
export const ExceptionDataContentBlock = (props) => {
    if (!props.userValid) {
        return <></>;
    }
    let viewDocumentDisabled = true;
    let viewDocumentButton = <></>;
    let singleUploadDisabled = true;
    if (props.elementSelected.length === 1) {
        viewDocumentDisabled = false;
        singleUploadDisabled = false;
        const recordId = props.elementSelected[0][CONSTANT.PR_FX_COL_TRADE_ID];
        viewDocumentButton = hasDocumentAttached(recordId, props.recordIdToDocumentIdMapping) ?
            <Button disabled ={viewDocumentDisabled}
                    onClick={() =>props.handleQueryDocument()}>View Documents</Button> : "";
    }

    const exceptionCreationDisabled = props.elementSelected.length !== 1;
    const buttonText = props.type === CONSTANT.ALL_TRADES_MODULE
        ? "Create Exception"
        : `${props.readAndWrite ? "Update/" : ""}View Documentation`;
    const exceptionCreationButton = (
        <Header actions={<SpaceBetween direction="horizontal" size="xs">
            {(props.type === CONSTANT.EXCEPTIONS_MODULE || (props.type === CONSTANT.ALL_TRADES_MODULE && props.readAndWrite)) &&
                <Button
                        name="Documentation"
                        formAction="submit"
                        disabled={exceptionCreationDisabled}
                    // DuplicateExceptionCheck only exists on the All Trades Module with read and write permission.
                        onClick={() => {
                            (props.readAndWrite && props.type === CONSTANT.ALL_TRADES_MODULE)
                                ? props.handleExceptionClick()
                                : props.handleViewModalClick()
                        }}
                >{buttonText}</Button>
            }
            <ButtonDropdown items={DOWNLOAD_SETTLEMENTS_DROPDOWN_ITEMS}
                            onItemClick={(event) => props.handleViewDownloadClick(event.detail.id)}>Download</ButtonDropdown>
            {viewDocumentButton}
            <Button disabled={singleUploadDisabled}
                    onClick={() => props.handleSingleDocumentClick()}>Single Upload Document</Button>
        </SpaceBetween>}/>);

    // Construct the action Item bars for the Exception Table based on group membership
    const tableHeader =
        <SpaceBetween direction="vertical" size="xs">
            {props.tableHeader}
            <Box float="right">
                {exceptionCreationButton}
            </Box>

        </SpaceBetween>;

    const downloadExceptionsModal =
        <DownloadModal downloadWindowVisible={props.downloadWindowVisible}
                        dataType={props.type === CONSTANT.ALL_TRADES_MODULE ? "FX Trade" : "Exception"}
                        handleDismissDownloadClick={props.handleDismissDownloadClick}
                        handleDownloadItemsClick={props.handleDownloadItemsClick}
                        downloadSelection={props.downloadSelection}
        />;
    
    // Defines the default sorting state of the Clouscape table. The table will be sorted
    // by Trade Date in descending order by default.
    const defaultSortingState = {
        defaultState: {
            sortingColumn: {
                sortingField: CONSTANT.EI_COL_TRADE_DATE
            },
            isDescending: true
        }
    }

    return (
            <Container header={<Header>{`Query ${props.type === CONSTANT.ALL_TRADES_MODULE ? 'All Trade' : 'Exception'} Data`}</Header>}>
                    {downloadExceptionsModal}
                    {props.recordIdToDocumentIdMapping &&
                    <DataContentTable distributions={props.recordIdToDocumentIdMapping ? props.distributions : []}
                                        contentSelectorOptions={props.contentSelectorOptions}
                                        filterOptions={props.filterOptions}
                                        columnDefinitions={props.columnDefinitions}
                                        header={tableHeader}
                                        sortableColumns={props.sortableColumns}
                                        defaultSortingState={defaultSortingState}
                                        elementSelected={props.elementSelected}
                                        onSelectionChange={props.onSelectionChange}
                                        onRowClick={props.onRowClick}
                                        sortingColumn={CONSTANT.EI_COL_TRADE_DATE}
                                        sortingDescending={true}
                                        pageSelectorOptions={PAGE_SELECTOR_OPTIONS}/>}
            </Container>
    );
}



/**
 * Renders the Modal message at the top of the UI container for displaying various states of the system.
 * 
 * @param props 
 *      Props passed into the FeedbackMessage component.
 * @returns {JSX.Element}
 *      Returns a JSX element that provides the status of the modal. This can either be a spinner while the
 *      data is loading, a flashbar if the data load fails, or an empty fragment if the data load is successful.
 */
export const FeedbackMessage = (props) => {
    let header, content, type, loading, dismissable;
    switch (props.status) {
        case CONSTANT.MODAL_OTHER_ERROR:
            header="Error";
            content=<div>{props.statusMsg}</div>;
            break;
        case CONSTANT.MODAL_QUERY_ERROR:
            header="Query failed";
            content=<div>Failed to Query the Records! {props.statusMsg}</div>;
            break;
        case CONSTANT.MODAL_REQUEST_ERROR:
            header="Update failed";
            content=<div>Request Failed! {props.statusMsg}</div>;
            break;
        case CONSTANT.MODAL_SYSTEM_ERROR:
            header="System Error";
            content=<div>Request Failed! {props.statusMsg}</div>;
            break;
        case CONSTANT.RESPONSE_INVALID_REQUEST:
            header="Invalid Request";
            content=<div>{props.statusMsg}</div>;
            break;
        case CONSTANT.DATE_RANGE_ERROR:
            header="Invalid Date Range";
            content=<>{props.statusMsg}</>;
            break;
        case CONSTANT.MODAL_QUERYING:
            type="success";
            content=<>Busy fetching data... Please wait.</>;
            loading=true;
            dismissable=false;
            break;
        case CONSTANT.MODAL_SUBMITTING:
            type="success";
            content=<>Busy Submitting data... Please wait.</>;
            loading=true;
            dismissable=false;
            break;
        case CONSTANT.EXCEPTION_UPDATE_SUCCESS:
            type="success";
            content=<>{props.statusMsg}</>;
            loading=false;
            dismissable=true;
            break;
        case CONSTANT.MODAL_UPDATE_SUCCESS:
            return <></>;
        default:
            header = "Unknown Error";
            content=<div>Unknown Error has occurred! {props.statusMsg}</div>;
    }
    return <Flashbar items={[{
        type: type ?? "error",
        loading: loading ?? false,
        content,
        dismissable: dismissable ?? true,
        header,
        onDismiss: () => props.setFeedbackFlashBarItems([])
    }]}/>;
};

/**
 * Return column definitions for Exception Table.
 * Formats columns appropriately based on data displayed.
 * 
 * @param columnsList
 *      The list of columns to display in the Cloudscape table.
 * @param hiddenColumnsList
 *      The list of columns to hide from the Cloudscape table.
 * @param recordIdToDocumentIdMapping
 *      The mapping from recordId to list of attached documents.
 * @returns {Object}
 *      Returns the table configuration properties that will be used to control the Cloudscape display on
 *      the calling methods.
 */
export const getColumnDefinitionsAndTableConfiguration = (columnsList, hiddenColumnsList, recordIdToDocumentIdMapping) => {
    const columnDefinitions = [];
    const sortableColumns = [];
    const visibleColumnOption = [];
    const firstOption = {'label': 'Properties'};
    const filterOptions = [];
    const contentSelectorOptions = [];
    const formatDisplayColumns = [CONSTANT.EI_COL_BOUGHT_AMOUNT, CONSTANT.EI_COL_SOLD_AMOUNT, CONSTANT.EI_COL_SPOT_RATE, CONSTANT.EI_COL_EXCEPTION_COST];
    const formatDisplayUSDColumns = [CONSTANT.EI_COL_USD_EQUIVALENT, CONSTANT.EI_COL_EXCEPTION_COST_USD];

    columnsList.forEach((column) => {
        const columnDefinitionsItem = {};
        const sortableColumnsItem = {};
        const filterColumnOptionItem = {};
        columnDefinitionsItem['id'] = column;
        if (column === CONSTANT.EI_COL_UPDATE_DATE) {
            columnDefinitionsItem['header'] = CONSTANT.EI_COL_UPDATE_DATE_CLEANED;
        } else if (column === CONSTANT.EI_COL_UPDATED_BY) {
            columnDefinitionsItem['header'] = CONSTANT.EI_COL_UPDATED_BY_CLEANED;
        } else if (column === CONSTANT.EI_COL_TROUBLE_TICKET) {
            columnDefinitionsItem['header'] = CONSTANT.EI_COL_RELATED_TTS;
        } else {
            columnDefinitionsItem['header'] = column;
        }
        columnDefinitionsItem['width'] = 180;
        columnDefinitionsItem['cell'] = (item => item[column]);

        // Sortable Columns
        sortableColumnsItem['id'] = column;
        sortableColumnsItem['field'] = column;
        sortableColumns.push(sortableColumnsItem);

        // Filtering Options:
        filterColumnOptionItem['propertyLabel'] = column;
        filterColumnOptionItem['propertyKey'] = column;
        filterColumnOptionItem['groupValuesLabel'] = column + " values";
        filterColumnOptionItem['values'] = [];
        if (column !== 'File Attached') {
            filterOptions.push(filterColumnOptionItem);
        }
        columnDefinitions.push(columnDefinitionsItem);

        // Content Selector Columns
        const contentSelectorItem = {};

        contentSelectorItem['id'] = column;
        contentSelectorItem['label'] = column;
        contentSelectorItem['editable'] = true;
        contentSelectorItem['visible'] = !hiddenColumnsList.includes(column);

        // Format columns appropriately
        contentSelectorOptions.push(contentSelectorItem);
        if (formatDisplayColumns.includes(column)) {
            columnDefinitionsItem['cell'] = (item => <Box textAlign="right">{FormatData.formatDisplayAmount(item[column])}</Box>);
        } else if (formatDisplayUSDColumns.includes(column)) {
            columnDefinitionsItem['cell'] = (item => <Box textAlign="right">{FormatData.formatUSDDisplayAmount(item[column])}</Box>);
        }
        // If the related TT is the 'true' string, blank it out as the display is a side-effect output of Glue.
        else if (column === CONSTANT.EI_COL_TROUBLE_TICKET) {
            columnDefinitionsItem['cell'] = (item => item[column] === 'true' ? '' : item[column]);
        } else if (column === CONSTANT.EI_COL_LATEST_COMMENT) {
            columnDefinitionsItem['cell'] = (item => getLatestComment(item));
        }
        // When displaying related TTs on the Polaris table, iterate through each of the TTs and split on the ';'
        // delimiter. Once delimited, convert each of the TT string variables into a <span> JSX element that will
        // be displayed as a series of <a> elements that will link the user to the TT associated with the
        // identifier. If the string starts with http, do not prepend the TT URL at the beginning.
        else if (column === CONSTANT.EI_COL_RELATED_TTS) {
            columnDefinitionsItem['cell'] = (item => {
                const ttHrefList = [];
                if (item[column] && item[column].trim().length > 0) {
                    const ttArray = item[column].split(CONSTANT.RELATED_TT_DELIMITER);
                    for (let i = 0; i < ttArray.length; i++) {
                        const url = ttArray[i].startsWith(CONSTANT.TT_HTTP_PREFIX) ? "" : CONSTANT.TT_URL + ttArray[i];
                        ttHrefList.push(<span>
                            <a href={url} target="_blank" rel="noreferrer">{ttArray[i]}</a>
                            {i === ttArray.length - 1 ? null : CONSTANT.RELATED_TT_DELIMITER}
                        </span>)
                    }
                }
                return <>{ttHrefList}</>
            })
        } else if (column === 'File Attached'){
            columnDefinitionsItem['cell'] = (item =>
                hasDocumentAttached(item[CONSTANT.EI_COL_TRADE_ID],
                    recordIdToDocumentIdMapping) ? 'Yes' : 'No');
        } else {
            columnDefinitionsItem['cell'] = (item => item[column]);
        }

    });

    firstOption['options'] = contentSelectorOptions;
    visibleColumnOption.push(firstOption);

    return {
        visibleColumnOption,
        sortableColumns,
        columnDefinitions,
        filterOptions
    }
}