import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {ensureAuthenticated} from "../auth/authenticate";
import TRSRestrict from "../TRSRestrict";
import config from '../config/config';
import log from '../utils/logger';
import {getStage} from "./environment";


const APIClient = {
    
    invoke: function (method, path, parameterJSON, dataJSON, callback) {
        ensureAuthenticated().then(auth => {
            let url = config.businessLogicAPI[getStage()] + config['apiStage'] + path
                + "?username=" + localStorage.getItem("userID");
            log.logInfo("dataJSON: " + JSON.stringify(dataJSON) + " parameterJSON: " + JSON.stringify(parameterJSON));
            for (let param in parameterJSON)
                url = url + "&" + param + "=" + parameterJSON[param];

            let opts = {
                method: method,
                headers: {'Authorization': auth.getSignInUserSession().getIdToken().getJwtToken()},
            };
            if (method === "POST" || method === "PUT") {
                if (path === 'tradeapproval' || path === 'attachDocuments'||
                    path === '/file/url' || path === 'attachDocuments/singleDelete') {
                    opts["body"] = JSON.stringify(dataJSON);
                } else {
                    opts["body"] = JSON.stringify({data: dataJSON});
                }
            }

            log.logInfo("url: " + url);
            fetch(url, opts).then(function (response) {
                return response.json();
            }).then(function (data) {
                log.logInfo("data fetched = " + data.toString());
                callback(null, data);
            }).catch((e) => {
                log.logError(e);
                return callback(e);
            })
        }).catch(() => {
            ReactDOM.render(
                <BrowserRouter>
                    <TRSRestrict/>
                </BrowserRouter>, document.getElementById("root"));
        })
    }
};

export default APIClient;