import {ContentLayout, Header} from '@amzn/awsui-components-react';
import React, {FunctionComponent} from 'react';

/**
 * Defines the information to be displayed in the landing page of TRS,
 * with text indicating to the user that they are in the landing page.
 * 
 * @returns {JSX.Element}
 */
const TRSWelcome: FunctionComponent = (): JSX.Element => {
    return <ContentLayout
        header={
            <Header
                variant="h1"
                description="Please use the Menu bar on the left to navigate to the module you want to use.">
                Welcome to TRS!
            </Header>
        }
    />
}

export default TRSWelcome;
