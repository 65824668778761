import React, { Component } from 'react';
import '@amzn/awsui-global-styles/polaris.css';
import {
    ColumnLayout, Button, Icon, Container, Header, Box, SpaceBetween, StatusIndicator
} from '@amzn/awsui-components-react';
import {Spin} from "antd";
import {SingleKeyValuePairVertical} from "../component/dataDisplay";
import Logger from "../utils/logger";
import CONSTANT from '../utils/constant';
import APIClient from '../utils/apiClient';
import config from '../config/config';
import { getStage } from '../utils/environment';
import SingleDocumentModule from "../document/upload/singleDocumentModule";

/**
 * ActionStrip contains button
 */
class ActionStrip extends Component {
    constructor(props) {
        super(props);
    }

    getButtons = () => {
        Logger.logInfo(this.props.uploadStatus);
        if (this.props.uploadStatus === CONSTANT.FEEDBACK_UPLOAD_SUCCESS) {
                return(
                    <Box>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary"
                                    disabled={false}
                                    onClick={() => {this.props.showSingleUploadPage()}}
                            >Attach single document to this trade</Button>
                            <Button variant="primary"
                                    disabled={false}
                                    onClick={() => {this.props.handleResetPage()}}
                            >Submit another trade</Button>
                        </SpaceBetween>
                    </Box>);
            } else {
                return (
                <Box>
                <SpaceBetween direction="horizontal" size="xs">
                    <Button 
                disabled={false}
                onClick={() => { this.props.handlePreviousStep() }}
            >Edit</Button>
            <Button variant="primary"
                disabled={false}
                onClick={() => { this.props.handleUpload() }}
            >Upload</Button></SpaceBetween> </Box>)
            }
    }

    render() {
        return <Box float='right'>{this.getButtons()}</Box>;
    }
}

/**
 * Element to show the file upload status based on the value of this.props.status
 */
class UploadStatus extends Component{
    render() {
        switch (this.props.status) {
            case CONSTANT.FEEDBACK_UI_UPLOAD_REQUIRED_EMPTY:
                return (
                    <div></div>
                );

            case CONSTANT.FEEDBACK_UPLOAD_NOT_TRIGGERED:
                return (
                    <div></div>
                );
            case CONSTANT.FEEDBACK_UPLOADING_START:
                return (
                    <Box textAlign='center' padding='xl'>
                        <SpaceBetween direction='vertical' size='m'>
                            <StatusIndicator type="pending"> Uploading your file</StatusIndicator>
                            <Spin/>
                        </SpaceBetween>
                    </Box>
                );
            case CONSTANT.FEEDBACK_UPLOAD_SUCCESS:
                return (
                    <Box textAlign='center' padding='xl'>
                        <SpaceBetween direction='vertical' size='m'>
                            <StatusIndicator type="success"> Upload succeed</StatusIndicator>
                            <div>Congratulations, your trade has been successfully uploaded, please see below for offline columns generated by TRS</div>
                            <ColumnLayout columns={3} variant="text-grid">
                                <SingleKeyValuePairVertical title="Trade ID" value={this.props.tradeID}/>
                            </ColumnLayout>
                        </SpaceBetween>
                    </Box>
                );
            case CONSTANT.FEEDBACK_UPLOAD_ERROR:
                return (
                    <Box textAlign='center' padding='xl'>
                        <SpaceBetween direction='vertical' size='m'>
                            <StatusIndicator type="warning"> Upload failed</StatusIndicator>
                            <div>Whoops, it seems something has gone wrong. Please retry or contact us.</div>
                            <Button onClick={this.props.handleUploadRetry} /> Retry <Button/>
                        </SpaceBetween>
                    </Box>
                );
            default: return <Box textAlign='center'>Wrong status</Box>;
        }
    }
}

class WorkflowTrackingStatus extends Component {
    render() {
        switch (this.props.status) {
            case CONSTANT.FEEDBACK_UPLOAD_TRACKING_NOT_TRIGGERED:
                return <></>;
            case CONSTANT.FEEDBACK_UPLOAD_TRACKING_IN_PROGRESS:
                return (
                    <Box textAlign='center' padding='xl'>
                        <SpaceBetween direction='vertical' size='m'>
                            <StatusIndicator type="pending"> Fetching Tracking URL</StatusIndicator>
                            <Spin />
                        </SpaceBetween>
                    </Box>
                );
            case CONSTANT.FEEDBACK_UPLOAD_TRACKING_SUCCESS:
                return (
                    <Box textAlign='center' padding='xl'>
                        <SpaceBetween direction='vertical' size='m'>
                            <StatusIndicator type="success"> Fetching Tracking Succeed</StatusIndicator>
                            <div>Please use this link to track the status of the uploaded trade {<a href={config.authConfig[getStage()].FocusUrl + "/track/" + this.props.workflowUUID}
                                    target="_blank"
                                    rel="noopener noreferrer">Tracking Link<Icon name="external" /></a>}</div>
                        </SpaceBetween>
                    </Box>
                );
            case CONSTANT.FEEDBACK_UPLOAD_TRACKING_ERROR:
                return (
                    <Box textAlign='center' padding='xl'>
                        <SpaceBetween direction='vertical' size='m'>
                            <StatusIndicator type="warning"> Fetching Tracking URL Failed</StatusIndicator>
                            <div>Whoops, it seems something has gone wrong. Please retry or contact us.</div>
                        </SpaceBetween>
                    </Box>
                );
            default: return <Box textAlign='center' padding='xl'> Wrong status</Box>;
        }
    }
}

/*
    Page 3: Review and Upload validated STR Trade. Sent for approval step included
    Input field will be presented in key-value pair division. Users are able to review the data after validation and
    upload. This page will wrap all the necessary fields and send them to the backend upload handler,
    and return "Trade ID" and "Workflow ID" generated from backend.

    [TO-DO]: The upload page should invoke backend to query the worflow tracking link
*/
class UploadTradeDataPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorText: {},
            uploadStatus: CONSTANT.FEEDBACK_UPLOAD_NOT_TRIGGERED,
            trackingStatus: CONSTANT.FEEDBACK_UPLOAD_TRACKING_NOT_TRIGGERED,
            workflowID: '',
            s3ObjectKey: '',
            versionID: '',
            uploadFile: '',
            fileData: [],
            tradeID: '',
            requesterGroup: this.props.requesterGroup,
            singleUploadVisible: false,
        }
    }

    handleEdit = () => {
        this.props.handleEdit(this.props.tradeData);
    }

    handleShowSingleUploadPage = () => {
        this.setState({
            singleUploadVisible: true,
        })
    }
    handleDismissSingleUpload = () => {
        this.setState({
            singleUploadVisible: false,
        })
    }

    // Handle STR trade upload to backend after enriching approver
    handleUpload = () => {
        this.setState({
            uploadStatus: CONSTANT.FEEDBACK_UPLOADING_START
        })

        let parameter = {
            requestType: "upload",
            requesterGroup: this.props.requesterGroup
        };


        APIClient.invoke('POST', 'singleTrade', parameter, this.props.tradeData, (err, requestData) => {
            Logger.logInfo("Response data: " + JSON.stringify(requestData));
            if (!err) {
                switch (requestData.status) {
                    case CONSTANT.RESPONSE_SUCCESS:
                        //delete the corresponding record from UI table
                        //Trade ID which generated from backend will be grabbed and show on the page
                        this.setState({
                            uploadStatus: CONSTANT.FEEDBACK_UPLOAD_SUCCESS,
                            s3ObjectKey: requestData.data.s3ObjectKey,
                            versionID: requestData.data.versionID,
                            uploadFile: requestData.data.uploadFile,
                            fileData: requestData.data.fileData,
                            tradeID: requestData.data["Trade ID"],
                            trackingStatus: CONSTANT.FEEDBACK_UPLOAD_TRACKING_IN_PROGRESS,
                        })
                        this.fetchTrackingUrl(requestData.data.s3ObjectKey, requestData.data.versionID);
                        Logger.logInfo("Upload result: " + JSON.stringify(requestData.data));
                        break;
                    case CONSTANT.RESPONSE_ERROR:
                        this.setState({
                            uploadStatus: CONSTANT.FEEDBACK_UPLOAD_ERROR
                        })
                        Logger.logError("Error uploading data records" );
                        break;
                    case CONSTANT.RESPONSE_SYSTEM_ERROR:
                        this.setState({
                            uploadStatus: CONSTANT.FEEDBACK_UPLOAD_ERROR
                        })
                        Logger.logError("System Error when uploading data" );
                        break;
                    default:
                }
            } else {
                this.setState({
                    uploadStatus: CONSTANT.FEEDBACK_UPLOAD_ERROR
                })
                Logger.logError("Other System Error when uploading trade data");
            }
        })
        
    }

    /*
        Fetch Workflow ID for tracking, the method sets interval for calling api every 5 seconds until the successful response or
        system error response is received.
    */
    fetchTrackingUrl = (s3ObjectKey, versionId) => {
        this.setState({
            trackingStatus: CONSTANT.FEEDBACK_UPLOAD_TRACKING_IN_PROGRESS
        });
        const parameter = {
            requestType: "tracking",
            s3ObjectKey: s3ObjectKey,
            versionId: versionId
        }

        let intervalCount = 0;
        let apiCall = setInterval(() => {
            APIClient.invoke('GET', 'singleTrade', parameter, undefined, (err, requestData) => {
                intervalCount += 1;
                Logger.logInfo("Response data: " + JSON.stringify(requestData));
                if (!err) {
                    switch (requestData.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            //When successfully get workflowUUID, exit the interval
                            this.setState({          
                                workflowUUID: requestData.data.workflowId,
                                trackingStatus: CONSTANT.FEEDBACK_UPLOAD_TRACKING_SUCCESS
                            });
                            clearInterval(apiCall);
                            Logger.logInfo("Upload result: " + JSON.stringify(requestData.data));
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            //If only response error, meaning that workflow id isn't generated yet, stay in interval and 
                            //proceed to next call.
                            //System time out after 48 * 5 = 240s (4 min)
                            if (intervalCount == 48) {
                                Logger.logError("System timed out when waiting for Workflow ID to be generated");
                                this.setState({
                                    trackingStatus: CONSTANT.FEEDBACK_UPLOAD_TRACKING_ERROR
                                })
                                clearInterval(apiCall);
                            }
                            Logger.logError("WorkflowId generation is still on going. Please wait");
                            break;
                        case CONSTANT.RESPONSE_SYSTEM_ERROR:
                            this.setState({
                                trackingStatus: CONSTANT.FEEDBACK_UPLOAD_TRACKING_ERROR
                            })
                            clearInterval(apiCall);
                            Logger.logError("System Error when fetching tracking workflow id");
                            break;
                        default:
                    }
                } else {
                    this.setState({
                        trackingStatus: CONSTANT.FEEDBACK_UPLOAD_TRACKING_ERROR
                    })
                    clearInterval(apiCall);
                    Logger.logError("Other System Error when fetching tracking workflow id");
                }  
            });
        }, 5000);
    }

    setErrorText = (name, errorText) => {
        let newErrorText = Object.assign({}, this.state.errorText, {[name]: errorText});
        if(errorText === "")
            delete newErrorText[name];
        this.setState({errorText: newErrorText});
    };

    render() {
        let singleUploadModule = this.state.singleUploadVisible === true && this.state.tradeID !== '' ?
            <SingleDocumentModule id={this.state.tradeID}
                                         handleDismissSingleUpload ={this.handleDismissSingleUpload}/> : '';


        return (
            <SpaceBetween direction="vertical" size="s">
                <Container
                    header={
                        <Header
                            variant="h2"
                        >
                            Upload Single Trade Request
                        </Header>
                    }
                >
                    <ColumnLayout columns={3} variant="text-grid">
                            <SingleKeyValuePairVertical title="Type of Trade" value={this.props.tradeData.tradeType}/>
                            <SingleKeyValuePairVertical title="SourceAccount" value={this.props.tradeData.sourceAccount}/>
                            <SingleKeyValuePairVertical title="Beneficiary" value={this.props.tradeData.beneficiary}/>
                            <SingleKeyValuePairVertical title="Buy or Sell" value={this.props.tradeData.buyOrSell}/>
                            <SingleKeyValuePairVertical title="Currency" value={this.props.tradeData.ccy}/>
                            <SingleKeyValuePairVertical title="Amount" value={this.props.tradeData.amount}/>
                            <SingleKeyValuePairVertical title="Value Date" value={this.props.tradeData.valueDate}/>
                            <SingleKeyValuePairVertical title="Additional Trade Details (i.e. OCM Tag)" 
                                                        value={this.props.tradeData.tradeCode}/>
                            <SingleKeyValuePairVertical title="Ticket ID" value={this.props.tradeData.ttID}/>
                    </ColumnLayout>
                    <UploadStatus status={this.state.uploadStatus}
                                  handleUploadRetry={this.handleUpload}
                                  tradeID={this.state.tradeID}
                    />
                    <WorkflowTrackingStatus status={this.state.trackingStatus}
                                            workflowUUID={this.state.workflowUUID}
                                            />
                </Container>
                <ActionStrip handlePreviousStep={this.props.handlePreviousStep}
                    handleResetPage={this.props.handleResetPage}
                    handleEdit={this.handleEdit}
                    handleUpload={this.handleUpload}
                    showSingleUploadPage ={this.handleShowSingleUploadPage}
                    uploadStatus={this.state.uploadStatus}
                />
                {singleUploadModule}
            </SpaceBetween>
        );
    }
}

export default UploadTradeDataPage;