import React, {useState} from "react";
import CONSTANT from "../utils/constant";
import Logger from "../utils/logger";
import APIClient from "../utils/apiClient";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import {Button} from "@amzn/awsui-components-react";
import {FreeTextElement} from "../component/dataEntry";
import {DocumentFeedbackMessage} from "./upload/documentUtils";

export const DeleteSingleDocumentModal = (props) => {
    const [comment, setComment] = useState("");
    const [feedbackStatus, setFeedbackStatus] = useState("");
    const [statusMsg, setStatusMsg] = useState("");
    const [disable, setDisable] = useState(true);
    const [deleted, setDeleted] = useState(false);

    const handleDeleteSubmit = () => {
        setFeedbackStatus(CONSTANT.MODAL_SUBMITTING);

        if (props.selectedItem.length !== 1) {
            return;
        }

        const id = props.selectedItem[0].id;
        const documentId = props.selectedItem[0].documentId;
        const deleteRequester = props.user;

        const parameter = {
            id: id,
            documentId: documentId,
            deleteRequester: deleteRequester,
            deleteComments: comment,
        };
        Logger.logInfo("handleDeleteSubmit: " + JSON.stringify(parameter));
        APIClient.invoke("DELETE", 'attachDocuments/singleDelete', parameter, props.selectedItem, (err, requestData) => {
            Logger.logInfo("Response data: " + JSON.stringify(requestData));
            if (!err) {
                switch (requestData.status) {
                    case CONSTANT.RESPONSE_SUCCESS:
                        Logger.logInfo("Delete Document succeed.");
                        setFeedbackStatus(CONSTANT.MODAL_DELETE_SUCCESS);
                        setDeleted(true)
                        break;
                    case CONSTANT.RESPONSE_ERROR:
                        Logger.logError("Error Delete Document Data = " + props.selectedItem.toString());
                        setFeedbackStatus(CONSTANT.MODAL_REQUEST_ERROR);
                        setStatusMsg(JSON.stringify(requestData['errorData']));
                        setDeleted(true)
                        break;
                    case CONSTANT.RESPONSE_SYSTEM_ERROR:
                        Logger.logError("System Error when Delete Document Data = " + props.selectedItem.toString());
                        setFeedbackStatus(CONSTANT.MODAL_SYSTEM_ERROR);
                        setStatusMsg(JSON.stringify(requestData['errorData']));
                        setDeleted(true)
                        break;
                    default:
                }
            } else {
                Logger.logError("Other System Error when Delete Document Data = " + JSON.stringify(props.selectedItem.toString()));
                setFeedbackStatus(CONSTANT.MODAL_OTHER_ERROR);
                setStatusMsg("Other Error when deleting Document.")
                setDeleted(true)
            }
        })
    }

    const handleValueChange = (name, value) => {
        Logger.logInfo("Delete Modal handleValueChange: " + name + " " + value);
        setComment(value);
        if (value !== '') {
            setDisable(false);
        }
    }

    return <>
        <Modal visible={props.deleteWindowVisible}
               header="Delete Single Document"
               onDismiss={props.handleDismissDocumentModalClick}
               expandToFit={true}
               size="max"
               footer={
                   <Box float="right">
                       <SpaceBetween direction="horizontal" size="xs" >
                           <Button variant={"link"}
                                   disabled={disable}
                                   onClick={props.cancelDeleteClick}
                           >Cancel</Button>
                           <Button variant={"primary"}
                                   disabled={disable || deleted}
                                   onClick={handleDeleteSubmit}
                           >Delete</Button>
                       </SpaceBetween>
                   </Box>}

        > Are you sure you want to delete the document? If so, please add reasons for your deletion.
            <div style={{display: "flex", justifyContent: "left"}}>
                <FreeTextElement title={"Delete Comments"}
                                 name={"comment"}
                                 value={comment}
                                 errorText={"ErrorText"}
                                 disabled={false}
                                 required={true}
                                 handleChange={handleValueChange}
                                 setErrorFlag={null}/>
            </div>
            <br/>
            <br/>
            <DocumentFeedbackMessage status={feedbackStatus}
                                     statusMsg={statusMsg}/>
        </Modal>;
    </>
}
