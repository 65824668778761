import React, {Component} from 'react';
import '@amzn/awsui-global-styles/polaris.css';
import ErrorBoundary from "../err/errorBoundary";
import {
    Flashbar,
    SpaceBetween,
    Box,
    StatusIndicator
} from '@amzn/awsui-components-react';
import FormatData from "../utils/formatData";

class SingleKeyValuePairVertical extends Component {

    render(){
        return(
            <ErrorBoundary content={this.props.title}>
                <SpaceBetween direction="vertical" size="n">
                    <Box variant="awsui-key-label" >{this.props.title}</Box>
                    {this.props.value}
                </SpaceBetween>
            </ErrorBoundary>
        );
    }

}

const StatusComponent = ({valueDate, exportDate, isItemDisabled}) => {
    if ((new Date(valueDate)) < FormatData.formatNewCurrDate()) {
        return <StatusIndicator type="error" colorOverride={isItemDisabled ? 'grey' : ''}>Value Date Error</StatusIndicator>
    } else {
        var one_day = 1000 * 60 * 60 * 24;
        var duration = (new Date(FormatData.formatNewDate()) - new Date(exportDate)) / one_day;
        if (duration >= 1) {
            return <StatusIndicator type="warning" colorOverride={isItemDisabled ? 'grey' : ''}>Exported {Math.floor(duration)} days ago</StatusIndicator>;
        } else {
            return <StatusIndicator type="success" colorOverride={isItemDisabled ? 'grey' : ''}>Ok</StatusIndicator>;
        }
    }
};

function PolarisV3FlashBar(props) {
    const [items, setItems] = React.useState([
        {
            dismissible: props.dismissible,
            type: props.type,
            content: props.content,
            header: props.header,
            loading: props.loading ? props.loading : false,
            onDismiss: () => setItems([])
        }
    ]);
    return <Flashbar items={items}/>;
}

export {
    SingleKeyValuePairVertical,
    StatusComponent,
    PolarisV3FlashBar
}
