import React, { Component } from 'react';
import '@amzn/awsui-global-styles/polaris.css';
import { ColumnLayout, Button, Container, Header, Box } from '@amzn/awsui-components-react';
import { DropDownElement } from "../component/dataEntry";
import Logger from "../utils/logger";
import CONSTANT from '../utils/constant';
import { arrayIntersection } from '../utils/arrayIntersection';

class ActionStrip extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Box float='right'>
                    <Button
                        onClick={() => { this.props.handleNextStep() }}
                        disabled={this.props.nextStepDisable}
                    >Next Step: Fill out form</Button>
            </Box>
        );
    }
}

class TradeTypeSelection extends Component {
    render() {
        return (
            <ColumnLayout>
                <DropDownElement title={'Type of Trade'}
                    name={'inputTypeOfTrade'}
                    value={this.props.typeOfTrade}
                    options={this.props.getSelectionList(CONSTANT.STR_TRADE_TYPE_LIST)}
                    disabled={false}
                    required={true}
                    handleChange={this.props.handleValueChange}
                    setErrorFlag={this.props.setErrorText} />
                <DropDownElement title={'Requester LDAP Group'}
                    name={'inputRequesterGroup'}
                    value={this.props.requesterGroup}
                    options={this.props.getSelectionList(this.props.requesterGroupList)}
                    disabled={false}
                    required={true}
                    handleChange={this.props.handleValueChange}
                    setErrorFlag={this.props.setErrorText} />
            </ColumnLayout>
        );
    }
}

/*
    First page: Specify Template for the type of STR trade, based on the type of trade, beneficiary/source account list may be different
*/
class SpecifyTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typeOfTrade: "",
            errorText: {},
            userGroup: localStorage.getItem(CONSTANT.USER_GROUP_NAME),
            user: localStorage.getItem(CONSTANT.LOCAL_STORAGE_USERID),
            requesterGroupList: [],
            requesterGroup: ""
        }
    }

    setErrorText = (name, errorText) => {
        let newErrorText = Object.assign({}, this.state.errorText, { [name]: errorText });
        if (errorText === "")
            delete newErrorText[name];
        this.setState({ errorText: newErrorText });
    };

    handleValueChange = (name, value) => {
        if (value === '') {
            Logger.logInfo("Empty type");
            return;
        }

        if (name === "inputTypeOfTrade") {
            let requesterGroupList = this.findRequesterUserGroupFromTemplate(value);
            this.setState({
                typeOfTrade: value,
                requesterGroupList: requesterGroupList,
                requesterGroup: ""
            });
            this.props.handleTradeTypeChange(value);
        } else if (name === "inputRequesterGroup") {
            this.setState({
                requesterGroup: value
            });
            this.props.handleRequesterGroupChange(value);
        }
    }

    getSelectionList = (options) => {
        return options.map(item => (({
            label: item,
            id: item,
            value: item
        })));
    }

    findRequesterUserGroupFromTemplate = (value) => {
        let validRequesterGroup = CONSTANT.TEMPLATE_REQUESTER_GROUP_ACCESS_MAPPING[value];
        let requesterGroup = arrayIntersection(validRequesterGroup, localStorage.getItem(CONSTANT.USER_GROUP_NAME));
        return requesterGroup;
    }

    render() {
        return (
            <div>
                <Container
                    header={
                        <Header
                            variant="h2"
                        >
                            Step 1 - Choose your data template
                        </Header>
                    }
                >
                    <TradeTypeSelection
                        handleValueChange={this.handleValueChange}
                        getSelectionList={this.getSelectionList}
                        setErrorText={this.setErrorText}
                        typeOfTrade={this.state.typeOfTrade}
                        requesterGroupList={this.state.requesterGroupList}
                        requesterGroup={this.state.requesterGroup}
                    />
                    <br />
                </Container>
                <br/>
                <ActionStrip
                    handleNextStep={this.props.handleNextStep}
                    nextStepDisable={this.props.firstPageNextDisable}
                />
            </div>
        );
    }
}

export default SpecifyTemplate;