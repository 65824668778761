import React, { useEffect } from 'react';
import { ExternalUrl, AuthConfigByStage } from "./model/Model";
import { getAuthConfig } from "./auth/authenticate";
import { useNavigate } from 'react-router';

interface ExternalUrlProps {
    externalUrlKey: ExternalUrl;
}

/**
 * Depending on the externalUrlKey passed in, open either the Focus URL corresponding to the
 * current stage or Amazon Approval.
 * 
 * @param {ExternalUrlProps}
 */
export const InvokeExternalUrl = ({externalUrlKey}: ExternalUrlProps): JSX.Element => {
    const navigate = useNavigate();
    const authConfig: AuthConfigByStage = getAuthConfig();

    useEffect(() => navigate(-1));
    switch(externalUrlKey) {
        case "FocusUrl":
            window.open(authConfig.FocusUrl);
            break;
        case "ApprovalUrl":
            window.open(authConfig.ApprovalUrl);
            break;
        default:
    }
    return <></>;
}
