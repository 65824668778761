import React, { Component } from 'react';
import Logger from '../utils/logger';

class ErrorBoundary extends Component{
    constructor(props){
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error, errorInfo) {
        this.setState({hasError:true});
        Logger.logError(error, errorInfo);
    }

    render(){
        if(this.state.hasError){
            return <div>Something went wrong. {this.props.content} related content should have been displayed here. </div>
        }else{
            return this.props.children;
        }
    }
}

export default ErrorBoundary;