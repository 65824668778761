import React, {Component} from 'react';
import '@amzn/awsui-global-styles/polaris.css';
import {
    Button,
    Form,
    Header,
    Container,
    ButtonDropdown,
    SpaceBetween,
    Box,
    TextContent
} from "@amzn/awsui-components-react";
import CONSTANT, { IC_SETTLEMENTS_LDAP } from "../utils/constant";
import APIClient from "../utils/apiClient";
import Logger from "../utils/logger";
import {
    VISIBLE_COLUMN_OPTIONS_OPEN_PAYMENT,
    HIDDEN_OPTIONS_PENDING_LIQUIDITY,
    DOWNLOAD_SETTLEMENTS_DROPDOWN_ITEMS
} from "../config/table";
import FormatData from "../utils/formatData";
import DataContentTable from "../component/dataContentTable";
import xlsxParser from '../utils/xlsxParser';
import {
    DOWNLOAD_FILE_PAYMENT_DELIMITER
} from "../config/file";
import {getStage} from "../utils/environment";
import BatchUpdatePaymentModal from "./common/modal/batchUpdatePaymentModal";
import UpdatePaymentModal from "./common/modal/updatePaymentModal";
import {
    calculateLiquidity,
    ifSendingBalanceIsOverHalfOfSendingAccountBalance,
    lookUpAccountBalance,
    getFeedbackMessage,
    getLatestComment,
    lookupAccountCategory4,
    populateRegion,
    lookupBranch
} from "./common/paymentRecordTableCommon";
import CommentModal from "./common/modal/commentModal";
import DownloadPaymentModal from "./common/modal/downloadPaymentModal";
import EmailNotificationModal from "./common/modal/emailNotificationModal";
import ValidateLiquidityWarningModal from "./common/modal/validateLiquidityWarningModal";
import { ldapMembershipAlert } from "../utils/polarisAlerts";
import { arrayIntersection } from '../utils/arrayIntersection';
import {NoDocumentModal} from "../document/noDocumentModal";
import {QueryDocumentData} from "../document/queryDocumentData";
import {hasDocumentAttached} from "../document/upload/documentUtils";
import SingleDocumentModule from "../document/upload/singleDocumentModule";
import {queryDocuments} from "./common/modal/documentQueryHelper";

/*
 * UI container that renders the pending payments data table and the action items bar.
 */
class PendingPaymentDataContentBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        Logger.logInfo("Intialize PendingPaymentDataContentBlock");
    }

    render() {
        let updateDisabled = true;
        let batchUpdateDisabled = true;
        let commentsDisabled = true;
        let approveDisabled = true;
        let downloadDisabled = false;
        let selectedElement = {};
        let updateWindow = "";
        let batchUpdateWindow = "";
        let viewDocumentButton = <></>;
        let singleUploadDisabled = true;

        // Determine whether user actions are allowed based on selected items.
        if (this.props.elementSelected.length > 0) {
            approveDisabled = false;
            if (this.props.elementSelected.length === 1) {
                updateDisabled = false;
                commentsDisabled = false;
                selectedElement = this.props.elementSelected[0];
                singleUploadDisabled = false;
                updateWindow = <UpdatePaymentModal handleConfirmUpdateClick={this.props.handleConfirmUpdateClick}
                                                   updateWindowVisible={this.props.updateWindowVisible}
                                                   selectedPayment={selectedElement}
                                                   elementSelected={this.props.elementSelected}
                                                   handleDismissClick={this.props.handleDismissClick}
                                                   accountCategoryToRegionMap={this.props.accountCategoryToRegionMap}
                                                   companyCodeToKyribaBankAccountsMap={this.props.companyCodeToKyribaBankAccountsMap}
                                                   fxRequiringCurrencyMapping={this.props.fxRequiringCurrencyMapping}
                                                   weekendCalendarMapping={this.props.weekendCalendarMapping}
                                                   holidayCalendarMapping={this.props.holidayCalendarMapping}
                                                   currencyToDecimalDigitMapping={this.props.currencyToDecimalDigitMapping}
                                                   disableOverrideButton={this.props.disableOverrideButton}
                                                   user={this.props.user}/>;
            } else {
                batchUpdateDisabled = false;
                batchUpdateWindow = <BatchUpdatePaymentModal handleConfirmBatchUpdateClick={this.props.handleConfirmBatchUpdateClick}
                                                   batchUpdateWindowVisible={this.props.batchUpdateWindowVisible}
                                                   elementSelected={this.props.elementSelected}
                                                   handleDismissClick={this.props.handleDismissClick}
                                                   user={this.props.user}
                                                             rejectionDisabled={false}/>;
            }
            // view document button should display if any of the selectedIds has a document attached
            const selectedIds = this.props.elementSelected.map(item => item[CONSTANT.PR_COL_SETTLEMENT_ID]);
            let shouldDisplayViewDocumentButton = false;
            selectedIds.forEach(recordId => {
                if (hasDocumentAttached(recordId, this.props.recordIdToDocumentIdMapping)) {
                    shouldDisplayViewDocumentButton = true;
                }
            });
            viewDocumentButton = shouldDisplayViewDocumentButton ?
                <Button onClick={() =>this.props.handleQueryDocument()}>View Documents</Button> : "";
        }

        //Comment window
        let commentModal = <CommentModal commentVisible={this.props.commentVisible}
                                         handleDismissClick={this.props.handleDismissClick}
                                         handleAddCommentClick={this.props.handleAddCommentClick}
                                         elementSelected={this.props.elementSelected}
                                         newComment={this.props.newComment}
                                         handleCommentUpdate={this.props.handleCommentUpdate}
                                         userComments={this.props.userComments}/>

        //Download Payment window
        let paymentDownloadModal =
            <DownloadPaymentModal downloadWindowVisible = {this.props.downloadWindowVisible}
                                  handleDismissClick={this.props.handleDismissClick}
                                  handleConfirmDownloadClick={this.props.handleConfirmDownloadClick}
                                  elementSelected={this.props.elementSelected}
                                  ifDownloadAll={this.props.ifDownloadAll}/>

        let emailNotificationModal =
            <EmailNotificationModal emailNotificationWindowVisible = {this.props.emailNotificationWindowVisible}
                                    handleDismissClick={this.props.handleDismissClick}
                                    handleSendEmailNotification={this.props.handleSendEmailNotification} />

        //Validate Liquity Check Warning Modal
        let validateLiquidityWarningModal = 
            <ValidateLiquidityWarningModal elementSelected={this.props.elementSelected}
                                           validateWarningModalVisble={this.props.validateWarningModalVisble}
                                           handleDismissClick={this.props.handleDismissClick}
                                           handleConfirmValidateClick={this.props.handleConfirmValidateClick}
                                           paymentValidationResult={this.props.paymentValidationResult}
                                           paymentValidationStatus={this.props.paymentValidationStatus}/>

        //Construct the action Item bars for the dataContentTable
        let tableHeader = <></>;
        if (this.props.userGroup.includes(CONSTANT.USER_GROUP_PROD_ADMIN) ||
            (this.props.userGroup.includes(CONSTANT.USER_GROUP_DEVELOPER) &&
                getStage() !== 'prod')) {
            // Developers get to access all buttons in the action bar.
            tableHeader = <SpaceBetween direction="vertical" size="m">
                <>{this.props.feedbackMessage}</>
                <TextContent><h2>Pending Liquidity Checks</h2></TextContent>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => this.props.handleViewNotificationWindowClick()}>Send Reminder</Button>
                            <Button disabled={singleUploadDisabled}
                                    onClick={() => this.props.handleSingleDocumentClick()}>Single Upload Document</Button>
                            <Button disabled={approveDisabled}
                                    onClick={() => this.props.handleValidateLiquidityClick(this.props.elementSelected)}>Validate Liquidity</Button>
                            <Button disabled={updateDisabled}
                                    onClick={() => this.props.handleViewUpdateWindowClick()}>Update/Reject</Button>
                            <Button disabled={batchUpdateDisabled}
                                    onClick={() => this.props.handleViewBatchUpdateWindowClick()}>Batch Update/Reject</Button>
                            <Button name="Comments"
                                    disabled={commentsDisabled}
                                    onClick={() => this.props.handleViewCommentClick()}>Add/View Comments</Button>
                            <ButtonDropdown
                                items={DOWNLOAD_SETTLEMENTS_DROPDOWN_ITEMS}
                                onItemClick={(event) => this.props.handleViewDownloadWindowClick(event.detail.id)}
                                disabled={downloadDisabled}>
                                Download
                            </ButtonDropdown>
                            {viewDocumentButton}
                        </SpaceBetween>
            </SpaceBetween>;
        } else if (this.props.userGroup.includes(CONSTANT.USER_GROUP_CASH_POSITIONER)) {
            tableHeader = <SpaceBetween direction="vertical" size="m">
                <>{this.props.feedbackMessage}</>
                <TextContent><h2>Pending Liquidity Checks</h2></TextContent>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button disabled={approveDisabled}
                                    onClick={() => this.props.handleValidateLiquidityClick(this.props.elementSelected)}>Validate Liquidity</Button>
                            <Button disabled={singleUploadDisabled}
                                    onClick={() => this.props.handleSingleDocumentClick()}>Single Upload Document</Button>
                            <Button disabled={updateDisabled}
                                    onClick={() => this.props.handleViewUpdateWindowClick()}>Update/Reject</Button>
                            <Button disabled={batchUpdateDisabled}
                                    onClick={() => this.props.handleViewBatchUpdateWindowClick()}>Batch Update/Reject</Button>
                            <Button name="Comments"
                                    disabled={commentsDisabled}
                                    onClick={() => this.props.handleViewCommentClick()}>Add/View Comments</Button>
                            <ButtonDropdown
                                items={DOWNLOAD_SETTLEMENTS_DROPDOWN_ITEMS}
                                onItemClick={(event) => this.props.handleViewDownloadWindowClick(event.detail.id)}
                                disabled={downloadDisabled}>
                                Download
                            </ButtonDropdown>
                            {viewDocumentButton}
                        </SpaceBetween>
            </SpaceBetween>;
        } else if (this.props.userGroup.includes(CONSTANT.USER_GROUP_INTERCOMPANY)) {
            tableHeader = <SpaceBetween direction="vertical" size="m">
                <>{this.props.feedbackMessage}</>
                <TextContent><h2>Pending Liquidity Checks</h2></TextContent>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => this.props.handleViewNotificationWindowClick()}>Send Reminder</Button>
                            <Button disabled={singleUploadDisabled}
                                    onClick={() => this.props.handleSingleDocumentClick()}>Single Upload Document</Button>
                            <Button disabled={approveDisabled}
                                    onClick={() => this.props.handleValidateLiquidityClick(this.props.elementSelected)}>Validate Liquidity</Button>
                            <Button disabled={updateDisabled}
                                    onClick={() => this.props.handleViewUpdateWindowClick()}>Update/Reject</Button>
                            <Button disabled={batchUpdateDisabled}
                                    onClick={() => this.props.handleViewBatchUpdateWindowClick()}>Batch Update/Reject</Button>
                            <Button name="Comments"
                                    disabled={commentsDisabled}
                                    onClick={() => this.props.handleViewCommentClick()}>Add/View Comments</Button>
                            <Button name="Download"
                                    disabled={downloadDisabled}
                                    onClick={() => this.props.handleViewDownloadWindowClick()}>Download</Button>
                            {viewDocumentButton}
                        </SpaceBetween>
            </SpaceBetween>;
        } else if (this.props.userGroup.includes(CONSTANT.USER_GROUP_SETTLEMENT_HISTORICAL)) {
            tableHeader = <SpaceBetween direction="vertical" size="m">
                <>{this.props.feedbackMessage}</>
                <TextContent><h2>Pending Liquidity Checks</h2></TextContent>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button disabled={singleUploadDisabled}
                                    onClick={() => this.props.handleSingleDocumentClick()}>Single Upload Document</Button>
                            <Button name="Comments"
                                    disabled={commentsDisabled}
                                    onClick={() => this.props.handleViewCommentClick()}>Add/View Comments</Button>
                            <Button name="Download"
                                    disabled={downloadDisabled}
                                    onClick={() => this.props.handleViewDownloadWindowClick()}>Download</Button>
                            {viewDocumentButton}
                        </SpaceBetween>
            </SpaceBetween>;
        } else {
            tableHeader =
                <Header>Pending Liquidity Checks</Header>;
        }

        return (
            <Form>
                <Container>
                    <div>
                        {commentModal}
                    </div>
                    <div>
                        {updateWindow}
                    </div>
                    <div>
                        {batchUpdateWindow}
                    </div>
                    <div>
                        {paymentDownloadModal}
                    </div>
                    <div>
                        {emailNotificationModal}
                    </div>
                    <div>
                        {validateLiquidityWarningModal}
                    </div>
                    <div>{
                        <DataContentTable distributions={
                                                this.props.companyCodeToKyribaBankAccountsMap &&
                                                this.props.accountCategoryToRegionMap &&
                                                this.props.fxRequiringCurrencyMapping &&
                                                this.props.weekendCalendarMapping &&
                                                this.props.holidayCalendarMapping &&
                                                this.props.recordIdToDocumentIdMapping ? this.props.distributions : []
                                          }
                                          onRowClick={this.props.onRowClick}
                                          contentSelectorOptions={this.props.contentSelectorOptions}
                                          filterOptions={this.props.filterOptions}
                                          columnDefinitions={this.props.columnDefinitions}
                                          header={tableHeader}
                                          sortableColumns={this.props.sortableColumns}
                                          elementSelected={this.props.elementSelected}
                                          onSelectionChange={this.props.onSelectionChange}/>
                    }</div>
                </Container>
            </Form>);
    }
}

/*
 *  QueryPendingPaymentData Parent class that wraps the above UI container and routes the business logic handlers.
 */
class QueryPendingPaymentData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: CONSTANT.MODAL_EMPTY,
            statusMsg: "",
            loading: false,
            distributions: [],
            companyCodeToKyribaBankAccountsMap: [],
            kyribaBankAccountToBalanceMap: [],
            accountCategoryToRegionMap: [],
            fxRequiringCurrencyMapping: [],
            weekendCalendarMapping: [],
            holidayCalendarMapping: [],
            currencyToDecimalDigitMapping: [],
            recordIdToDocumentIdMapping: {},
            recordIdToDocumentIdMappingLoaded: false,
            elementSelected: [],
            userGroup: localStorage.getItem(CONSTANT.USER_GROUP_NAME),
            user: localStorage.getItem(CONSTANT.LOCAL_STORAGE_USERID),
            newComment: '',
            commentsList: 0,
            userComments: {'userComment': [], 'commentId': ''},
            commentVisible: false,
            updateWindowVisible: false,
            batchUpdateWindowVisible: false,
            downloadWindowVisible: false,
            emailNotificationWindowVisible: false,
            validateWarningModalVisble: false,
            ifDownloadAll: true,
            disableOverrideButton: true,
            paymentValidationResult: [],
            paymentValidationStatus: "",
            documentData:[],
            documentVisible: false,
            singleUploadVisible: false,
            icLdapOverlap: arrayIntersection(IC_SETTLEMENTS_LDAP, localStorage.getItem(CONSTANT.USER_GROUP_NAME)),
        }
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleCommentUpdate = this.handleCommentUpdate.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);
    }

    /**
     * Function for row click in the data table.
     * The elementSelected is the row selected.
     */
    onRowClick = ({detail}) => {
        let newElementSelected = [...this.state.elementSelected];
        if (newElementSelected.includes(detail.item)) {
            newElementSelected = newElementSelected.filter(x => x !== detail.item)
        } else {
            newElementSelected.push(detail.item)
        }
        this.onSelectionChange({detail: {selectedItems: newElementSelected}});
    };

    componentDidMount() {
        Logger.logInfo('componentDidMount');

        /**
         * No processing is needed if the user is in more than one IC Settlements group, as the module
         * will simply return a Polaris Alert asking the user to remove themselves from all
         * but one IC settlement group before proceeding.
         */
        if (this.state.icLdapOverlap.length > 1) {
            return;
        }
        
        this.setState({
            status: CONSTANT.MODAL_QUERYING
        });
        var parameterGetSettlement = {indexString: CONSTANT.INDEX_STRING_PENDING_PAYMENT_REVIEW, userGroup: this.state.userGroup};

        //Get paymentRecords of corresponding status for display
        APIClient.invoke('GET', 'payment', parameterGetSettlement,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    Logger.logInfo("Data reterived: " + JSON.stringify(data));
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:

                            this.setState({
                                status: CONSTANT.MODAL_UPDATE_SUCCESS,
                                distributions: data.data
                            });
                            Logger.logInfo("Query Payment distributions state:" + JSON.stringify(this.state.distributions))
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Response Error when Querying data records ");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                distributions: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        case CONSTANT.RESPONSE_SYSTEM_ERROR:
                            Logger.logError("System Error when Querying data records ");
                            this.setState({
                                status: CONSTANT.MODAL_SYSTEM_ERROR,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data['errorData']));
                            break;
                        case CONSTANT.MODAL_REQUEST_ERROR:
                            Logger.logError("Request Error when Querying data records ");
                            this.setState({
                                status: CONSTANT.MODAL_REQUEST_ERROR,
                                distributions: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data['errorData']));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other Error when  Querying payment data records ");
                    this.setState({
                        status: CONSTANT.MODAL_OTHER_ERROR,
                        statusMsg: "Other System Error when Querying payment data records"
                    });
                }
            });

        // GET accountCategoryToRegionMapping
        parameterGetSettlement = {indexString: CONSTANT.INDEX_STRING_REGION_MAPPING};

        APIClient.invoke('GET', 'payment', parameterGetSettlement,
            undefined,
            (err, data) => {
                if (!err) {
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            this.setState({
                                accountCategoryToRegionMap: data.data
                            });
                            Logger.logInfo("accountCategoryToRegionMap:" + JSON.stringify(this.state.accountCategoryToRegionMap))
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Response Error when Querying accountCategoryToRegionMap.");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                accountCategoryToRegionMap: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other Error when  Querying accountCategoryToRegionMap.");
                    if (this.state.status != CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other System Error when Querying accountCategoryToRegionMap"
                        });
                    }
                }
            });

        // Get All Kyriba Bank Accounts Information as CompanyCode->KyribaBankAccountRecordsList Map
        var parameterGetKyribaBankAccount = {dataType: CONSTANT.QUERY_STRING_PARAM_TYPE_KYRIBA_BANK_ACCOUNT};
        APIClient.invoke('GET', 'queryKyribaData', parameterGetKyribaBankAccount,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            this.setState({
                                companyCodeToKyribaBankAccountsMap: data.data
                            });
                            Logger.logInfo("companyCodeToKyribaBankAccountsMap state:" + JSON.stringify(this.state.companyCodeToKyribaBankAccountsMap))
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Not able to query kyribaBankAccount information ");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                companyCodeToKyribaBankAccountsMap: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other Error when QueryingkyribaBankAccount information");
                    if (this.state.status != CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other System Error when Querying kyribaBankAccount information"
                        });
                    }
                }
            });

        ///Get All Kyriba Bank Accounts Balance Information as BankAccountCode->latestBankAccountBalanceRecord Map
        var parameterGetKyribaBankBalance = {dataType: CONSTANT.QUERY_STRING_PARAM_TYPE_KYRIBA_CASH_BALANCE};
        APIClient.invoke('GET', 'queryKyribaData', parameterGetKyribaBankBalance,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            this.setState({
                                kyribaBankAccountToBalanceMap: data.data
                            });
                            Logger.logInfo("kyribaBankAccountToBalanceMap state:" + JSON.stringify(this.state.kyribaBankAccountToBalanceMap))
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Not able to query kyribaBankBalance information ");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                kyribaBankAccountToBalanceMap: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other Error when Querying kyribaBankBalance information");
                    if (this.state.status != CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other System Error when Querying kyribaBankBalance information"
                        });
                    }
                }
            });

        // Get Kyriba Currency to Decimal Digit Mapping 
        var parameterGetKyribaCurrency = {dataType: CONSTANT.QUERY_STRING_PARAM_TYPE_KYRIBA_CURRENCY};
        APIClient.invoke('GET', 'queryKyribaData', parameterGetKyribaCurrency,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            this.setState({
                                currencyToDecimalDigitMapping: data.data
                            });
                            Logger.logInfo("currencyToDecimalDigitMapping state:" + JSON.stringify(this.state.currencyToDecimalDigitMapping))
                            break;
                        case CONSTANT.RESPONSE_SYSTEM_ERROR:
                            Logger.logError("Not able to query kyribaCurrency information");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other error occurred when querying for kyribaCurrency information: " + JSON.stringify(err));
                    if (this.state.status != CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other error occurred when querying for kyribaCurrency information"
                        });
                    }
                }
            });

        //Get FxRequiringCurrencyMapping
        var parameterGetFxRequiringCurrencyMapping = {dataType:
            CONSTANT.QUERY_STRING_PARAM_TYPE_GENERAL_TRS_FX_REQUIRING_CURRENCY};

        APIClient.invoke('GET', 'generalTRSData', parameterGetFxRequiringCurrencyMapping,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            this.setState({
                                fxRequiringCurrencyMapping: data.data
                            });
                            Logger.logInfo("fxRequiringCurrencyMapping state:" + JSON.stringify(this.state.fxRequiringCurrencyMapping))
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Not able to query FXRequiringCurrency information ");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                fxRequiringCurrencyMapping: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other Error when Querying FXRequiringCurrency information");
                    if (this.state.status != CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other System Error when Querying FXRequiringCurrency information"
                        });
                    }
                }
            });

        //Get WeekendCalendarMapping
        var parameterGetWeekendCalendarMapping = {dataType:
            CONSTANT.QUERY_STRING_PARAM_TYPE_GENERAL_TRS_FX_WEEKEND_CALENDAR};

        APIClient.invoke('GET', 'generalTRSData', parameterGetWeekendCalendarMapping,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            this.setState({
                                weekendCalendarMapping: data.data
                            });
                            Logger.logInfo("weekendCalendarMapping state:" + JSON.stringify(this.state.weekendCalendarMapping))
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Not able to query weekendCalendarMapping information ");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                weekendCalendarMapping: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other Error when Querying weekendCalendarMapping information");
                    if (this.state.status != CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other System Error when Querying weekendCalendarMapping information"
                        });
                    }
                }
            });

        //Get HolidayCalendarMapping
        var parameterGetHolidayCalendarMapping = {dataType:
            CONSTANT.QUERY_STRING_PARAM_TYPE_GENERAL_TRS_FX_HOLIDAY_CALENDAR};

        APIClient.invoke('GET', 'generalTRSData', parameterGetHolidayCalendarMapping,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            this.setState({
                                holidayCalendarMapping: data.data
                            });
                            Logger.logInfo("holidayCalendarMapping state:" + JSON.stringify(this.state.holidayCalendarMapping))
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Not able to query holidayCalendarMapping information ");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                holidayCalendarMapping: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other Error when Querying holidayCalendarMapping information");
                    if (this.state.status != CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other System Error when Querying holidayCalendarMapping information"
                        });
                    }
                }
            });

        //Query RecordIdToDocumentIdMapping
        const paramGetRecordIdToDocumentIdMapping = {dataType:
            CONSTANT.QUERY_STRING_PARAM_TYPE_DOCUMENT_ID_MAPPING};

        APIClient.invoke('GET', '/attachDocuments/documentData', paramGetRecordIdToDocumentIdMapping,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    this.setState({
                        recordIdToDocumentIdMapping: data,
                        recordIdToDocumentIdMappingLoaded: true,
                    });
                    Logger.logInfo("recordIdToDocumentIdMaping state:" + JSON.stringify(this.state.recordIdToDocumentIdMapping))
                } else {
                    Logger.logError("Other Error when Querying recordIdToDocumentIdMapping information");
                    if (this.state.status != CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other System Error when Querying recordIdToDocumentIdMapping information"
                        });
                    }
                }
            });
    }

    // Handler for selection change in the data table
    onSelectionChange({detail}) {
        Logger.logInfo("onSelectionChange: " + JSON.stringify(detail));
        this.setState(() => {
            let commentObject = {};

            if (detail.selectedItems.length === 1) {
                detail.selectedItems.forEach((item) => {
                    commentObject["commentId"] = item[CONSTANT.TR_COL_COMMENT_ID];
                    commentObject["userComment"] = item[CONSTANT.PR_COL_USER_COMMENTS] ? JSON.parse(item[CONSTANT.PR_COL_USER_COMMENTS]) : [];
                });

                return {
                    newComment: '',
                    commentsList: 1,
                    userComments: commentObject,
                    elementSelected: detail.selectedItems,
                };
            } else {
                return {
                    newComment: '',
                    commentsList: detail.selectedItems.length,
                    userComments: {'userComment': [], 'commentId': ''},
                    elementSelected: detail.selectedItems,
                };
            }
        });
        Logger.logInfo("State commentsList: " + JSON.stringify(this.state.commentsList));
    }

    // Handle view Comment Click
    handleViewCommentClick = () => {
        Logger.logInfo("handleViewCommentClick: ");
        this.setState({commentVisible: true})
        Logger.logInfo("commentVisible: " + this.state.commentVisible);
    }

    // Handle Comment Update
    handleCommentUpdate = (event) => {
        Logger.logInfo("Comment event: " + JSON.stringify(event));
        this.setState({
            newComment: event.detail.value
        });
    }

    // Handle View UpdateWindow Click
    handleViewUpdateWindowClick = () => {
        Logger.logInfo("handleViewUpdateWindowClick: ");
        this.setState({updateWindowVisible: true})
        Logger.logInfo("updateWindowVisible: " + this.state.updateWindowVisible);
    }

    handleViewBatchUpdateWindowClick = () => {
        Logger.logInfo("handleViewBatchUpdateWindowClick: ");
        this.setState({batchUpdateWindowVisible: true})
        Logger.logInfo("batchUpdateWindowVisible: " + this.state.batchUpdateWindowVisible);
    }

    // Handle ConfirmUpdate  Click
    handleConfirmUpdateClick = (elementSelected) => {
        Logger.logInfo("handleConfirmUpdateClick: ");
        this.handleUpdate(elementSelected, "Update")
        this.setState({updateWindowVisible: false});
    }

    // Handle ConfirmUpdate  Click
    handleConfirmBatchUpdateClick = (elementSelected) => {
        Logger.logInfo("handleConfirmBatchUpdateClick: ");
        this.handleUpdate(elementSelected, "Update")
        this.setState({batchUpdateWindowVisible: false});
    }

    // Handle Confirm Validate Liquidity Click
    handleConfirmValidateClick = (elementSelected) => {
        Logger.logInfo("handleConfirmValidateClick: ");
        this.handleUpdate(elementSelected, "Approve")
        this.setState({validateWarningModalVisble: false});
    }

    // Handle Dismiss or Cancel for modals' window
    handleDismissClick = () => {
        Logger.logInfo("handleDismissClick Start: ");
        if (this.state.commentVisible) {
            this.setState({commentVisible: false})
        } else if (this.state.rejectionMessageVisible) {
            this.setState({rejectionMessageVisible: false})
        } else if (this.state.updateWindowVisible) {
            this.setState({updateWindowVisible: false})
        } else if (this.state.batchUpdateWindowVisible){
            this.setState({batchUpdateWindowVisible: false})
        } if (this.state.downloadWindowVisible) {
            this.setState({downloadWindowVisible: false})
        } else if (this.state.emailNotificationWindowVisible) {
            this.setState({emailNotificationWindowVisible: false})
        } else if (this.state.validateWarningModalVisble) {
            this.setState({validateWarningModalVisble: false})
        }
    }

    // Handle add comment in the comment dialog
    handleAddCommentClick = (elementSelected, newComment) => {
        Logger.logInfo("handleAddCommentClick: " + newComment);

        if (newComment) {
            let commentDate = FormatData.formateNewUTCCurrentTime();

            //Add the new comments to userComments field of the selected elements.
            elementSelected.map((entry, index) => {
                // The comment field is stored as serialized string, deserialize the string to get the userComments Object.
                let userCommentsList = elementSelected[index][CONSTANT.PR_COL_USER_COMMENTS] ?
                    JSON.parse(elementSelected[index][CONSTANT.PR_COL_USER_COMMENTS]) : [];

                let newCommentObject = {};
                newCommentObject["Comment"] = newComment;
                newCommentObject["User"] = this.state.user;
                newCommentObject["CommentDate"] = commentDate;
                userCommentsList.push(newCommentObject);

                elementSelected[index][CONSTANT.PR_COL_USER_COMMENTS] = JSON.stringify(userCommentsList);
            })

            // Invoke update handler to call the update API endpoints.
            this.handleUpdate(elementSelected, 'AddComments');
            this.setState({commentVisible: false});
        } else {
            Logger.logInfo("No Comment: ");
            this.setState({commentVisible: true});
        }
    }

    // Handle view emailNotification window click
    handleViewNotificationWindowClick = () => {
        Logger.logInfo("handleViewNotificationWindowClick: ");
        this.setState({emailNotificationWindowVisible: true})
        Logger.logInfo("emailNotificationWindowVisible: " + this.state.emailNotificationWindowVisible);
    }

    // Handle sending email notifications
    handleSendEmailNotification = (deadline) => {

        var parameterPOSTNotification = {deadline: deadline};

        APIClient.invoke('POST', 'notifyPayment', parameterPOSTNotification, null, (err, requestData) => {
            if (!err) {
                switch (requestData.status) {
                    case CONSTANT.RESPONSE_SUCCESS:
                        this.setState({
                            status: CONSTANT.MODAL_NOTIFICATION_SUCCESS,
                        });
                        Logger.logInfo("Successfully notify for liquidity checks");
                        break;
                    default:
                }
            } else {
                Logger.logError("Other System Error when trying to send email notification.");
                this.setState({
                    status: CONSTANT.MODAL_OTHER_ERROR,
                    statusMsg: "Other System Error when trying to send email notification."
                });
            }
        })
    }

    // Handle view downloadWindow click
    handleViewDownloadWindowClick = (button_id) => {
        Logger.logInfo("handleViewDownloadWindowClick: ");
        let ifDownloadAll = button_id === 'download_all' ? true : false;
        this.setState({
            downloadWindowVisible: true,
            ifDownloadAll: ifDownloadAll
        })
        Logger.logInfo("downloadWindowVisible: " + this.state.downloadWindowVisible);
    }
    // Handle confirm download click
    handleConfirmDownloadClick = (fileType, ifDownloadAll) => {
        Logger.logInfo("handleDownloadClick fileType: " + fileType);
        let fileName = "TRS_Settlements_Download" + FormatData.formatNewFileDate() + '.' + fileType;
        let delimiter = DOWNLOAD_FILE_PAYMENT_DELIMITER;
        let header = VISIBLE_COLUMN_OPTIONS_OPEN_PAYMENT;
        let enrichedPayments;
        if (ifDownloadAll) {
            enrichedPayments = this.enrichPaymentsForDownload(this.state.distributions);
        } else {
            enrichedPayments = this.enrichPaymentsForDownload(this.state.elementSelected);
        }
        if (fileType === 'xlsx') {
            xlsxParser.writeFile(fileName, delimiter, enrichedPayments, header, fileType);
        } else if (fileType === 'csv') {
            xlsxParser.writeFileANSI(fileName, delimiter, enrichedPayments, header);
        }

        // Dismiss the download window
        this.setState({downloadWindowVisible: false})
    }
    // queryDocuments for the queryDocumentData page.
    handleQueryDocument = async () => {
        this.setState({
            status: CONSTANT.MODAL_QUERYING
        });

        const result = await queryDocuments(this.state.elementSelected);

        switch (result.status) {
            case CONSTANT.RESPONSE_SUCCESS:
                this.setState({
                    documentData: result.documentData,
                    documentVisible: true,
                });
                Logger.logInfo("Query Document Data succeeded.");
                break;
            case CONSTANT.RESPONSE_ERROR:
                Logger.logError("Error Query Document Data = " + this.state.elementSelected.toString());
                this.setState({
                    documentVisible: false,
                    status: CONSTANT.MODAL_QUERY_ERROR
                });
                break;
            case CONSTANT.RESPONSE_SYSTEM_ERROR:
                Logger.logError("System Error when Query Document Data = " + this.state.elementSelected.toString());
                this.setState({
                    status: CONSTANT.MODAL_SYSTEM_ERROR,
                    statusMsg: JSON.stringify(result.errorMessage)
                });
                break;
            case CONSTANT.MODAL_OTHER_ERROR:
                Logger.logError("Other System Error when Query Document Data");
                this.setState({
                    status: CONSTANT.MODAL_OTHER_ERROR,
                    statusMsg: "Other System Error when query document data records."
                });
                break;
            default:
                Logger.logError("Unhandled response status:", result.status);
                this.setState({
                    status: CONSTANT.MODAL_OTHER_ERROR,
                    statusMsg: "Received unknown response status"
                });

        }
    }

    /*
        Handle Validate Liquidity Click
        Selected element will be sent to backend for validating if each payment has a duplicate relationship
        The validate warning modal will be visible to check all the warnings
    */
    handleValidateLiquidityClick = (elementSelected) => {
        let validatePayments = [];
        elementSelected.map(payment => {
            let validatePaymentItem = {};
            validatePaymentItem[CONSTANT.PR_COL_OCM_MAPPING] = payment[CONSTANT.PR_COL_OCM_MAPPING];
            validatePaymentItem[CONSTANT.PR_COL_SETTLEMENT_ID] = payment[CONSTANT.PR_COL_SETTLEMENT_ID];
            validatePaymentItem[CONSTANT.PR_COL_SETTLEMENT_CYCLE] = payment[CONSTANT.PR_COL_SETTLEMENT_CYCLE];
            validatePayments.push(validatePaymentItem);
        });

        const parameter = {
            indexString: CONSTANT.INDEX_STRING_PAYMENT_DUPLICATE_RELATIONSHIP,
            userGroup: this.state.userGroup
        };

        this.setState({
            validateWarningModalVisble: true,
            paymentValidationStatus: CONSTANT.MODAL_PAYMENT_VALIDATE_START,
        });
        
        APIClient.invoke('POST', 'payment', parameter, elementSelected, (err, requestData) => {
            Logger.logInfo("Response data: " + JSON.stringify(requestData));
            if (!err) {
                switch (requestData.status) {
                    case CONSTANT.RESPONSE_SUCCESS:
                        this.setState({
                            paymentValidationStatus: CONSTANT.MODAL_PAYMENT_VALIDATE_FINISH,
                            paymentValidationResult: requestData.data
                        });
                        Logger.logInfo("Payment duplicate relationship check finish");
                        break;
                    case CONSTANT.RESPONSE_ERROR:
                        Logger.logError("Error finding duplicate relationship with payments list = " + elementSelected.toString());
                        this.setState({
                            paymentValidationStatus: CONSTANT.MODAL_PAYMENT_VALIDATE_FAIL
                        });
                        break;
                    case CONSTANT.RESPONSE_SYSTEM_ERROR:
                        Logger.logError("System Error when finding duplicate relationship records with payments list = " + elementSelected.toString());
                        this.setState({
                            status: CONSTANT.MODAL_SYSTEM_ERROR,
                            statusMsg: JSON.stringify(requestData['errorData'])
                        });
                        break;
                    case CONSTANT.MODAL_REQUEST_ERROR:
                        this.setState({
                            status: CONSTANT.MODAL_REQUEST_ERROR,
                            distributions: requestData.data,
                            statusMsg: JSON.stringify(requestData['errorData'])
                        });
                        break;
                    default:
                }
            } else {
                Logger.logError("Other System Error when finding duplicate relationship records with payments list = " + this.props.elementSelected.toString());
                this.setState({
                    status: CONSTANT.MODAL_OTHER_ERROR,
                    statusMsg: "Other System Error when finding duplicate relationship data records"
                });
            }
        })
    }

    /*  Enrich Payment for Download
        Since some fields displayed the table are not contained in the elementSelected/distribution object
        and are retrieved elsewhere instead,
        this function enrich those fields so that all the columnds displayed in the table can be downloaded. */
    enrichPaymentsForDownload = (elementSelected) => {
        // Prepare a deep clone of elementSelected.
        let elementSelectedCopy = JSON.parse(JSON.stringify(elementSelected))
        let enrichedPayments =  elementSelectedCopy.map(payment => {
            // Enrich some fields of each of the payment element.
            VISIBLE_COLUMN_OPTIONS_OPEN_PAYMENT.forEach((column) => {
                if (column === CONSTANT.PR_COL_RECEIVING_AMOUNT ||
                    column === CONSTANT.PR_COL_SENDING_AMOUNT ||
                    column === CONSTANT.PR_COL_SETTLEMENT_USD_EQUIV ||
                    column === CONSTANT.PR_COL_TARGET_AMOUNT) {
                    //pass
                } else if (column === CONSTANT.PR_COL_UPLOAD_DATE ||
                    column === CONSTANT.PR_COL_CREATION_DATE ||
                    column === CONSTANT.PR_COL_UPDATE_DATE ||
                    column === CONSTANT.PR_COL_APPROVAL_DATE ||
                    column === CONSTANT.PR_COL_REJECTION_DATE ||
                    column === CONSTANT.PR_COL_EXPORT_DATE){
                    //Format UTC ISO DateString (Used in DDB) to local timezone DateString
                    payment[column] = FormatData.formatISODateToPSTTimezoneDate(payment[column]);
                } else if (column === CONSTANT.PR_COL_SENDING_ACCOUNT_BALANCE ||
                    column === CONSTANT.PR_COL_RECEIVING_ACCOUNT_BALANCE) {

                    //Use the AccountCode to retrieve bankBalanceRecord
                    let accountKey = (column === CONSTANT.PR_COL_SENDING_ACCOUNT_BALANCE) ?
                        CONSTANT.PR_COL_SENDING_ACCOUNT : CONSTANT.PR_COL_RECEIVING_ACCOUNT
                    let accountBalance = lookUpAccountBalance(this.state.kyribaBankAccountToBalanceMap, payment[accountKey]);

                    payment[column] = (typeof accountBalance === 'undefined') ?
                        'Not Available' : accountBalance;
                } else if (column === 'IC out 50%') {
                    payment[column] = ifSendingBalanceIsOverHalfOfSendingAccountBalance(this.state.kyribaBankAccountToBalanceMap, payment, this.state.distributions);
                } else if (column === 'Liquidity Check') {
                    let liquidity = calculateLiquidity(this.state.kyribaBankAccountToBalanceMap, payment, this.state.distributions);
                    payment[column] = liquidity;
                } else if (column === 'Latest Comment') {
                    payment[column] = getLatestComment(payment);
                } else if (column === 'Sending Branch') {
                    payment[column] = lookupBranch(payment[CONSTANT.PR_COL_SENDING_ACCOUNT],
                        payment[CONSTANT.PR_COL_SENDING_CO], this.state.companyCodeToKyribaBankAccountsMap);
                } else if (column === 'Receiving Branch') {
                    payment[column] = lookupBranch(payment[CONSTANT.PR_COL_RECEIVING_ACCOUNT],
                        payment[CONSTANT.PR_COL_RECEIVING_CO], this.state.companyCodeToKyribaBankAccountsMap);
                } else if (column === 'Sending Account Type' || column === 'Receiving Account Type'){
                    payment[column] = lookupAccountCategory4(payment, column, this.state.companyCodeToKyribaBankAccountsMap);
                } else if (column === 'Sending Region' || column === 'Receiving Region') {
                    payment[column] = populateRegion(payment, column);
                } else if (column === 'File Attached'){
                    payment[column] = hasDocumentAttached(payment[CONSTANT.PR_COL_SETTLEMENT_ID],
                        this.state.recordIdToDocumentIdMapping) ? 'Yes' : 'No';
                }
            });
            // If the payment element contain a key that's not in VISIBLE_COLUMN_OPTIONS_OPEN_PAYMENT, remove the value.
            for (const key in payment) {
                if (!VISIBLE_COLUMN_OPTIONS_OPEN_PAYMENT.includes(key)) {
                    delete payment[key];
                }
            }
            return payment;
        })
        return enrichedPayments;
    }

    // Main handler for updating settlements
    handleUpdate = (elementSelected, name) => {
        Logger.logInfo("handleUpdate elementSelected: " + JSON.stringify(elementSelected)
            + " name: " + name);

        this.setState({
            status: CONSTANT.MODAL_SUBMITTING
        });

        let newData = this.state.distributions;
        let updateSuccessState = CONSTANT.MODAL_PAYMENT_UPDATE_SUCCESS;

        //Update the Selected Element Values
        elementSelected.map((entry, index) => {
            var objIndex = newData.findIndex((obj => obj[CONSTANT.PR_COL_SETTLEMENT_ID] === entry[CONSTANT.PR_COL_SETTLEMENT_ID]));

            if (name === "Approve") {

                if (elementSelected[index][CONSTANT.PR_COL_PAYMENT_TYPE] === 'Bank Portal Wire' ||
                    elementSelected[index][CONSTANT.PR_COL_PAYMENT_TYPE] === 'Sub-Executed' ||
                    elementSelected[index][CONSTANT.PR_COL_FILE_TYPE] === 'ICSC') {
                    /* For Approving payments in the type of  Bank Portal Wire and ICSC,
                       Set the status as CLOSED * */
                    elementSelected[index][CONSTANT.PR_COL_STATUS] = CONSTANT.PAYMENT_STATUS_CLOSED;
                    elementSelected[index][CONSTANT.PR_COL_CLOSE_DATE] = FormatData.formateNewUTCCurrentTime();
                    elementSelected[index][CONSTANT.PR_COL_CLOSED_BY] = this.state.user;
                } else if (elementSelected[index][CONSTANT.PR_COL_PAYMENT_TYPE] === 'Cancelled' ||
                    elementSelected[index][CONSTANT.PR_COL_PAYMENT_TYPE] === 'Settled Early') {
                    /* For Approving payments in the type of Cancelled and Settled Early'
                       Set the status as REJECTED and set the comment * */
                    elementSelected[index][CONSTANT.PR_COL_STATUS] = CONSTANT.PAYMENT_STATUS_REJECTED;
                    elementSelected[index][CONSTANT.PR_COL_REJECTION_DATE] = FormatData.formateNewUTCCurrentTime();
                    elementSelected[index][CONSTANT.PR_COL_REJECTED_BY] = this.state.user;
                } else {
                    // For Approving payment set the status as Approved
                    elementSelected[index][CONSTANT.PR_COL_STATUS] = CONSTANT.PAYMENT_STATUS_APPROVED;
                    elementSelected[index][CONSTANT.PR_COL_APPROVAL_DATE] = FormatData.formateNewUTCCurrentTime();
                    elementSelected[index][CONSTANT.PR_COL_APPROVED_BY] = this.state.user;
                }

                updateSuccessState = CONSTANT.MODAL_APPROVE_SUCCESS;
            }
            // Capture the user who made the latest edits.
            elementSelected[index][CONSTANT.PR_COL_UPDATE_DATE] = FormatData.formateNewUTCCurrentTime();
            elementSelected[index][CONSTANT.PR_COL_UPDATE_BY] = this.state.user;

            newData[objIndex] = entry;
        })

        var parameter = {
            indexString: CONSTANT.INDEX_STRING_PENDING_PAYMENT_REVIEW,
            userGroup: this.state.userGroup,
            request: name
        };

        APIClient.invoke('POST', 'payment', parameter, elementSelected, (err, requestData) => {
            Logger.logInfo("Response data: " + JSON.stringify(requestData));
            if (!err) {
                switch (requestData.status) {
                    case CONSTANT.RESPONSE_SUCCESS:
                        this.setState({
                            status: updateSuccessState,
                            distributions: requestData.data,
                            elementSelected: []
                        });
                        Logger.logInfo("Update distributions state: " + JSON.stringify(this.state.distributions));
                        break;
                    case CONSTANT.RESPONSE_ERROR:
                        Logger.logError("Error updating payment records with payments list = " + elementSelected.toString());
                        this.setState({
                            status: CONSTANT.MODAL_UPDATE_ERROR,
                            distributions: requestData.data,
                            statusMsg: requestData['errorData']
                        });
                        break;
                    case CONSTANT.RESPONSE_SYSTEM_ERROR:
                        Logger.logError("System Error when updating payment records with payments list = " + elementSelected.toString());
                        this.setState({
                            status: CONSTANT.MODAL_SYSTEM_ERROR,
                            statusMsg: JSON.stringify(requestData['errorData'])
                        });
                        break;
                    case CONSTANT.MODAL_REQUEST_ERROR:
                        this.setState({
                            status: CONSTANT.MODAL_REQUEST_ERROR,
                            distributions: requestData.data,
                            statusMsg: JSON.stringify(requestData['errorData'])
                        });
                        break;
                    default:
                }
            } else {
                Logger.logError("Other System Error when updating payment records with payments list = " + elementSelected.toString());
                this.setState({
                    status: CONSTANT.MODAL_OTHER_ERROR,
                    statusMsg: "Other System Error when updating payment data records"
                });
            }
        })
    };

    handleDismissDocumentModalClick = () => {
        this.setState({
            documentVisible: false,
        })
    }

    handleDismissSingleUpload = () => {
        this.setState({
            singleUploadVisible: false,
        })
    }
    handleSingleDocumentClick = () => {
        this.setState({
            singleUploadVisible: true
        })
    }
    // Configure column definitions & table configurations for displaying settlement data.
    getColumnDefinitionsAndTableConfiguration = () => {
        let columnDefinitions = [];
        let sortableColumns = [];
        let visibleColumnOption = [];
        let firstOption = {};
        firstOption['label'] = 'Properties';
        let filterOptions = [];
        let contentSelectorOptions = [];

        VISIBLE_COLUMN_OPTIONS_OPEN_PAYMENT.forEach((column) => {
            let columnDefinitionsItem = {};
            let sortableColumnsItem = {};
            let filterColumnOptionItem = {};
            columnDefinitionsItem['id'] = column;
            columnDefinitionsItem['header'] = column;
            columnDefinitionsItem['width'] = 190;
            // ColumnDefinitionsItem['minWidth'] = '100px';

            if (column === CONSTANT.PR_COL_RECEIVING_AMOUNT ||
                column === CONSTANT.PR_COL_SENDING_AMOUNT ||
                column === CONSTANT.PR_COL_SETTLEMENT_USD_EQUIV ||
                column === CONSTANT.PR_COL_TARGET_AMOUNT) {
                //Format USD amount into Display format
                columnDefinitionsItem['cell'] = (item => <Box textAlign="right">{FormatData.formatUSDDisplayAmountAccounting(item[column])}</Box>);
            } else if (column === CONSTANT.PR_COL_UPLOAD_DATE ||
                column === CONSTANT.PR_COL_CREATION_DATE ||
                column === CONSTANT.PR_COL_UPDATE_DATE ||
                column === CONSTANT.PR_COL_APPROVAL_DATE ||
                column === CONSTANT.PR_COL_REJECTION_DATE ||
                column === CONSTANT.PR_COL_EXPORT_DATE) {
                //Format UTC ISO DateString (Used in DDB) to local timezone DateString
                columnDefinitionsItem['cell'] = (item => FormatData.formatISODateToPSTTimezoneDate(item[column]));
            } else if (column === CONSTANT.PR_COL_SENDING_ACCOUNT_BALANCE ||
                column === CONSTANT.PR_COL_RECEIVING_ACCOUNT_BALANCE) {

                //Use the AccountCode to retrieve bankBalanceRecord
                let accountKey = (column === CONSTANT.PR_COL_SENDING_ACCOUNT_BALANCE) ?
                    CONSTANT.PR_COL_SENDING_ACCOUNT : CONSTANT.PR_COL_RECEIVING_ACCOUNT

                columnDefinitionsItem['cell'] = (item => {
                    let accountBalance = lookUpAccountBalance(this.state.kyribaBankAccountToBalanceMap, item[accountKey]);
                    return (typeof accountBalance === 'undefined') ? 'Not Available'
                        : FormatData.formatUSDDisplayAmountAccounting(accountBalance);

                });
                columnDefinitionsItem['width'] = 210;
            } else if (column === 'IC out 50%') {
                columnDefinitionsItem['cell'] = (item =>
                    ifSendingBalanceIsOverHalfOfSendingAccountBalance(this.state.kyribaBankAccountToBalanceMap, item, this.state.distributions));
            } else if (column === 'Liquidity Check') {
                columnDefinitionsItem['cell'] = (item => {
                    let liquidity = calculateLiquidity(this.state.kyribaBankAccountToBalanceMap, item, this.state.distributions);
                    return (liquidity === 'Not Available') ? liquidity : FormatData.formatUSDDisplayAmountAccounting(liquidity);
                });
            } else if (column === 'Latest Comment') {
                columnDefinitionsItem['cell'] = (item => getLatestComment(item));
            } else if (column === 'Sending Account Type' || column === 'Receiving Account Type') {
                columnDefinitionsItem['cell'] = (item => lookupAccountCategory4(item, column,
                    this.state.companyCodeToKyribaBankAccountsMap));
            } else if (column === 'Sending Branch') {
                columnDefinitionsItem['cell'] = (item => lookupBranch(
                    item[CONSTANT.PR_COL_SENDING_ACCOUNT], item[CONSTANT.PR_COL_SENDING_CO],
                    this.state.companyCodeToKyribaBankAccountsMap));
            } else if (column === 'Receiving Branch') {
                columnDefinitionsItem['cell'] = (item => lookupBranch(item[CONSTANT.PR_COL_RECEIVING_ACCOUNT],
                    item[CONSTANT.PR_COL_RECEIVING_CO], this.state.companyCodeToKyribaBankAccountsMap));
            } else if (column === 'Sending Region' || column === 'Receiving Region') {
                columnDefinitionsItem['cell'] = (item => populateRegion(item, column));
            } else if (column === 'File Attached'){
                columnDefinitionsItem['cell'] = (item =>
                    hasDocumentAttached(item[CONSTANT.PR_COL_SETTLEMENT_ID],
                        this.state.recordIdToDocumentIdMapping) ? 'Yes' : 'No');
            } else {
                columnDefinitionsItem['cell'] = (item => item[column]);
            }

            // Sortable Columns
            sortableColumnsItem['id'] = column;
            sortableColumnsItem['field'] = column;
            sortableColumns.push(sortableColumnsItem);

            // Filtering Options:
            filterColumnOptionItem['propertyLabel'] = column;
            if (column === 'Sending Region') {
                filterColumnOptionItem['propertyKey'] = CONSTANT.PR_COL_REGION;
            } else {
                filterColumnOptionItem['propertyKey'] = column;
            }
            filterColumnOptionItem['groupValuesLabel'] = column + " values";
            filterColumnOptionItem['values'] = [];

            // Remove columns that are rendered on the front end
            if (column !== 'File Attached' &&
                column !== 'Liquidity Check' &&
                column !== 'IC out 50%') {
                filterOptions.push(filterColumnOptionItem);
            }

            columnDefinitions.push(columnDefinitionsItem);

            // Content Selector Columns
            let contentSelectorItem = {};
            if (HIDDEN_OPTIONS_PENDING_LIQUIDITY.indexOf(column) !== -1) {
                contentSelectorItem['id'] = column;
                contentSelectorItem['label'] = column;
                contentSelectorItem['editable'] = true;
                contentSelectorItem['visible'] = false;
            } else {
                contentSelectorItem['id'] = column;
                contentSelectorItem['label'] = column;
                contentSelectorItem['editable'] = true;
                contentSelectorItem['visible'] = true;
            }

            contentSelectorOptions.push(contentSelectorItem);
        });

        firstOption['options'] = contentSelectorOptions;
        visibleColumnOption.push(firstOption);

        return {
            visibleColumnOption,
            sortableColumns,
            columnDefinitions,
            filterOptions
        }
    }

    render() {

        /**
         * If the user is in more than 1 IC Settlements LDAP group,
         * Display a Polaris alert to prompt them to remove themselves
         * from all but 1 group.
         */
         if (this.state.icLdapOverlap.length > 1) {
            return ldapMembershipAlert(this.state.icLdapOverlap);
        }

        /**
         * Display the Single Upload Document Module.
         */
        if (this.state.singleUploadVisible === true) {
            return <SingleDocumentModule id={this.state.elementSelected[0][CONSTANT.PR_COL_SETTLEMENT_ID]}
                                         handleDismissSingleUpload ={this.handleDismissSingleUpload}
            />
        }

        let {visibleColumnOption, sortableColumns, columnDefinitions, filterOptions} = this.getColumnDefinitionsAndTableConfiguration();

        return (
            <div>
                {this.state.documentVisible === true && this.state.documentData.length > 0 &&
                    <QueryDocumentData distributions={this.state.documentData}
                                       handleDismissDocumentModalClick={this.handleDismissDocumentModalClick}
                                       columnDefinitions={columnDefinitions}
                                       contentSelectorOptions={visibleColumnOption}
                                       filterOptions={filterOptions}
                                       elementSelected={this.state.elementSelected}
                                       onSelectionChange={this.onSelectionChange}
                                       sortableColumns={sortableColumns}
                    />}
                {this.state.documentVisible === true && this.state.documentData.length === 0 &&
                    <NoDocumentModal handleDismissDocumentModalClick={this.handleDismissDocumentModalClick}/>}
                <Form>
                    <PendingPaymentDataContentBlock distributions={this.state.distributions}
                                                    onRowClick={this.onRowClick}
                                                    loading={this.state.loading}
                                                    elementSelected={this.state.elementSelected}
                                                    contentSelectorOptions={visibleColumnOption}
                                                    sortableColumns={sortableColumns}
                                                    columnDefinitions={columnDefinitions}
                                                    filterOptions={filterOptions}
                                                    userGroup={this.state.userGroup}
                                                    onSelectionChange={this.onSelectionChange}
                                                    handleUpdateClick={this.handleUpdate}
                                                    handleDismissClick={this.handleDismissClick}
                                                    disableOverrideButton={this.state.disableOverrideButton}
                                                    //User Comments Modal Related
                                                    commentVisible={this.state.commentVisible}
                                                    newComment={this.state.newComment}
                                                    userComments={this.state.userComments}
                                                    commentsList={this.state.commentsList}
                                                    handleViewCommentClick={this.handleViewCommentClick}
                                                    handleAddCommentClick={this.handleAddCommentClick}
                                                    handleCommentUpdate={this.handleCommentUpdate}
                                                    feedbackMessage={getFeedbackMessage(this.state.status, this.state.statusMsg)}
                                                    //Update Modal Related
                                                    handleViewUpdateWindowClick={this.handleViewUpdateWindowClick}
                                                    handleConfirmUpdateClick={this.handleConfirmUpdateClick}
                                                    updateWindowVisible={this.state.updateWindowVisible}
                                                    fxRequiringCurrencyMapping={this.state.fxRequiringCurrencyMapping}
                                                    weekendCalendarMapping={this.state.weekendCalendarMapping}
                                                    holidayCalendarMapping={this.state.holidayCalendarMapping}
                                                    currencyToDecimalDigitMapping={this.state.currencyToDecimalDigitMapping}
                                                    //Batch Update Modal Related
                                                    handleViewBatchUpdateWindowClick={this.handleViewBatchUpdateWindowClick}
                                                    handleConfirmBatchUpdateClick={this.handleConfirmBatchUpdateClick}
                                                    batchUpdateWindowVisible={this.state.batchUpdateWindowVisible}
                                                    //Download Modal Related
                                                    handleViewDownloadWindowClick={this.handleViewDownloadWindowClick}
                                                    downloadWindowVisible={this.state.downloadWindowVisible}
                                                    handleConfirmDownloadClick={this.handleConfirmDownloadClick}
                                                    companyCodeToKyribaBankAccountsMap={this.state.companyCodeToKyribaBankAccountsMap}
                                                    accountCategoryToRegionMap={this.state.accountCategoryToRegionMap}
                                                    ifDownloadAll={this.state.ifDownloadAll}
                                                    //Validate Liquidity Related
                                                    handleValidateLiquidityClick={this.handleValidateLiquidityClick}
                                                    handleConfirmValidateClick={this.handleConfirmValidateClick}
                                                    validateWarningModalVisble={this.state.validateWarningModalVisble}
                                                    paymentValidationResult={this.state.paymentValidationResult}
                                                    paymentValidationStatus={this.state.paymentValidationStatus}
                                                    //Email Notification Modal Related
                                                    handleViewNotificationWindowClick={this.handleViewNotificationWindowClick}
                                                    emailNotificationWindowVisible={this.state.emailNotificationWindowVisible}
                                                    handleSendEmailNotification={this.handleSendEmailNotification}
                                                    user={this.state.user}
                                                    //Single Document Upload
                                                    handleSingleDocumentClick={this.handleSingleDocumentClick}
                                                    //View Document
                                                    handleQueryDocument={this.handleQueryDocument}
                                                    recordIdToDocumentIdMapping={this.state.recordIdToDocumentIdMapping}
                    />
                </Form>
            </div>
        );
    }
}


export default QueryPendingPaymentData;