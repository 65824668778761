const CONSTANT = Object.freeze({
    //backend response related
    RESPONSE_SUCCESS: 'SUCCESS',
    RESPONSE_DUPLICATE: 'DUPLICATE',
    RESPONSE_ERROR: 'ERROR',
    RESPONSE_SYSTEM_ERROR: 'SYSTEM_ERROR',
    RESPONSE_CONDITION_ERROR: 'CONDITION_ERROR',
    RESPONSE_KEY_NOT_EXIST: 'KEY_NOT_EXIST',
    RESPONSE_REQUEST_ERROR: 'REQUEST_ERROR',
    RESPONSE_INVALID_REQUEST: 'INVALID_REQUEST',
    RESPONSE_DEV_BUG: 'INVALID_REQUEST',

    //Exchange rate type
    EXCHANGE_RATE_TYPE: 'Corporate',

    //Local Storage User ID:
    LOCAL_STORAGE_USERID: "userID",
    LOCAL_STORAGE_CONTENTSELECTOR: "contentSelectorOptions",

    //General modal state
    MODAL_EMPTY: 'MODAL_EMPTY',
    MODAL_REQUIRED_EMPTY: 'MODAL_REQUIRED_EMPTY',
    MODAL_SUBMITTING: 'MODAL_SUBMITTING',
    MODAL_INPUT_ERROR: 'MODAL_INPUT_ERROR',
    MODAL_OTHER_ERROR:'MODAL_OTHER_ERROR',

    //general create request related modal state
    MODAL_CREATE_SUCCESS: 'MODAL_CREATE_SUCCESS',
    MODAL_CREATE_DUPLICATE: 'MODAL_CREATE_DUPLICATE',
    MODAL_CREATE_ERROR: 'MODAL_CREATE_ERROR',

    //general query request related modal state
    MODAL_QUERY_SUCCESS: 'MODAL_QUERY_SUCCESS',
    MODAL_QUERY_ERROR: 'MODAL_QUERY_ERROR',
    MODAL_QUERYING: 'MODAL_QUERYING',
    MODAL_QUERY_RANGE_ERROR: 'MODAL_QUERY_RANGE_ERROR',

    //general delete request related modal state
    MODAL_DELETE_SUCCESS: 'MODAL_DELETE_SUCCESS',
    MODAL_DELETE_ERROR: 'MODAL_DELETE_ERROR',

    //general download request related modal state
    MODAL_DOWNLOAD_SUCCESS: 'MODAL_DOWNLOAD_SUCCESS',
    MODAL_DOWNLOAD_ERROR: 'MODAL_DOWNLOAD_ERROR',
    MODAL_DOWNLOAD_SUBMITTING: 'MODAL_DOWNLOAD_SUBMITTING',

    //general update request related modal state
    MODAL_UPDATE_SUCCESS: 'MODAL_UPDATE_SUCCESS',
    MODAL_PAYMENT_UPDATE_SUCCESS: 'MODAL_PAYMENT_UPDATE_SUCCESS',
    MODAL_REJECT_SUCCESS: 'MODAL_REJECT_SUCCESS',
    MODAL_APPROVE_SUCCESS: 'MODAL_APPROVE_SUCCESS',
    MODAL_NOTIFICATION_SUCCESS: 'MODAL_NOTIFICATION_SUCCESS',
    MODAL_UPDATE_ERROR: 'MODAL_UPDATE_ERROR',
    MODAL_SYSTEM_ERROR: 'MODAL_SYSTEM_ERROR',
    MODAL_UPDATE_KEY_NOT_EXIST: 'MODAL_UPDATE_KEY_NOT_EXIST',
    MODAL_UPDATE_CONDITIONAL_ERROR: 'MODAL_UPDATE_CONDITIONAL_ERROR',
    MODAL_FILE_GENERATION_SUCCESS: 'MODAL_FILE_GENERATION_SUCCESS',

    //Update Settlement Modal Invalid Statues:
    MODAL_UPDATE_EMPTY_REJECTION_REASON: 'MODAL_UPDATE_EMPTY_REJECTION_REASON',
    MODAL_UPDATE_EMPTY_ADDITIONAL_REJECTION_REASON : 'MODAL_UPDATE_EMPTY_ADDITIONAL_REJECTION_REASON',
    MODAL_UPDATE_VALIDATION_FAIL: 'MODAL_UPDATE_VALIDATION_FAIL',

    //Modal state unique to 'create meta data' page
    MODAL_META_ADD_BUTTON_CLICK: 'MODAL_META_ADD_BUTTON_CLICK',
    MODAL_META_ADD_DUPLICATE_VALUE: 'MODAL_META_ADD_DUPLICATE_VALUE',

    //modal state unique to 'query meta data page'
    MODAL_META_QUERY_KEY_NOT_EXIST: 'MODAL_META_QUERY_KEY_NOT_EXIST',

    //Constant used for date range errors when using the Date Picker outside of modals.
    DATE_RANGE_ERROR: 'DATE_RANGE_ERROR',

    // E&I Module modal state
    EXCEPTION_UPDATE_SUCCESS: 'EXCEPTION_UPDATE_SUCCESS',
    EXCEPTION_UPDATE_FAILURE: 'EXCEPTION_UPDATE_FAILURE',

    // Constants that are used to display and render the tiles and Polaris hyperlinks for related TTs.
    TT_URL: 'https://t.corp.amazon.com/',
    TT_HTTP_PREFIX: 'http',

    //debugging purposes
    MODAL_REQUEST_ERROR: 'MODAL_REQUEST_ERROR',

    //Modal state unique to 'create group' page
    MODAL_GROUP_CREATE_DUPLICATE: 'MODAL_GROUP_CREATE_DUPLICATE',
    MODAL_GROUP_CREATE_SUCCESS: 'MODAL_GROUP_CREATE_SUCCESS',
    MODAL_GROUP_INPUT_ERROR: 'MODAL_GROUP_INPUT_ERROR',
    MODAL_USER_UPDATE_SUCCESS: 'MODAL_USER_UPDATE_SUCCESS',
    MODAL_USER_UPDATE_FAIL: 'MODAL_USER_UPDATE_FAIL',
    MODAL_VALUEDATE_ERROR: 'MODAL_VALUEDATE_ERROR',
    MODAL_ACCOUNT_ERROR: 'MODAL_ACCOUNT_ERROR',

    //Checkout Records
    MODAL_CHECKEDOUT_RECORDS: 'MODAL_CHECKEDOUT_RECORDS',
    MODAL_CHECKEDOUT_ERROR: 'MODAL_CHECKEDOUT_ERROR',

    //Comments
    MODAL_EMPTY_COMMENTS: 'MODAL_EMPTY_COMMENTS',

    //Validate Liquidity:
    MODAL_PAYMENT_VALIDATE_START: 'MODAL_PAYMENT_VALIDATE_START',
    MODAL_PAYMENT_VALIDATE_FINISH: 'MODAL_PAYMENT_VALIDATE_FINISH',
    MODAL_PAYMENT_VALIDATE_FAIL: 'MODAL_PAYMENT_VALIDATE_FAIL',

    //Export Status
    FEEDBACK_EXPORTING: 'FEEDBACK_EXPORTING',
    FEEDBACK_EXPORT_SUCCESS: 'FEEDBACK_EXPORT_SUCCESS',
    FEEDBACK_EXPORT_ERROR: 'FEEDBACK_EXPORT_ERROR',

    //TradeRequest Records
    TR_COL_RECORD_STATUS:'Record Status',
    TR_COL_STATUS: 'Status',
    TR_COL_CHECKEDOUTUSER: 'Checked Out User',
    TR_COL_VALUEDATE: 'Value Date',
    TR_COL_CHECKEDOUTDATE: 'Checked Out Date',
    TR_COL_CURRENCYPAIR: 'Currency Pair',
    TR_COL_SYSTEMID: 'System ID',
    TR_COL_EXPORTDATE: 'Export Date',
    TR_COL_EXPORTEDBY: 'Exported By',
    TR_COL_CFEFFECTIVEDATE: 'CF Effective Date',
    TR_COL_CFNOTIONALAMOUNT1: 'CF Notional amount 1',
    TR_COL_CFLEGALENTITY: 'CF Legal entity',
    TR_COL_NOTIONAL: 'Notional',
    TR_COL_USD_NOTIONAL: 'USD Notional',
    TR_COL_EXCHANGE_RATE: 'Exchange Rate',
    TR_COL_EXCHANGE_DATE:'Exchange Date',
    TR_COL_CFPRODUCT: 'CF Product',
    TR_COL_UPDATE_DATE: 'Update_Date',
    TR_COL_UPDATED_BY: 'Updated_By',
    TR_COL_EXECUTION_DATE: 'Execution Date',
    TR_COL_REJECTION_DATE: 'Rejection Date',
    TR_COL_COMMENT_ID: 'Comment Id',
    TR_COL_USER_COMMENTS: 'User Comments',
    TR_COL_CF_EDITABLE: 'CF Editable',
    TR_COL_CF_GROUPID: 'CF Group Id',
    TR_COL_CF_EXTERNALID: 'CF External Id',
    TR_COL_BUY_SELL: 'Buy/Sell',
    TR_COL_CF_ACTION: 'CF Action',
    TR_COL_CF_CURRENCY1: 'CF Currency 1',
    TR_COL_CF_CURRENCY2: 'CF Currency 2',
    TR_COL_CF_NOTIONALCURRENCY: 'CF Notional Currency',
    TR_COL_CFNOTIONALAMOUNT2: 'CF Notional amount 2',
    TR_COL_CF_MATURITYDATE: 'CF Maturity Date',
    TR_COL_CF_LIMITRATE: 'CF Limit rate',
    TR_COL_CF_STOPRATE: 'CF Stop rate',
    TR_COL_CF_FIXING: 'CF Fixing',
    TR_COL_CF_FIXINGDATE: 'CF Fixing date',
    TR_COL_CF_MISYS_Beneficiary: 'MISYS_Beneficiary',
    TR_COL_CF_AMZNTT: 'AMZN_Trouble_Ticket',
    TR_COL_CF_AMZNSOURCE: 'AMZN_Source',
    TR_COL_CF_DESCRIPTION: 'AMZN_Description',
    TR_COL_CF_PAYREF: 'AMZN_OFA_Payment_Ref',
    TR_COL_CF_PAYDATE: 'AMZN_OFA_Payment_Date',
    TR_COL_CF_TRADEID: 'AMZN_Trade_ID',
    TR_COL_CF_SOURCEACCOUNT: 'Source Account',
    TR_COL_CF_TRADECODE: 'Trade Code',
    TR_COL_TRADE_SYSTEM: 'Trade System',
    TR_COL_EXPORT_FILE: 'Export File',
    TR_COL_CF_TRADEIDCR: 'AMZN_Trade_ID\u000D',
    TR_COL_CR: "\u000D",

    //360T new 14 headers
    TR_COL_CF_PRODUCT_FIXINGDATE1: 'CF Product fixing date 1',
    TR_COL_CF_PRODUCT_FIXINGDATE2: 'CF Product fixing date 2',
    TR_COL_CF_PRODUCT_FIXING_REFERENCE: 'CF Product fixing reference', 
    TR_COL_CF_TWOWAY_PRICING: 'CF Two-way pricing',
    TR_COL_CF_PROVIDER_LIST: 'CF Provider List',
    TR_COL_CF_PREMIUM_CURRENCY: 'CF Premium currency',
    TR_COL_CF_EXPIRY_TIME: 'CF Expiry time',
    TR_COL_CF_SPOT_RATE: 'CF Spot rate',
    TR_COL_CF_STRIKE_RATE: 'CF Strike rate', 
    TR_COL_CF_DELIVERY_DATE: 'CF Delivery date',
    TR_COL_CF_OPTION_STYLE_TYPE: 'CF Option style - type',
    TR_COL_CF_OCO_LEG: 'CF OCO Leg',
    TR_COL_CF_OFFLINE_SPOT_RATE: 'CF Offline Spot Rate',
    TR_COL_CF_OFFLINE_FORWARD_POINTS: 'CF Offline Forward Points',
    TR_COL_BLANK_STRING: "N/A",

    //Bloomberg Columns
    TR_COL_BL_INSTRUMENT: 'Instrument',
    TR_COL_BL_CURRENCYPAIR: 'CurrencyPair',
    TR_COL_BL_SIDE: 'Side',
    TR_COL_BL_TT: 'Trouble Ticket',
    TR_COL_BL_AMZNTRADEID: 'Amazon Trade ID',
    TR_COL_BL_VALUEDATE: 'ValueDate',
    TR_COL_BL_SOURCEACCOUNT: 'Source_Account',
    TR_COL_BL_CURRENCY: 'Currency',
    TR_COL_BL_AMOUNT: 'Amount',
    TR_COL_BL_BENEFICIARY: 'Beneficiary',
    TR_COL_BL_TRADECODE: 'Trade Code',
    TR_COL_BL_ACCOUNT: 'Account',
    TR_COL_BL_MARKETTYPE: 'MarketType',
    TR_COL_BL_SSIKEYWORD: 'SSI Keyword',

    //PaymentRequest Records
    PR_COL_SETTLEMENT_ID: 'Settlement ID',
    PR_COL_STATUS: 'Status',
    PR_COL_NATURE_OF_BALANCE: 'Nature of Balance',
    PR_COL_POBO: 'POBO',
    PR_COL_ROBO: 'ROBO',
    PR_COL_OCM_MAPPING: 'OCM Mapping',
    PR_COL_RECEIVING_AMOUNT: 'Receiving Amount',
    PR_COL_RECEIVING_CO: 'Receiving Co',
    PR_COL_RECEIVING_CO_NAME: 'Receiving Co Name',
    PR_COL_RECEIVING_CURRENCY: 'Receiving Currency',
    PR_COL_RECEIVING_ACCOUNT: 'Receiving Account',
    PR_COL_RECEIVING_ACCOUNT_BALANCE: 'Receiving Account Balance',
    PR_COL_SENDING_AMOUNT: 'Sending Amount',
    PR_COL_SENDING_CO: 'Sending Co',
    PR_COL_SENDING_CO_NAME: 'Sending Co Name',
    PR_COL_SENDING_CURRENCY: 'Sending Currency',
    PR_COL_SENDING_ACCOUNT: 'Sending Account',
    PR_COL_SENDING_ACCOUNT_BALANCE: 'Sending Account Balance',
    PR_COL_SETTLEMENT_USD_EQUIV: 'Settlement USD Equiv',
    PR_COL_PAYMENT_TYPE: 'Payment Type',
    PR_COL_FILE_TYPE: 'File Type',
    PR_COL_SPECIAL_WIRE_INSTRUCTION: 'Special Wire Instruction',
    PR_COL_TARGET_AMOUNT: 'Target Amount',
    PR_COL_TARGET_CURRENCY: 'Target Currency',
    PR_COL_UPLOAD_DATE: 'Upload_Date',
    PR_COL_UPLOAD_FILE: 'Upload_File',
    PR_COL_UPLOAD_USER: 'Upload_User',
    PR_COL_CREATED_BY: 'Created_By',
    PR_COL_CREATION_DATE: 'Creation_Date',
    PR_COL_WIRE_PATH: 'Wire Path',
    PR_COL_REGION: 'Region',
    PR_COL_RECEIVING_REGION: 'Receiving Region',
    PR_COL_VALUE_DATE: 'Value Date',
    PR_COL_TT_ID: 'TT ID',
    PR_COL_USER_COMMENTS: 'Comments',
    PR_COL_REVIEWER: 'Reviewer',
    PR_COL_UPDATE_BY: 'Update_By',
    PR_COL_UPDATE_DATE: 'Update_Date',
    PR_COL_APPROVED_BY: 'Approved_By',
    PR_COL_APPROVAL_DATE: 'Approval_Date',
    PR_COL_REJECTED_BY: 'Rejected_By',
    PR_COL_REJECTION_DATE: 'Rejection_Date',
    PR_COL_EXPORTED_BY: 'Exported_By',
    PR_COL_EXPORT_DATE: 'Export_Date',
    PR_COL_CLOSED_BY: 'Closed_By',
    PR_COL_CLOSE_DATE: 'Close_Date',
    PR_COL_RANGE_QUERY_PARTITION_KEY: 'RangeQueryPartitionKey',
    PR_COL_CANCELLATION_REASON: 'CancellationReason',
    PR_COL_ADDITIONAL_CANCELLATION_REASON: 'AdditionalCancellationReason',
    PR_COL_SETTLEMENT_CYCLE: 'Settlement Cycle',

    //PaymentRequest Record FX File Columns
    PR_FX_COL_TT_ID: 'TT ID',
    PR_FX_COL_TRADE_ID: 'Trade ID',
    PR_FX_COL_VALUE_DATE: 'Value Date',
    PR_FX_COL_SOURCE_ACCOUNT: 'Source Account',
    PR_FX_COL_NOTIONAL_CCY: 'Notional CCY',
    PR_FX_COL_NOTIONAL: 'Notional',
    PR_FX_COL_BENEFICIARY: 'Beneficiary',
    PR_FX_COL_TRADE_CODE: 'Trade Code',
    PR_FX_COL_TRADE_DETAILS: 'Trade Details',

    //PaymentRequest Record Wire File Columns
    PR_WIRE_COL_1: 'Action',
    PR_WIRE_COL_2: '2',
    PR_WIRE_COL_3: '3',
    PR_WIRE_COL_4: '4',
    PR_WIRE_COL_5: '5',
    PR_WIRE_COL_6: '6.00',
    PR_WIRE_COL_7: '7',
    PR_WIRE_COL_8: '8',
    PR_WIRE_COL_9: '9',
    PR_WIRE_COL_10: '10',
    PR_WIRE_COL_11: '11',
    PR_WIRE_COL_12: '12',
    PR_WIRE_COL_13: '13.00',
    PR_WIRE_COL_14: '14-Free-text',
    PR_WIRE_COL_15: '15-Free-Text',
    PR_WIRE_COL_16: '16',
    PR_WIRE_COL_17: '17',
    PR_WIRE_COL_18: '18',
    PR_WIRE_COL_19: '19',

    PR_WIRE_FIXED_ROW2: {
        'Action': 'Action',
        '2': 'Payment-Date-(MM/DD/YYYY)',
        '3': 'Transaction-Code',
        '4': 'Debit-Account',
        '5': 'Credit-Account',
        '6.00': 'Receiving Amount',
        '7': ' Receiving Currency ',
        '8': ' Reason-1 ',
        '9': ' Reason-2 ',
        '10': 'Reason-3',
        '11': 'Vlookup invoice numberReason-4',
        '12': 'NO TEXT HERE',
        '13.00': 'Budget-Code',
        '14-Free-text': 'FreeText-1',
        '15-Free-Text': 'FreeText-2',
        '16': 'FreeText-3',
        '17': 'Correspondent-BIC',
        '18': 'Correspondant-Bank-NameS',
        '19': 'Correspondant-Country-code-(ISO-code)'
    },

    //Kyriba Transaction Code
    KT_CODE_ICBK: 'ICBK',
    KT_CODE_ICLW: 'ICLW',
    KT_CODE_ICDT: 'ICDT',
    KT_CODE_ICCB: 'ICCB',
    KT_CODE_ICFX: 'ICFX',

    //E&I Module Attributes not Related to a Field
    EI_ATTR_NEW_COMMENT: 'newComment',

    //ExceptionInvestigation Records
    EI_COL_RECORD_STATUS:'Record Status',
    EI_COL_SYSTEM_ID: 'System ID',
    EI_COL_TRADE_ID: 'Trade ID',
    EI_COL_TRADE_DATE: 'Trade Date',
    EI_COL_VALUE_DATE: 'Value Date',
    EI_COL_COUNTERPARTY: 'Counterparty',
    EI_COL_TRADE_CATEGORY: 'Trade Category',
    EI_COL_TRADER: 'Trader',
    EI_COL_SOLD_CCY: 'Sold CCY',
    EI_COL_SOLD_AMOUNT: 'Sold Amount',
    EI_COL_BOUGHT_CCY: 'Bought CCY',
    EI_COL_BOUGHT_AMOUNT: 'Bought Amount',
    EI_COL_USD_EQUIVALENT: 'USD Equivalent',
    EI_COL_SPOT_RATE: 'Spot Rate',
    EI_COL_CCY_PAIR: 'CCY Pair',
    EI_COL_TARGET_CCY: 'Target CCY',
    EI_COL_EXCEPTION_CCY: 'Exception CCY',
    EI_COL_REFERENCE: 'Reference',
    EI_COL_TROUBLE_TICKET: 'Trouble Ticket',
    EI_COL_TRADE_ORIGIN: 'Trade Origin',
    EI_COL_SOURCE_ACCOUNT_CODE: 'Source Account Code',
    EI_COL_SOURCE_ACCOUNT_COMPANY_CODE: 'Source Account Company Code',
    EI_COL_SOURCE_ACCOUNT_NAME: 'Source Account Name',
    EI_COL_SOURCE_ACCOUNT_BANK_BRANCH: 'Source Account Bank Branch',
    EI_COL_SOURCE_ACCOUNT_NUMBER: 'Source Account Number',
    EI_COL_DEBIT_DATE: 'Debit Date',
    EI_COL_DEBIT_DESCRIPTION: 'Debit Description',
    EI_COL_BENEFICIARY_ACCOUNT_CODE: 'Beneficiary Account Code',
    EI_COL_MISYS_ID: 'MISYS ID',
    EI_COL_BENEFICIARY_ACCOUNT_NAME: 'Beneficiary Account Name',
    EI_COL_BENEFICIARY_BIC: 'Beneficiary BIC',
    EI_COL_BENEFICIARY_INTERMEDIARY_BIC: 'Beneficiary Intermediary BIC',
    EI_COL_BENEFICIARY_ACCOUNT_NUMBER: 'Beneficiary Account Number',
    EI_COL_MISYS_ID_INSTRUCTED: 'MISYS ID Instructed',
    EI_COL_MISYS_ID_RECONCILED: 'MISYS ID Reconciled',
    EI_COL_CREDIT_DATE: 'Credit Date',
    EI_COL_CREDIT_DESCRIPTION: 'Credit Description',
    EI_COL_INQUIRY_DATE: 'Inquiry Date',
    EI_COL_EXCEPTION_OWNER: 'Exception Owner',
    EI_COL_EXCEPTION_CATEGORY: 'Exception Category',
    EI_COL_EXCEPTION_SUBCATEGORY: 'Exception Sub-category',
    EI_COL_COMMENTS: 'User Comments',
    EI_COL_LATEST_COMMENT: 'Latest Comment',
    EI_COL_COMMENT_ID: 'Comment Id',
    EI_COL_RELATED_TTS: 'Related TT',
    EI_COL_RESOLUTION_DATE: 'Resolution Date',
    EI_COL_EXCEPTION_COST_USD: 'Exception Cost $',
    EI_COL_ROOT_CAUSE: 'Root Cause',
    EI_COL_EXCEPTION_STATUS: 'Exception Status',
    EI_COL_CR: "\u000D",
    EI_COL_UPDATE_DATE: 'Update_Date',
    EI_COL_UPDATED_BY: 'Updated_By',
    EI_COL_UPDATE_DATE_CLEANED: 'Update Date',
    EI_COL_UPDATED_BY_CLEANED: 'Updated By',
    EI_COL_EXECUTION_DATE: 'Execution Date',
    EI_COL_REJECTION_DATE: 'Rejection Date',
    EI_PENDING_STATUS: 'Pending',
    EI_RESOLVED_STATUS: 'Resolved',
    EI_CATEGORY_LIST: ['Counterparty Non-Receipt', 'Beneficiary Non-Receipt', 'Counterparty Inquiry',
        'Beneficiary Inquiry', 'AP Inquiry', 'Trade Reversal', 'Other'],
    EI_SUBCATEGORY_LISTS: {
        undefined: [],
        '' : [],
        'Counterparty Non-Receipt': [
             'Incorrect/Outdated FX SSIs',
             'Counterparty Error',
             'Source Account Closure',
             'Source Account Insufficient Balance',
             'System/Technical Issue',
             'TBO/CashOps Operational Issue',
             'TBO/CashOps System Access Issue',
             'MO Operational Issue',
             'T&I Operational Issue',
             'Other'],
         'Beneficiary Non-Receipt': [
             'Counterparty Error',
             'Credited in Multiple Amounts',
             'FinOps Operational Issue',
             'Incorrect/Outdated Beneficiary Details',
             'Incorrect Finastra CI Field 72',
             'Incorrect SSIs Applied',
             'Vendor No Response',
             'Pending Documentation',
             'T&I Operational Issue',
             'MO Operational Issue',
             'Other'],
         'Counterparty Inquiry': [
             'Invoice',
             'Address',
             'Callback',
             'Other Additional Account Information',
             'Business Relationship',
             'Beneficiary Name',
             'Compliance Issue',
             'Counterparty Error',
             'Payment Purpose',
             'Other'],
         'Beneficiary Inquiry':[],
         'AP Inquiry': [
            'Debit not Found',
            'Different Credit Amount',
            'Different Debit Amount',
            'Request for SWIFT'
         ],
         'Trade Reversal':[],
         'Other':[]
    },

//USER Group
    USER_GROUP_APPROVER: 'Approver',
    USER_GROUP_CASH_POSITIONER: 'TTECH-TRADE-CASH-POSITIONER-ACCESS',
    USER_GROUP_CREATOR: 'Creator',
    USER_GROUP_DEVELOPER: 'trs-preprod-admin-access',
    USER_GROUP_INTERCOMPANY: 'TTECH-TRADE-INTERCOMPANY-ACCESS',
    USER_GROUP_TBO_PAYMENT_AUTHORIZORS: 'tbo-trs-payment-authorizers',
    USER_GROUP_MO:'TSYMiddleOffice',
    USER_GROUP_NAME:'groups',
    USER_GROUP_PROD_ADMIN: 'TTECH-TRADE-PROD-ADMIN-ACCESS',
    USER_GROUP_REVIEWER: 'TTECH-TRADE-REVIEWER-ACCESS',
    USER_GROUP_SETTLEMENT_HISTORICAL: 'TTECH-SETTLEMENT-HISTORICAL-GENERAL-ACCESS',
    USER_GROUP_IC_SETTLEMENT: 'ic-settlement',
    USER_GROUP_EXCEPTION_VIEW_ACCESS: 'trs-exception-view-access',

    // MO Users
    EI_MO_USERS: ['Bohan Shao (@bohansha)', 'Erik Sobczak (@erikpsob)', 'Lilian Orozco (@seleneor)',
        'Tanvi Jain (@jaiqtanv)', 'Yihan Liu (@liuam)', 'Breanna Ocean (@anocean)'],


    //TRade system
    TRADE_SYSTEM_360T: 'SYSTEM_360T',
    TRADE_SYSTEM_BLOOMBERG: 'SYSTEM_Bloomberg',
    TRADE_SYSTEM_MANUAL: 'SYSTEM_Manual',

    //Index String
    INDEX_STRING_STATUSREVIEW: 'StatusReview',

    INDEX_STRING_PENDING_PAYMENT_REVIEW: 'PendingPaymentStatusReview',
    INDEX_STRING_APPROVED_PAYMENT_REVIEW: 'ApprovedPaymentStatusReview',
    INDEX_STRING_HISTORIC_PAYMENT_REVIEW: 'PaymentHistoryReview',
    INDEX_STRING_REGION_MAPPING : 'PaymentRegionMapping',
    INDEX_STRING_EXCEPTION_STATUS_REVIEW: 'ExceptionStatusReview',

    INDEX_STRING_HISTORICAL_TRADE_BY_EXECUTION_DATE : "HistoryReviewByExecutionDate",
    INDEX_STRING_HISTORICAL_TRADE_BY_EXPORT_DATE : "HistoryReviewByExportDate",
    INDEX_STRING_PAYMENT_DUPLICATE_RELATIONSHIP: "paymentDuplicateRelationship",
    INDEX_STRING_APPROVED_PAYMENTS_DUPLICATE_RELATIONSHIP: "approvedPaymentDuplicateRelationship",
    INDEX_STRING_EXCEPTION_BY_TRADE_DATE : "ExceptionTradeDateRangeReview",
    INDEX_STRING_EXCEPTION_BY_INQUIRY_DATE : "ExceptionInquiryDateRangeReview",
    INDEX_STRING_EXCEPTION_MAPPING_BY_TRADE_ID : "ExceptionsByTradeIDMappingReview",

    //Query Kyriba API Param
    QUERY_STRING_PARAM_TYPE_KYRIBA_BANK_ACCOUNT : "kyribaBankAccount",
    QUERY_STRING_PARAM_TYPE_KYRIBA_CASH_BALANCE : "kyribaCashBalance",
    QUERY_STRING_PARAM_TYPE_KYRIBA_MISYS_MAPPING: "kyribaMisysMapping",
    QUERY_STRING_PARAM_TYPE_KYRIBA_CURRENCY: "kyribaCurrencyDecimalDigits",

    //Query General TRS Data API Param
    QUERY_STRING_PARAM_TYPE_GENERAL_TRS_FX_REQUIRING_CURRENCY : "RequiringCurrency",
    QUERY_STRING_PARAM_TYPE_GENERAL_TRS_FX_HOLIDAY_CALENDAR : "HolidayCalendar",
    QUERY_STRING_PARAM_TYPE_GENERAL_TRS_FX_WEEKEND_CALENDAR : "WeekendCalendar",
    QUERY_STRING_PARAM_TYPE_GENERAL_TRS_BLOOMBERG_ACCOUNTS : "BloombergAccounts",

    //Query DocumentData Pamra
    QUERY_STRING_PARAM_TYPE_DOCUMENT_ID_MAPPING : "allIdDocumentIdMapping",

    //Record status
    RECORD_STATUS_CHECKEDOUT: 'Checked Out',
    RECORD_STATUS_APPROVED: 'Approved',
    RECORD_STATUS_EXPORTED: 'Exported',
    RECORD_STATUS_EXECUTED: 'Executed',
    RECORD_STATUS_EXPORT_ERROR: 'Export Error',
    RECORD_STATUS_SENT_TO_360T: 'Sent To 360T',
    RECORD_STATUS_REJECTED_TRADE: 'Rejected for Trade',
    RECORD_STATUS_ERROR1: 'Error1',
    RECORD_STATUS_ERROR2: 'Error2',

    //PaymentStatus
    PAYMENT_STATUS_OPEN: 'Open',
    PAYMENT_STATUS_APPROVED: 'Approved',
    PAYMENT_STATUS_SENTFORAPPROVAL: 'Sent for Approval',
    PAYMENT_STATUS_REJECTED: 'Rejected',
    PAYMENT_STATUS_EXPORTED: 'Exported',
    PAYMENT_STATUS_CLOSED: 'Closed',

    //Payment Type
    PAYMENT_TYPE_LIST: ['Wire', 'FX', 'Cancelled', 'Bank Portal Wire', 'Sub-Executed', 'Settled Early', 'Hold'],
    PAYMENT_TYPE_LIST_BATCH_UPDATE: ['Cancelled', 'Settled Early'],
    //Cancellation Reason
    CANCELLATION_REASON_LIST: ['Liquidity Reason', 'No Bank Account', 'Other'],

    //EXPORT 360 VALUES
    EXPORT_TR_COL_BL_CFPRODUCT: 'Spot',
    EXPORT_TR_COL_360_CFPRODUCT: 'Forward',
    EXPORT_TR_COL_MARKETTYPE: 'ONSHORE',
    EXPORT_TR_COL_CFLEGALENTITY: 'Amazon.com',
    EXPORT_TR_COL_CFLEDITABLE: 'TRUE',
    SIDE_BUY: 'Buy',
    SIDE_SELL: 'Sell',

    MOMENT_PST_TIMEZONE: 'America/Los_Angeles',
    MOMENT_PST_DATEFORMATNOTIME: 'YYYY-MM-DD',
    MOMENT_PST_DATEFORMAT: 'YYYY-MM-DD HH:mm:ss.S',
    MOMENT_PST_DATEFORMAT_NO_MS: 'YYYY-MM-DD HH:mm',
    MOMENT_PST_FILE_DATEFORMAT: 'YYYY-MM-DD_HH_mm_ssS',

    TBO_IC_SETTLEMENT_RULE_VALIDATION_SUCCESS: 'Success',
    //TBO Settlement Validation Rules
    UPDATE_VALIDATION_RULE_TO_MESSAGES_MAP : {
        50 : 'If payment type is Wire and Bank is DB, SendingCCY should match TargetCCY',
        51 : 'If payment type is FX,  SendingCCY should not match ReceivingCCY',
        52 : 'TargetCCY should match either SendingCCY or ReceivingCCY',
        'EMPTY_REJECTION_REASON': 'Cancellation reason cannot be empty.',
        'EMPTY_ADDITIONAL_REJECTION_REASON': 'Additional Update Details cannot be empty.'
    },

    /** DaysOfWeek To num day in a week defined in JS date object map
     * An integer number, between 0 and 6, corresponding to the day of the week for the given date,
     * according to local time: 0 for Sunday, 1 for Monday, 2 for Tuesday, and so on.
     */
    DAY_DISPLAY_NAME_TO_DAY_IN_A_WEEK_INDEX_MAP  : {
        'SUNDAY' : 0,
        'MONDAY' : 1,
        'TUESDAY' : 2,
        'WEDNESDAY' : 3,
        'THURSDAY' : 4,
        'FRIDAY' : 5,
        'SATURDAY' : 6
    },

    UPDATE_ERROR_TEXT_PREFIX: "One or more items failed to update: ",
    UPDATE_ERROR_TEXT_POSTFIX_CONDITIONAL: "\n. Items not mentioned above were processed successfully." +
        " Failed item(s) was edited by others and your browser contains stale data. " +
        " Please refresh your browser.  Once refreshed, REVIEW the item(s) and submit again.",
    //Single Trade Request Items
    STR_TRADE_TYPE_LIST: ['InterCompany', 'Positioning', 'Equity', 'M and A', 'Subs', 'AP'],
    STR_BUY_OR_SELL_LIST: ['Buy', 'Sell'],
    STR_VD_OPTION_LIST: ['Spot', 'Tom', 'Other'],
    TEMPLATE_REQUESTER_GROUP_ACCESS_MAPPING: {
        "InterCompany": ["TTECH-TRADE-INTERCOMPANY-ACCESS", "trs-preprod-admin-access", "TTECH-TRADE-REVIEWER-ACCESS", "TTECH-TRADE-PROD-ADMIN-ACCESS"
        , "apac-south-trs-requester", "apac-north-trs-requester", "emea-trs-trade-requester"],
        "Positioning": ["tbo-trs-trade-requester", "apac-south-trs-requester", "apac-north-trs-requester", "emea-trs-trade-requester", "TTECH-TRADE-PROD-ADMIN-ACCESS"],
        "Equity": ["tbo-trs-trade-requester", "apac-south-trs-requester", "apac-north-trs-requester", "emea-trs-trade-requester", "TTECH-TRADE-PROD-ADMIN-ACCESS"],
        "M and A": ["tbo-trs-trade-requester", "apac-south-trs-requester", "apac-north-trs-requester", "emea-trs-trade-requester", "TTECH-TRADE-PROD-ADMIN-ACCESS"],
        "Subs": ["TTECH-TRADE-SUBS-ACCESS", "TTECH-TRADE-PROD-ADMIN-ACCESS"],
        "AP": ["TTECH-TRADE-AP-ACCESS", "TTECH-TRADE-PROD-ADMIN-ACCESS"]
    },

    //Defines the users and the owners of the embedded Quicksight Dashboards.
    QUICKSIGHT_DASHBOARD_MAPPING: {
        USER: {
            FX_TEAM: 'FX',
            MO_TEAM: 'MO'
        },
        OWNER: {
            TRS: 'TRS',
            TCBI: 'TCBI'
        }
    },

    // Defines the keys that get returned as a result of invoking the /quicksightDashboard/{owner} call.
    QUICKSIGHT_DASHBOARD_RESPONSE_KEYS: {
        URL: 'URL',
        REGISTERED: 'Registered',
        STATUS: 'status'
    },

    //Modal status unique to Single Trade Request Form
    FEEDBACK_VALIDATION_NOT_TRIGGERED: 'FEEDBACK_VALIDATION_NOT_TRIGGERED',
    FEEDBACK_VALIDATION_START: 'FEEDBACK_VALIDATION_START',
    FEEDBACK_VALIDATION_STATUS_SUCCESS: 'FEEDBACK_VALIDATION_STATUS_SUCCESS',
    FEEDBACK_VALIDATION_STATUS_SUCCESS_TRADE_ERROR: 'FEEDBACK_VALIDATION_STATUS_SUCCESS_TRADE_ERROR',
    FEEDBACK_VALIDATION_STATUS_ERROR: 'FEEDBACK_VALIDATION_STATUS_ERROR',
    STR_STATUS_UPLOAD_LOADING: 'STR_STATUS_LOADING',
    STR_STATUS_UPLOAD_DONE: 'STR_STATUS_UPLOAD_DONE',
    FEEDBACK_QUERY_ACCOUNT_LOADING: 'FEEDBACK_QUERY_ACCOUNT_LOADING',
    FEEDBACK_QUERY_ACCOUNT_SUCCESS: 'FEEDBACK_QUERY_ACCOUNT_SUCCESS',
    FEEDBACK_QUERY_ACCOUNT_ERROR: 'FEEDBACK_QUERY_ACCOUNT_ERROR',

    FEEDBACK_STAGING_SUCCESS: 'FEEDBACK_STAGING_SUCCESS',

    FEEDBACK_UI_VALIDATION_INPUT_ERROR: 'FEEDBACK_UI_VALIDATION_INPUT_ERROR',
    FEEDBACK_UI_VALIDATION_REQUIRED_EMPTY: 'FEEDBACK_UI_VALIDATION_REQUIRED_EMPTY',

    FEEDBACK_UPLOAD_NOT_TRIGGERED: 'FEEDBACK_UPLOADING_NOT_TRIGGERED',
    FEEDBACK_UPLOADING_START: 'FEEDBACK_UPLOADING_START',
    FEEDBACK_UPLOAD_SUCCESS: 'FEEDBACK_UPLOAD_SUCCESS',
    FEEDBACK_UPLOAD_ERROR: 'FEEDBACK_UPLOAD_ERROR',
    FEEDBACK_UI_UPLOAD_REQUIRED_EMPTY: 'FEEDBACK_UI_UPLOAD_REQUIRED_EMPTY',
    FEEDBACK_APPROVAL_NOT_TRIGGERED: 'FEEDBACK_APPROVAL_NOT_TRIGGERED',
    FEEDBACK_APPROVAL_START: 'FEEDBACK_APPROVAL_START',
    FEEDBACK_APPROVAL_SUCCESS: 'FEEDBACK_APPROVAL_SUCCESS',
    FEEDBACK_APPROVAL_ERROR: 'FEEDBACK_APPROVAL_ERROR',
    FEEDBACK_UPLOAD_TRACKING_IN_PROGRESS: 'FEEDBACK_UPLOAD_TRACKING_IN_PROGRESS',
    FEEDBACK_UPLOAD_MAPPING_FILE_IN_PROGRESS: 'FEEDBACK_UPLOAD_MAPPING_FILE_IN_PROGRESS',
    FEEDBACK_UPLOAD_TRACKING_SUCCESS: 'FEEDBACK_UPLOAD_TRACKING_SUCCESS',
    FEEDBACK_UPLOAD_TRACKING_ERROR: 'FEEDBACK_UPLOAD_TRACKING_ERROR',
    FEEDBACK_UPLOAD_TRACKING_NOT_TRIGGERED: 'FEEDBACK_UPLOAD_TRACKING_NOT_TRIGGERED',
    FEEDBACK_UPLOAD_MAPPING_FILE_SUCCESS: 'FEEDBACK_UPLOAD_MAPPING_FILE_SUCCESS',

    // Defines the delimiter used when separating related TTs.
    RELATED_TT_DELIMITER: ';',

    // Blank target defined to open a new tab when a user clicks on an <a> element.
    BLANK_TARGET: '_blank',

    // The referrer information will be hidden when noreferrer is used as the rel property within an <a> element.
    NO_REFERRER: 'noreferrer',

    // Defines the prop passed into the exception component to determine whether or not the All Trades or the Exception module should be rendered.
    ALL_TRADES_MODULE: 'AllTrades',
    EXCEPTIONS_MODULE: 'Exceptions',

    // Defines different export types necessary for IC Payments 
    EXPORT_AUTO: 'AUTO',
    EXPORT_MANUAL: 'MANUAL',

    // Empty string.
    EMPTY_STRING: '',

    // HTTP Operation Constants.
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',

});

export default CONSTANT;

//Creating a new IC_SETTLEMENTS_LDAP constant as they are based on constants from CONSTANT.
export const IC_SETTLEMENTS_LDAP = Object.freeze(
    [
        CONSTANT.USER_GROUP_CASH_POSITIONER,
        CONSTANT.USER_GROUP_INTERCOMPANY,
        CONSTANT.USER_GROUP_SETTLEMENT_HISTORICAL,
    ]
);

// List of possible E&I Exception Statuses
export const EI_STATUS_LIST = Object.freeze(
    [
        CONSTANT.EI_PENDING_STATUS,
        CONSTANT.EI_RESOLVED_STATUS
    ]
);

// List of fields required in E&I Documentation Modal
export const REQUIRED_E_AND_I_FIELDS = Object.freeze(
    [
        CONSTANT.EI_COL_EXCEPTION_STATUS,
        CONSTANT.EI_COL_INQUIRY_DATE,
        CONSTANT.EI_COL_EXCEPTION_OWNER,
        CONSTANT.EI_COL_EXCEPTION_CATEGORY,
    ]
);

export const TEMP_ATTACHING_DOCUMENT_BUCKET_PREFIX =  "trs-temp-attaching-documents-";
export const PERM_ATTACHING_DOCUMENT_BUCKET_PREFIX =  "trs-perm-attaching-documents-";