import React, { FunctionComponent } from 'react';
import config from '../config/config.json'
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter } from '../TRSController';
import { BannerProps } from '../model/Model';
import { ButtonDropdown } from '@amzn/awsui-components-react';
import "../style/header.css"

/**
 * A functional component that defines the banner of TRS. It takes in props shaped as BannerProps
 * and returns a banner that contains the environment the user is in, a Report a Bug button, and
 * their phonetool profile picture.
 * 
 * @param {BannerProps} props
 *      Props that take the shape of BannerProps.
 * @returns {JSX.Element}
 *      A JSX Element representing the banner that contains the environment the user is in,
 *      a Report a Bug button, and their phonetool profile picture.
 */
const Banner: FunctionComponent<BannerProps> = (props: BannerProps): JSX.Element => {
    const navigate = useNavigate();
    const bannerText = capitalizeFirstLetter(props.stage);
    return (
        <ul className={(bannerText === 'Prod'? "menu-list-prod" : "menu-list-other")}>
            <li>
                <div className={(bannerText === 'Prod'? "stage-text-prod" : "stage-text-other")}
                    onClick={() => {navigate('/')}}>
                    {bannerText}
                </div>
            </li>
            <li>
                <ButtonDropdown
                    className="call-dropdown"
                    items={[
                        {
                            id: "bug-report",
                            text: "Report a Production Issue",
                            href: config.ticketTemplateURL.reportIssue,
                            external: true
                        },
                        {
                            id: "enhancement-request",
                            text: "Request an Enhancement",
                            href: config.ticketTemplateURL.featureRequest,
                            external: true
                        }
                    ]}
                    variant="normal"
                >
                    Contact Us
                </ButtonDropdown >
            </li>
            <li>
                <div className="badge-photo">
                    <img src={props.photoUrl} alt={"No Image"}/>
                </div>
            </li>
        </ul>
    );
}

export default Banner;
