import React, { FunctionComponent } from 'react';

/**
 * A simple React fragment that indicates to the user that they are
 * not in a valid LDAP group.
 * 
 * @returns {JSX.Element}
 */
const TRSRestrict: FunctionComponent = (): JSX.Element => {
    return <>This website is restricted to the TRS LDAP group only.</>
};

export default TRSRestrict;