import CONSTANT from "./constant";
import FormatData from "./formatData";
import Logger from "./logger";

/**
 * Helper class for handling the logic of updating payments (based on specific business rules)
 */
class paymentUpdateUtil {

    /**
     * Update the payment status based on specific rules related to payment type (https://sim.amazon.com/issues/FT-LYRA-3454)
     * TODO: We should see if we can simplify/remove these specific rules
     * @param previousPaymentState the selected payment state, prior to any updates being applied
     * @param paymentStateToUpdate the selected payment state to apply override to; may already have updates applied before method call
     * @param updateWindowState the state of the update process
     * @param updatedBy the user who is performing the update
     */
    static overridePaymentUpdateForPaymentTypeChange = (previousPaymentState, paymentStateToUpdate, updateWindowState, updatedBy) => {

        // Current timestamp string
        let currentUTCTimeString = FormatData.formateNewUTCCurrentTime();
        // Flag for if the payment type changed
        let paymentTypeChanged = previousPaymentState[CONSTANT.PR_COL_PAYMENT_TYPE] !== updateWindowState.inputPaymentType;

        if (paymentTypeChanged) {
            if (updateWindowState.inputPaymentType === 'Cancelled') {
                // When paymentType is updated to 'Cancelled', Set the status to REJECTED.
                paymentStateToUpdate[CONSTANT.PR_COL_STATUS] = CONSTANT.PAYMENT_STATUS_REJECTED;
                paymentStateToUpdate[CONSTANT.PR_COL_REJECTION_DATE] = currentUTCTimeString;
                paymentStateToUpdate[CONSTANT.PR_COL_REJECTED_BY] = updatedBy;

                // Append rejection comment to existing payment comments
                let commentData = paymentStateToUpdate[CONSTANT.PR_COL_USER_COMMENTS] ?
                    JSON.parse(paymentStateToUpdate[CONSTANT.PR_COL_USER_COMMENTS]) : [];
                let rejectionComment = (updateWindowState.inputCancellationReason === 'Other') ?
                    updateWindowState.inputAdditionalCancellationReason : updateWindowState.inputCancellationReason
                let rejectionMessageCommentObject = {};
                rejectionMessageCommentObject["Comment"] = rejectionComment;
                rejectionMessageCommentObject["User"] = updatedBy;
                rejectionMessageCommentObject["CommentDate"] = currentUTCTimeString;
                commentData.push(rejectionMessageCommentObject);
                Logger.logInfo("commentData: " + JSON.stringify(commentData));
                paymentStateToUpdate[CONSTANT.PR_COL_USER_COMMENTS] = JSON.stringify(commentData);
            } else if (
                updateWindowState.inputPaymentType === 'Bank Portal Wire' ||
                updateWindowState.inputPaymentType === 'Sub-Executed' ||
                updateWindowState.inputPaymentType === 'Settled Early'
            ) {
                // When paymentType is updated to 'Bank Portal Wire', 'Sub-Executed' or 'Settled Early', Set the status to CLOSED.
                paymentStateToUpdate[CONSTANT.PR_COL_STATUS] = CONSTANT.PAYMENT_STATUS_CLOSED;
                paymentStateToUpdate[CONSTANT.PR_COL_CLOSE_DATE] = currentUTCTimeString;
                paymentStateToUpdate[CONSTANT.PR_COL_CLOSED_BY] = updatedBy;
            } else if ((
                previousPaymentState[CONSTANT.PR_COL_STATUS] === CONSTANT.PAYMENT_STATUS_REJECTED ||
                previousPaymentState[CONSTANT.PR_COL_STATUS] === CONSTANT.PAYMENT_STATUS_CLOSED ||
                previousPaymentState[CONSTANT.PR_COL_STATUS] === CONSTANT.RECORD_STATUS_EXECUTED
            ) && (
                updateWindowState.inputPaymentType === 'Wire' ||
                updateWindowState.inputPaymentType === 'FX'
            )) {
                // When paymentType of historical payment is updated to 'FX' or 'Wire', Set the status to APPROVED or OPEN
                // A historical payment is one with status 'Rejected', 'Closed', or 'Executed
                if (previousPaymentState[CONSTANT.PR_COL_PAYMENT_TYPE] === 'Bank Portal Wire' ||
                    previousPaymentState[CONSTANT.PR_COL_PAYMENT_TYPE] === 'Wire' ||
                    previousPaymentState[CONSTANT.PR_COL_PAYMENT_TYPE] === 'FX'
                ) {
                    paymentStateToUpdate[CONSTANT.PR_COL_STATUS] = CONSTANT.PAYMENT_STATUS_APPROVED;
                }
                if (previousPaymentState[CONSTANT.PR_COL_PAYMENT_TYPE] === 'Cancelled') {
                    paymentStateToUpdate[CONSTANT.PR_COL_STATUS] = CONSTANT.PAYMENT_STATUS_OPEN;
                }
            }
        }
        paymentStateToUpdate[CONSTANT.PR_COL_PAYMENT_TYPE] = updateWindowState.inputPaymentType;
    }
}

export default paymentUpdateUtil;