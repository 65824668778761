import React, {Component} from "react";
import {
    Box,
    Button,
    Modal,
    SpaceBetween,
} from "@amzn/awsui-components-react";
import '@amzn/awsui-global-styles/polaris.css';

// UI Modal for the Download Payment Window.
class DownloadPaymentModal extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <Modal
            visible={this.props.downloadWindowVisible}
            header="Select file format for settlement download"
            expandToFit={true}
            onDismiss={this.props.handleDismissClick}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="primary"
                                onClick={() => this.props.handleConfirmDownloadClick('xlsx', this.props.ifDownloadAll)}>xlsx</Button>
                        <Button variant="primary"
                                onClick={() => this.props.handleConfirmDownloadClick('csv', this.props.ifDownloadAll)}>csv</Button>
                    </SpaceBetween>
                </Box>}>
        </Modal>;
    }
}

export default DownloadPaymentModal;